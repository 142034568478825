import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';

// Shared components
import SearchInput from 'components/SearchInput';

// Shared services
import { updateOrganization } from 'store/organization/actionsCreator';

// Common
import * as roles from 'common/roles';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class ZonesToolbar extends Component {
  handleDeleteZones = async () => {
    const { organization, selectedZones, onSelect } = this.props;

    if (organization.zones == null) return;

    // ignore no-loop-func error, because we don't call asynchronous function here
    for (let i = 0; i < selectedZones.length; i++) {
      var id = selectedZones[i];

      // eslint-disable-next-line 
      if (organization.zones.findIndex(el => el._id === id) !== -1) {
        // eslint-disable-next-line 
        organization.zones.splice(organization.zones.findIndex(el => el._id === id), 1);
      }
    }

    await this.props.updateOrganization(
      this.props.organization._id,
      organization,
      error => {
        if (error) {
          this.setState({
            submitError: error
          });
        } else {
          onSelect([]);
        }
      }
    );
  };

  render() {
    const {
      classes,
      className,
      openAddZoneDialog,
      role,
      selectedZones,
      strings
    } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            onChange={this.props.onFilterChange}
            placeholder={strings.search}
          />
          <span className={classes.spacer} />
          {selectedZones.length > 0 && (
            <Button
              className={classes.deleteButton}
              color="default"
              onClick={this.handleDeleteZones}
              size="small"
              variant="contained"
            >
              <DeleteIcon className={classes.deleteIcon} />{' '}
              {strings.common.delete}
            </Button>
          )}
          {role === roles.Admin && (
            <Button
              className={classes.addButton}
              color="primary"
              onClick={openAddZoneDialog}
              size="small"
              variant="contained"
            >
              {strings.addZone}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ZonesToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func,
  openAddZoneDialog: PropTypes.func.isRequired,
  selectedZones: PropTypes.array,
  strings: PropTypes.object.isRequired
};

ZonesToolbar.defaultProps = {
  onFilterChange: () => {},
  onSelect: () => {},
  selectedZones: []
};

const mapStateToProps = state => {
  return {
    role: state.authorize.role,
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.organization.loading,
    organization: state.organization.organization
  };
};

const mapDispatchToProps = {
  updateOrganization
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('ZonesToolbar'),
  withStyles(styles)
)(ZonesToolbar);
