import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared components
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';

// Material icons
import Add from '@material-ui/icons/Add';

// Shared services
import { addBikeType } from 'store/bikeType/actionsCreator';
import { fetchPricingPlans } from 'store/pricingPlan/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

class AddBikeType extends Component {
  signal = true;

  state = {
    values: {
      name: { fr: '', en: '', nl: '' },
      description: { fr: '', en: '', nl: '' },
      pricingPlan: ''
    },
    touched: {
      name: { fr: false, en: false, nl: false },
      pricingPlan: false
    },
    errors: {
      name: { fr: null, en: null, nl: null },
      pricingPlan: null
    },
    isValid: false,
    submitError: null
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchPricingPlans();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchPricingPlans();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors.name.fr = null;
    newState.errors.name.en = null;
    newState.errors.name.nl = null;
    newState.errors.pricingPlan = null;
    newState.isValid = errors ? false : true;
    
    if(errors) {
      Object.keys(errors).forEach(function(key, index) {
        var field = key.split('.');

        if(Array.isArray(field) && field.length === 2) {
          newState.errors[field[0]][field[1]] = errors[key];
        } else {
          newState.errors[field] = errors[key];
        }
      });
    }

    this.setState(newState);
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    if(Array.isArray(field) && field.length === 2) {
      if (newState.touched[field[0]])
        newState.touched[field[0]][field[1]] = true;
      if (newState.values[field[0]])
        newState.values[field[0]][field[1]] = value;
    } else {
      newState.touched[field] = true;
      newState.values[field] = value;
    }

    this.setState(newState, this.validateForm);
  };

  handleAdd = async () => {
    const { values } = this.state;

    this.props.addBikeType(values, error => {
      if (error) {
        this.setState({
          submitError: error
        });
      } else {
        let values = { ...this.state.values };
        values.name.fr = '';
        values.name.en = '';
        values.name.nl = '';
        values.description.fr = '';
        values.description.en = '';
        values.description.nl = '';
        values.pricingPlan = '';
        let touched = { ...this.state.touched };
        touched.name.fr = false;
        touched.name.en = false;
        touched.name.nl = false;
        touched.pricingPlan = false;
        let errors = { ...this.state.errors };
        errors.name.fr = null;
        errors.name.en = null;
        errors.name.nl = null;
        errors.pricingPlan = null;

        this.setState({
          values,
          touched,
          errors,
          isValid: false,
          submitError: null
        });
      }
    });
  };

  render() {
    const { classes, isPricingPlanLoading, className, openAddPricingPlanDialog, pricingPlans, strings } = this.props;

    const { values, touched, errors, isValid, submitError } = this.state;

    const rootClassName = classNames(classes.root, className);

    const showNameFrError = touched.name.fr && errors.name.fr;
    const showNameEnError = touched.name.en && errors.name.en;
    const showNameNlError = touched.name.nl && errors.name.nl;
    const showPricingPlanError = touched.pricingPlan && errors.pricingPlan;

    return (
      <Portlet className={rootClassName}>
        <PortletHeader>
          <PortletLabel title={strings.title} />
        </PortletHeader>
        <PortletContent>
          <form autoComplete="off" className={classes.form}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  error={showNameFrError ? true : false}
                  helperText={showNameFrError ? strings.name.fr.error : ''}
                  label={strings.name.fr.label}
                  margin="normal"
                  name="name.fr"
                  onChange={event =>
                    this.handleChange(['name', 'fr'], event.target.value)
                  }
                  required
                  type="text"
                  value={values.name.fr}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={showNameEnError ? true : false}
                  helperText={showNameEnError ? strings.name.en.error : ''}
                  label={strings.name.en.label}
                  margin="normal"
                  name="name.en"
                  onChange={event =>
                    this.handleChange(['name', 'en'], event.target.value)
                  }
                  required
                  type="text"
                  value={values.name.en}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={showNameNlError ? true : false}
                  helperText={showNameNlError ? strings.name.nl.error : ''}
                  label={strings.name.nl.label}
                  margin="normal"
                  name="name.nl"
                  onChange={event =>
                    this.handleChange(['name', 'nl'], event.target.value)
                  }
                  required
                  type="text"
                  value={values.name.nl}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label={strings.description.fr.label}
                  margin="normal"
                  multiline
                  name="description.fr"
                  onChange={event =>
                    this.handleChange(['description', 'fr'], event.target.value)
                  }
                  rows="3"
                  rowsMax="3"
                  type="text"
                  value={values.description.fr}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label={strings.description.en.label}
                  margin="normal"
                  multiline
                  name="description.en"
                  onChange={event =>
                    this.handleChange(['description', 'en'], event.target.value)
                  }
                  rows="3"
                  rowsMax="3"
                  type="text"
                  value={values.description.en}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label={strings.description.nl.label}
                  margin="normal"
                  multiline
                  name="description.nl"
                  onChange={event =>
                    this.handleChange(['description', 'nl'], event.target.value)
                  }
                  rows="3"
                  rowsMax="3"
                  type="text"
                  value={values.description.nl}
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    endAdornment: isPricingPlanLoading ? (
                      <InputAdornment position="end">
                        <CircularProgress />
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={openAddPricingPlanDialog}
                        >
                          <Add />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  SelectProps={{
                    MenuProps: {}
                  }}
                  className={classes.textField}
                  disabled={isPricingPlanLoading}
                  error={showPricingPlanError ? true : false}
                  helperText={showPricingPlanError ? strings.pricingPlan.error : ''}
                  label={strings.pricingPlan.label}
                  margin="normal"
                  name="pricingPlan"
                  onChange={event =>
                    this.handleChange('pricingPlan', event.target.value)
                  }
                  required
                  select
                  type="text"
                  value={values.pricingPlan}
                  variant="outlined"
                >
                  {pricingPlans.map(option => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {strings.errors.unknownError}
              </Typography>
            )}
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            disabled={!isValid}
            onClick={this.handleAdd}
            variant="contained"
          >
            {strings.add}
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

AddBikeType.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  openAddPricingPlanDialog: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isBikeTypeLoading: state.bikeType.loading,
    isPricingPlanLoading: state.pricingPlan.loading,
    pricingPlans: state.pricingPlan.pricingPlans
  };
};

const mapDispatchToProps = {
  addBikeType,
  fetchPricingPlans
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('AddBikeType'),
  withStyles(styles)
)(AddBikeType);
