import green from '@material-ui/core/colors/green';

export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  switchField: {
    width: '100%',
    height: '90px',
    justifyContent: 'center',
    '& span:last-child': {
      fontSize: '16px',
    }
  },
  groupLabel: {
    marginBottom: theme.spacing.unit
  },
  shortTextField: {
    width: '210px',
    maxWidth: '100%',
    marginRight: theme.spacing.unit * 2
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  },
  textField: {
    width: '100%',
  },
  portletLoader: {
    
  },
  portletFooter: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  snackbar: {
    margin: theme.spacing.unit * 3,
  },
  snackbarContent:{
    backgroundColor: green[600],
  },
  back: {
    marginLeft: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
  },
  close: {
    padding: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing.unit,
    }
  }
});
