import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Shared services
import { fetchBikeTypes } from 'store/bikeType/actionsCreator';

// Shared components
import Portlet from 'components/Portlet';

// Custom components
import BikeTypesTable from './components/BikeTypesTable';
import AddBikeType from './components/AddBikeType';
import AddPricingPlanDialog from './components/AddPricingPlanDialog';

// Component styles
import styles from './style';

class BikeTypeList extends Component {
  signal = true;

  state = {
    error: null,
    openAddPricingPlanDialog: false   
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchBikeTypes();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchBikeTypes();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleOpenAddPricingPlanDialog = () => {
    this.setState({ openAddPricingPlanDialog: true });
  };

  handleCloseAddPricingPlanDialog = () => {
    this.setState({ openAddPricingPlanDialog: false });
  };

  renderBikeTypes() {
    const { classes, isLoading, error, strings, bikeTypes } = this.props;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (bikeTypes.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    return <BikeTypesTable bikeTypes={bikeTypes} />;
  }

  render() {
    const { classes, strings } = this.props;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <div className={classes.content}>
            <Grid container spacing={24}>
              <Grid item lg={8} xs={12}>
                <div>{this.renderBikeTypes()}</div>
              </Grid>
              <Grid item lg={4} xs={12}>
                <AddBikeType openAddPricingPlanDialog={this.handleOpenAddPricingPlanDialog} />
              </Grid>
            </Grid>
          </div>
        </div>
        <AddPricingPlanDialog
          handleClose={this.handleCloseAddPricingPlanDialog}
          handleOpen={this.handleOpenAddPricingPlanDialog}
          open={this.state.openAddPricingPlanDialog}
        />
      </DashboardLayout>
    );
  }
}

BikeTypeList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.bikeType.loading,
    bikeTypes: state.bikeType.bikeTypes
  };
};

const mapDispatchToProps = {
  fetchBikeTypes
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('BikeTypeList'),
  withStyles(styles)
)(BikeTypeList);
