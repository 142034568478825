import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletToolbar from 'components/PortletToolbar';
import PortletContent from 'components/PortletContent';

// Shared services
import { deletePrice } from 'store/price/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class PricesTable extends Component {
  state = {
    selectedPrices: []
  };

  handleDeletePrices = async () => {

    await this.props.deletePrice(this.state.selectedPrices, error => {
      if (error) {
        this.setState({
          submitError: error
        });
      } else {
        this.setState({selectedPrices: []});
      }
    });
  };

  handleSelectAll = event => {
    const { prices } = this.props;

    let selectedPrices;

    if (event.target.checked) {
      selectedPrices = prices.map(price => price._id);
    } else {
      selectedPrices = [];
    }

    this.setState({ selectedPrices });
  };

  handleSelectOne = (event, id) => {
    const { selectedPrices } = this.state;

    const selectedIndex = selectedPrices.indexOf(id);
    let newSelectedPrices = [];

    if (selectedIndex === -1) {
      newSelectedPrices = newSelectedPrices.concat(selectedPrices, id);
    } else if (selectedIndex === 0) {
      newSelectedPrices = newSelectedPrices.concat(selectedPrices.slice(1));
    } else if (selectedIndex === selectedPrices.length - 1) {
      newSelectedPrices = newSelectedPrices.concat(selectedPrices.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPrices = newSelectedPrices.concat(
        selectedPrices.slice(0, selectedIndex),
        selectedPrices.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedPrices: newSelectedPrices });
  };

  render() {
    const { classes, className, prices, strings } = this.props;
    const { selectedPrices } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel
            subtitle={`${prices.length} ${strings.common.total}`}
            title={strings.title}
          />
          <PortletToolbar>
            {selectedPrices.length > 0 && (
              <Button
                className={classes.deleteButton}
                color="default"
                onClick={this.handleDeletePrices}
                size="small"
                variant="outlined"
              >
                <DeleteIcon className={classes.deleteIcon} /> {strings.delete}
              </Button>
            )}
          </PortletToolbar>
        </PortletHeader>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedPrices.length === prices.length}
                      color="primary"
                      indeterminate={
                        selectedPrices.length > 0 &&
                        selectedPrices.length < prices.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="left">{strings.price}</TableCell>
                  <TableCell align="left">{strings.amountcredit}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prices.map(creditPrice => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={creditPrice._id}
                    selected={selectedPrices.indexOf(creditPrice._id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <div className={classes.tableCellInner}>
                        <Checkbox
                          checked={
                            selectedPrices.indexOf(creditPrice._id) !== -1
                          }
                          color="primary"
                          onChange={event =>
                            this.handleSelectOne(event, creditPrice._id)
                          }
                          value="true"
                        />
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {creditPrice.price ? creditPrice.price + ' €' : '/'} 
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {creditPrice.credit ? creditPrice.credit + ' ' + strings.credit : '/'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    );
  }
}

PricesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  prices: PropTypes.array.isRequired,
  strings: PropTypes.object.isRequired
};

PricesTable.defaultProps = {
  prices: []
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.price.loading
  };
};

const mapDispatchToProps = {
  deletePrice
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('PricesTable'),
  withStyles(styles)
)(PricesTable);
