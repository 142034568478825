import actions from './actions';

const initState = {
  dashboard: {},
  footer: {},
  sidebar: {},
  topbar: {}
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_DASHBOARD:
      return { ...state, dashboard: action.value };
    case actions.SET_FOOTER:
      return { ...state, footer: action.value };
    case actions.SET_SIDEBAR:
      return { ...state, sidebar: action.value };
    case actions.SET_TOPBAR:
      return { ...state, topbar: action.value };
    default:
      return state;
  }
};

export default reducer;
