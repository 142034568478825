// Palette
import palette from 'theme/palette';

// Externals
import moment from 'moment';
import _ from 'underscore';

// Chart data
export const data = (rents, bookings, strings) => {

  var data = {
    labels: [],
    datasets: [],
  }

  for(let i = 6; i >= 0; i--) {
    data.labels.push(moment().add(-i, 'days').format('DD MMM'))
  }

  // Bookings
  let bookingsData = [];

  _.each(bookings, function(booking) {
    booking.startDate = moment(booking.startDate).format('DD MMM');
    booking.endDate = moment(booking.startDate).format('DD MMM');
  });
  
  for(let i = 0; i < 7; i++) {
    bookingsData.push(_.where(bookings, { startDate : data.labels[i]}).length)
  }

  data.datasets.push({
    label: strings.bookings,
    backgroundColor: palette.primary.light,
    data: bookingsData
  })

  // Rents
  let rentsData = [];

  _.each(rents, function(rent) {
    rent.startDate = moment(rent.startDate).format('DD MMM');
    rent.endDate = moment(rent.startDate).format('DD MMM');
  });
  
  for(let i = 0; i < 7; i++) {
    rentsData.push(_.where(rents, { startDate : data.labels[i]}).length)
  }

  data.datasets.push({
    label: strings.rents,
    backgroundColor: palette.primary.main,
    data: rentsData
  })

  return data;
};

// Chart options
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.border,
    backgroundColor: palette.common.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0,
          stepSize: 1
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};
