import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Shared services
import { fetchOrganizations } from 'store/organization/actionsCreator';

// Shared components
import Portlet from 'components/Portlet';

// Custom components
import OrganizationsToolbar from './components/OrganizationsToolbar';
import OrganizationsTable from './components/OrganizationsTable';
import AddOrganizationDialog from './components/AddOrganizationDialog';

// Component styles
import styles from './style';

class OrganizationList extends Component {
  signal = true;

  state = {
    error: null,
    limit: 10,
    openAddOrganizationDialog: false,
    selectedOrganizations: []
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchOrganizations();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchOrganizations();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleOpenAddOrganizationDialog = () => {
    this.setState({ openAddOrganizationDialog: true });
  };

  handleCloseAddOrganizationDialog = () => {
    this.setState({ openAddOrganizationDialog: false });
  };

  handleFilterChange = (event) => {
    this.setState({
      filter: event.target.value
    });
  };

  handleSelect = (selectedOrganizations) => {
    this.setState({ selectedOrganizations });
  };

  getFilteredOrganizations = () => {
    const { organizations } = this.props;
    const { filter } = this.state;
    if (!filter) {
      return organizations;
    }

    const lowerFilter = filter.toLowerCase();

    return organizations.filter((o) => {
      return o.name.toLowerCase().indexOf(lowerFilter) >= 0;
    });
  };

  renderOrganizations() {
    const { classes, error, isLoading, strings } = this.props;

    const organizations = this.getFilteredOrganizations();

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (organizations.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    return <OrganizationsTable onSelect={this.handleSelect} organizations={organizations} />;
  }

  render() {
    const { classes, strings } = this.props;
    const { selectedOrganizations } = this.state;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <OrganizationsToolbar
            onFilterChange={this.handleFilterChange}
            openAddOrganizationDialog={this.handleOpenAddOrganizationDialog}
            selectedOrganizations={selectedOrganizations}
          />
          <div className={classes.content}>{this.renderOrganizations()}</div>
        </div>
        <AddOrganizationDialog
          handleClose={this.handleCloseAddOrganizationDialog}
          handleOpen={this.handleOpenAddOrganizationDialog}
          open={this.state.openAddOrganizationDialog}
        />
      </DashboardLayout>
    );
  }
}

OrganizationList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.organization.loading,
    organizations: state.organization.organizations
  };
};

const mapDispatchToProps = {
  fetchOrganizations
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('OrganizationList'),
  withStyles(styles)
)(OrganizationList);
