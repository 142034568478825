import actions from './actions';

import * as bikeService from 'services/bike';
import * as rentService from 'services/rent';

// eslint-disable-next-line no-unused-vars
export const fetchBikes = () => async (dispatch, getState) => {
  // if (getState().bike.bikes.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_BIKES });

  try {
    const organization = getState().organization.currentOrganization;

    const bikes = await bikeService.getBikes(organization._id);

    dispatch({
      type: actions.SET_BIKES,
      value: bikes
    });

    dispatch({ type: actions.END_FETCH_BIKES });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_BIKES });
  }
};

export const fetchOrganizationBikes = (organizationId) => async (dispatch, getState) => {
  // if (getState().bike.bikes.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_BIKES });

  try {
    const bikes = await bikeService.getBikes(organizationId);

    dispatch({
      type: actions.SET_BIKES,
      value: bikes
    });

    dispatch({ type: actions.END_FETCH_BIKES });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_BIKES });
  }
};

// eslint-disable-next-line no-unused-vars
export const deleteBikes = (bikesToDelele) => async (dispatch, getState) => {
  dispatch({ type: actions.START_DELETING_BIKES });

  try {
    const organization = getState().organization.currentOrganization;

    const bikes = await bikeService.deleteBikes(organization._id, bikesToDelele);

    dispatch({
      type: actions.SET_BIKES,
      value: bikes
    });

    dispatch({ type: actions.END_DELETING_BIKES });
    return {error : null};
  } catch (error) {
    dispatch({ type: actions.END_DELETING_BIKES });
    return {error : error};
  }
};

// eslint-disable-next-line no-unused-vars
export const fetchLocations = (bikeId) => async (dispatch, getState) => {
  // if (getState().bike.locations.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_LOCATIONS });

  try {
    const organization = getState().organization.currentOrganization;

    const locations = await bikeService.getLocations(organization._id, bikeId);

    dispatch({
      type: actions.SET_LOCATIONS,
      value: locations
    });

    dispatch({ type: actions.END_FETCH_LOCATIONS });
    return { error: null };
  } catch (error) {
    dispatch({ type: actions.END_FETCH_LOCATIONS });
    return { error: error };
  }
};

// eslint-disable-next-line no-unused-vars
export const addBike = (bikeToAdd, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_ADD_BIKE });

  try {
    const organization = getState().organization.currentOrganization;

    const bikes = await bikeService.addBike(organization._id, bikeToAdd);

    dispatch({
      type: actions.SET_BIKES,
      value: bikes
    });

    dispatch({ type: actions.END_ADD_BIKE });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_ADD_BIKE });
    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const fetchBike = (bikeId) => async (dispatch, getState) => {
  dispatch({ type: actions.START_FETCH_BIKE });

  try {
    const organization = getState().organization.currentOrganization;

    const bike = await bikeService.getBike(organization._id, bikeId);

    dispatch({
      type: actions.SET_BIKE,
      value: bike
    });

    dispatch({ type: actions.END_FETCH_BIKE });
    return { bike: bike };
  } catch (error) {
    dispatch({ type: actions.END_FETCH_BIKE });
    return { error: error };
  }
};

// eslint-disable-next-line no-unused-vars
export const updateBike = (bikeId, bikeToUpdate, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_UPDATE_BIKE });

  try {
    const organization = getState().organization.currentOrganization;

    const bike = await bikeService.updateBike(organization._id, bikeId, bikeToUpdate);

    dispatch({
      type: actions.SET_BIKE,
      value: bike
    });

    dispatch({ type: actions.END_UPDATE_BIKE });
    return { error: null };
  } catch (error) {
    dispatch({ type: actions.END_UPDATE_BIKE });
    return { error: error };
  }
};

export const addBikeLocation = (bikeId, coordinates) => async (dispatch, getState) => {
  dispatch({ type: actions.START_ADD_BIKE_LOCATION });

  try {
    const organization = getState().organization.currentOrganization;

    const bike = await bikeService.addBikeLocation(organization._id, bikeId, coordinates);

    dispatch({
      type: actions.SET_BIKE,
      value: bike
    });

    dispatch({ type: actions.END_ADD_BIKE_LOCATION });
    return { error: null };
  } catch (error) {
    dispatch({ type: actions.END_ADD_BIKE_LOCATION });
    return { error: error };
  }
};

// eslint-disable-next-line no-unused-vars
export const endRent = (bikeId, coordinates, userRentId) => async (dispatch, getState) => {
  dispatch({ type: actions.START_BIKE_END_RENT });

  try {
    const organization = getState().organization.currentOrganization;

    const bike = await rentService.endRent(organization._id, bikeId, coordinates, userRentId);

    console.log(bike);
    dispatch({
      type: actions.SET_BIKE,
      value: bike
    });

    dispatch({ type: actions.END_BIKE_END_RENT });
    return { error: null };
  } catch (error) {
    dispatch({ type: actions.END_BIKE_END_RENT });
    return { error: error };
  }
 };