import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';

// Shared components
import SearchInput from 'components/SearchInput';

// Shared services
import { deleteComments } from 'store/comment/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class CommentsToolbar extends Component {
  handleDeleteComments = async () => {
    const { onSelect, selectedComments } = this.props;

    const { error } = await this.props.deleteComments(selectedComments);
    if (error) {
      this.setState({
        submitError: error
      });
    } 
      
    onSelect([]);
  };
  
  render() {
    const { classes, className, selectedComments, strings } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput className={classes.searchInput} onChange={this.props.onFilterChange} placeholder={strings.search} />
          <span className={classes.spacer} />
          {selectedComments.length > 0 && (
            <Button className={classes.deleteButton} onClick={this.handleDeleteComments} size="small" variant="contained">
              <DeleteIcon className={classes.deleteIcon} /> {strings.common.delete}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

CommentsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func,
  selectedComments: PropTypes.array,
  strings: PropTypes.object.isRequired
};

CommentsToolbar.defaultProps = {
  onFilterChange: () => {},
  onSelect: () => {},
  selectedComments: []
};

const mapStateToProps = state => {
  return {
    role: state.authorize.role
  };
};

const mapDispatchToProps = {
  deleteComments
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('CommentsToolbar'),
  withStyles(styles)
)(CommentsToolbar);
