import { withStyles } from '@material-ui/core';
import React from 'react';

const styles = (theme) => ({
  overlay: {
    zIndex: 10,
    backgroundColor: 'black',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  quote: {
    backgroundColor: theme.palette.common.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/shareabike-carre.webp)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    zIndex: 15,
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.common.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing.unit * 3,
    color: theme.palette.common.white
  },
  bio: {
    color: theme.palette.common.white
  }
});

const Quote = (props) => {
  const { classes } = props;

  return (
    <div className={classes.quote}>
      <div className={classes.overlay} />
      {/* <div className={classes.quoteInner}>
        <Typography className={classes.quoteText} variant="h1">
          {strings.quote.text}
        </Typography>
        <div className={classes.person}>
          <Typography className={classes.name} variant="body1">
            {strings.quote.author}
          </Typography>
          <Typography className={classes.bio} variant="body2">
            {strings.quote.bio}
          </Typography>
        </div>
      </div> */}
    </div>
  );
};

export default withStyles(styles)(Quote);
