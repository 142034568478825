export interface CreditPrice {
  _id: string;
  price: number;
  credit: number;
}

export const getPrices = (): Promise<CreditPrice[]> =>
  authorize(async (resolve: any, reject: any, auth: string) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/creditprice', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        }
      });
      if (response.ok) {
        const json = (await response.json()) as CreditPrice[];
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  }) as Promise<CreditPrice[]>;

export const addPrice = (organizationId: string, price: object) =>
  authorize(async (resolve: any, reject: any, auth: string) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/credits/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify(price)
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const deletePrice = async (organizationId: string, price: object) =>
  authorize(async (resolve: any, reject: any, auth: string) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/credits', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify(price)
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const authorize = async (call: any, ...props: any[]) => {
  const promise = new Promise(async (resolve: any, reject: any) => {
    const auth = localStorage.getItem('x-auth');
    if (!auth) {
      reject({
        error: 'missing auth token'
      });
      return;
    }
    await call(resolve, reject, auth, ...props);
  });
  return promise;
};
