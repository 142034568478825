export default {
  'name.fr': {
    presence: { allowEmpty: false }
  },
  'name.en': {
    presence: { allowEmpty: true }
  },
  'name.nl': {
    presence: { allowEmpty: true }
  },
  state: {
    presence: { allowEmpty: false }
  },
  type: {
    presence: { allowEmpty: false }
  },
  padlockKind: {
    presence: { allowEmpty: false }
  },
  padlockMac: {
    presence: { allowEmpty: false }
  }
};
