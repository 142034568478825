import actions from './actions';

// eslint-disable-next-line no-unused-vars
export const setDashboard = (dashboardState) => async (dispatch, getState) => {
  return dispatch({
    type: actions.SET_DASHBOARD,
    value: dashboardState
  });
};

// eslint-disable-next-line no-unused-vars
export const setFooter = (footerState) => async (dispatch, getState) => {
  return dispatch({
    type: actions.SET_FOOTER,
    value: footerState
  });
};

// eslint-disable-next-line no-unused-vars
export const setSidebar = (sidebarState) => async (dispatch, getState) => {
  return dispatch({
    type: actions.SET_SIDEBAR,
    value: sidebarState
  });
};

// eslint-disable-next-line no-unused-vars
export const setTopbar = (topbarState) => async (dispatch, getState) => {
  return dispatch({
    type: actions.SET_TOPBAR,
    value: topbarState
  });
};