import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles/index';

// Material components
import Divider from '@material-ui/core/Divider/index';
import Grid from '@material-ui/core/Grid/index';
import Typography from '@material-ui/core/Typography/index';

// Shared components
import Link from 'components/Link';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';
import Quote from 'components/Layout/Quote';

// Actions

class TermsOfUse extends Component {
  render() {
    const { classes, strings } = this.props;

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteWrapper} item lg={6}>
            <Quote />
          </Grid>
          <Grid className={classes.content} item lg={6} xs={12}>
            <div className={classes.contentHeader} />
            <div className={classes.contentBody}>
              <div className={classes.contentWrapper}>
                <Typography className={classes.title} variant="h2">
                  {strings.title}
                </Typography>
                {strings.sections.map((s) => (
                  <TermsSection classes={classes} section={s} />
                ))}
              </div>
            </div>
            <div className={classes.contentFooter}>
              <Divider className={classes.divider} variant="fullWidth" />
              <div className={classes.footerLinks}>
                <Link component="button" href="/sign-in" variant="body1">
                  {strings.signIn}
                </Link>
                <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                <Link component="button" href="/contact-us" variant="body1">
                  {strings.contactUs}
                </Link>
                <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                <Link component="button" href="/terms-of-use" target="_blank" variant="body1">
                  {strings.CGU}
                </Link>
                <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                <Link component="button" href="/help" target="_blank" variant="body1">
                  {strings.help}
                </Link>
                <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                <Link component="button" href="/about" variant="body1">
                  {strings.about}
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const TermsSection = ({ section, classes }) => (
  <div className={classes.section}>
    <Typography variant="h3">{section.name}</Typography>
    {section.articles.map((a) => (
      <TermsArticle article={a} classes={classes} />
    ))}
  </div>
);

const TermsArticle = ({ article, classes }) => (
  <div className={classes.article}>
    <u>
      <Typography variant="h5">{article.name}</Typography>
    </u>
    {article.paragraphs.map((p) => (
      <TermsParagraph classes={classes} paragraph={p} />
    ))}
  </div>
);

const TermsParagraph = ({ paragraph, classes }) => (
  <div className={classes.paragraph}>
    {paragraph.map((pl) => (
      <TermsParagraphLine paragraphLine={pl} />
    ))}
  </div>
);

const TermsParagraphLine = ({ paragraphLine }) => (
  <Typography style={{ textAlign: 'justify' }} variant="body1">
    {paragraphLine}
  </Typography>
);

TermsOfUse.propTypes = {
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
  translate('TermsOfUse')
)(TermsOfUse);
