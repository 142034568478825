export enum LockKinds {
  LinkaV1 = 'LinkaV1',
  LinkaV2 = 'LinkaV2',
  AXAERL2 = "AXAERL2"
}

export const LockKindsLabel = {
  [LockKinds.LinkaV1]: 'Linka Original',
  [LockKinds.LinkaV2]: 'Linka Leo2',
  [LockKinds.AXAERL2]: 'Axa ERL 2'
};

export const LockKindsValues = Object.values(LockKinds);
