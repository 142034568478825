import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Shared services
import { fetchBikes } from 'store/bike/actionsCreator';

// Shared components
import Portlet from 'components/Portlet';

// Custom components
import BikesToolbar from './components/BikesToolbar';
import BikesTable from './components/BikesTable';
import BikesMap from './components/BikesMap';
import AddBikeDialog from './components/AddBikeDialog';
import AddBikeTypeDialog from './components/AddBikeTypeDialog';
import AddPricingPlanDialog from './components/AddPricingPlanDialog';

// Component styles
import styles from './style';

class BikeList extends Component {
  signal = true;

  state = {
    error: null,
    openAddBikeDialog: false,
    openAddBikeTypeDialog: false,
    openAddPricingPlanDialog: false,
    selectedBikes: []
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchBikes();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchBikes();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleOpenAddBikeDialog = () => {
    this.setState({ openAddBikeDialog: true });
  };

  handleCloseAddBikeDialog = () => {
    this.setState({ openAddBikeDialog: false });
  };

  handleOpenAddBikeTypeDialog = () => {
    this.setState({ openAddBikeTypeDialog: true });
  };

  handleCloseAddBikeTypeDialog = () => {
    this.setState({ openAddBikeTypeDialog: false });
  };

  handleOpenAddPricingPlanDialog = () => {
    this.setState({ openAddPricingPlanDialog: true });
  };

  handleCloseAddPricingPlanDialog = () => {
    this.setState({ openAddPricingPlanDialog: false });
  };

  handleFilterChange = event => {
    this.setState({
      filter: event.target.value
    });
  };

  handleSelect = selectedBikes => {
    this.setState({ selectedBikes });
  };

  getFilteredBikes = () => {
    const { bikes } = this.props;
    const { filter } = this.state;
    if (!filter) {
      return bikes;
    }

    const lowerFilter = filter.toLowerCase();

    return bikes.filter(b => {
      return (
        b.name.toLowerCase().indexOf(lowerFilter) >= 0 ||
        (b.bikeType &&
          b.bikeType.description.toLowerCase().indexOf(lowerFilter) >= 0)
      );
    });
  };

  renderBikes() {
    const { classes, error, isLoading, strings } = this.props;

    const bikes = this.getFilteredBikes();

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (bikes.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    return <BikesTable bikes={bikes} onSelect={this.handleSelect} />;
  }

  render() {
    const { classes, strings } = this.props;
    const { selectedBikes } = this.state;

    const bikes = this.getFilteredBikes();

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <Portlet className={classes.mapWrapper}>
            <BikesMap markers={bikes} />
          </Portlet>
          <div className={classes.content}>
            <BikesToolbar
              onFilterChange={this.handleFilterChange}
              openAddBikeDialog={this.handleOpenAddBikeDialog}
              selectedBikes={selectedBikes}
            />
            <div className={classes.content}>{this.renderBikes()}</div>
          </div>
        </div>
        <AddBikeDialog
          handleClose={this.handleCloseAddBikeDialog}
          handleOpen={this.handleOpenAddBikeDialog}
          open={this.state.openAddBikeDialog}
          openAddBikeTypeDialog={this.handleOpenAddBikeTypeDialog}
        />
        <AddBikeTypeDialog
          handleClose={this.handleCloseAddBikeTypeDialog}
          handleOpen={this.handleOpenAddBikeTypeDialog}
          open={this.state.openAddBikeTypeDialog}
          openAddPricingPlanDialog={this.handleOpenAddPricingPlanDialog}
        />
        <AddPricingPlanDialog
          handleClose={this.handleCloseAddPricingPlanDialog}
          handleOpen={this.handleOpenAddPricingPlanDialog}
          open={this.state.openAddPricingPlanDialog}
        />
      </DashboardLayout>
    );
  }
}

BikeList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.bike.loading,
    bikes: state.bike.bikes
  };
};

const mapDispatchToProps = {
  fetchBikes
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('BikeList'),
  withStyles(styles)
)(BikeList);
