export default (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh'
  },
  grid: {
    height: '100vh',
    '& > div': {
      overflow: 'auto'
    }
  },
  quoteWrapper: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    position: 'relative'
  },
  overlay: {
    zIndex: 10,
    backgroundColor: 'black',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 5,
    paddingBototm: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentFooter: {},
  footerLinks: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing.unit
  },
  contentWrapper: {
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingBottom: '125px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    }
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  title: {
    marginTop: theme.spacing.unit * 3
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 0.5
  },
  fields: {
    marginTop: theme.spacing.unit * 2
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing.unit * 2
    }
  },
  submitError: {
    color: theme.palette.danger.main,
    marginTop: theme.spacing.unit * 2
  },
  wrapper: {
    marginTop: theme.spacing.unit * 2,
    position: 'relative'
  },
  button: {
    width: '100%'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -(theme.spacing.unit * 2 + theme.spacing.unit / 2),
    marginLeft: -(theme.spacing.unit * 2 + theme.spacing.unit / 2)
  },
  refreshWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  refreshImage: {
    width: '250px'
  },
  refreshProgress: {
    marginTop: theme.spacing.unit * 2,
    width: '180px'
  }
});
