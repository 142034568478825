import actions from './actions';

import * as commentService from 'services/comment';

// eslint-disable-next-line no-unused-vars
export const fetchComments = () => async (dispatch, getState) => {
  dispatch({ type: actions.START_FETCH_COMMENTS });

  try {
    const organization = getState().organization.currentOrganization;

    const comments = await commentService.getComments(organization._id);

    dispatch({
      type: actions.SET_COMMENTS,
      value: comments
    });

    dispatch({ type: actions.END_FETCH_COMMENTS });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_COMMENTS });
  }
};

export const deleteComments = (commentsToDelele) => async (dispatch, getState) => {
  dispatch({ type: actions.START_DELETING_COMMENTS });

  try {
    const organization = getState().organization.currentOrganization;

    const comments = await commentService.deleteComments(organization._id, commentsToDelele);

    dispatch({
      type: actions.SET_COMMENTS,
      value: comments
    });

    dispatch({ type: actions.END_DELETING_COMMENTS });
    return { error: null };
  } catch (error) {
    dispatch({ type: actions.END_DELETING_COMMENTS });
    return { error: error };
  }
};
