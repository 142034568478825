export default {
  name: {
    presence: { allowEmpty: false }
  },
  type: {
    presence: { allowEmpty: false }
  },
  padlockKind: {
    presence: { allowEmpty: false }
  },
  padlockMac: {
    presence: { allowEmpty: false }
  }
};
