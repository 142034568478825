import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Shared components
import Portlet from 'components/Portlet';

// Shared services
import { fetchComments } from '../../store/comment/actionsCreator';

// Custom components
import CommentsToolbar from './components/CommentsToolbar';
import CommentsTable from './components/CommentsTable';

// Component styles
import styles from './style';

class CommentsList extends Component {
  signal = true;

  state = {
    limit: 10,
    comments: [],
    selectedComments: [],
    error: null
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchComments();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchComments();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = selectedComments => {
    this.setState({ selectedComments });
  };

  handleFilterChange = event => {
    this.setState({
      filter: event.target.value
    });
  };

  getFilteredComments = () => {
    const { comments } = this.props;
    const { filter } = this.state;
    if (!filter) {
      return comments;
    }

    const lowerFilter = filter.toLowerCase();

    return comments.filter(c => {
      return (
        c.userEmail.toLowerCase().indexOf(lowerFilter) >= 0 ||
        c.message.toLowerCase().indexOf(lowerFilter) >= 0
      );
    });
  };

  renderComments() {
    const { classes, isLoading, comments, error, strings } = this.props;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (comments.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    return (
      <CommentsTable
        comments={this.getFilteredComments()}
        onSelect={this.handleSelect}
      />
    );
  }

  render() {
    const { classes, strings } = this.props;
    const { selectedComments } = this.state;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <CommentsToolbar
            onFilterChange={this.handleFilterChange}
            onSelect={this.handleSelect}
            selectedComments={selectedComments}
          />
          <div className={classes.content}>{this.renderComments()}</div>
        </div>
      </DashboardLayout>
    );
  }
}

CommentsList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.comment.loading,
    comments: state.comment.comments
  };
};

const mapDispatchToProps = {
  fetchComments
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('CommentsList'),
  withStyles(styles)
)(CommentsList);
