import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';

// Shared helpers
import getInitials from 'helpers/getInitials';

// Shared components
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class InvitationsTable extends Component {
  state = {
    selectedInvitations: [],
    rowsPerPage: 10,
    page: 0
  };

  handleSelectAll = event => {
    const { users, onSelect } = this.props;

    let selectedInvitations;

    if (event.target.checked) {
      selectedInvitations = users.map(user => user._id);
    } else {
      selectedInvitations = [];
    }

    this.setState({ selectedInvitations });

    onSelect(selectedInvitations);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedInvitations } = this.state;

    const selectedIndex = selectedInvitations.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedInvitations, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedInvitations.slice(1));
    } else if (selectedIndex === selectedInvitations.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedInvitations.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedInvitations.slice(0, selectedIndex),
        selectedInvitations.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedInvitations: newSelectedUsers });

    onSelect(newSelectedUsers);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, className, users, strings } = this.props;
    const { selectedInvitations, rowsPerPage, page } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedInvitations.length === users.length}
                      color="primary"
                      indeterminate={
                        selectedInvitations.length > 0 &&
                        selectedInvitations.length < users.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="left">{strings.name}</TableCell>
                  <TableCell align="left">{strings.email}</TableCell>
                  <TableCell align="left">{strings.invitationDate}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map(user => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user._id}
                      selected={selectedInvitations.indexOf(user._id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <div className={classes.tableCellInner}>
                          <Checkbox
                            checked={selectedInvitations.indexOf(user._id) !== -1}
                            color="primary"
                            onChange={event =>
                              this.handleSelectOne(event, user._id)
                            }
                            value="true"
                          />
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Avatar className={classes.avatar} src={user.avatarUrl}>
                          {getInitials(`${user.firstname} ${user.name}`)}
                        </Avatar>                      
                        <Typography
                          className={classes.nameText}
                          variant="body1"
                        >
                          {`${user.firstname} ${user.name}`}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {moment(user.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            backIconButtonProps={{
              'aria-label': strings.common.previousPage
            }}
            component="div"
            count={users.length}
            labelDisplayedRows={({ from, to, count }) => {
              return from + '-' + to + ' ' + strings.common.of + ' ' + count;
            }}
            labelRowsPerPage={strings.common.rowsPerPage}
            nextIconButtonProps={{
              'aria-label': strings.common.nextPage
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

InvitationsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  strings : PropTypes.object.isRequired,
  users: PropTypes.array.isRequired
};

InvitationsTable.defaultProps = {
  users: [],
  onSelect: () => {},
  onShowDetails: () => {}
};


export default compose(
  translate('InvitationsTable'),
  withStyles(styles)
)(InvitationsTable);
