import { bindActionCreators } from 'redux';

import actions from './actions';

import * as organizationService from 'services/organization';
import * as roles from 'common/roles';

import { setRole } from '../authorize/actionsCreator';
import { fetchOrganizationBikes } from '../bike/actionsCreator';

// eslint-disable-next-line no-unused-vars
export const fetchOrganizations = () => async (dispatch, getState) => {
  dispatch({ type: actions.START_FETCH_ORGANIZATIONS });

  try {
    const organizations = await organizationService.getOrganizations();

    dispatch({
      type: actions.SET_ORGANIZATIONS,
      value: organizations
    });

    if (getState().organization.currentOrganization === null && organizations.length > 0) {
      const currentOrganizationId = localStorage.getItem('currentOrganizationId');
      if (currentOrganizationId) {
        await bindActionCreators(setCurrentOrganizationById, dispatch)(currentOrganizationId);
      } else {
        await bindActionCreators(setCurrentOrganization, dispatch)(organizations[0]);
      }
      if (!getState().authorize.role || !getState().authorize.role === roles.Admin) {
        await bindActionCreators(setRole, dispatch)(roles.Operator);
      }
    }

    dispatch({ type: actions.END_FETCH_ORGANIZATIONS });
  } catch (data) {
    if (data.error === 'InsufficientAccessLevel') {
      await bindActionCreators(setRole, dispatch)(roles.User);
    }
    dispatch({ type: actions.END_FETCH_ORGANIZATIONS });
  }
};

// eslint-disable-next-line no-unused-vars
export const deleteOrganizations = (organizationsToDelete) => async (dispatch, getState) => {
  dispatch({ type: actions.START_DELETING_ORGANIZATIONS });

  try {
    const organization = getState().organization.currentOrganization;

    const organizations = await organizationService.deleteOrganizations(organization._id, organizationsToDelete);

    dispatch({
      type: actions.SET_ORGANIZATIONS,
      value: organizations
    });

    await bindActionCreators(setCurrentOrganization, dispatch)(organizations[0]);

    dispatch({ type: actions.END_DELETING_ORGANIZATIONS });
    return { error: null };
  } catch (error) {
    dispatch({ type: actions.END_DELETING_ORGANIZATIONS });
    return { error: error };
  }
};

// eslint-disable-next-line no-unused-vars
export const addOrganization = (organizationToAdd, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_ADD_ORGANIZATION });

  try {
    const organizations = await organizationService.addOrganization(organizationToAdd);

    dispatch({
      type: actions.SET_ORGANIZATIONS,
      value: organizations
    });

    dispatch({ type: actions.END_ADD_ORGANIZATION });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_ADD_ORGANIZATION });
    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const fetchOrganization = (organizationId, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_FETCH_ORGANIZATION });

  try {
    const organization = await organizationService.getOrganization(organizationId);

    dispatch({
      type: actions.SET_ORGANIZATION,
      value: organization
    });

    await bindActionCreators(fetchOrganizationBikes, dispatch)(organizationId);

    dispatch({ type: actions.END_FETCH_ORGANIZATION });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_FETCH_ORGANIZATION });
    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const updateOrganization = (organizationId, organizationToUpdate, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_UPDATE_ORGANIZATION });

  try {
    const organization = await organizationService.updateOrganization(organizationId, organizationToUpdate);

    dispatch({
      type: actions.SET_ORGANIZATION,
      value: organization
    });

    dispatch({ type: actions.END_UPDATE_ORGANIZATION });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_UPDATE_ORGANIZATION });
    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const setCurrentOrganization = (organization) => async (dispatch, getState) => {
  dispatch({
    type: actions.SET_CURRENT_ORGANIZATION,
    value: organization
  });
};

// eslint-disable-next-line no-unused-vars
export const setCurrentOrganizationById = (organizationId) => async (dispatch, getState) => {
  localStorage.setItem('currentOrganizationId', organizationId);
  const organizations = getState().organization.organizations;
  if (!organizations || !organizations.length) {
    return;
  }

  const organization = organizations.find((o) => o._id === organizationId);
  if (organization === null) {
    return;
  }

  dispatch({
    type: actions.SET_CURRENT_ORGANIZATION,
    value: organization
  });
};

export const updateOrganizationStatus = (organizationId, onFinished) => async (dispatch) => {
  dispatch({ type: actions.START_UPDATESTATUS_ORGANIZATION });

  try {
    await organizationService.updateStatus(organizationId);
    dispatch({ type: actions.END_UPDATESTATUS_ORGANIZATION });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_UPDATESTATUS_ORGANIZATION });
    onFinished(error);
  }
};
