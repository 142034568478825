import React from 'react';

// Redux
import { useSelector } from 'react-redux';

// Views
import Dashboard from '../Dashboard';
import UserProfile from '../UserProfile';

// Models
import * as Roles from 'common/roles';

const HomeSelector = (props: any) => {
  const role = useSelector((state: any) => state.authorize.role);

  if (role === Roles.User) {
    return <UserProfile {...props} />;
  } else if (role === Roles.Admin || role === Roles.Operator || role === Roles.Transporter) {
    return <Dashboard {...props} />;
  }
};

export default HomeSelector;
