export default theme => ({
  root: {},
  dialogTitle: {
    '& h6': {
      fontSize: '20px'
    }
  },
  dialogLoader: {
    marginTop: theme.spacing.unit * 2
  },
  dialogForm: {
    marginTop: theme.spacing.unit * 2
  },
  textField: {
    width: '100%'
  },
  mapField: {
    marginTop: theme.spacing.unit * 1,
    height: '50vh',
    minHeight: 200
  },
  mapHelperText: {
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  }
});
