import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import validate from 'validate.js';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles/index';

// Material components
import Button from '@material-ui/core/Button/index';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import Divider from '@material-ui/core/Divider/index';
import Grid from '@material-ui/core/Grid/index';
import LinearProgress from '@material-ui/core/LinearProgress/index';
import TextField from '@material-ui/core/TextField/index';
import Typography from '@material-ui/core/Typography/index';

// Shared components
import Link from 'components/Link';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

// Actions
import { fetchProfile, logout, signIn } from 'store/authentication/actionsCreator';
import Quote from 'components/Layout/Quote';

class SignIn extends Component {
  state = {
    values: {
      email: '',
      password: ''
    },
    touched: {
      email: false,
      password: false
    },
    errors: {
      email: null,
      password: null
    },
    isValid: false,
    submitError: null,
    isAccessedOrRefreshed: false
  };

  // constructor(props) {
  //   super(props);
  //   // I leaved that there if we have to do some special logic when we refresh a page
  //   if (window.performance) {
  //     // A page has been reloaded
  //     if (performance.navigation.type === 1) {
  //       // do something
  //     }
  //   }
  // }

  componentDidMount() {
    if (localStorage.getItem('x-auth')) {
      this.setState(
        {
          isAccessedOrRefreshed: true
        },
        this.handleFetch
      );
    }
  }

  validateForm = () => {
    const newState = { ...this.state };

    const errors = validate(newState.values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleFetch = async () => {
    const { strings } = this.props;

    await this.props.fetchProfile((error) => {
      if (error) {
        this.setState({
          submitError: strings.errors.unknownError
        });

        this.props.logout();
      }
    });
  };

  handleSignIn = async (e) => {
    e.preventDefault();

    const { strings } = this.props;
    const { values } = this.state;

    await this.props.signIn(values.email, values.password, (result) => {
      if (result) {
        if (result.error === 'WrongCredentials') {
          this.setState({
            submitError: strings.errors.wrongCredentialsError
          });
        } else if (result.error === 'NotAdminOrOperatorUser') {
          this.setState({
            submitError: strings.errors.notEnoughPrivilegesError
          });
        } else {
          this.setState({
            submitError: strings.errors.unknownError
          });
        }
      }
    });
  };

  render() {
    const { classes, strings, loading } = this.props;
    const { values, touched, errors, isValid, submitError, isAccessedOrRefreshed } = this.state;

    const showEmailError = touched.email && errors.email;
    const showPasswordError = touched.password && errors.password;

    return (
      <div className={classes.root}>
        {isAccessedOrRefreshed ? (
          <div className={classes.refreshWrapper}>
            <img alt="ShareABike" className={classes.refreshImage} src="/images/logos/logo-large.png" />
            {submitError ? (
              <Typography className={classes.submitError} variant="body2">
                {submitError}
              </Typography>
            ) : (
              <LinearProgress className={classes.refreshProgress} />
            )}
          </div>
        ) : (
          <Grid className={classes.grid} container>
            <Grid className={classes.quoteWrapper} item lg={6}>
              <Quote />
            </Grid>
            <Grid className={classes.content} item lg={6} xs={12}>
              <div className={classes.contentHeader} />
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <Typography className={classes.title} variant="h2">
                    {strings.title}
                  </Typography>
                  <Typography className={classes.subtitle} variant="body1">
                    {strings.subtitle}
                  </Typography>
                  <div className={classes.fields}>
                    <TextField
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.textField}
                      error={showEmailError ? true : false}
                      helperText={showEmailError ? strings.email.error : ''}
                      label={strings.email.label}
                      name="email"
                      onChange={(event) => this.handleChange('email', event.target.value)}
                      type="text"
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.textField}
                      error={showPasswordError ? true : false}
                      helperText={showPasswordError ? strings.password.error : ''}
                      label={strings.password.label}
                      name="password"
                      onChange={(event) => this.handleChange('password', event.target.value)}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                  </div>
                  {submitError && (
                    <Typography className={classes.submitError} variant="body2">
                      {submitError}
                    </Typography>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={!isValid || loading}
                      onClick={this.handleSignIn}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {strings.action}
                    </Button>
                    {loading && <CircularProgress className={classes.progress} />}
                  </div>
                  <Divider className={classes.divider} variant="fullWidth" />
                  <Link component="button" href="/sign-up" variant="body1">
                    {strings.signUp}
                  </Link>
                  <span style={{ marginLeft: 8, marginRight: 8 }}>&mdash;</span>
                  <Link component="button" href="/reset-password" variant="body1">
                    {strings.resetPassword}
                  </Link>

                  <div className={classes.callToAction}>
                    <div>
                      &mdash;{' '}
                      <a
                        className={classes.CTALink}
                        target="_blank"
                        href="https://professionnels.provelo.org/les-velos-partages-shareabike/"
                        variant="body1"
                      >
                        Vous êtes un utilisateur ShareABike
                      </a>
                    </div>
                    <div>
                      &mdash;{' '}
                      <a
                        className={classes.CTALink}
                        target="_blank"
                        href="https://professionnels.provelo.org/services/achat-et-gestion-de-flotte/"
                      >
                        Vous êtes une entreprise
                      </a>
                    </div>
                    <div>
                      &mdash;{' '}
                      <a
                        className={classes.CTALink}
                        target="_blank"
                        href="https://professionnels.provelo.org/services/share-a-bike-velo-libre-service/"
                        variant="body1"
                      >
                        Vous êtes un service public
                      </a>
                    </div>
                  </div>
                </form>
              </div>
              <div className={classes.contentFooter}>
                <Divider className={classes.divider} variant="fullWidth" />
                <div className={classes.footerLinks}>
                  <Link component="button" href="/sign-in" variant="body1">
                    {strings.signIn}
                  </Link>
                  <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                  <Link component="button" href="/contact-us" variant="body1">
                    {strings.contactUs}
                  </Link>
                  <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                  <Link component="button" href="/terms-of-use" target="_blank" variant="body1">
                    {strings.CGU}
                  </Link>
                  <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                  <Link component="button" href="/help" target="_blank" variant="body1">
                    {strings.help}
                  </Link>
                  <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                  <Link component="button" href="/about" variant="body1">
                    {strings.about}
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  strings: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    loading: state.authentication.loading
  };
};

const mapDispatchToProps = {
  fetchProfile,
  signIn,
  logout
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
  translate('SignIn')
)(SignIn);
