import actions from './actions';

import * as priceService from 'services/price';

// eslint-disable-next-line no-unused-vars
export const fetchPrices = () => async (dispatch, getState) => {
  // if (getState().price.prices.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_PRICES });

  try {
    const prices = await priceService.getPrices();

    dispatch({
      type: actions.SET_PRICES,
      value: prices
    });

    dispatch({ type: actions.END_FETCH_PRICES });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_PRICES });
  }
};

// eslint-disable-next-line no-unused-vars
export const addPrice = (priceToAdd, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_ADD_PRICE });

  try {
    const organization = getState().organization.currentOrganization;

    const prices = await priceService.addPrice(organization._id, priceToAdd);

    dispatch({
      type: actions.SET_PRICES,
      value: prices
    });

    dispatch({ type: actions.END_ADD_PRICE });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_ADD_PRICE });
    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const deletePrice = (pricesToDelete, onFinished) => async (
  dispatch,
  getState
) => {
  dispatch({ type: actions.START_DELETING_PRICE });

  try {
    const organization = getState().organization.currentOrganization;

    const prices = await priceService.deletePrice(organization._id, pricesToDelete);

    dispatch({
      type: actions.SET_PRICES,
      value: prices
    });

    dispatch({ type: actions.END_DELETING_PRICE });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_DELETING_PRICE });
    onFinished(error);
  }
};
