import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import randomColor from 'randomcolor';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Material icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';

// Custom components
import BikeMap from './components/BikeMap';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Shared services
import { fetchBike, updateBike, addBikeLocation, endRent } from 'store/bike/actionsCreator';
import { fetchBikeTypes } from 'store/bikeType/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './style';

// Form validation schema
import schema from './schema';
import { string } from 'prop-types';

// Model
import { LockKindsLabel, LockKindsValues } from 'models/lockKind';

class BikeDetails extends Component {
  signal = true;

  state = {
    values: {
      name: { fr: '', en: '', nl: '' },
      isDefective: false,
      padlockKind: '',
      padlockMac: '',
      imageUrl: '',
      imagePreview: '',
      state: '',
      type: ''
    },
    touched: {
      name: { fr: false, en: false, nl: false },
      state: false,
      type: false,
      padlockKind: false,
      padlockMac: false
    },
    errors: {
      name: { fr: null, en: null, nl: null },
      state: null,
      padlockKind: null,
      padlockMac: null,
      type: null
    },
    open: false,
    isValid: false,
    submitErrorDetails: null,
    submitErrorLocation: null,
    submitErrorEndRent: null,
    coordinates: null,
    zoneColors: []
  };

  componentDidMount() {
    this.signal = true;

    if (
      this.props.currentOrganization &&
      this.props.currentOrganization.zones &&
      this.props.currentOrganization.zones.length > 0
    ) {
      let zoneColors = [];
      for (let i = 0; i < this.props.currentOrganization.zones.length; i++) {
        zoneColors.push(
          randomColor({
            luminosity: 'bright',
            format: 'rgb',
            seed: this.props.currentOrganization.zones[i].name
          })
        );
      }
      this.setState({
        zoneColors
      });
    }

    this.handleFetch();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.handleFetch();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors.name.fr = null;
    newState.errors.name.en = null;
    newState.errors.name.nl = null;
    newState.errors.state = null;
    newState.errors.type = null;
    newState.errors.padlockKind = null;
    newState.errors.padlockMac = null;
    newState.isValid = errors ? false : true;

    if (errors) {
      Object.keys(errors).forEach(function (key, index) {
        var field = key.split('.');

        if (Array.isArray(field) && field.length === 2) {
          newState.errors[field[0]][field[1]] = errors[key];
        } else {
          newState.errors[field] = errors[key];
        }
      });
    }

    this.setState(newState);
  };

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  handleChangeDetails = (field, value) => {
    const newState = { ...this.state };

    newState.submitErrorDetails = null;
    if (Array.isArray(field) && field.length === 2) {
      if (newState.touched[field[0]]) newState.touched[field[0]][field[1]] = true;
      if (newState.values[field[0]]) newState.values[field[0]][field[1]] = value;
    } else {
      newState.touched[field] = true;
      newState.values[field] = value;
    }

    this.setState(newState, this.validateForm);
  };

  handleChangeLocation = (value) => {
    const newState = { ...this.state };

    newState.submitErrorDetails = null;
    newState.coordinates = value;

    this.setState(newState);
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleFetch = async () => {
    const { strings } = this.props;

    // fetch all the bikeTypes
    await this.props.fetchBikeTypes();

    // fetch the bike by id
    const { error } = await this.props.fetchBike(this.props.match.params.id);
    if (error) {
      this.setState({
        submitErrorDetails: strings.errors.fetchError
      });

      return;
    }

    const { bike } = this.props;
    let values = { ...this.state.values };
    values.name.fr = bike.name.fr ? bike.name.fr : '';
    values.name.en = bike.name.en ? bike.name.en : '';
    values.name.nl = bike.name.nl ? bike.name.nl : '';
    values.isDefective = bike.isDefective;
    values.padlockKind = bike.padlock && bike.padlock.kind ? bike.padlock.kind : '';
    values.padlockMac = bike.padlock && bike.padlock.mac ? bike.padlock.mac : '';
    values.state = bike.state;
    values.type = bike.bikeType && bike.bikeType._id ? bike.bikeType._id : '';
    this.setState({
      values,
      submitErrorDetails: null
    });
  };

  handleSaveDetails = async () => {
    const { strings } = this.props;

    const { error } = await this.props.updateBike(this.props.match.params.id, this.state.values);
    if (error) {
      this.setState({
        submitErrorDetails: strings.errors.unknownError
      });

      return;
    }

    const { bike } = this.props;
    let values = { ...this.state.values };
    values.name.fr = bike.name.fr ? bike.name.fr : '';
    values.name.en = bike.name.en ? bike.name.en : '';
    values.name.nl = bike.name.nl ? bike.name.nl : '';
    values.isDefective = bike.isDefective;
    values.padlockKind = bike.padlock && bike.padlock.kind ? bike.padlock.kind : '';
    values.padlockMac = bike.padlock && bike.padlock.mac ? bike.padlock.mac : '';
    values.state = bike.state;
    values.type = bike.bikeType && bike.bikeType._id ? bike.bikeType._id : '';
    this.setState({
      values,
      isValid: false,
      submitErrorDetails: null
    });
    this.handleOpen();
  };

  handleSaveLocation = async () => {
    const { strings } = this.props;
    const { coordinates } = this.state;

    const { error } = await this.props.addBikeLocation(this.props.match.params.id, coordinates);
    if (error) {
      this.setState({
        submitErrorLocation: strings.errors.unknownError
      });

      return;
    }

    const { bike } = this.props;
    let values = { ...this.state.values };
    values.name.fr = bike.name.fr ? bike.name.fr : '';
    values.name.en = bike.name.en ? bike.name.en : '';
    values.name.nl = bike.name.nl ? bike.name.nl : '';
    values.isDefective = bike.isDefective;
    values.padlockKind = bike.padlock && bike.padlock.kind ? bike.padlock.kind : '';
    values.padlockMac = bike.padlock && bike.padlock.mac ? bike.padlock.mac : '';
    values.state = bike.state;
    values.type = bike.bikeType && bike.bikeType._id ? bike.bikeType._id : '';
    this.setState({
      values,
      submitErrorLocation: null
    });
    this.handleOpen();
  };

  handleEndRent = async () => {
    const { strings } = this.props;
    const { lastKnownLocation, userRent } = this.props.bike;

    const { error } = await this.props.endRent(
      this.props.match.params.id,
      lastKnownLocation.location.coordinates,
      userRent.user._id
    );
    if (error) {
      this.setState({
        submitErrorEndRent: strings.errors.endRentError
      });

      return;
    }

    const { bike } = this.props;
    let values = { ...this.state.values };
    values.name.fr = bike.name.fr ? bike.name.fr : '';
    values.name.en = bike.name.en ? bike.name.en : '';
    values.name.nl = bike.name.nl ? bike.name.nl : '';
    values.isDefective = bike.isDefective;
    values.padlockKind = bike.padlock && bike.padlock.kind ? bike.padlock.kind : '';
    values.padlockMac = bike.padlock && bike.padlock.mac ? bike.padlock.mac : '';
    values.state = bike.state;
    values.type = bike.bikeType && bike.bikeType._id ? bike.bikeType._id : '';
    this.setState({
      values,
      submitErrorEndRent: null
    });
    this.handleOpen();
  };

  render() {
    const { bike, bikeTypes, classes, currentOrganization, isBikeLoading, isBikeTypeLoading, strings } = this.props;

    const {
      values,
      touched,
      errors,
      open,
      isValid,
      submitErrorDetails,
      submitErrorLocation,
      submitErrorEndRent,
      coordinates,
      zoneColors
    } = this.state;

    const showNameFrError = touched.name.fr && errors.name.fr;
    const showNameEnError = touched.name.en && errors.name.en;
    const showNameNlError = touched.name.nl && errors.name.nl;
    const showStateError = touched.state && errors.state;
    const showTypeError = touched.type && errors.type;
    const showPadlockKindError = touched.padlockKind && errors.padlockKind;
    const showPadlockMacError = touched.padlockMac && errors.padlockMac;

    const states = [
      { value: 'Available', name: strings.common.available },
      { value: 'Booked', name: strings.common.booked },
      { value: 'Rented', name: strings.common.rented },
      { value: 'ToRelocate', name: strings.common.toRelocate },
      { value: 'Relocating', name: strings.common.relocating },
      { value: 'Repairing', name: strings.common.repairing },
      { value: 'Unavailable', name: strings.common.unavailable },
      { value: 'OutOfService', name: strings.common.outOfService }
    ];

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.header}>
          <IconButton className={classes.back} onClick={this.handleBack} title={strings.common.back}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className={classes.root}>
          <Portlet>
            <PortletHeader>
              <PortletLabel subtitle={strings.portletSubtitle} title={strings.portletTitle} />
              {bike.state === states[2].value && (
                <Button color="primary" onClick={() => this.handleEndRent()} size="small" variant="contained">
                  {strings.endRent}
                </Button>
              )}
            </PortletHeader>
            <PortletContent>
              {isBikeLoading && <LinearProgress className={classes.portletLoader} />}
              <form autoComplete="off" noValidate>
                <Grid container spacing={24}>
                  <Grid item md={3} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={showNameFrError ? true : false}
                      helperText={showNameFrError ? strings.name.fr.error : ''}
                      label={strings.name.fr.label}
                      margin="normal"
                      name="name.fr"
                      onChange={(event) => this.handleChangeDetails(['name', 'fr'], event.target.value)}
                      required
                      type="text"
                      value={values.name.fr}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={showNameEnError ? true : false}
                      helperText={showNameEnError ? strings.name.en.error : ''}
                      label={strings.name.en.label}
                      margin="normal"
                      name="name.en"
                      onChange={(event) => this.handleChangeDetails(['name', 'en'], event.target.value)}
                      type="text"
                      value={values.name.en}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={showNameNlError ? true : false}
                      helperText={showNameNlError ? strings.name.nl.error : ''}
                      label={strings.name.nl.label}
                      margin="normal"
                      name="name.nl"
                      onChange={(event) => this.handleChangeDetails(['name', 'nl'], event.target.value)}
                      type="text"
                      value={values.name.nl}
                      variant="outlined"
                    />
                    <FormControlLabel
                      className={classes.switchField}
                      control={
                        <Switch
                          checked={values.isDefective}
                          color="primary"
                          name="isDefective"
                          onChange={() => this.handleChangeDetails('isDefective', !values.isDefective)}
                          value={values.isDefective}
                        />
                      }
                      label={strings.isDefective}
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      InputProps={{
                        endAdornment: isBikeTypeLoading ? (
                          <InputAdornment position="end">
                            <CircularProgress />
                          </InputAdornment>
                        ) : null
                      }}
                      SelectProps={{
                        MenuProps: {}
                      }}
                      className={classes.textField}
                      disabled={isBikeTypeLoading}
                      error={showTypeError ? true : false}
                      helperText={showTypeError ? strings.type.error : ''}
                      label={strings.type.label}
                      margin="normal"
                      name="type"
                      onChange={(event) => this.handleChangeDetails('type', event.target.value)}
                      required
                      select
                      type="text"
                      value={values.type}
                      variant="outlined"
                    >
                      {bikeTypes.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      disabled={bike.state === states[2].value}
                      SelectProps={{
                        MenuProps: {}
                      }}
                      className={classes.textField}
                      error={showStateError ? true : false}
                      helperText={showStateError ? strings.state.error : ''}
                      label={strings.state.label}
                      margin="normal"
                      name="state"
                      onChange={(event) => this.handleChangeDetails('state', event.target.value)}
                      required
                      select
                      type="text"
                      value={values.state}
                      variant="outlined"
                    >
                      {states.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      SelectProps={{
                        MenuProps: {}
                      }}
                      className={classes.textField}
                      error={showPadlockKindError ? true : false}
                      helperText={showPadlockKindError ? strings.padlockKind.error : ''}
                      label={strings.padlockKind.label}
                      margin="normal"
                      name="padlockKind"
                      onChange={(event) => this.handleChangeDetails('padlockKind', event.target.value)}
                      required
                      select
                      type="text"
                      value={values.padlockKind}
                      variant="outlined"
                    >
                      {LockKindsValues.map((kind) => (
                        <MenuItem key={kind} value={kind}>
                          {LockKindsLabel[kind]}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      className={classes.textField}
                      error={showPadlockMacError ? true : false}
                      helperText={showPadlockMacError ? strings.padlockMac.error : ''}
                      label={strings.padlockMac.label}
                      margin="normal"
                      name="name"
                      onChange={(event) => this.handleChangeDetails('padlockMac', event.target.value)}
                      required
                      type="text"
                      value={values.padlockMac}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      label={strings.imageUrl}
                      margin="normal"
                      name="imageUrl"
                      onChange={(event) => this.handleChangeDetails(['imageUrl'], event.target.value)}
                      type="url"
                      value={values.imageUrl}
                      variant="outlined"
                    />
                    <img
                      alt=""
                      className={classes.imageField}
                      onError={() => {
                        let values = { ...this.state.values };
                        values.imagePreview = '/images/bike_placeholder.jpg';
                        this.setState({
                          values
                        });
                      }}
                      src={values.imagePreview ? values.imagePreview : '/images/bike_placeholder.jpg'}
                    />
                  </Grid>
                </Grid>
                {submitErrorDetails && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitErrorDetails}
                  </Typography>
                )}
                {submitErrorEndRent && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitErrorEndRent}
                  </Typography>
                )}
              </form>
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
              <Button color="primary" disabled={!isValid} onClick={this.handleSaveDetails} variant="contained">
                {strings.saveDetails}
              </Button>
            </PortletFooter>
          </Portlet>
        </div>
        {bike && (
          <div className={classes.root}>
            <Portlet>
              <PortletHeader>
                <PortletLabel subtitle={strings.mapSubtitle} title={strings.mapTitle} />
              </PortletHeader>
              <PortletContent className={classes.mapWrapper}>
                {isBikeLoading && <LinearProgress className={classes.portletLoader} />}
                <BikeMap
                  changeLocation={this.handleChangeLocation}
                  marker={bike}
                  zoneColors={zoneColors}
                  zones={currentOrganization.zones}
                />
                {submitErrorLocation && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitErrorLocation}
                  </Typography>
                )}
              </PortletContent>
              <PortletFooter className={classes.portletFooter}>
                <Button color="primary" disabled={!coordinates} onClick={this.handleSaveLocation} variant="contained">
                  {strings.saveLocation}
                </Button>
              </PortletFooter>
            </Portlet>
          </div>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          autoHideDuration={6000}
          className={classes.snackbar}
          onClose={this.handleClose}
          open={open}
        >
          <SnackbarContent
            action={[
              <IconButton className={classes.close} color="inherit" key="close" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            ]}
            aria-describedby="message-id"
            className={classes.snackbarContent}
            message={
              <span className={classes.message} id="message-id">
                <CheckCircleIcon />
                {strings.success}
              </span>
            }
          />
        </Snackbar>
      </DashboardLayout>
    );
  }
}

BikeDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isBikeLoading: state.bike.loading,
    bike: state.bike.bike,
    isBikeTypeLoading: state.bikeType.loading,
    bikeTypes: state.bikeType.bikeTypes
  };
};

const mapDispatchToProps = {
  fetchBike,
  updateBike,
  addBikeLocation,
  fetchBikeTypes,
  endRent
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('BikeDetails'),
  withStyles(styles)
)(BikeDetails);
