import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Externals
import { Chart } from 'react-chartjs-2';

// Material helpers
import { MuiThemeProvider } from '@material-ui/core/styles';

// ChartJS helpers
import { draw } from './helpers/chartjs';

// Theme
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

// Routes
import Routes from './Routes';

// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, { draw });

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router history={this.props.history}>
          <Routes />
        </Router>
      </MuiThemeProvider>
    );
  }

  getChildContext() {
    return {
      currentLanguage: this.props.currentLanguage
    };
  }
}

App.propTypes = {
  currentLanguage: PropTypes.string,
  history: PropTypes.object.isRequired
};

App.childContextTypes = {
  currentLanguage: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return { currentLanguage: state.localization.language };
}

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
