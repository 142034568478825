import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Material icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Shared services
import { fetchUser, updateUser } from '../../store/user/actionsCreator';

// Component styles
import styles from './style';

// Form validation schema
import schema from './schema';

class UserDetails extends Component {
  signal = true;

  state = {
    values: {
      userId: '',
      firstname: '',
      name: '',
      email: '',
      phone: '',
      birthYear: 0,
      street: '',
      postalCode: '',
      city: '',
      gender: '',
      phoneType: '',
      balance: 0,
      organizationRoles: []
    },
    touched: {
      firstname: false,
      name: false,
      email: false
    },
    errors: {
      firstname: null,
      name: null,
      email: null
    },
    open: false,
    isValid: false,
    submitError: null,
    roleIsVisible: false
  };

  componentDidMount() {
    this.signal = true;
    this.handleFetch();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.handleFetch();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors.firstname = null;
    newState.errors.name = null;
    newState.errors.email = null;
    newState.isValid = errors ? false : true;

    if (errors) {
      Object.keys(errors).forEach(function(key, index) {
        var field = key.split('.');

        if (Array.isArray(field) && field.length === 2) {
          newState.errors[field[0]][field[1]] = errors[key];
        } else {
          newState.errors[field] = errors[key];
        }
      });
    }

    this.setState(newState);
  };

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    if(field === 'role') { 
      var roleIndex = newState.values.organizationRoles.findIndex(r => r.organization_id === this.props.currentOrganization._id);
      newState.values.organizationRoles[roleIndex].role = value;
    }

    newState.submitError = null;
    if (Array.isArray(field) && field.length === 2) {
      if (newState.touched[field[0]])
        newState.touched[field[0]][field[1]] = true;
      if (newState.values[field[0]])
        newState.values[field[0]][field[1]] = value;
    } else {
      newState.touched[field] = true;
      newState.values[field] = value;
    }

    this.setState(newState, this.validateForm);
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleFetch = async () => {
    const { strings } = this.props;

    // fetch the organization by id
    const { error } = await this.props.fetchUser(this.props.match.params.id);
    if (error) {
      this.setState({
        submitError: strings.errors.fetchError
      });

      return;
    }
    
    const { user } = this.props;
    let { values, roleIsVisible } = this.state;

    var organizationRole = user.organizationRoles.find(r => r.organization_id === this.props.currentOrganization._id);
    if(organizationRole) {
      roleIsVisible = true;
    } else {
      roleIsVisible = false;
    }

    values.firstname = user.firstname;
    values.name = user.name;
    values.email = user.email;
    values.phone = user.phone;
    values.birthYear = user.birthYear;
    values.street = user.street;
    values.postalCode = user.postalCode;
    values.city = user.city;
    values.gender = user.gender;
    values.phoneType = user.phoneType;
    values.balance = user.balance;
    values.organizationRoles = user.organizationRoles
    this.setState({
      values,
      roleIsVisible,
      submitError: null
    });
  };

  handleSave = async () => {
    const { strings } = this.props;

    const {error} = await this.props.updateUser(this.props.match.params.id, this.state.values)
    if (error) {
      this.setState({
        submitError: strings.errors.unknownError
      });

      return;
    } 

    const { user } = this.props;
    let values = { ...this.state.values };

    values.firstname = user.firstname;
    values.name = user.name;
    values.email = user.email;
    values.phone = user.phone;
    values.birthYear = user.birthYear;
    values.street = user.street;
    values.postalCode = user.postalCode;
    values.city = user.city;
    values.gender = user.gender;
    values.phoneType = user.phoneType;
    values.balance = user.balance;
    values.organizationRoles = user.organizationRoles
    this.setState({
      values,
      isValid: false,
      submitError: null
    });
    this.handleOpen();
  };

  renderOrganizationRole = organizationRoles => {

    var organizationRole = organizationRoles.find(r => r.organization_id === this.props.currentOrganization._id);

    if(organizationRole) {
      return organizationRole.role;
    } else {
      return '';
    }
  }

  render() {
    const { classes, isLoading, strings } = this.props;
    const { values, touched, errors, isValid, submitError, open, roleIsVisible } = this.state;

    const genders = [
      { value: 'M', name: strings.man },
      { value: 'F', name: strings.woman }
    ];

    const phoneTypes = [
      { value: 'Android', name: 'Android' },
      { value: 'iPhone', name: 'iPhone' },
      { value: 'X', name: 'X' }
    ];

    const roles = [
      { value: 'User', name: strings.roles.user },
      { value: 'Transporter', name: strings.roles.transporter },
      { value: 'Operator', name: strings.roles.operator }
    ];

    const showFirstNameError = touched.firstname && errors.firstname;
    const showNameError = touched.name && errors.name;
    const showEmailError = touched.email && errors.email;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.header}>
          <IconButton
            className={classes.back}
            onClick={this.handleBack}
            title={strings.common.back}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className={classes.root}>
          <Portlet>
            <PortletHeader>
              <PortletLabel
                subtitle={strings.portletSubtitle}
                title={strings.portletTitle}
              />
            </PortletHeader>
            <PortletContent>
              {isLoading && (
                <LinearProgress className={classes.portletLoader} />
              )}
              <form autoComplete="off" noValidate>
                <Grid container spacing={24}>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h5">
                      {strings.personalDetails}
                    </Typography>
                    <TextField
                      className={classes.textField}
                      error={showFirstNameError ? true : false}
                      helperText={
                        showFirstNameError ? strings.firstname.error : ''
                      }
                      label={strings.firstname.label}
                      margin="normal"
                      name="firstname"
                      onChange={event =>
                        this.handleChange('firstname', event.target.value)
                      }
                      required
                      value={values.firstname}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={showNameError ? true : false}
                      helperText={showNameError ? strings.name.error : ''}
                      label={strings.name.label}
                      margin="normal"
                      name="name"
                      onChange={event =>
                        this.handleChange('name', event.target.value)
                      }
                      required
                      value={values.name}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={showEmailError ? true : false}
                      helperText={showEmailError ? strings.email.error : ''}
                      label={strings.email.label}
                      margin="normal"
                      name="email"
                      onChange={event =>
                        this.handleChange('email', event.target.value)
                      }
                      required
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      label={strings.phoneNumber.label}
                      margin="normal"
                      name="phone"
                      onChange={event =>
                        this.handleChange('phone', event.target.value)
                      }
                      value={values.phone}
                      variant="outlined"
                    />
                    <TextField
                      SelectProps={{
                        MenuProps: {}
                      }}
                      className={classes.textField}
                      label={strings.gender.label}
                      margin="normal"
                      name="gender"
                      onChange={event =>
                        this.handleChange('gender', event.target.value)
                      }
                      select
                      value={values.gender}
                      variant="outlined"
                    >
                      {genders.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      className={classes.shortTextField}
                      label={strings.birthYear.label}
                      margin="normal"
                      name="birthYear"
                      onChange={event =>
                        this.handleChange('birthYear', event.target.value)
                      }
                      type="number"
                      value={values.birthYear}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h5">{strings.address}</Typography>
                    <TextField
                      className={classes.textField}
                      label={strings.street.label}
                      margin="normal"
                      name="street"
                      onChange={event =>
                        this.handleChange('street', event.target.value)
                      }
                      value={values.street}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      label={strings.city.label}
                      margin="normal"
                      name="city"
                      onChange={event =>
                        this.handleChange('city', event.target.value)
                      }
                      value={values.city}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.shortTextField}
                      label={strings.postalCode.label}
                      margin="normal"
                      name="postalCode"
                      onChange={event =>
                        this.handleChange('postalCode', event.target.value)
                      }
                      type="number"
                      value={values.postalCode}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h5">{strings.userSettings}</Typography>
                    <TextField
                      SelectProps={{
                        MenuProps: {}
                      }}
                      className={classes.textField}
                      label={strings.phoneType.label}
                      margin="normal"
                      name="phoneType"
                      onChange={event =>
                        this.handleChange('phoneType', event.target.value)
                      }
                      select
                      value={values.phoneType}
                      variant="outlined"
                    >
                      {phoneTypes.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      className={classes.shortTextField}
                      label={strings.balance.label}
                      margin="normal"
                      name="balance"
                      onChange={event =>
                        this.handleChange('balance', event.target.value)
                      }
                      type="number"
                      value={values.balance}
                      variant="outlined"
                    />
                    {roleIsVisible && (
                      <TextField
                        SelectProps={{
                          MenuProps: {}
                        }}
                        className={classes.textField}
                        label={strings.organizationRole.label}
                        margin="normal"
                        name="role"
                        onChange={event =>
                          this.handleChange('role', event.target.value)
                        }
                        select
                        value={this.renderOrganizationRole(values.organizationRoles)}
                        variant="outlined"
                      >
                        {roles.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                </Grid>
                {submitError && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitError}
                  </Typography>
                )}
              </form>
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
              <Button
                color="primary"
                disabled={!isValid}
                onClick={this.handleSave}
                variant="contained"
              >
                {strings.save}
              </Button>
            </PortletFooter>
          </Portlet>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          autoHideDuration={6000}
          className={classes.snackbar}
          onClose={this.handleClose}
          open={open}
        >
          <SnackbarContent
            action={[
              <IconButton
                className={classes.close}
                color="inherit"
                key="close"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
            aria-describedby="message-id"
            className={classes.snackbarContent}
            message={
              <span className={classes.message} id="message-id">
                <CheckCircleIcon />
                {strings.success}
              </span>
            }
          />
        </Snackbar>
      </DashboardLayout>
    );
  }
}

UserDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.user.loading,
    user: state.user.user
  };
};

const mapDispatchToProps = {
  fetchUser,
  updateUser
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('UserDetails'),
  withStyles(styles)
)(UserDetails);
