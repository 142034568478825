import actions from './actions';

const initState = {
  user: {},
  token: null,
  isAuthenticated: false,
  loading: true,
  indeterminate: true
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.START_FETCH_PROFILE:
      return { ...state, loading: true };
    case actions.END_FETCH_PROFILE:
      console.log('update indeterminate');
      return { ...state, loading: false, indeterminate: false };
    case actions.START_SIGN_IN:
      return { ...state, loading: true };
    case actions.START_SIGN_UP:
      return { ...state, loading: true };
    case actions.START_SIGN_OUT:
      return { ...state, loading: true };
    case actions.START_VERIFY:
      return { ...state, loading: false };
    case actions.END_SIGN_IN:
      return { ...state, loading: false };
    case actions.END_SIGN_UP:
      return { ...state, loading: false };
    case actions.END_SIGN_OUT:
      return { ...state, loading: false, indeterminate: false };
    case actions.END_VERIFY:
      return { ...state, loading: false };
    case actions.SET_PROFILE:
      return {
        ...state,
        isAuthenticated: true,
        user: action.value.user,
        token: action.value.token
      };
    case actions.SET_UNAUTHENTICATED:
      localStorage.removeItem('x-auth');
      return { ...initState };
    default:
      return state;
  }
};

export default reducer;
