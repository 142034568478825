export default theme => ({
  root: {},
  chartWrapper: {
    height: '400px',
    position: 'relative'
  },
  progressWrapper: {
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textWrapper: {
    height: '400px',
    display: 'flex',
    justifyContent: 'center'
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});
