// Palette
import palette from 'theme/palette';

// Externals
import _ from 'underscore';

// Chart data
export const data = (users, strings) => {

  var data = {
    datasets: [],
    labels: [strings.basic, strings.complete, strings.creditcard]
  };

  var basicAccount = _.where(users, { accessLevel : 1 } ).length;
  var completeAccount = _.where(users, { accessLevel : 2 } ).length;
  var creditcardAccount = _.where(users, { accessLevel : 3 } ).length;
  var unknownAccount = users.length - (basicAccount + completeAccount + creditcardAccount);

  // Bookings
  let usersData = [
    (Math.round((100 * (basicAccount/users.length)) * 10) / 10),
    (Math.round((100 * (completeAccount/users.length)) * 10) / 10),
    (Math.round((100 * (creditcardAccount/users.length)) * 10) / 10),
    (Math.round((100 * (unknownAccount/users.length)) * 10) / 10),
  ];

  data.datasets.push({
    data: usersData,
    backgroundColor: [
      palette.warning.main,
      palette.info.main,
      palette.success.main,
      palette.common.muted
    ],
    borderWidth: 8,
    borderColor: palette.common.white,
    hoverBorderColor: palette.common.white
  })

  return data;
};

// Chart options
export const options = {
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.border,
    backgroundColor: palette.common.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  }
};
