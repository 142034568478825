export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    flexGrow: 1
  },
  back: {
    marginLeft: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
  },
  mapWrapper: {
    height: '60vh',
    minHeight: 200,
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center'
  },
  textWrapper: {
    paddingTop: '48px',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center'
  }
});
