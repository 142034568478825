import React, { useEffect } from 'react';

// Material
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

import styles from './styles';
import { useDispatch } from 'react-redux';

import { fetchProfile, logout } from '../../store/authentication/actionsCreator';

const Splashscreen = ({ classes }: { classes: any }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem('x-auth')) {
      handleFetch();
    } else {
      dispatch(logout());
    }
  }, []);

  const handleFetch = async () => {
    dispatch(
      fetchProfile((error: any) => {
        if (error) {
          dispatch(logout());
        }
      })
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.refreshWrapper}>
        <img alt="ShareABike" className={classes.refreshImage} src="/images/logos/logo-large.png" />
        <LinearProgress className={classes.refreshProgress} />
      </div>
    </div>
  );
};

export default withStyles(styles as any)(Splashscreen);
