import React, { Component } from 'react';

// Externals
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Typography from '@material-ui/core/Typography';

// Material icons
import Battery20 from '@material-ui/icons/Battery20';
import Battery30 from '@material-ui/icons/Battery30';
import Battery50 from '@material-ui/icons/Battery50';
import Battery60 from '@material-ui/icons/Battery60';
import Battery80 from '@material-ui/icons/Battery80';
import Battery90 from '@material-ui/icons/Battery90';
import Battery100 from '@material-ui/icons/BatteryFull';
import BatteryAlert from '@material-ui/icons/BatteryAlert';
import BatteryUnknown from '@material-ui/icons/BatteryUnknown';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class BatteryLevel extends Component {
  render() {
    const { classes, strings, battery = -1 } = this.props;

    let batteryIcon = <BatteryUnknown />;
    if (battery > 90) {
      batteryIcon = <Battery100 />;
    } else if (battery > 80) {
      batteryIcon = <Battery90 />;
    } else if (battery > 60) {
      batteryIcon = <Battery80 />;
    } else if (battery > 50) {
      batteryIcon = <Battery60 />;
    } else if (battery > 30) {
      batteryIcon = <Battery50 />;
    } else if (battery > 20) {
      batteryIcon = <Battery30 />;
    } else if (battery > 5) {
      batteryIcon = <Battery20 />;
    } else if (battery > -1) {
      batteryIcon = <BatteryAlert />;
    }

    return (
      <div className={classes.batteryContainer}>
        <Typography title={strings.batteryStatus} variant="subtitle2">
          {battery > -1 ? battery +'%' : ' '}
        </Typography>
        {batteryIcon}
      </div>
    );
  }
}

export default compose(
  translate('BatteryLevel'),
  withStyles(styles)
)(BatteryLevel);
