import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';

// Material icons
import ExploreIcon from '@material-ui/icons/ExploreOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

// Shared components
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';
import BatteryLevel from 'components/BatteryLevel';

// Common
// import * as roles from 'common/roles';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class BikesTable extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    selectedBikes: []
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleSelectAll = event => {
    const { bikes, onSelect } = this.props;

    let selectedBikes;

    if (event.target.checked) {
      selectedBikes = bikes.map(bike => bike._id);
    } else {
      selectedBikes = [];
    }

    this.setState({ selectedBikes });

    onSelect(selectedBikes);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedBikes } = this.state;

    const selectedIndex = selectedBikes.indexOf(id);
    let newSelectedBikes = [];

    if (selectedIndex === -1) {
      newSelectedBikes = newSelectedBikes.concat(selectedBikes, id);
    } else if (selectedIndex === 0) {
      newSelectedBikes = newSelectedBikes.concat(selectedBikes.slice(1));
    } else if (selectedIndex === selectedBikes.length - 1) {
      newSelectedBikes = newSelectedBikes.concat(selectedBikes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBikes = newSelectedBikes.concat(
        selectedBikes.slice(0, selectedIndex),
        selectedBikes.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedBikes: newSelectedBikes });

    onSelect(newSelectedBikes);
  };

  renderState = state => {
    const { strings } = this.props;

    switch (state) {
      case 'Available':
        return strings.common.available;
      case 'Booked':
        return strings.common.booked;
      case 'Rented':
        return strings.common.rented;
      case 'ToRelocate':
        return strings.common.toRelocate;
      case 'Relocating':
        return strings.common.relocating;
      case 'Repairing':
        return strings.common.repairing;
      case 'Unavailable':
        return strings.common.unavailable;
      case 'OutOfService':
        return strings.common.outOfService;
      default:
        return strings.common.unknown;
    }
  };

  render() {
    const { classes, className, bikes, strings } = this.props;
    const { page, rowsPerPage, selectedBikes } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedBikes.length === bikes.length}
                      color="primary"
                      indeterminate={
                        selectedBikes.length > 0 &&
                        selectedBikes.length < bikes.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="center">{strings.actions}</TableCell>
                  <TableCell align="left" padding="none">
                    {strings.name}
                  </TableCell>
                  <TableCell align="left">{strings.type}</TableCell>
                  <TableCell align="left">{strings.description}</TableCell>
                  <TableCell align="left">{strings.pricingPlan}</TableCell>
                  <TableCell align="left">{strings.state}</TableCell>
                  <TableCell align="center">{strings.battery}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bikes
                  .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map(bike => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={bike._id}
                      selected={selectedBikes.indexOf(bike._id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <div className={classes.tableCellInner}>
                          <Checkbox
                            checked={selectedBikes.indexOf(bike._id) !== -1}
                            color="primary"
                            onChange={event =>
                              this.handleSelectOne(event, bike._id)
                            }
                            value="true"
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        <Link to={'/bike/' + bike._id + '/locations'}>
                          <IconButton title={strings.bikelocationshistory}>
                            <ExploreIcon />
                          </IconButton>
                        </Link>
                        <Link to={'/bike/' + bike._id}>
                          <IconButton title={strings.bikedetails}>
                            <EditIcon />
                          </IconButton>
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell} padding="none">
                        <Typography
                          className={classes.nameText}
                          variant="body1"
                        >
                          {bike.name ? bike.name : '/'}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {bike.bikeType && bike.bikeType.name
                          ? bike.bikeType.name
                          : '/'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {bike.bikeType && bike.bikeType.description
                          ? bike.bikeType.description
                          : '/'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {bike.bikeType &&
                        bike.bikeType.pricingPlan &&
                        bike.bikeType.pricingPlan.name
                          ? bike.bikeType.pricingPlan.name
                          : '/'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {this.renderState(bike.state)}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        <div className={classes.tableCellInner}>
                          <BatteryLevel battery={bike.lastKnownLocation && bike.lastKnownLocation.batteryLevel ? bike.lastKnownLocation.batteryLevel : -1} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            backIconButtonProps={{
              'aria-label': strings.common.previousPage
            }}
            component="div"
            count={bikes.length}
            labelDisplayedRows={({ from, to, count }) => {
              return from + '-' + to + ' ' + strings.common.of + ' ' + count;
            }}
            labelRowsPerPage={strings.common.rowsPerPage}
            nextIconButtonProps={{
              'aria-label': strings.common.nextPage
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

BikesTable.propTypes = {
  bikes: PropTypes.array.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  strings: PropTypes.object.isRequired
};

BikesTable.defaultProps = {
  bikes: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

const mapStateToProps = state => {
  return {
    role: state.authorize.role
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('BikesTable'),
  withStyles(styles)
)(BikesTable);
