import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

// Shared components
import SearchInput from 'components/SearchInput';

// Translations
import translate from 'helpers/translate';

import { deleteInvitations } from 'store/user/actionsCreator';

// Component styles
import styles from './styles';

class InvitationsToolbar extends Component {
  handleDeleteInvitations = () => {
    this.props.deleteInvitations(this.props.selectedInvitations);
  };

  render() {
    const {
      classes,
      className,
      selectedInvitations,
      strings,
      deleteLoading
    } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            onChange={this.props.onFilterChange}
            placeholder={strings.search}
          />
          <span className={classes.spacer} />
          {selectedInvitations.length > 0 && (
            <div className={classes.wrapper}>
              <Button
                className={classes.deleteButton}
                disabled={deleteLoading}
                onClick={this.handleDeleteInvitations}
                size="small"
                variant="contained"
              >
                <DeleteIcon className={classes.deleteIcon} />{' '}
                {strings.common.delete}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

InvitationsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func,
  selectedInvitations: PropTypes.array,
  strings: PropTypes.object.isRequired
};

InvitationsToolbar.defaultProps = {
  onFilterChange: () => {},
  selectedInvitations: []
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    invitations: state.user.invitations
  };
};

const mapDispatchToProps = {
  deleteInvitations
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('InvitationsToolbar'),
  withStyles(styles)
)(InvitationsToolbar);
