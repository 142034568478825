export const getMessages = () =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/contacts/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.json();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const addMessage = async ({ name, email, message, profile, city }) => {
  try {
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/contacts/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, email, message, profile, city })
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      const text = await response.json();
      throw new Error(text);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteMessages = (messages) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/contacts', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        },
        body: JSON.stringify(messages)
      });
      const json = await response.json();
      if (response.ok) {
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const authorize = async (call, ...props) => {
  const promise = new Promise(async (resolve, reject) => {
    const auth = localStorage.getItem('x-auth');
    if (!auth) {
      reject({
        error: 'missing auth token'
      });
      return;
    }
    await call(resolve, reject, auth, ...props);
  });
  return promise;
};
