import actions from './actions';

import * as bikeTypeService from 'services/bikeType';

// eslint-disable-next-line no-unused-vars
export const fetchBikeTypes = () => async (dispatch, getState) => {
  // if (getState().bikeType.bikeTypes.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_BIKETYPES });

  try {
    const organization = getState().organization.currentOrganization;

    const bikeTypes = await bikeTypeService.getBikeTypes(organization._id);

    dispatch({
      type: actions.SET_BIKETYPES,
      value: bikeTypes
    });

    dispatch({ type: actions.END_FETCH_BIKETYPES });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_BIKETYPES });
  }
};

// eslint-disable-next-line no-unused-vars
export const deleteBikeTypes = (bikeTypesToDelele) => async (dispatch, getState) => {
  dispatch({ type: actions.START_DELETING_BIKETYPES });

  try {
    const organization = getState().organization.currentOrganization;

    const bikeTypes = await bikeTypeService.deleteBikeTypes(organization._id, bikeTypesToDelele);

    dispatch({
      type: actions.SET_BIKETYPES,
      value: bikeTypes
    });

    dispatch({ type: actions.END_DELETING_BIKETYPES });
    return {error : null};
  } catch (error) {
    dispatch({ type: actions.END_DELETING_BIKETYPES });
    return {error : error};
  }
};

// eslint-disable-next-line no-unused-vars
export const addBikeType = (bikeType, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_ADD_BIKETYPE });

  try {
    const organization = getState().organization.currentOrganization;

    const bikeTypes = await bikeTypeService.addBikeType(organization._id, bikeType);

    dispatch({
      type: actions.SET_BIKETYPES,
      value: bikeTypes
    });

    dispatch({ type: actions.END_ADD_BIKETYPE });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_ADD_BIKETYPE });
    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const fetchBikeType = (bikeTypeId, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_FETCH_BIKETYPE });

  try {
    const organization = getState().organization.currentOrganization;

    const bikeType = await bikeTypeService.getBikeType(organization._id, bikeTypeId);

    dispatch({
      type: actions.SET_BIKETYPE,
      value: bikeType
    });

    dispatch({ type: actions.END_FETCH_BIKETYPE });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_FETCH_BIKETYPE });
    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const updateBikeType = (bikeTypeId, bikeTypeToUpdate, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_UPDATE_BIKETYPE });

  try {
    const organization = getState().organization.currentOrganization;

    const bikeType = await bikeTypeService.updateBike(organization._id, bikeTypeId, bikeTypeToUpdate);

    dispatch({
      type: actions.SET_BIKETYPE,
      value: bikeType
    });

    dispatch({ type: actions.END_UPDATE_BIKETYPE });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_UPDATE_BIKETYPE });
    onFinished(error);
  }
};
