import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Common
import PrivateRoute from './helpers/PrivateRoute';
import AnonymousRoute from './helpers/AnonymousRoute';

// Views
import AllUserList from './views/AllUserList';
import BikeDetails from './views/BikeDetails';
import BikeList from './views/BikeList';
import BikeLocationDetails from './views/BikeLocationDetails';
import BikeTypeDetails from './views/BikeTypeDetails';
import BikeTypeList from './views/BikeTypeList';
import CommentsList from './views/CommentsList';
import ContactForm from './views/ContactForm';
import HomeSelector from './views/Home';
import InvitationList from './views/InvitationList';
import NotFound from './views/NotFound';
import OrganizationDetails from './views/OrganizationDetails';
import OrganizationList from './views/OrganizationList';
import PriceList from './views/PriceList';
import PricingPlanDetails from './views/PricingPlanDetails';
import PricingPlanList from './views/PricingPlanList';
import RentList from './views/RentList';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import About from './views/About';
import Help from './views/Help';
import TermsOfUse from './views/TermsOfUse';
import ContactUs from './views/ContactUs';
import Verify from './views/Verify';
import UnderDevelopment from './views/UnderDevelopment';
import UserDetails from './views/UserDetails';
import UserList from './views/UserList';
import ResetPassword from 'views/ResetPassword';
import ResetPasswordConfirmation from 'views/ResetPasswordConfirmation';
import Checkout from './views/Checkout';

import * as Roles from './common/roles';
import { useSelector } from 'react-redux';
import Splashscreen from './views/Splashscreen';

const Routes = () => {
  const indeterminate = useSelector((state: any) => state.authentication.indeterminate);

  console.log('routes', indeterminate);
  if (indeterminate) {
    return <Splashscreen />;
  }

  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <PrivateRoute component={HomeSelector} exact path="/home" />
      <PrivateRoute component={Checkout} exact path="/checkout" />

      <PrivateRoute authorizedRoles={[Roles.Admin]} component={AllUserList} exact path="/all-users" />
      <PrivateRoute authorizedRoles={[Roles.Admin, Roles.Operator]} component={UserList} exact path="/users" />
      <PrivateRoute authorizedRoles={[Roles.Admin, Roles.Operator]} component={UserDetails} exact path="/user/:id" />
      <PrivateRoute
        authorizedRoles={[Roles.Admin, Roles.Operator]}
        component={InvitationList}
        exact
        path="/invitations"
      />
      <PrivateRoute authorizedRoles={[Roles.Admin, Roles.Operator]} component={BikeList} exact path="/bikes" />
      <PrivateRoute authorizedRoles={[Roles.Admin, Roles.Operator]} component={BikeDetails} exact path="/bike/:id" />
      <PrivateRoute
        authorizedRoles={[Roles.Admin, Roles.Operator]}
        component={BikeLocationDetails}
        exact
        path="/bike/:id/locations"
      />
      <PrivateRoute authorizedRoles={[Roles.Admin, Roles.Operator]} component={BikeTypeList} exact path="/bike-types" />
      <PrivateRoute
        authorizedRoles={[Roles.Admin, Roles.Operator]}
        component={BikeTypeDetails}
        exact
        path="/bike-type/:id"
      />
      <PrivateRoute authorizedRoles={[Roles.Admin, Roles.Operator]} component={CommentsList} exact path="/comments" />
      <PrivateRoute
        authorizedRoles={[Roles.Admin, Roles.Operator]}
        component={PricingPlanList}
        exact
        path="/pricing-plans"
      />
      <PrivateRoute
        authorizedRoles={[Roles.Admin, Roles.Operator]}
        component={PricingPlanDetails}
        exact
        path="/pricing-plan/:id"
      />
      <PrivateRoute authorizedRoles={[Roles.Admin, Roles.Operator]} component={RentList} exact path="/rents" />
      <PrivateRoute authorizedRoles={[Roles.Admin]} component={OrganizationList} exact path="/organizations" />
      <PrivateRoute
        authorizedRoles={[Roles.Admin, Roles.Operator]}
        component={OrganizationDetails}
        exact
        path="/organization/:id"
      />
      <PrivateRoute authorizedRoles={[Roles.Admin]} component={PriceList} exact path="/prices" />
      <PrivateRoute authorizedRoles={[Roles.Admin]} component={ContactForm} exact path="/contact-form" />

      <AnonymousRoute component={SignIn} exact path="/sign-in" />
      <AnonymousRoute component={SignUp} exact path="/sign-up" />
      <AnonymousRoute component={ResetPassword} exact path="/reset-password" />
      <AnonymousRoute component={ResetPasswordConfirmation} exact path="/confirm-reset-password" />
      <AnonymousRoute component={Verify} exact path="/verify" />
      <AnonymousRoute component={About} exact path="/about" />
      <AnonymousRoute component={Help} exact path="/help" />
      <AnonymousRoute component={TermsOfUse} exact path="/terms-of-use" />
      <AnonymousRoute component={ContactUs} exact path="/contact-us" />

      <Route component={UnderDevelopment} exact path="/under-development" />
      <Route component={NotFound} exact path="/not-found" />
    </Switch>
  );
};

export default Routes;
