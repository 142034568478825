import React from 'react';
import { connect } from 'react-redux';

import { CardElement, injectStripe, Elements, StripeProvider } from 'react-stripe-elements';
import 'react-stripe-script-loader';

// Externals
import compose from 'recompose/compose';
import validate from 'validate.js';

// Material Helpers
import { withStyles } from '@material-ui/core/styles';

// Material Components
import Button from '@material-ui/core/Button';
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

// Material Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
// import DeleteIcon from '@material-ui/icons/Delete';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

import {
  updateUserProfile,
  fetchPaymentInfo,
  updatePaymentInfo,
  deletePaymentMethod
} from '../../store/user/actionsCreator';

// Component styles
import styles from './style';

// Schema
import BaseSchema from './Schema/baseSchema';
import MoreSchema from './Schema/moreSchema';
import { string } from 'prop-types';
import ConfirmDeleteAccountDialog from './components/ConfirmDeleteAccountDialog';

const createOptions = (fontSize) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  };
};

class _UserForm extends React.Component {
  state = {
    baseValues: {
      firstname: '',
      name: '',
      email: ''
    },
    moreValues: {
      phone: '',
      birthYear: 0,
      street: '',
      postalCode: '',
      city: '',
      gender: '',
      phoneType: ''
    },
    baseTouched: {
      firstname: false,
      name: false,
      email: false
    },
    moreTouched: {},
    baseInfoErrors: {
      firstname: null,
      name: null,
      email: null
    },
    moreInfoErrors: {},
    paymentError: null,
    open: false,
    isBaseInfoValid: false,
    isMoreInfoValid: false,
    isPaymentInfoValid: false,
    submitBaseError: null,
    submitMoreError: null
  };

  componentDidMount() {
    const { user } = this.props;
    let { baseValues, moreValues } = this.state;
    baseValues.firstname = user.firstname;
    baseValues.name = user.name;
    baseValues.email = user.email;
    moreValues.phone = user.phone;
    moreValues.birthYear = user.birthYear;
    moreValues.street = user.street;
    moreValues.postalCode = user.postalCode;
    moreValues.city = user.city;
    moreValues.gender = user.gender;
    moreValues.phoneType = user.phoneType;
    this.setState({
      openConfirmDeleteAccountDialog: false,
      baseValues,
      moreValues,
      submitBaseError: null,
      submitMoreError: null
    });
    this.props.fetchPaymentInfo();
  }

  handleBasisInfoChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitBaseError = null;
    newState.submitMoreError = null;
    if (Array.isArray(field) && field.length === 2) {
      if (newState.baseTouched[field[0]]) newState.baseTouched[field[0]][field[1]] = true;
      if (newState.baseValues[field[0]]) newState.baseValues[field[0]][field[1]] = value;
    } else {
      newState.baseTouched[field] = true;
      newState.baseValues[field] = value;
    }

    this.setState(newState, this.validateBasisInfoForm);
  };

  handleMoreInfoChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitBaseError = null;
    newState.submitMoreError = null;
    if (Array.isArray(field) && field.length === 2) {
      if (newState.moreTouched[field[0]]) newState.moreTouched[field[0]][field[1]] = true;
      if (newState.moreValues[field[0]]) newState.moreValues[field[0]][field[1]] = value;
    } else {
      newState.moreTouched[field] = true;
      newState.moreValues[field] = value;
    }

    this.setState(newState, this.validateMoreInfoForm);
  };

  validateBasisInfoForm = () => {
    const { baseValues } = this.state;

    const newState = { ...this.state };
    const errors = validate(baseValues, BaseSchema);

    newState.baseInfoErrors.firstname = null;
    newState.baseInfoErrors.name = null;
    newState.baseInfoErrors.email = null;
    newState.isBaseInfoValid = errors ? false : true;

    if (errors) {
      Object.keys(errors).forEach(function (key) {
        var field = key.split('.');

        if (Array.isArray(field) && field.length === 2) {
          newState.baseInfoErrors[field[0]][field[1]] = errors[key];
        } else {
          newState.baseInfoErrors[field] = errors[key];
        }
      });
    }

    this.setState(newState);
  };

  validateMoreInfoForm = () => {
    const { moreValues } = this.state;

    const newState = { ...this.state };
    const errors = validate(moreValues, MoreSchema);

    newState.isMoreInfoValid = errors ? false : true;

    if (errors) {
      Object.keys(errors).forEach(function (key) {
        var field = key.split('.');

        if (Array.isArray(field) && field.length === 2) {
          newState.moreInfoErrors[field[0]][field[1]] = errors[key];
        } else {
          newState.moreInfoErrors[field] = errors[key];
        }
      });
    }

    this.setState(newState);
  };

  handleSave = async (saveIndex) => {
    const { strings, user } = this.props;
    let newState = { ...this.state };

    switch (saveIndex) {
      case 0: {
        const uptadedUser = Object.assign(user, this.state.baseValues);
        const result = await this.props.updateUserProfile(user._id, uptadedUser);
        if (!result.error) {
          newState.baseValues.firstname = result.user.firstname;
          newState.baseValues.name = result.user.name;
          newState.baseValues.email = result.user.email;
          newState.isBaseInfoValid = false;
          newState.submitBaseError = null;
          this.setState(newState);
          this.handleOpen();
        } else {
          this.setState({
            submitBaseError: strings.errors.unknownError
          });
        }
        break;
      }
      case 1: {
        const uptadedUser = Object.assign(user, this.state.moreValues);
        const result = await this.props.updateUserProfile(user._id, uptadedUser);
        if (!result.error) {
          newState.moreValues.phone = result.user.phone;
          newState.moreValues.birthYear = result.user.birthYear;
          newState.moreValues.street = result.user.street;
          newState.moreValues.postalCode = result.user.postalCode;
          newState.moreValues.city = result.user.city;
          newState.moreValues.gender = result.user.gender;
          newState.moreValues.phoneType = result.user.phoneType;
          newState.isMoreInfoValid = false;
          newState.submitMoreError = null;
          this.setState(newState);
          this.handleOpen();
        } else {
          this.setState({
            submitMoreError: strings.errors.unknownError
          });
        }
        break;
      }
      case 2: {
        if (this.props.stripe) {
          this.props.stripe.createToken({ type: 'card' }).then(this.handleUpdatePayment);
        } else {
          this.setState({
            paymentError: strings.errors.unknownError
          });
        }
        break;
      }
      default:
        break;
    }
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleCardChange = (event) => {
    let { isPaymentInfoValid } = this.state;

    if (event.error) {
      this.setState({ paymentError: event.error.message });
    } else {
      isPaymentInfoValid = event.complete;
      this.setState({ isPaymentInfoValid, paymentError: null });
    }
  };

  handleUpdatePayment = (result) => {
    const { strings } = this.props;
    let newState = { ...this.state };
    if (!result.error) {
      this.props.updatePaymentInfo(result.token.id, (error) => {
        if (!error) {
          newState.paymentSource = null;
          newState.isPaymentInfoValid = false;
          this.setState(newState);
          this.handleOpen();
        } else {
          this.setState({
            paymentError: strings.errors.unknownError
          });
        }
      });
    } else {
      this.setState({
        paymentError: strings.errors.unknownError
      });
    }
  };

  handleDeletePayment = async () => {
    const { strings, paymentSource } = this.props;

    const { error } = await this.props.deletePaymentMethod(paymentSource.id);
    if (error) {
      this.setState({
        paymentError: strings.errors.deleteError
      });

      return;
    }

    this.handleOpen();
  };

  render() {
    const { classes, strings } = this.props;
    const {
      baseValues,
      moreValues,
      baseTouched,
      baseInfoErrors,
      paymentError,
      isBaseInfoValid,
      isMoreInfoValid,
      isPaymentInfoValid,
      submitBaseError,
      submitMoreError,
      open
    } = this.state;

    const genders = [
      { value: 'M', name: strings.genders.man },
      { value: 'F', name: strings.genders.woman },
      { value: 'O', name: strings.genders.other }
    ];

    const phoneTypes = [
      { value: 'Android', name: strings.phoneTypes.android },
      { value: 'iPhone', name: strings.genders.iphone },
      { value: 'X', name: strings.genders.unknown }
    ];

    const showFirstNameError = baseTouched.firstname && baseInfoErrors.firstname;
    const showNameError = baseTouched.name && baseInfoErrors.name;
    const showEmailError = baseTouched.email && baseInfoErrors.email;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <div className={classes.content}>
            <Portlet>
              <PortletHeader>
                <PortletLabel title={strings.card1} />
              </PortletHeader>
              <PortletContent>
                <form autoComplete="off" noValidate>
                  <Grid container spacing={24}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        className={classes.textField}
                        error={showFirstNameError ? true : false}
                        helperText={showFirstNameError ? strings.firstname.error : ''}
                        label={strings.firstname.label}
                        margin="normal"
                        name="firstname"
                        onChange={(event) => this.handleBasisInfoChange('firstname', event.target.value)}
                        value={baseValues.firstname}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        error={showNameError ? true : false}
                        helperText={showNameError ? strings.name.error : ''}
                        label={strings.name.label}
                        margin="normal"
                        name="name"
                        onChange={(event) => this.handleBasisInfoChange('name', event.target.value)}
                        value={baseValues.name}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        error={showEmailError ? true : false}
                        helperText={showEmailError ? strings.email.error : ''}
                        label={strings.email.label}
                        margin="normal"
                        name="email"
                        onChange={(event) => this.handleBasisInfoChange('email', event.target.value)}
                        value={baseValues.email}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  {submitBaseError && (
                    <Typography className={classes.submitError} variant="body2">
                      {submitBaseError}
                    </Typography>
                  )}
                </form>
              </PortletContent>
              <PortletFooter className={classes.portletFooter}>
                <Button
                  color="primary"
                  disabled={!isBaseInfoValid}
                  onClick={() => this.handleSave(0)}
                  variant="contained"
                >
                  {strings.save}
                </Button>
              </PortletFooter>
            </Portlet>
          </div>
          <div className={classes.content}>
            <Portlet>
              <PortletHeader>
                <PortletLabel title={strings.card2} />
              </PortletHeader>
              <PortletContent>
                <form autoComplete="off" noValidate>
                  <Grid container spacing={24}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        className={classes.textField}
                        label={strings.phoneNumber.label}
                        margin="normal"
                        name="phone"
                        onChange={(event) => this.handleMoreInfoChange('phone', event.target.value)}
                        value={moreValues.phone}
                        variant="outlined"
                      />
                      <TextField
                        SelectProps={{
                          MenuProps: {}
                        }}
                        className={classes.textField}
                        label={strings.gender.label}
                        margin="normal"
                        name="gender"
                        onChange={(event) => this.handleMoreInfoChange('gender', event.target.value)}
                        select
                        value={moreValues.gender}
                        variant="outlined"
                      >
                        {genders.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        SelectProps={{
                          MenuProps: {}
                        }}
                        className={classes.textField}
                        label={strings.phoneType.label}
                        margin="normal"
                        name="phoneType"
                        onChange={(event) => this.handleMoreInfoChange('phoneType', event.target.value)}
                        select
                        value={moreValues.phoneType}
                        variant="outlined"
                      >
                        {phoneTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        className={classes.textField}
                        label={strings.street.label}
                        margin="normal"
                        name="street"
                        onChange={(event) => this.handleMoreInfoChange('street', event.target.value)}
                        value={moreValues.street}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        label={strings.city.label}
                        margin="normal"
                        name="city"
                        onChange={(event) => this.handleMoreInfoChange('city', event.target.value)}
                        value={moreValues.city}
                        variant="outlined"
                      />
                      <Grid container spacing={24}>
                        <Grid item xs={6}>
                          <TextField
                            className={classes.textField}
                            label={strings.postalCode.label}
                            margin="normal"
                            name="postalCode"
                            onChange={(event) => this.handleMoreInfoChange('postalCode', event.target.value)}
                            type="text"
                            value={moreValues.postalCode}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            className={classes.textField}
                            label={strings.birthYear.label}
                            margin="normal"
                            name="birthYear"
                            onChange={(event) => this.handleMoreInfoChange('birthYear', event.target.value)}
                            type="number"
                            value={moreValues.birthYear}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {submitMoreError && (
                    <Typography className={classes.submitError} variant="body2">
                      {submitMoreError}
                    </Typography>
                  )}
                </form>
              </PortletContent>
              <PortletFooter className={classes.portletFooter}>
                <Button
                  color="primary"
                  disabled={!isMoreInfoValid}
                  onClick={() => this.handleSave(1)}
                  variant="contained"
                >
                  {strings.save}
                </Button>
              </PortletFooter>
            </Portlet>
          </div>
          {/* Credits */}
          <div className={classes.content}>
            <Portlet>
              <PortletHeader>
                <PortletLabel title={strings.card3} />
              </PortletHeader>
              <PortletContent>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h4" style={{ marginBottom: 8 }}>
                      {strings.creditBalance + this.props.user.balance}{' '}
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Pour approvisionner votre compte en crédits, sélectionnez le pack que vous souhaitez, et cliquez
                      sur "Ajouter des crédits".
                    </Typography>
                    <Button
                      href="/checkout"
                      className={classes.addCreditButton}
                      size="medium"
                      variant="contained"
                      style={{ marginBottom: 24 }}
                    >
                      {strings.addCredit}
                    </Button>
                  </Grid>
                  {/* <Grid item md={12} xs={12}>
                    {this.props.paymentSource.last4 && (
                      <>
                        <Grid container spacing={24}>
                          <Grid item xs={12}>
                            <Typography variant="h5">{strings.savedCard}</Typography>
                          </Grid>
                          <Grid item>
                            <Card className={classes.savedCard}>
                              <div className={classes.card}>
                                <Typography variant="body1">**** **** **** {this.props.paymentSource.last4}</Typography>
                                <Typography className={classes.expireDate} variant="body1">
                                  {this.props.paymentSource.exp_month} / {this.props.paymentSource.exp_year}
                                </Typography>
                              </div>
                            </Card>
                          </Grid>
                          <Grid item>
                            <Button
                              className={classes.deleteButton}
                              onClick={this.handleDeletePayment}
                              size="small"
                              variant="contained"
                            >
                              <DeleteIcon className={classes.deleteIcon} /> {strings.common.delete}
                            </Button>
                          </Grid>
                        </Grid>

                        <Grid container spacing={24}>
                          <Grid item md={4} sm={8} xs={12}>
                            <Typography variant="h5">{strings.updateCard}</Typography>
                            <Card className={classes.newCard}>
                              <CardElement {...createOptions(18)} onChange={this.handleCardChange} />
                            </Card>
                            {paymentError && (
                              <Typography className={classes.submitError} variant="body2">
                                {paymentError}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {!this.props.paymentSource.last4 && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body1" style={{ marginBottom: 8 }}>
                            {strings.addCard}
                          </Typography>
                          <Card className={classes.newCard}>
                            <CardElement {...createOptions(18)} onChange={this.handleCardChange} />
                          </Card>
                          {paymentError && (
                            <Typography className={classes.submitError} variant="body2">
                              {paymentError}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid> */}
                </Grid>
              </PortletContent>
            </Portlet>
          </div>
          <div className={classes.content}>
            <Portlet>
              <PortletHeader>
                <PortletLabel title={strings.deleteAccountTitle} />
              </PortletHeader>
              <PortletContent>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8, fontWeight: 'bold' }}>
                      {strings.deleteAccountWarning}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      {strings.deleteAccountRemainingCredits}
                    </Typography>
                    <Button
                      className={classes.deleteAccountButton}
                      size="medium"
                      variant="contained"
                      onClick={() => this.setState({ openConfirmDeleteAccountDialog: true })}
                      style={{ marginBottom: 24 }}
                    >
                      {strings.deleteAccountTitle}
                    </Button>
                  </Grid>
                </Grid>
              </PortletContent>
            </Portlet>
          </div>
        </div>
        <ConfirmDeleteAccountDialog
          handleClose={() => this.setState({ openConfirmDeleteAccountDialog: false })}
          open={this.state.openConfirmDeleteAccountDialog}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          autoHideDuration={6000}
          className={classes.snackbar}
          onClose={this.handleClose}
          open={open}
        >
          <SnackbarContent
            action={[
              <IconButton className={classes.close} color="inherit" key="close" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            ]}
            aria-describedby="message-id"
            className={classes.snackbarContent}
            message={
              <span className={classes.message} id="message-id">
                <CheckCircleIcon />
                {strings.success}
              </span>
            }
          />
        </Snackbar>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    paymentSource: state.user.paymentSource
  };
};

const mapDispatchToProps = {
  updateUserProfile,
  fetchPaymentInfo,
  updatePaymentInfo,
  deletePaymentMethod
};

const UserForm = injectStripe(
  compose(connect(mapStateToProps, mapDispatchToProps), translate('UserProfile'), withStyles(styles))(_UserForm)
);

export default class UserProfile extends React.Component {
  render() {
    return (
      <StripeProvider apiKey="pk_test_Z11QYlvk4vEqGXjaw6YVBSXe">
        <Elements>
          <UserForm />
        </Elements>
      </StripeProvider>
    );
  }
}
