import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

// Shared components
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

import * as RentStatus from 'common/rentStatus';

class RentsTable extends Component {
  state = {
    rowsPerPage: 10,
    page: 0
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, className, rents, strings } = this.props;
    const { rowsPerPage, page } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{strings.startDate}</TableCell>
                  <TableCell align="left">{strings.endDate}</TableCell>
                  <TableCell align="left">{strings.bike}</TableCell>
                  <TableCell align="left">{strings.price}</TableCell>
                  <TableCell align="left">{strings.user}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rents.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((rent) => (
                  <TableRow className={classes.tableRow} hover key={rent._id}>
                    <TableCell className={classes.tableCell}>
                      {rent.startDate
                        ? moment(rent.startDate).format('DD/MM/YYYY ' + strings.common.to + ' HH:mm')
                        : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {!rent.endDate || rent.status === RentStatus.Running
                        ? '/'
                        : moment(rent.endDate).format('DD/MM/YYYY ' + strings.common.to + ' HH:mm')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {rent.bike && rent.bike.name ? rent.bike.name : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{rent.price ? rent.price : '/'}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {rent.user && rent.user.fullname ? rent.user.fullname : '/'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            backIconButtonProps={{
              'aria-label': strings.common.previousPage
            }}
            component="div"
            count={rents.length}
            labelDisplayedRows={({ from, to, count }) => {
              return from + '-' + to + ' ' + strings.common.of + ' ' + count;
            }}
            labelRowsPerPage={strings.common.rowsPerPage}
            nextIconButtonProps={{
              'aria-label': strings.common.nextPage
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

RentsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  rents: PropTypes.array.isRequired,
  strings: PropTypes.object.isRequired
};

RentsTable.defaultProps = {
  rents: []
};

export default compose(
  translate('RentsTable'),
  withStyles(styles)
)(RentsTable);
