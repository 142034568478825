import actions from './actions';

const initState = {
  loading: false,
  prices: []
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.START_FETCH_PRICES:
      return { ...state, loading: true };
    case actions.END_FETCH_PRICES:
      return { ...state, loading: false };
    case actions.START_ADD_PRICE:
      return { ...state, loading: true };
    case actions.END_ADD_PRICE:
      return { ...state, loading: false };
    case actions.START_DELETE_PRICE:
      return { ...state, loading: true };
    case actions.END_DELETE_PRICE:
      return { ...state, loading: false };
    case actions.SET_PRICES:
      return { ...state, prices: action.value };
    default:
      return state;
  }
};

export default reducer;
