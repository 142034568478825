import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Translations
import translate from 'helpers/translate';

import {
  setCurrentOrganizationById,
  fetchOrganizations
} from 'store/organization/actionsCreator';

// Component styles
import styles from './styles';

class OrganizationSwitch extends Component {
  componentDidMount() {
  }

  handleChange = event => {
    this.props.setCurrentOrganizationById(event.target.value);
  };

  render() {
    const { className, classes, organizations, strings } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.header}>
          <Typography variant="h6">{strings.title}</Typography>
          <Typography className={classes.subtitle} variant="body2">
            {strings.subtitle}
          </Typography>
        </div>
        {organizations.length > 0 ? (
          <form autoComplete="off" className={classes.content}>
            <FormControl className={classes.formControl}>
              <Select
                inputProps={{
                  name: 'organization-switch',
                  id: 'organization-switch'
                }}
                onChange={this.handleChange}
                value={
                  this.props.currentOrganization
                    ? this.props.currentOrganization._id
                    : '-1'
                }
              >
                {organizations.map(option => {
                  return (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        ) : (
          <div className={classes.empty}>
            <div className={classes.emptyImageWrapper}>
              <img
                alt="Empty list"
                className={classes.emptyImage}
                src="/images/empty.png"
              />
            </div>
            <Typography variant="h4">{strings.nothing}</Typography>
          </div>
        )}
      </div>
    );
  }
}

OrganizationSwitch.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  fetchOrganizations: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  organizations: PropTypes.array.isRequired,
  setCurrentOrganizationById: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    organizations: state.organization.organizations,
    loading: state.organization.loading
  };
};

const mapDispatchToProps = {
  setCurrentOrganizationById,
  fetchOrganizations
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('OrganizationSwitch'),
  withStyles(styles)
)(OrganizationSwitch);
