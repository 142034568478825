import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

// Shared helpers
import getInitials from 'helpers/getInitials';

// Shared services
import { fetchUser } from '../../store/user/actionsCreator';

// Component styles
import styles from './styles';

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    width: 80,
    marginLeft: 10,
    backgroundColor: '#eeeeee'
  },
  bar: {
    backgroundColor: '#dddddd'
  }
})(LinearProgress);

class UserBadge extends Component {
  state = {
    user: null,
    loading: true,
    error: null
  };

  componentDidMount() {
    if (this.props.userId) {
      this.handleFetch();
    } else if (this.props.user) {
      this.setState({
        user: this.props.user,
        loading: false
      });
    }
  }

  handleFetch = async () => {
    const result = await this.props.fetchUser(this.props.userId);

    if (!result.error) {
      this.setState({
        user: result.user,
        loading: false
      });
    } else {
      this.setState({
        error: 'missing',
        loading: false
      });
    }
  };

  getFullname = () => {
    const { user } = this.state;

    if (!user) {
      return '';
    }

    return `${user.firstname || ''} ${user.name || ''}`.trim();
  };

  render() {
    const { classes } = this.props;

    const { user } = this.state;

    if (this.state.loading) {
      return (
        <div className={classes.loadingRoot}>
          <Avatar className={classes.avatar} />
          <BorderLinearProgress color="secondary" />
        </div>
      );
    }

    if (this.state.error === 'missing') {
      return (
        <div className={classes.loadingRoot}>
          <Avatar className={classes.avatar}>X</Avatar>
          <div className={classes.nameText}>
            <Typography variant="body2">{`Utilisateur supprimé`}</Typography>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.loadingRoot}>
        <Avatar className={classes.avatar} src={user.avatarUrl}>
          {getInitials(this.getFullname())}
        </Avatar>
        <div className={classes.nameText}>
          <Typography variant="body1">{this.getFullname()}</Typography>
          {user.email && <Typography variant="body2">{`${user.email}`}</Typography>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.authorize.role
  };
};

const mapDispatchToProps = {
  fetchUser
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(UserBadge);
