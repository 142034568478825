import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';

// Translations
import translate from 'helpers/translate';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

// Shared helpers
import getInitials from 'helpers/getInitials';

// Shared services
import { fetchBike } from '../../store/bike/actionsCreator';

// Component styles
import styles from './styles';

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    width: 80,
    marginLeft: 10,
    backgroundColor: '#eeeeee'
  },
  bar: {
    backgroundColor: '#dddddd'
  }
})(LinearProgress);

class BikeBadge extends Component {
  state = {
    bike: null,
    loading: true,
    error: null
  };

  componentDidMount() {
    if (this.props.bikeId) {
      this.handleFetch();
    }
  }

  handleFetch = async () => {
    const result = await this.props.fetchBike(this.props.bikeId);

    if (!result.error) {
      this.setState({
        bike: result.bike,
        loading: false
      });
    } else {
      this.setState({
        error: 'missing',
        loading: false
      });
    }
  }

  render() {
    const { classes, strings } = this.props;

    const { bike } = this.state;

    if (this.state.loading) {
      return (
        <div className={classes.loadingRoot}>
          <Avatar className={classes.avatar} src={'/images/bike_placeholder.jpg'}/>
          <BorderLinearProgress color="secondary" />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className={classes.loadingRoot}>
          <Avatar className={classes.avatar} src={'/images/bike_placeholder.jpg'}/>
          <Typography className={classes.nameText} variant="body1">
            {`${this.props.bikeId}`}
          </Typography>
        </div>
      );
    }

    return (
      <div className={classes.loadingRoot}>
        <Avatar className={classes.avatar} src={bike.imageUrl || '/images/bike_placeholder.jpg'}>
          {getInitials(`${bike.bikeType.name[strings.common.languageCode]}`)}
        </Avatar>
        <Typography className={classes.nameText} variant="body1">
          {`${bike.name[strings.common.languageCode]}`}
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    role: state.authorize.role
  };
};

const mapDispatchToProps = {
  fetchBike
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('CommentsList'),
  withStyles(styles)
)(BikeBadge);
