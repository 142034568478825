const actions = {
  START_FETCH_PRICINGPLANS: 'pricingPlan/START_FETCH_PRICINGPLANS',
  END_FETCH_PRICINGPLANS: 'bikeType/END_FETCH_PRICINGPLANS',
  START_DELETING_PRICINGPLANS: 'pricingPlan/START_DELETING_PRICINGPLANS',
  END_DELETING_PRICINGPLANS: 'bikeType/END_DELETING_PRICINGPLANS',
  START_ADD_PRICINGPLAN: 'bikeType/START_ADD_PRICINGPLAN',
  END_ADD_PRICINGPLAN: 'bikeType/END_ADD_PRICINGPLAN',
  START_FETCH_PRICINGPLAN: 'bike/START_FETCH_PRICINGPLAN',
  END_FETCH_PRICINGPLAN: 'bike/END_FETCH_PRICINGPLAN',
  START_UPDATE_PRICINGPLAN: 'bike/START_UPDATE_PRICINGPLAN',
  END_UPDATE_PRICINGPLAN: 'bike/END_UPDATE_PRICINGPLAN',
  SET_PRICINGPLANS: 'bikeType/SET_PRICINGPLANS',
  SET_PRICINGPLAN: 'bikeType/SET_PRICINGPLAN'
};

export default actions;
