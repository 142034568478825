import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

// Shared components
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class BookingsTable extends Component {
  state = {
    rowsPerPage: 10,
    page: 0
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, className, bookings, strings } = this.props;
    const { rowsPerPage, page } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{strings.startDate}</TableCell>
                  <TableCell align="left">{strings.endDate}</TableCell>
                  <TableCell align="left">{strings.bike}</TableCell>
                  <TableCell align="left">{strings.price}</TableCell>
                  <TableCell align="left">{strings.user}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(booking => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={booking._id}
                  >
                    <TableCell className={classes.tableCell}>
                      {booking.startDate
                        ? moment(booking.startDate).format(
                          'DD/MM/YYYY ' + strings.common.to + ' HH:mm'
                        )
                        : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {booking.endDate
                        ? moment(booking.endDate).format(
                          'DD/MM/YYYY ' + strings.common.to + ' HH:mm'
                        )
                        : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {booking.bike && booking.bike.name ? booking.bike.name : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {booking.price 
                        ? booking.price 
                        : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {booking.user && booking.user.fullname ? booking.user.fullname : '/'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            backIconButtonProps={{
              'aria-label': strings.common.previousPage
            }}
            component="div"
            count={bookings.length}
            labelDisplayedRows={({ from, to, count }) => {
              return from + '-' + to + ' ' + strings.common.of + ' ' + count;
            }}
            labelRowsPerPage={strings.common.rowsPerPage}
            nextIconButtonProps={{
              'aria-label': strings.common.nextPage
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

BookingsTable.propTypes = {
  bookings: PropTypes.array.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

BookingsTable.defaultProps = {
  bookings: []
};

export default compose(
  translate('BookingsTable'),
  withStyles(styles)
)(BookingsTable);
