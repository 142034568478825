export default theme => ({
  root: {
    width: '350px',
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing.unit * 3,
    paddingBottom: 0
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  content: {
    padding: theme.spacing.unit * 3
  },
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  arrowForward: {
    color: theme.palette.text.secondary,
    height: '16px',
    width: '16px'
  },
  footer: {
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'center'
  },
  empty: {
    textAlign: 'center',
    padding: theme.spacing.unit * 3
  },
  emptyImageWrapper: {
    marginBottom: theme.spacing.unit * 3
  },
  emptyImage: {
    width: '240px',
    maxWidth: '100%',
    height: 'auto'
  }
});
