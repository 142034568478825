import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Material icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Shared components
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletToolbar from 'components/PortletToolbar';
import BatteryLevel from 'components/BatteryLevel';

// Custom components
import BikeLocationsMap from './components/BikeLocationsMap';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Shared services
import { fetchLocations } from 'store/bike/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './style';

class BikeLocationDetails extends Component {
  signal = true;

  state = {
    error: null,
    battery: 0
  };

  componentDidMount() {
    this.signal = true;
    this.handleFetch();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.handleFetch();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  handleFetch = async () => {
    const { strings } = this.props;

    // fetch the bike locations by id
    const { error } = await this.props.fetchLocations(this.props.match.params.id);
    if (error) {
      this.setState({
        error: strings.errors.fetchError
      });

      return;
    } 

    const { locations } = this.props;
    this.setState({
      battery: locations[locations.length -1].batteryLevel ? locations[locations.length -1].batteryLevel : -1,
      error: null
    });
  };

  renderBikeLocationsMap() {
    const { classes, isLoading, locations, strings } = this.props;

    const { error, battery } = this.state;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (locations.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    let polylines = [];
    for (let i = 0; i < locations.length; i++) {
      polylines.push({
        lat: locations[i].location.coordinates[0],
        lng: locations[i].location.coordinates[1]
      });
    }

    return (
      <Portlet>
        <PortletHeader>
          <PortletLabel
            subtitle={strings.portletSubtitle}
            title={strings.portletTitle}
          />
          <PortletToolbar>
            <BatteryLevel battery={battery} />
          </PortletToolbar>
        </PortletHeader>
        <PortletContent noPadding>
          <div className={classes.mapWrapper}>
            <BikeLocationsMap markers={locations} polylines={polylines} />
          </div>
        </PortletContent>
      </Portlet>
    );
  }

  render() {
    const { classes, strings } = this.props;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.header}>
          <IconButton
            className={classes.back}
            onClick={this.handleBack}
            title={strings.common.back}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className={classes.root}>{this.renderBikeLocationsMap()}</div>
      </DashboardLayout>
    );
  }
}

BikeLocationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.bike.loading,
    locations: state.bike.locations
  };
};

const mapDispatchToProps = {
  fetchLocations
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('BikeLocationDetails'),
  withStyles(styles)
)(BikeLocationDetails);
