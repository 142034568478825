const actions = {
  START_FETCH_BIKES: 'bike/START_FETCH_BIKES',
  END_FETCH_BIKES: 'bike/END_FETCH_BIKES',
  START_DELETING_BIKES: 'bike/START_DELETING_BIKES',
  END_DELETING_BIKES: 'bike/END_DELETING_BIKES',
  START_FETCH_LOCATIONS: 'bike/START_FETCH_LOCATIONS',
  END_FETCH_LOCATIONS: 'bike/END_FETCH_LOCATIONS',
  START_ADD_BIKE: 'bike/START_ADD_BIKE',
  END_ADD_BIKE: 'bike/END_ADD_BIKE',
  START_FETCH_BIKE: 'bike/START_FETCH_BIKE',
  END_FETCH_BIKE: 'bike/END_FETCH_BIKE',
  START_UPDATE_BIKE: 'bike/START_UPDATE_BIKE',
  END_UPDATE_BIKE: 'bike/END_UPDATE_BIKE',
  START_ADD_BIKE_LOCATION : 'bike/START_ADD_BIKE_LOCATION',
  END_ADD_BIKE_LOCATION : 'bike/END_ADD_BIKE_LOCATION',
  SET_BIKES: 'bike/SET_BIKES',
  SET_LOCATIONS: 'bike/SET_LOCATIONS',
  SET_BIKE: 'bike/SET_BIKE',
  START_BIKE_END_RENT: 'rent/START_END_RENT',
  END_BIKE_END_RENT: 'rent/END_END_RENT'
};

export default actions;
