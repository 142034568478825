import { bindActionCreators } from 'redux';

import actions from './actions';

import * as userService from 'services/user';

import * as roles from 'common/roles';

import { fetchOrganizations, setCurrentOrganization } from '../organization/actionsCreator';

import { setRole } from '../authorize/actionsCreator';

// eslint-disable-next-line no-unused-vars
export const fetchProfile = (onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_FETCH_PROFILE });
  console.log('start fetch profile');

  try {
    const profile = await userService.getProfile();

    if (profile.user.isAdmin) {
      await bindActionCreators(setRole, dispatch)(roles.Admin);
    }

    await bindActionCreators(fetchOrganizations, dispatch)();

    setTimeout(() => {
      dispatch({
        type: actions.SET_PROFILE,
        value: {
          user: profile.user,
          token: profile.token
        }
      });

      console.log('end fetch profile');
      dispatch({ type: actions.END_FETCH_PROFILE });

      if (onFinished) onFinished();
    }, 750);
  } catch (error) {
    console.log('end fetch profile');
    await bindActionCreators(logout, dispatch)();

    dispatch({ type: actions.END_FETCH_PROFILE });

    if (onFinished) onFinished({ error });
  }
};

// eslint-disable-next-line no-unused-vars
export const signIn = (email, password, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_SIGN_IN });

  try {
    const result = await userService.signIn(email, password);

    localStorage.setItem('x-auth', result.token);

    await bindActionCreators(fetchProfile, dispatch)();

    dispatch({ type: actions.END_SIGN_IN });

    onFinished();
  } catch (error) {
    await bindActionCreators(logout, dispatch)();

    dispatch({ type: actions.END_SIGN_IN });

    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const signUp = (
  firstName,
  lastName,
  email,
  password,
  birthYear,
  gender,
  phoneNumber,
  phoneType,
  street,
  number,
  zip,
  city,
  stripeToken,
  accessLevel,
  keyVerify,
  onFinished
) => async (dispatch, getState) => {
  dispatch({ type: actions.START_SIGN_UP });

  try {
    const result = await userService.signUp(
      firstName,
      lastName,
      email,
      password,
      birthYear,
      gender,
      phoneNumber,
      phoneType,
      street,
      number,
      zip,
      city,
      stripeToken,
      accessLevel,
      keyVerify
    );

    dispatch({ type: actions.END_SIGN_UP });

    onFinished(result, false);
  } catch (error) {
    await bindActionCreators(logout, dispatch)();

    dispatch({ type: actions.END_SIGN_UP });

    onFinished(error, true);
  }
};

// eslint-disable-next-line no-unused-vars
export const verify = (email, key, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_VERIFY });

  try {
    await userService.verify(email, key);

    dispatch({ type: actions.END_VERIFY });

    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_VERIFY });

    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const logout = () => async (dispatch, getState) => {
  dispatch({ type: actions.START_SIGN_OUT });

  try {
    dispatch({ type: actions.SET_UNAUTHENTICATED });

    await bindActionCreators(setRole, dispatch)(undefined);
    await bindActionCreators(setCurrentOrganization, dispatch)(null);

    dispatch({ type: actions.END_SIGN_OUT });
  } catch (error) {
    // DO SOMETHING

    dispatch({ type: actions.END_SIGN_OUT });
  }
};

export const deleteAccount = () => async (dispatch, getState) => {
  try {
    const result = await userService.deleteAccount();

    await bindActionCreators(logout, dispatch)();
  } catch (error) {
    // DO SOMETHING
  }
};
