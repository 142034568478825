export const getPricingPlans = (organizationId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/pricing-plans/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const deletePricingPlans = (organizationId, pricingPlans) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/pricing-plans', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify(pricingPlans)
      });
      const json = await response.json();
      if (response.ok) {
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const addPricingPlan = (organizationId, pricingPlan) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/pricing-plans/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId,
          language: localStorage.getItem('x-localization') ? localStorage.getItem('x-localization') : 'fr'
        },
        body: JSON.stringify(pricingPlan)
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const getPricingPlan = (organizationId, pricingPlanId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/pricing-plans/get/' + pricingPlanId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const updatePricingPlan = (organizationId, pricingPlanId, pricingPlanType) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/api/v1/admin/pricing-plans/update/' + pricingPlanId,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-auth': auth,
            'x-organization-id': organizationId
          },
          body: JSON.stringify(pricingPlanType)
        }
      );
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const authorize = async (call, ...props) => {
  const promise = new Promise(async (resolve, reject) => {
    const auth = localStorage.getItem('x-auth');
    if (!auth) {
      reject({
        error: 'missing auth token'
      });
      return;
    }
    await call(resolve, reject, auth, ...props);
  });
  return promise;
};
