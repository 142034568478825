import React from 'react';

import Link from '@material-ui/core/Link';
import history from 'helpers/history';

import PropTypes from 'prop-types';

const LinkHelper = ({ href, ...rest }) => {
  const handleClick = () => history.push(href);
  return <Link {...rest} onClick={handleClick} />;
};

LinkHelper.props = {
  href: PropTypes.string.isRequired
};

export default LinkHelper;
