export default {
  firstName: {
    presence: { allowEmpty: false }
  },
  lastName: {
    presence: { allowEmpty: false }
  },
  email: {
    presence: { allowEmpty: false },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false },
    length: {
      maximum: 128
    }
  },
  confirmPassword: {
    presence: { allowEmpty: false },
    length: {
      maximum: 128
    }
  }
};
