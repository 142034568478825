import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Shared components
import Portlet from 'components/Portlet';

// Translations
import translate from 'helpers/translate';

// Shared services
import { fetchInvitations } from '../../store/user/actionsCreator';

// Custom components
import InvitationsToolbar from './components/InvitationsToolbar';
import InvitationsTable from './components/InvitationsTable';

// Component styles
import styles from './style';

class InvitationList extends Component {
  signal = true;

  state = {
    limit: 10,
    users: [],
    selectedInvitations: [],
    error: null
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchInvitations();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchInvitations();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = selectedInvitations => {
    this.setState({ selectedInvitations });
  };

  handleFilterChange = event => {
    this.setState({
      filter: event.target.value
    });
  };

  getFilteredInvitations = () => {
    const { invitations } = this.props;
    const { filter } = this.state;
    if (!filter) {
      return invitations;
    }

    const lowerFilter = filter.toLowerCase();

    return invitations.filter(u => {
      return (
        u.name.toLowerCase().indexOf(lowerFilter) >= 0 ||
        u.firstname.toLowerCase().indexOf(lowerFilter) >= 0 ||
        u.email.toLowerCase().indexOf(lowerFilter) >= 0
      );
    });
  };

  renderInvitations() {
    const { classes, isLoading, invitations, error, strings } = this.props;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (invitations.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    return (
      <InvitationsTable
        onSelect={this.handleSelect}
        users={this.getFilteredInvitations()}
      />
    );
  }

  render() {
    const { classes, strings } = this.props;
    const { selectedInvitations } = this.state;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <InvitationsToolbar
            onFilterChange={this.handleFilterChange}
            selectedInvitations={selectedInvitations}
          />
          <div className={classes.content}>{this.renderInvitations()}</div>
        </div>
      </DashboardLayout>
    );
  }
}

InvitationList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  fetchInvitations: PropTypes.func.isRequired,
  invitations: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.user.loading,
    invitations: state.user.invitations
  };
};

const mapDispatchToProps = {
  fetchInvitations
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('InvitationList'),
  withStyles(styles)
)(InvitationList);
