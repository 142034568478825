export default {
  AddBikeDialog: {
    title: 'Ajouter un vélo',
    subtitle:
      'Pour ajouter un vélo, veuillez remplir les champs ci-dessous. Les champs marqués par un astérisque sont obligatoires.',
    name: {
      label: 'Nom',
      error: 'Le nom du vélo est obligatoire'
    },
    type: {
      label: 'Type',
      error: 'Le type du vélo est obligatoire'
    },
    description: {
      label: 'Description'
    },
    padlockKind: {
      label: 'Type de cadenas',
      error: 'Le type du cadenas est obligatoire'
    },
    padlockMac: {
      label: 'Adresse MAC du cadenas',
      error: "l'adresse MAC du cadenas est obligatoire"
    },
    imageUrl: {
      label: "Lien de l'image"
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'ajout du vélo."
    },
    cancel: 'Annuler',
    add: 'Ajouter'
  },
  AddBikeTypeDialog: {
    title: 'Ajouter un type de vélo',
    subtitle:
      'Pour ajouter un type de vélo, veuillez remplir les champs ci-dessous. Les champs marqués par un astérisque sont obligatoires.',
    name: {
      label: 'Nom',
      error: 'Le nom du type du vélo est obligatoire'
    },
    description: {
      label: 'Description'
    },
    pricingPlan: {
      label: 'Plan tarifaire',
      error: 'Le plan tarifaire est obligatoire'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'ajout du type de vélo."
    },
    cancel: 'Annuler',
    add: 'Ajouter'
  },
  AddOrganizationDialog: {
    title: 'Ajouter une organisation',
    subtitle:
      'Pour ajouter une organisation, veuillez remplir les champs ci-dessous. Les champs marqués par un astérisque sont obligatoires.',
    name: {
      label: 'Nom',
      error: 'Le nom est obligatoire'
    },
    isPublic: {
      label: "l'organisation est publique",
      error: 'Le champ est obligatoire'
    },
    bookingMaxDurationInMinutes: {
      label: 'Durée maximale de réservation (minutes)'
    },
    simultaneousBookingMaxCount: {
      label: 'Nombre maximal de réservations simultanées'
    },
    rentMaxDurationInMinutes: {
      label: 'Durée maximale de location (minutes)'
    },
    simultaneousRentMaxCount: {
      label: 'Nombre maximal de locations simultanées'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'ajout de l'organisation."
    },
    cancel: 'Annuler',
    add: 'Ajouter'
  },
  AddPricingPlanDialog: {
    title: 'Ajouter un plan tarifaire',
    subtitle:
      'Pour ajouter un plan tarifaire, veuillez remplir les champs ci-dessous. Les champs marqués par un astérisque sont obligatoires.',
    name: {
      label: 'Nom',
      error: 'Le nom est obligatoire'
    },
    bookingCost: {
      label: 'Coût de réservation',
      error: 'La quantité est obligatoire'
    },
    firstHalfHour: {
      label: 'Première demi-heure',
      error: 'La première demi-heure est obligatoire'
    },
    secondHalfHour: {
      label: 'Deuxième demi-heure',
      error: 'La deuxième demi-heure est obligatoire'
    },
    thirdHalfHour: {
      label: 'Troisième demi-heure',
      error: 'La troisième demi-heure est obligatoire'
    },
    nextHalfHours: {
      label: 'Demi-heures suivantes',
      error: 'Les demi-heures suivantes est obligatoire'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'ajout du plan tarifaire."
    },
    cancel: 'Annuler',
    add: 'Ajouter'
  },
  AddUserDialog: {
    roles: {
      User: 'Utilisateur',
      Operator: 'Opérateur'
    },
    firstname: {
      label: 'Prénom',
      error: 'Le prénom est obligatoire'
    },
    name: {
      label: 'Nom',
      error: 'Le nom est obligatoire'
    },
    email: {
      label: 'Email',
      error: 'Un email valide est obligatoire'
    },
    roleLabel: 'Sélection du rôle',
    roleHelperText:
      "Le rôle détermine l'accès qu'aura l'utilisateur. Un simple utilisateur de l'application ou un opérateur avec des droits d'administration.",
    subtitle:
      "Si l'utilisateur invité a déjà un compte, un droit d'accès lui sera donné pour cette organisation. Dans le cas contraire, il sera invité à créer un compte utilisateur sur ShareABike.",
    errors: {
      HasPendingAccess: "Cet utilisateur a déjà été invité et n'a pas encore accepté l'invitation.",
      HasAccess: 'Cet utilisateur a déjà accès.',
      UnknownError: "Une erreur est survenue pendant l'invitation."
    },
    title: 'Inviter un utilisateur',
    cancel: 'Annuler',
    add: 'Ajouter'
  },
  AddZoneDialog: {
    title: 'Ajouter une zone',
    subtitle:
      'Pour ajouter une zone, veuillez remplir les champs ci-dessous ainsi que de dessiner une zone sur la carte. Les champs marqués par un astérisque sont obligatoires.',
    name: {
      label: 'Nom',
      error: 'Le nom de la zone est obligatoire'
    },
    map: {
      helper: 'Veuillez dessiner une zone.'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'ajout de la zone."
    },
    cancel: 'Annuler',
    add: 'Ajouter'
  },
  ManageZoneNoteDialog: {
    title: 'Gerer les notes pour cette zone',
    subtitle: 'Pour modifier une note, veuillez remplir les champs ci-dessous.',
    notes: {
      fr: {
        label: 'Note en français',
        error: 'La note en français est obligatoire'
      },
      en: {
        label: 'Note en anglais',
        error: 'La note en anglais est obligatoire'
      },
      nl: {
        label: 'Note en néerlandais',
        error: 'La note en néerlandais est obligatoire'
      }
    },
    errors: {
      unknownError: 'Une erreur est survenue, veuillez réessayer plus tard.'
    },
    cancel: 'Annuler',
    save: 'Sauvegarder'
  },
  AddBikeType: {
    title: 'Ajouter un type de vélo',
    name: {
      fr: {
        label: 'Nom en français',
        error: 'Le nom en français est obligatoire'
      },
      en: {
        label: 'Nom en anglais',
        error: 'Le nom en anglais est obligatoire'
      },
      nl: {
        label: 'Nom en néerlandais',
        error: 'Le nom en néerlandais est obligatoire'
      }
    },
    description: {
      fr: {
        label: 'Description en français'
      },
      en: {
        label: 'Description en anglais'
      },
      nl: {
        label: 'Description en néerlandais'
      }
    },
    pricingPlan: {
      label: 'Plan tarifaire',
      error: 'Le plan tarifaire est obligatoire'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'ajout du type de vélo."
    },
    add: 'Ajouter'
  },
  AddPrice: {
    title: 'Ajouter un tarif',
    price: {
      label: 'Prix',
      error: 'Le prix est obligatoire'
    },
    credit: {
      label: 'Quantité de crédits',
      error: 'La quantité est obligatoire'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'ajout du tarif."
    },
    add: 'Ajouter'
  },
  AddPricingPlan: {
    title: 'Ajouter un plan tarifaire',
    name: {
      label: 'Nom',
      error: 'Le nom est obligatoire'
    },
    bookingCost: {
      label: 'Coût de réservation',
      error: 'La quantité est obligatoire'
    },
    firstHalfHour: {
      label: 'Première demi-heure',
      error: 'La première demi-heure est obligatoire'
    },
    secondHalfHour: {
      label: 'Deuxième demi-heure',
      error: 'La deuxième demi-heure est obligatoire'
    },
    thirdHalfHour: {
      label: 'Troisième demi-heure',
      error: 'La troisième demi-heure est obligatoire'
    },
    nextHalfHours: {
      label: 'Demi-heures suivantes',
      error: 'Les demi-heures suivantes est obligatoire'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'ajout du plan tarifaire."
    },
    add: 'Ajouter'
  },
  AllUserList: {
    title: 'Utilisateurs',
    nothing: 'Aucun utilisateur répertorié'
  },
  AllUsersTable: {
    name: 'Nom',
    email: 'Email',
    address: 'Adresse',
    phone: 'Téléphone',
    accessLevel: "Niveau d'accès",
    accessLevels: {
      basic: 'Basique',
      complete: 'Complet',
      creditcard: 'Carte de crédit'
    },
    registrationDate: "Date d'inscription",
    actions: 'Actions',
    userdetails: "Détails de l'utilisateur"
  },
  AllUsersToolbar: {
    search: 'Rechercher un utilisateur'
  },
  BatteryLevel: {
    batteryStatus: 'Statut de la batterie'
  },
  BikeDetails: {
    title: 'Détails',
    portletTitle: 'Détails du vélo',
    portletSubtitle: 'Les informations ci-dessous peuvent être éditées',
    mapTitle: 'Position du vélo',
    mapSubtitle: 'Bougez le curseur pour repositionner le vélo',
    imageUrl: "Lien de l'image",
    isDefective: 'Ce vélo est défectueux',
    endRent: 'Arreter la location',
    name: {
      fr: {
        label: 'Nom en français',
        error: 'Le nom en français est obligatoire'
      },
      en: {
        label: 'Nom en anglais',
        error: 'Le nom en anglais est obligatoire'
      },
      nl: {
        label: 'Nom en néerlandais',
        error: 'Le nom en néerlandais est obligatoire'
      }
    },
    state: {
      label: 'État',
      error: "l'état est obligatoire"
    },
    type: {
      label: 'Type',
      error: 'Le type du vélo est obligatoire'
    },
    padlockKind: {
      label: 'Type de cadenas',
      error: 'Le type du cadenas est obligatoire'
    },
    padlockMac: {
      label: 'Adresse MAC du cadenas',
      error: "l'adresse MAC du cadenas est obligatoire"
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'enregistrement des modifications.",
      fetchError: 'Une erreur est survenue, les détails de ce vélo ne peuvent pas être affichés.',
      endRentError: "Une erreur est survenue pendant l'arret de la location."
    },
    success: 'Les modifications ont bien été enregistrées',
    saveDetails: 'Enregister les modifications',
    saveLocation: 'Enregister la position'
  },
  BikeList: {
    title: 'Vélos',
    nothing: 'Aucun vélo répertorié'
  },
  BikeLocationDetails: {
    title: 'Historique des trajets',
    nothing: "Aucun historique des trajets n'est disponible pour ce vélo",
    portletTitle: 'Historique des trajets du vélo',
    portletSubtitle: 'Les informations ci-dessous représente les trajets du vélo depuis sa mise en place',
    errors: {
      fetchError: "Une erreur est survenue, l'historique des trajets du vélo ne peut pas être affiché."
    }
  },
  BikesTable: {
    name: 'Nom',
    type: 'Type',
    description: 'Description',
    pricingPlan: 'Plan tarifaire',
    state: 'État',
    actions: 'Actions',
    bikelocationshistory: 'Historique des trajets du vélo',
    bikedetails: 'Détails du vélo',
    unknown: 'Inconnu',
    battery: 'Batterie'
  },
  BikesToolbar: {
    addBike: 'Ajouter un vélo',
    search: 'Rechercher un vélo',
    delete: 'Supprimer'
  },
  BikeTypeDetails: {
    title: 'Détails',
    portletTitle: 'Détails du type de vélo',
    portletSubtitle: 'Les informations ci-dessous peuvent être éditées',
    name: {
      fr: {
        label: 'Nom en français',
        error: 'Le nom en français est obligatoire'
      },
      en: {
        label: 'Nom en anglais',
        error: 'Le nom en anglais est obligatoire'
      },
      nl: {
        label: 'Nom en néerlandais',
        error: 'Le nom en néerlandais est obligatoire'
      }
    },
    description: {
      fr: {
        label: 'Description en français'
      },
      en: {
        label: 'Description en anglais'
      },
      nl: {
        label: 'Description en néerlandais'
      }
    },
    pricingPlan: {
      label: 'Plan tarifaire',
      error: 'Le plan tarifaire est obligatoire'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'enregistrement des modifications.",
      fetchError: 'Une erreur est survenue, les détails de ce type de vélo ne peuvent pas être affichés.'
    },
    success: 'Les modifications ont bien été enregistrées',
    save: 'Enregister les modifications'
  },
  BikeTypeList: {
    title: 'Type de vélo',
    nothing: 'Aucun type de vélo répertorié'
  },
  BikeTypesTable: {
    title: 'Liste des types de vélo',
    name: 'Nom',
    description: 'Description',
    pricingPlan: 'Plan tarifaire',
    delete: 'Supprimer',
    actions: 'Actions',
    biketypedetails: 'Détails du plan tarifaire'
  },
  BookingsTable: {
    startDate: 'Début de la réservation',
    endDate: 'Fin de la réservation',
    bike: 'Vélo',
    price: 'Coût',
    user: 'Utilisateur'
  },
  CommentsList: {
    title: 'Commentaires',
    nothing: 'Aucun commentaire répertorié'
  },
  CommentsTable: {
    user: 'Utilisateur',
    actions: 'Actions',
    bike: 'Vélo',
    comment: 'Commentaire',
    issue: 'Problèmes',
    date: 'Date',
    defective: 'Défectueux',
    outofservice: 'Hors service'
  },
  CommentsToolbar: {
    search: 'Rechercher un commentaire'
  },
  Common: {
    common: {
      languageCode: 'fr',
      back: 'Retour',
      name: 'Nom',
      firstname: 'Prénom',
      cancel: 'Annuler',
      delete: 'Supprimer',
      previousPage: 'Page précédente',
      nextPage: 'Page suivante',
      to: 'à',
      of: 'de',
      total: 'au total',
      rowsPerPage: 'Nombre de lignes par page:',
      available: 'Disponible',
      booked: 'Réservé',
      rented: 'Loué',
      toRelocate: 'À relocaliser',
      relocating: 'En transfert',
      repairing: 'En réparation',
      unavailable: 'Indisponible',
      outOfService: 'Hors-service',
      unknown: 'Inconnu',
      yes: 'Oui',
      no: 'Non',
      administrator: 'Administrateur',
      operator: 'Opérateur',
      user: 'Utilisateur',
      emailVerified: 'Email vérifié',
      emailNotVerified: 'Email non-vérifié'
    }
  },
  Dashboard: {
    title: 'Tableau de bord'
  },
  InvitationList: {
    title: 'Invitaitons',
    nothing: 'Aucune invitation en attente'
  },
  InvitationsTable: {
    name: 'Nom',
    email: 'Email',
    invitationDate: "Date de l'invitation"
  },
  InvitationsToolbar: {
    search: 'Rechercher une invitation'
  },
  LanguageSwitch: {
    title: 'Choix de la langue',
    subtitle: 'Sélectionnez la langue avec laquelle travailler.',
    nothing: "Il n'y a rien ici...",
    languages: {
      en: 'Anglais',
      fr: 'Français',
      nl: 'Néerlandais'
    }
  },
  MessagesTable: {
    name: 'Nom',
    email: 'Email',
    profile: 'Profil',
    city: 'Ville',
    message: 'Message',
    createdAt: 'Reçu le'
  },
  OrganizationDetails: {
    title: 'Détails',
    portletTitle: "Détails de l'organisation",
    portletSubtitle: 'Les informations ci-dessous peuvent être éditées',
    bookings: 'Réservations',
    rentings: 'Locations',
    access: 'Gestion des accès',
    domainAccess: 'Accès par domaine',
    zonesTitle: 'Gestion des zones',
    zonesSubtitle: 'Consultez et modifiez les zones de votre organisation',
    name: {
      label: 'Nom',
      error: 'Le nom est obligatoire'
    },
    isPublic: {
      label: "l'organisation est publique",
      error: 'Le champ est obligatoire'
    },
    bookingMaxDurationInMinutes: {
      label: 'Durée maximale de réservation (minutes)'
    },
    simultaneousBookingMaxCount: {
      label: 'Nombre maximal de réservations simultanées'
    },
    rentMaxDurationInMinutes: {
      label: 'Durée maximale de location (minutes)'
    },
    simultaneousRentMaxCount: {
      label: 'Nombre maximal de locations simultanées'
    },
    accessLevel: {
      label: "Niveau d'accès requis"
    },
    accessDomains: {
      label: 'Nom de domaine',
      description: 'Les nouveaux inscrits utilisant un des noms de domaine spécifiés auront accès à cette organisation'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'enregistrement des modifications.",
      fetchError: 'Une erreur est survenue, les détails de cette organisation ne peuvent pas être affichés.'
    },
    success: 'Les modifications ont bien été enregistrées',
    save: 'Enregister les modifications',
    accessLevels: {
      basic: 'Basique',
      complete: 'Complet',
      creditcard: 'Carte de crédit'
    },
    nothing: 'Aucune zone répertorié'
  },
  OrganizationList: {
    title: 'Organisations',
    nothing: 'Aucune organisation répertorié'
  },
  OrganizationSwitch: {
    title: "Choix de l'organisation",
    subtitle: "Sélectionnez l'organisation avec laquelle travailler.",
    nothing: "Il n'y a rien ici..."
  },
  OrganizationsTable: {
    name: 'Nom',
    isPublic: 'Est publique',
    bookingMaxDurationInMinutes: 'Durée maximale de réservation (minutes)',
    simultaneousBookingMaxCount: 'Nombre maximal de réservations simultanées',
    rentMaxDurationInMinutes: 'Durée maximale de location (minutes)',
    simultaneousRentMaxCount: 'Nombre maximal de locations simultanées',
    actions: 'Actions',
    organizationdetails: "Détails de l'organisation"
  },
  OrganizationsToolbar: {
    addOrganization: 'Ajouter une organisation',
    search: 'Rechercher une organisation',
    delete: 'Supprimer'
  },
  PriceList: {
    title: 'Tarifs',
    nothing: 'Aucun tarif répertorié'
  },
  PricesTable: {
    title: 'Liste des tarifs',
    amountcredit: 'Quantité de crédits',
    credit: 'crédit(s)',
    delete: 'Supprimer',
    price: 'Prix'
  },
  PricingPlanDetails: {
    title: 'Détails',
    portletTitle: 'Détails du plan tarifaire',
    portletSubtitle: 'Les informations ci-dessous peuvent être éditées',
    name: {
      label: 'Nom',
      error: 'Le nom est obligatoire'
    },
    bookingCost: {
      label: 'Coût de réservation',
      error: 'La quantité est obligatoire'
    },
    firstHalfHour: {
      label: 'Première demi-heure',
      error: 'La première demi-heure est obligatoire'
    },
    secondHalfHour: {
      label: 'Deuxième demi-heure',
      error: 'La deuxième demi-heure est obligatoire'
    },
    thirdHalfHour: {
      label: 'Troisième demi-heure',
      error: 'La troisième demi-heure est obligatoire'
    },
    nextHalfHours: {
      label: 'Demi-heures suivantes',
      error: 'Les demi-heures suivantes est obligatoire'
    },
    errors: {
      unknownError: "Une erreur est survenue pendant l'enregistrement des modifications.",
      fetchError: 'Une erreur est survenue, les détails du plan tarifaire ne peuvent pas être affichés.'
    },
    success: 'Les modifications ont bien été enregistrées',
    save: 'Enregister les modifications'
  },
  PricingPlanList: {
    title: 'Plan tarifaire',
    nothing: 'Aucun plan tarifaire répertorié'
  },
  PricingPlansTable: {
    title: 'Liste des plans tarifaires',
    name: 'Nom',
    bookingCost: 'Coût de réservation',
    firstHalfHour: 'Première demi-heure',
    secondHalfHour: 'Deuxième demi-heure',
    thirdHalfHour: 'Troisième demi-heure',
    nextHalfHours: 'Demi-heures suivantes',
    credit: 'crédit(s)',
    actions: 'Actions',
    pricingplandetails: 'Détails du plan tarifaire',
    delete: 'Supprimer'
  },
  RentList: {
    title: 'Locations',
    bookings: 'Réservations',
    rentals: 'Locations',
    nothing: {
      rents: 'Aucune location répertoriée',
      bookings: 'Aucune réservation répertoriée'
    }
  },
  RentsTable: {
    startDate: 'Début de la location',
    endDate: 'Fin de la location',
    bike: 'Vélo',
    price: 'Coût',
    user: 'Utilisateur'
  },
  Sidebar: {
    administration: 'Administration',
    administrator: 'Administrateur',
    allUserList: 'Liste de tous les utilisateurs',
    bikeList: 'Liste des vélos',
    bikes: 'Vélos',
    bikeTypes: 'Type de vélo',
    comments: 'Commentaires',
    contactForm: 'Formulaire de contact',
    dashboard: 'Tableau de bord',
    invitationList: 'Liste des invitations',
    manager: 'Exploitant',
    organization: 'Organisation',
    organizations: 'Organisations',
    prices: 'Tarifs',
    pricingPlans: 'Plan tarifaire',
    rentals: 'Locations',
    settings: 'Paramètres',
    support: 'Support clientèle',
    about: 'À propos',
    userList: 'Liste des utilisateurs',
    users: 'Utilisateurs'
  },
  SignIn: {
    quote: {
      text: "“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.”",
      author: 'Albert Einstein',
      bio: 'Physicien théoricien'
    },
    title: 'Se connecter',
    subtitle: 'Connectez-vous à la plateforme ShareABike',
    email: { label: 'Adresse email', error: "l'adresse email est obligatoire" },
    password: {
      label: 'Mot de passe',
      error: 'Le mot de passe est obligatoire'
    },
    action: 'Connexion',
    errors: {
      unknownError: 'Une erreur est survenue, veuillez réessayer plus tard.',
      wrongCredentialsError: "Votre nom d'utilisateur ou votre mot de passe est incorrect.",
      notEnoughPrivilegesError: "Vous n'avez pas les droits nécessaires pour vous connecter."
    },
    resetPassword: 'Mot de passe oublié ?',
    signIn: 'Se connecter',
    signUp: "s'inscrire",
    about: 'À propos',
    contactUs: 'Nous contacter',
    CGU: "Conditions d'utilisation",
    help: "Besoin d'aide"
  },
  About: {
    quote: {
      text: "“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.”",
      author: 'Albert Einstein',
      bio: 'Physicien théoricien'
    },
    title: 'À propos',
    paragraphs: [
      "ShareABike est un système de partage de vélos en libre-service. Les vélos sont équipés de cadenas intelligents qu'on peut (dé)verrouiller via une application sur smartphone. C'est une innovation wallonne créée par l'Université de Namur et l'Université de Mons dont Pro Velo a la licence d'exploitation exclusive sur le territoire belge.",
      "Grâce à ShareABike, vous pouvez localiser le vélo disponible le plus proche de votre position et, d'un simple « clic », déverrouiller le cadenas grâce à votre téléphone... Une fois votre trajet effectué, il suffit de le notifier via l'application et de refermer le cadenas. Simple et intuitif!"
    ],
    signIn: 'Se connecter',
    signUp: "s'inscrire",
    about: 'À propos',
    contactUs: 'Nous contacter',
    CGU: "Conditions d'utilisation",
    help: "Besoin d'aide"
  },
  Help: {
    quote: {
      text: "“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.”",
      author: 'Albert Einstein',
      bio: 'Physicien théoricien'
    },
    title: "Besoin d'aide",
    paragraphs: [
      "Vous rencontrez un problème lors de la création de votre compte, lors de la réservation ou de l'utilisation d'un vélo? Nous sommes à votre disposition. S'il s'agit d'un problème avec un vélo en particulier, nous vous demanderons le numéro du vélo.",
      'Téléphone: +32 65 84 95 81 (de 9h à 19h du lundi au vendredi)',
      'Mail: info@shareabike.be'
    ],
    signIn: 'Se connecter',
    signUp: "s'inscrire",
    about: 'À propos',
    contactUs: 'Nous contacter',
    CGU: "Conditions d'utilisation",
    help: "Besoin d'aide"
  },
  TermsOfUse: {
    quote: {
      text: "“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.”",
      author: 'Albert Einstein',
      bio: 'Physicien théoricien'
    },
    title: "Conditions générales d'utilisation du système de vélos en libre-service ShareABike",
    sections: [
      {
        name: 'Description',
        articles: [
          {
            name: 'Article 1',
            paragraphs: [
              [
                "ShareABike est un système de vélos en libre-service fonctionnant par l'intermédiaire d'une application et de cadenas intelligents, créée par l'Université de Mons et l'Université de Namur et pour laquelle Pro Velo asbl a obtenu une licence d'exploitation."
              ]
            ]
          },
          {
            name: 'Article 1.1',
            paragraphs: [
              [
                'Coordonnées de contact liées à ShareABike :',
                'Pro Velo asbl',
                'Rue de Londres, 15',
                '1050 Bruxelles',
                'Site internet : www.provelo.org/shareabike'
              ]
            ]
          }
        ]
      },
      {
        name: 'Fonctionnement',
        articles: [
          {
            name: 'Article 2',
            paragraphs: [
              [
                "Le système ShareABike est constitué de vélos munis de cadenas électroniques et d'une application mobile permettant son fonctionnement. Pour toute utilisation du système, l'utilisateur doit au préalable télécharger l'application ShareABike."
              ]
            ]
          },
          {
            name: 'Article 2.1',
            paragraphs: [
              [
                "l'utilisateur doit utiliser un appareil possédant une connexion internet mobile 3G minimum, une connexion Bluetooth et une fonction GPS."
              ]
            ]
          },
          {
            name: 'Article 2.2',
            paragraphs: [
              [
                "Pour la bonne utilisation du système, il est obligatoire que l'utilisateur active avant utilisation les fonctionnalités mentionnées à l'article 2.1."
              ]
            ]
          },
          {
            name: 'Article 2.3',
            paragraphs: [
              [
                "La location du vélo est limitée à une durée maximale de 24 heures, à partir du déverrouillage du vélo par l'application mobile du système."
              ],
              ['Celle-ci prend fin lorsque le vélo est verrouillé pour un arrêt définitif.'],
              [
                'Les verrouillages pour arrêts temporaires sont compris dans la durée de location et rentrent dans la limite maximale des 24 heures.'
              ]
            ]
          }
        ]
      },
      {
        name: 'Application mobile',
        articles: [
          {
            name: 'Article 3',
            paragraphs: [
              [
                "l'application mobile permet, après téléchargement et inscription, de réaliser les tâches suivantes :",
                "- s'identifier avec son nom d'utilisateur et son mot de passe à son compte utilisateur",
                '- Accéder à ses informations personnelles et les modifier si besoin',
                "- Visualiser la zone d'utilisation de ShareABike où les vélos peuvent être pris et déposés",
                '- Localiser les vélos disponibles en fonction de sa position',
                "- s'informer sur les caractéristiques des vélos disponibles",
                '- Déverrouiller et verrouiller le vélo qui est ou sera utilisé',
                '- Informer les autres utilisateurs et le gestionnaire du système de tout problème éventuel avec les vélos mis à disposition'
              ]
            ]
          }
        ]
      },
      {
        name: 'Cadenas intelligents',
        articles: [
          {
            name: 'Article 4',
            paragraphs: [
              [
                "Les cadenas électroniques sont fixés aux vélos et sont combinés à une chaîne qui ne peut en aucun cas être dissociée du vélo (sous peine d'amendes)."
              ]
            ]
          },
          {
            name: 'Article 4.1',
            paragraphs: [
              [
                "Le cadenas électronique permet de verrouiller et déverrouiller le vélo à un support fixe, en glissant un maillon de la chaîne dans la barre de fermeture du cadenas lors du verrouillage de celui-ci. Ce cadenas se verrouille et se déverrouille grâce à l'application mobile ShareABike."
              ]
            ]
          },
          {
            name: 'Article 4.2',
            paragraphs: [
              [
                "Pour pouvoir déverrouiller ou verrouiller le cadenas, l'utilisateur doit posséder un smartphone ayant la fonctionnalité Bluetooth et l'activer au préalable comme mentionné à l'article 2.2. En outre, l'utilisateur doit bien entendu avoir téléchargé l'application ShareABike au préalable."
              ]
            ]
          },
          {
            name: 'Article 4.3',
            paragraphs: [
              [
                "Les vélos appartenant au système peuvent être verrouillés uniquement à l'intérieur de la zone d'utilisation définie dans l'application mobile (pour un verrouillage définitif, c'est-à-dire lorsque l'utilisateur ne se servira plus du vélo). Le bon verrouillage du vélo est de la responsabilité de l'utilisateur."
              ],
              [
                'Celui-ci doit veiller à verrouiller le vélo emprunté à un endroit qui :',
                "- n'entrave pas la bonne circulation des piétons, véhicules motorisés ou non motorisés possédant 2 ou 4 roues (liste non exhaustive).",
                "- Se situe sur le territoire public ou au sein de la zone définie par l'organisation partenaire.",
                "- Doit rester accessible à tout autre utilisateur du système voulant emprunter le vélo par après. Pour les verrouillages temporaires (c'est-à-dire qui n'interrompent pas la location), le verrouillage peut se faire hors zone."
              ]
            ]
          },
          {
            name: 'Article 4.4',
            paragraphs: [
              [
                "Si le cadenas ne se verrouille pas correctement ou s'avère impossible à verrouiller, l'utilisateur doit avertir immédiatement le 065/84.95.81 (Pro Velo asbl), en indiquant clairement le numéro du vélo concerné et sa localisation. Ce numéro de téléphone est valable du lundi au vendredi de 9h à 19h. En cas de problème hors des heures ouvrables (y compris week-end et jours fériés), l'utilisateur devra impérativement envoyer un email à info@shareabike.be."
              ]
            ]
          },
          {
            name: 'Article 4.5',
            paragraphs: [
              [
                "s'il s'avère que le mauvais verrouillage du vélo ou une mauvaise manipulation de l'utilisateur entraîne la disparition ou la détérioration du vélo, une indemnité compensatoire pourra être réclamée à l'utilisateur."
              ]
            ]
          },
          {
            name: 'Article 4.6',
            paragraphs: [
              [
                "De même, tout problème identifié sur un vélo doit être signalé au système à travers l'application. l'utilisateur transmettant cette information ne sera pas tenu responsable, sauf s'il s'avère que sa responsabilité est engagée dans ledit problème identifié."
              ]
            ]
          }
        ]
      },
      {
        name: 'Vélos',
        articles: [
          {
            name: 'Article 5',
            paragraphs: [
              [
                "Les vélos appartenant au système et qui peuvent être utilisés sont clairement identifiables par le logo ShareABike posé sur le cadre. Les vélos possèdent tous un numéro, visible sur le cache de la roue arrière ainsi que sur le cadre. Ce numéro doit être mentionné dans toute communication avec ShareABike si l'utilisateur rencontre ou note un problème."
              ]
            ]
          },
          {
            name: 'Article 5.1',
            paragraphs: [
              [
                "Les vélos sont mixtes et ajustables selon la taille de l'utilisateur. l'utilisateur est encouragé avant toute utilisation à vérifier les quelques éléments de base ci-dessous (liste non exhaustive) :",
                '- Les freins sont en état de fonctionnement;',
                '- Les pneus sont bien gonflés;',
                "- Le système de transmission (c'est-à-dire la chaîne) est en bon état;",
                "- Le système d'éclairage fonctionne correctement."
              ],
              [
                "Pour tout élément ne paraissant pas normal ou qui requiert une réparation, l'utilisateur doit en faire part dans les plus brefs délais au service technique ShareABike en mentionnant le numéro du vélo. En outre, il ne doit en aucun cas utiliser ce vélo."
              ]
            ]
          },
          {
            name: 'Article 5.2',
            paragraphs: [
              [
                "Lorsque l'utilisateur dépose, temporairement ou définitivement, le vélo, il est tenu de verrouiller le vélo à un support fixe grâce au cadenas électronique, à la chaîne et à l'application. Il est tenu de vérifier que celui-ci est bien attaché, solidement, et qu'il ne peut être détaché sans déverrouiller le cadenas."
              ]
            ]
          }
        ]
      },
      {
        name: "Obligations de l'utilisateur",
        articles: [
          {
            name: 'Article 6',
            paragraphs: [
              [
                "l'utilisateur s'engage à ne pas communiquer son identifiant et son mot de passe à des tiers. l'identifiant et le mot de passe ne servent uniquement qu'à s'identifier auprès du système afin d'utiliser l'application mobile et emprunter un vélo."
              ]
            ]
          },
          {
            name: 'Article 6.1',
            paragraphs: [
              [
                "l'utilisateur s'engage à utiliser le service en bon père de famille et dans le respect des présentes conditions générales ainsi que dans le respect du code de la route et des règlements en vigueur."
              ]
            ]
          },
          {
            name: 'Article 6.2',
            paragraphs: [
              [
                "l'utilisateur assume la garde du vélo qu'il a retiré. Il devra éviter sa dégradation, sa destruction ou sa disparition."
              ]
            ]
          },
          {
            name: 'Article 6.3',
            paragraphs: [
              [
                "l'utilisateur s'engage à restituer le vélo dans les délais de la durée d'utilisation continue autorisée, à savoir 24 heures. En cas de manquement à cette obligation, une pénalité forfaitaire de 5€ pourra être demandée par jour de retard à l'utilisateur."
              ]
            ]
          },
          {
            name: 'Article 6.4',
            paragraphs: [
              [
                "En cas de constatation d'une utilisation du vélo contraire aux dispositions de l'article 6.1, l'utilisateur s'engage à restituer le vélo à la première demande de ShareABike ou d'un des partenaires."
              ]
            ]
          },
          {
            name: 'Article 6.5',
            paragraphs: [
              [
                "L'utilisateur s'engage à déclarer toute perte ou tout vol du vélo ou de ses accessoires à ShareABike et aux autorités de police, dans un délai de 24 heures."
              ]
            ]
          }
        ]
      },
      {
        name: 'Responsabilités',
        articles: [
          {
            name: 'Article 7',
            paragraphs: [
              [
                "l'utilisateur assume seul l'entière responsabilité des dommages matériels (à ses biens, au vélo ou aux biens de tiers) ou corporels (à lui ou à un tiers) causés par l'utilisation qui est faite du vélo pendant toute la durée d'utilisation, c'est-à-dire dès le déverrouillage du vélo jusqu'à son verrouillage (sauf si le vélo n'est pas verrouillé suivant l'article 4.3, ce qui de facto engage également la responsabilité de l'utilisateur). Il est également responsable des dommages susmentionnés lorsque la durée d'utilisation excède la durée maximum autorisée, c'est-à-dire encas de restitution tardive. Par conséquent, ShareABike et les partenaires ne peuvent en aucun cas être tenus pour responsables."
              ],
              [
                "Si l'utilisateur contrevient aux lois et règlements en vigueur, au cours de la location, ce compris dans la période excédant la durée maximum autorisée, ShareABike et les partenaires ne peuvent en aucun cas en être tenus pour responsables. Les cyclistes roulent sous leur propre responsabilité et s'engagent à respecter le code de la route."
              ]
            ]
          },
          {
            name: 'Article 7.1',
            paragraphs: [
              [
                "Les parents ou représentants légaux de tout mineur abonné au service ShareABike seront tenus responsables de tout dommage causé directement ou indirectement par le mineur du fait de l'utilisation du service. l'âge minimum requis pour l'utilisation du service est fixé à 16 ans."
              ]
            ]
          },
          {
            name: 'Article 7.2',
            paragraphs: [
              [
                "Tout emprunt pour plus de 24 heures (délai commençant dès le déverrouillage du vélo) est considéré comme un cas de disparition du vélo, jusqu'à ce que ce dernier soit retrouvé."
              ],
              [
                "L'utilisateur est susceptible de recevoir une amende (équivalente à 5€ par jour de retard) et, en cas de comportement répété (emprunt supérieur à 24h), d'être banni du système."
              ]
            ]
          },
          {
            name: 'Article 7.3',
            paragraphs: [
              [
                "En cas de disparition du vélo dont l'utilisateur est responsable, celui-ci a l'obligation (cf. article 6.5) de signaler cette disparition au fournisseur de service ShareABike à l'adresse email suivante : info@shareabike.be, dans les 24 heures suivant l'emprunt initial, et, dans les 48 heures, de déposer, auprès des services de police, une plainte pour vol ; le vélo demeure sous sa pleine et entière responsabilité jusqu'à la communication d'une copie dudit dépôt de plainte au fournisseur de service ShareABike."
              ]
            ]
          },
          {
            name: 'Article 7.4',
            paragraphs: [
              [
                "En cas d'accident et/ou incident mettant en cause le vélo, l'utilisateur a l'obligation (cf. article 6.5) de signaler les faits dans les délais, comme indiqué à l'article 4.4. Le vélo reste sous sa responsabilité jusqu'à ce qu'il sécurise le vélo au moyen du cadenas électronique présent sur le vélo. Si le cadenas électronique a été endommagé dans l'accident et ne fonctionne plus, l'utilisateur doit se référer à l'article 4.4 et suivre la procédure indiquée."
              ]
            ]
          },
          {
            name: 'Article 7.5',
            paragraphs: [
              [
                "L'utilisateur déclare être en mesure d'utiliser un vélo et avoir la condition physique adaptée à cette utilisation."
              ]
            ]
          },
          {
            name: 'Article 7.6',
            paragraphs: [
              [
                "Le vélo étant placé sous la responsabilité de l'utilisateur (cf. articles 6.2 et 7), il lui est recommandé de procéder, avant le déverrouillage et l'utilisation du vélo, à une vérification des principaux éléments, comme expliqué à l'article 5.1."
              ]
            ]
          },
          {
            name: 'Article 7.7',
            paragraphs: [
              [
                "Il est en outre recommandé à l'utilisateur :",
                "- D'adapter sa distance de freinage en cas d'intempéries ;",
                "- D'effectuer le réglage de la selle pour adapter la hauteur à sa morphologie ;",
                "- De porter un casque homologué et des vêtements adaptés. En outre, l'utilisateur doit respecter le code de la route en vigueur au moment de l'utilisation du service ShareABike (par exemple : respecter les feux de signalisation, ne pas rouler sur le trottoir, etc.)"
              ]
            ]
          },
          {
            name: 'Article 7.8',
            paragraphs: [
              ["L'utilisateur certifie qu'il est bien titulaire d'une police d'assurance responsabilité civile."]
            ]
          }
        ]
      },
      {
        name: 'Pénalités',
        articles: [
          {
            name: 'Article 8',
            paragraphs: [
              [
                "Dans le cas où le vélo est perdu, volé, accidenté ou détérioré, lors de l'utilisation de celui-ci par l'utilisateur, ShareABike se réserve le droit de réclamer à celui-ci une indemnité équivalente au montant de la caution, soit 50 €, dans le cas où une caution est demandée."
              ]
            ]
          },
          {
            name: 'Article 8.1',
            paragraphs: [
              [
                "Le montant de l'indemnité est exigible à la première demande de ShareABike ou de l'un des partenaires, en cas de constatation d'un manquement de l'utilisateur à ses obligations au titre des présentes conditions générales."
              ]
            ]
          }
        ]
      },
      {
        name: 'Confidentialité et utilisation des données personnelles',
        articles: [
          {
            name: 'Article 9',
            paragraphs: [
              [
                "En téléchargeant l'application, l'utilisateur donne son accord quant à l'accès relatif à certaines fonctionnalités propres à son smartphone, en ce compris la localisation GPS."
              ]
            ]
          },
          {
            name: 'Article 9.1',
            paragraphs: [
              [
                "Les données à caractère personnel que l'utilisateur fournit au fournisseur de service ShareABike sont conservées dans les fichiers dudit service. ShareABike est le responsable du traitement de ces données."
              ],
              [
                'Ces données sont traitées pour les finalités suivantes :',
                "- l'envoi des informations relatives à l'usage par utilisateur;",
                "- l'analyse dépersonnalisée et non nominative des trajets des utilisateurs afin de prévoir la répartition géographique des vélos;",
                "- l'utilisation dépersonnalisée et non nominative des données de trajets et des données socio-économiques à des fins scientifiques."
              ]
            ]
          },
          {
            name: 'Article 9.2',
            paragraphs: [
              ['Les données rassemblées ne seront en aucun cas communiquées à des tiers.'],
              [
                "Conformément à la Loi du 8 décembre 1992 relative à la protection de la vie privée à l'égard de données à caractère personnel, l'utilisateur dispose d'un droit d'accès aux informations qui le concernent et d'un droit de correction. Si l'utilisateur souhaite exercer ces droits, il doit contacter ShareABike à l'adresse postale visée à l'article 1.1."
              ]
            ]
          }
        ]
      },
      {
        name: 'Règlement des litiges',
        articles: [
          {
            name: 'Article 10',
            paragraphs: [
              [
                "Les présentes conditions générales sont soumises à la loi belge à l'exception de ses règles de conflit de lois. Tout différend relatif à leur exécution et à leurs suites sera soumis aux Cours et Tribunaux de l'arrondissement judiciaire de Mons où les Parties font expressément attribution de compétence, y compris en cas de référé, d'appel engarantie ou de pluralité de défendeurs."
              ]
            ]
          }
        ]
      },
      {
        name: 'Modification des présentes conditions générales',
        articles: [
          {
            name: 'Article 11',
            paragraphs: [
              [
                "Les utilisateurs seront informés par le site Internet et l'application mobile de toutes modifications apportées aux présentes conditions générales."
              ]
            ]
          }
        ]
      }
    ],
    signIn: 'Se connecter',
    signUp: "s'inscrire",
    about: 'À propos',
    contactUs: 'Nous contacter',
    CGU: "Conditions d'utilisation",
    help: "Besoin d'aide"
  },
  ContactUs: {
    quote: {
      text: "“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.”",
      author: 'Albert Einstein',
      bio: 'Physicien théoricien'
    },
    title: 'Nous contacter',
    subtitle: 'Envoyez-nous un message et nous vous recontacterons',
    email: { label: 'Adresse email', error: "l'adresse email est obligatoire" },
    message: {
      label: 'Votre message',
      error: 'Un message est obligatoire'
    },
    profile: { label: 'Votre profil', error: 'Vous devez indiquer votre profil' },
    profileOptions: {
      city: 'Ville',
      enterprise: 'Entreprise',
      user: 'Utilisateur'
    },
    city: { label: 'Votre ville', error: 'Vous devez indiquer votre ville' },
    name: { label: 'Votre nom', error: 'votre nom est obligatoire' },
    action: 'Envoyer',
    errors: {
      unknownError: 'Une erreur est survenue, veuillez réessayer plus tard.'
    },
    signIn: 'Se connecter',
    signUp: "s'inscrire",
    about: 'À propos',
    contactUs: 'Nous contacter',
    CGU: "Conditions d'utilisation",
    help: "Besoin d'aide"
  },
  SignUp: {
    quote: {
      text: "“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.”",
      author: 'Albert Einstein',
      bio: 'Physicien théoricien'
    },
    contactUs: 'Nous contacter',
    about: 'À propos',
    title: "s'inscrire",
    subtitle: 'Inscrivez-vous à la plateforme ShareABike',
    signUpConfirmationPartOne: 'En vous inscrivant, vous confirmez avoir lu et approuvé les',
    signUpConfirmationPartTwo: "conditions générales d'utilisation",
    firstName: { label: 'Prénom', error: 'Le prénom est obligatoire' },
    lastName: { label: 'Nom', error: 'Le nom est obligatoire' },
    email: { label: 'Adresse email', error: "l'adresse email est obligatoire" },
    password: {
      label: 'Mot de passe',
      error: 'Le mot de passe est obligatoire'
    },
    confirmPassword: {
      label: 'Confirmation du mot de passe',
      error: 'La confirmation du mot de passe est obligatoire',
      different: 'Le mot de passe et le mot de passe de confirmation ne correspondent pas'
    },
    birthYear: {
      label: 'Année de naissance',
      error: "l'année de naissance est obligatoire"
    },
    gender: {
      label: 'Sexe',
      error: 'Le sexe est obligatoire'
    },
    genders: {
      man: 'Homme',
      woman: 'Femme',
      other: 'Autre'
    },
    phoneNumber: {
      label: 'Numéro de téléphone',
      error: 'Le numéro de téléphone est obligatoire'
    },
    phoneType: {
      label: 'Type de téléphone',
      error: 'Le type de téléphone est obligatoire'
    },
    phoneTypes: {
      android: 'Android',
      iphone: 'iPhone',
      unknown: 'Je ne sais pas'
    },
    street: {
      label: 'Rue',
      error: 'La rue est obligatoire'
    },
    number: {
      label: 'Numéro',
      error: 'Le numéro est obligatoire'
    },
    zip: {
      label: 'Code postal',
      error: 'Le code postal est obligatoire',
      invalid: 'Le format du code postal est invalide'
    },
    city: {
      label: 'Ville',
      error: 'La ville est obligatoire'
    },
    cardNumber: {
      label: 'Carte de crédit ou de débit',
      error: 'La carte de crédit ou de débit est obligatoire'
    },
    action: {
      back: 'Retour',
      next: 'Suivant',
      skip: 'Passer',
      finish: 'Inscription'
    },
    errors: {
      alreadyExist: 'Un compte existe déjà pour cette adresse e-mail.',
      unknownError: 'Une erreur est survenue lors de votre inscription, veuillez réessayer plus tard.'
    },
    success: "Merci! Un e-mail vous a été envoyé pour l'activation de votre compte.",
    step1: 'Informations du compte',
    step2: 'Informations supplémentaires',
    step3: 'Informations bancaires',
    step4: 'Validation',
    signIn: 'Se connecter'
  },
  RentsChart: {
    title: 'Dernières locations',
    last7days: '7 derniers jours',
    overview: 'Voir plus',
    bookings: 'Réservations',
    rents: 'Locations'
  },
  UsersChart: {
    title: 'Complétion du profil des utilisateurs',
    basic: 'Basique',
    complete: 'Complet',
    creditcard: 'Carte de crédit'
  },
  Topbar: {
    organizations: 'Organisations',
    languages: 'Languages',
    notifications: 'Notifications',
    signout: 'Se déconnecter',
    closeMenu: 'Fermer le menu',
    openMenu: 'Ouvrir le menu'
  },
  UserDetails: {
    title: 'Détails',
    portletTitle: "Détails de l'utilisateur",
    portletSubtitle: 'Les informations ci-dessous peuvent être éditées',
    firstname: {
      label: 'Prénom',
      error: 'Le prénom est obligatoire'
    },
    name: {
      label: 'Nom',
      error: 'Le nom est obligatoire'
    },
    email: {
      label: 'Email',
      error: "l'email est obligatoire"
    },
    phoneNumber: {
      label: 'Numéro de téléphone'
    },
    birthYear: {
      label: 'Année de naissance'
    },
    street: {
      label: 'Rue'
    },
    postalCode: {
      label: 'Code postal'
    },
    city: {
      label: 'Ville'
    },
    gender: {
      label: 'Sexe'
    },
    phoneType: {
      label: 'Type de smartphone'
    },
    balance: {
      label: 'Crédits'
    },
    organizationRole: {
      label: "Rôle dans l'organisation"
    },
    man: 'Homme',
    woman: 'Femme',
    roles: {
      user: 'Utilisateur',
      transporter: 'Transporteur',
      operator: 'Opérateur'
    },
    address: 'Adresse',
    personalDetails: 'Informations personnelles',
    userSettings: "Paramètres de l'utilisateur",
    errors: {
      unknownError: "Une erreur est survenue pendant l'enregistrement des modifications.",
      fetchError: 'Une erreur est survenue, les détails de cet utilisateur ne peuvent pas être affichés.'
    },
    success: 'Les modifications ont bien été enregistrées',
    save: 'Enregister les modifications'
  },
  UserList: {
    title: 'Utilisateurs',
    nothing: 'Aucun utilisateur répertorié'
  },
  UsersTable: {
    name: 'Nom',
    email: 'Email',
    address: 'Adresse',
    phone: 'Téléphone',
    accessLevel: "Niveau d'accès",
    accessLevels: {
      basic: 'Basique',
      complete: 'Complet',
      creditcard: 'Carte de crédit'
    },
    role: 'Rôle',
    roles: {
      user: 'Utilisateur',
      transporter: 'Transporteur',
      operator: 'Opérateur'
    },
    registrationDate: "Date d'inscription",
    actions: 'Actions',
    userdetails: "Détails de l'utilisateur"
  },
  UsersToolbar: {
    addUser: 'Ajouter un utilisateur',
    search: 'Rechercher un utilisateur'
  },
  UserProfile: {
    title: 'Votre profil',
    firstname: {
      label: 'Prénom',
      error: 'Le prénom est obligatoire'
    },
    name: {
      label: 'Nom',
      error: 'Le nom est obligatoire'
    },
    email: {
      label: 'Email',
      error: "l'email est obligatoire"
    },
    phoneNumber: {
      label: 'Numéro de téléphone'
    },
    birthYear: {
      label: 'Année de naissance'
    },
    street: {
      label: 'Rue'
    },
    postalCode: {
      label: 'Code postal'
    },
    city: {
      label: 'Ville'
    },
    gender: {
      label: 'Sexe'
    },
    genders: {
      man: 'Homme',
      woman: 'Femme',
      other: 'Autre'
    },
    phoneType: {
      label: 'Type de smartphone'
    },
    phoneTypes: {
      android: 'Android',
      iphone: 'iPhone',
      unknown: 'Je ne sais pas'
    },
    creditBalance: 'Solde de crédits : ',
    addCredit: 'Ajouter des crédits',
    addCard: 'Enregistrer une carte de crédit pour des paiements futurs',
    savedCard: 'Méthode de paiement enregistrée',
    updateCard: 'Modifier votre méthode de paiement',
    errors: {
      unknownError: "Une erreur est survenue pendant l'enregistrement des modifications.",
      deleteError: "Une erreur est survenue, le moyen de paiement n'a pu être supprimé."
    },
    success: 'Les modifications ont bien été enregistrées',
    save: 'Enregister les modifications',
    card1: 'Informations du compte',
    card2: 'Informations supplémentaires',
    card3: 'Informations bancaires',
    deleteAccountTitle: 'Supprimer mon compte',
    deleteAccountWarning:
      'Cette action est irréversible et supprimera toutes les données liées à votre compte et à votre identité.',
    deleteAccountRemainingCredits:
      'Si vous avez des crédits restants sur votre compte, ils seront perdus. Le cas échéant, veuillez prendre contact avec le support avant de procéder à la suppression de votre compte.'
  },
  ConfirmDeleteAccountDialog: {
    title: 'Supprimer mon compte',
    subtitle: 'Attention, cette action est irréversible.',
    cancel: 'Annuler',
    confirm: 'Confirmer la suppression'
  },
  Verify: {
    quote: {
      text: "“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.”",
      author: 'Albert Einstein',
      bio: 'Physicien théoricien'
    },
    title: {
      welcome: 'Bonjour',
      error: 'Oups...'
    },
    subtitle: {
      loading: 'Veuillez patienter un instant, nous activons votre compte...',
      finish: 'Bienvenue à bord !'
    },
    errors: {
      missingParamsError: 'Il manque des informations dans votre requête, veuillez contacter votre administrateur.',
      alreadyValidatedError: 'Votre compte a déjà été activé.',
      unknownError:
        "Une erreur s'est produite lors de l'activation de votre compte, veuillez contacter votre administrateur."
    },
    success: 'Merci! Votre compte a été correctement activé.'
  },
  ZonesTable: {
    actions: 'Actions',
    title: 'Liste des zones',
    name: 'Nom',
    bikesCount: 'Nombre de vélos',
    color: 'Couleur de la zone'
  },
  ZonesToolbar: {
    addZone: 'Ajouter une zone',
    search: 'Rechercher une zone'
  },
  ResetPassword: {
    quote: {
      text: "“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.”",
      author: 'Albert Einstein',
      bio: 'Physicien théoricien'
    },
    title: 'Réinitialiser mon mot de passe',
    subtitle: 'Vous avez oublié votre mot de passe ? Pas de problème !',
    email: { label: 'Adresse email', error: "l'adresse email est obligatoire" },
    action: 'Continuer',
    errors: {
      unknownError: 'Une erreur est survenue, veuillez réessayer plus tard.'
    },
    nextAction: 'Veuillez consulter vos emails pour poursuivre la procédure',
    signIn: 'Se connecter',
    signUp: "s'inscrire",
    about: 'À propos',
    contactUs: 'Nous contacter',
    CGU: "Conditions d'utilisation",
    help: "Besoin d'aide"
  },
  ResetPasswordConfirmation: {
    quote: {
      text: "“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.”",
      author: 'Albert Einstein',
      bio: 'Physicien théoricien'
    },
    title: 'Réinitialiser mon mot de passe',
    subtitle: 'Vous avez oublié votre mot de passe ? Pas de problème !',
    email: { label: 'Adresse email', error: "l'adresse email est obligatoire" },
    password: {
      label: 'Mot de passe',
      error: 'Le mot de passe est obligatoire'
    },
    action: 'Continuer',
    errors: {
      unknownError: 'Une erreur est survenue, veuillez réessayer plus tard.'
    },
    nextAction: 'Vous pouvez vous connecter avec votre nouveau mot de passe.',
    signIn: 'Se connecter',
    signUp: "s'inscrire",
    about: 'À propos',
    contactUs: 'Nous contacter',
    CGU: "Conditions d'utilisation",
    help: "Besoin d'aide"
  }
};
