import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Shared services
import { fetchPrices } from 'store/price/actionsCreator';

// Shared components
import Portlet from 'components/Portlet';

// Custom components
import PricesTable from './components/PricesTable';
import AddPrice from './components/AddPrice';

// Component styles
import styles from './style';

class PriceList extends Component {
  signal = true;

  state = {
    error: null,
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchPrices();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchPrices();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  renderPrices() {
    const { classes, isLoading, error, strings, prices } = this.props;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (prices.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    return <PricesTable prices={prices} />;
  }

  render() {
    const { classes, strings } = this.props;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <div className={classes.content}>
            <Grid container spacing={24}>
              <Grid item lg={8} xs={12}>
                {this.renderPrices()}
              </Grid>
              <Grid item lg={4} xs={12}>
                <AddPrice />
              </Grid>
            </Grid>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

PriceList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};


const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.price.loading,
    prices: state.price.prices
  };
};

const mapDispatchToProps = {
  fetchPrices
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('PriceList'),
  withStyles(styles)
)(PriceList);
