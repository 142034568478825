import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';

// Shared components
import SearchInput from 'components/SearchInput';

// Shared services
import { deleteOrganizations } from 'store/organization/actionsCreator';

// Common 
import * as roles from 'common/roles';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class OrganizationsToolbar extends Component {
  
  handleDeleteOrganizations = async () => {
    const { onSelect, selectedOrganizations } = this.props;

    const { error } = await this.props.deleteOrganizations(selectedOrganizations);
    if (error) {
      this.setState({
        submitError: error
      });

      return;
    } 
      
    onSelect([]);
  };

  render() {
    const {
      classes,
      className,
      openAddOrganizationDialog,
      selectedOrganizations,
      strings,
      role
    } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            onChange={this.props.onFilterChange}
            placeholder={strings.search}
          />
          <span className={classes.spacer} />
          {selectedOrganizations.length > 0 && (
            <Button
              className={classes.deleteButton}
              color="default"
              onClick={this.handleDeleteOrganizations}
              size="small"
              variant="contained"
            >
              <DeleteIcon className={classes.deleteIcon} /> {strings.delete}
            </Button>
          )}
          {role === roles.Admin && <Button
            className={classes.addButton}
            color="primary"
            onClick={openAddOrganizationDialog}
            size="small"
            variant="contained"
                                   >
            {strings.addOrganization}
          </Button>}
        </div>
      </div>
    );
  }
}

OrganizationsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func,
  openAddOrganizationDialog: PropTypes.func.isRequired,
  selectedOrganizations: PropTypes.array,
  strings: PropTypes.object.isRequired
};

OrganizationsToolbar.defaultProps = {
  onFilterChange: () => {},
  onSelect: () => {},
  selectedOrganizations: []
};

const mapStateToProps = state => {
  return {
    role: state.authorize.role
  };
};

const mapDispatchToProps = {
  deleteOrganizations
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('OrganizationsToolbar'),
  withStyles(styles)
)(OrganizationsToolbar);
