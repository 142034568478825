import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Shared components
import Link from 'components/Link';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';
import Quote from 'components/Layout/Quote';

import CheckoutForm from './CheckoutForm';
import './app.css';
import { createPaymentIntent } from '../../services/payments';
import { CreditPrice, getPrices } from '../../services/price';
import { CircularProgress, MenuItem, Select } from '@material-ui/core';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

// Actions

const Checkout = ({ classes, strings }: any) => {
  const [clientSecret, setClientSecret] = useState('');
  const [priceId, setPriceId] = useState('');
  const [prices, setPrices] = useState<CreditPrice[]>([]);

  useEffect(() => {
    getPrices().then((prices) => {
      console.log(prices);
      setPriceId(prices[0]._id);
      setPrices(prices);
    });
  }, []);

  useEffect(() => {
    if (priceId) {
      setClientSecret('');
      // Create PaymentIntent as soon as the page loads
      createPaymentIntent(priceId).then((data) => setClientSecret(data.clientSecret));
    }
  }, [priceId]);

  const appearance: Appearance = {
    theme: 'flat'
  };

  const options = {
    clientSecret,
    appearance
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteWrapper} item lg={6}>
          <Quote />
        </Grid>
        <Grid className={classes.content} item lg={6} xs={12}>
          <div className={classes.contentHeader} />
          <div className={classes.contentBody}>
            <div className={classes.contentWrapper}>
              <div className={classes.contentText}>
                <Typography variant="h4">Combien de crédits souhaitez-vous acheter ?</Typography>
                <Select
                  className={classes.selectPrice}
                  onChange={(e) => {
                    setPriceId(e.target.value);
                  }}
                  value={priceId}
                  variant="filled"
                >
                  {prices.map((price) => (
                    <MenuItem value={price._id} key={price._id}>
                      {`${price.price}€`} &mdash; {`${price.credit} crédits`}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div id="checkout" style={{ minHeight: 350 }}>
                {clientSecret ? (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                  </Elements>
                ) : (
                  <div style={{ height: 350, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress size={50} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles as any),
  translate('Checkout')
)(Checkout);
