export const getBookings = (organizationId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/bookings/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId,
          language: localStorage.getItem('x-localization') ? localStorage.getItem('x-localization') : 'fr'
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const getRents = (organizationId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/rents/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId,
          language: localStorage.getItem('x-localization') ? localStorage.getItem('x-localization') : 'fr'
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const endRent = (organizationId, bikeId, coordinates, userRentId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/rents/end', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId,
          language: localStorage.getItem('x-localization') ? localStorage.getItem('x-localization') : 'fr'
        },
        body: JSON.stringify({
          bike_id: bikeId,
          coordinates: coordinates,
          userRent_id: userRentId
        })
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const authorize = async (call, ...props) => {
  const promise = new Promise(async (resolve, reject) => {
    const auth = localStorage.getItem('x-auth');
    if (!auth) {
      reject({
        error: 'missing auth token'
      });
      return;
    }
    await call(resolve, reject, auth, ...props);
  });
  return promise;
};