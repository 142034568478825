import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import queryString from 'query-string';

// Material helpers
import { withStyles } from '@material-ui/core/styles/index';

// Material components
import Grid from '@material-ui/core/Grid/index';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography/index';

// Material icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Actions
import { verify } from 'store/authentication/actionsCreator';
import Quote from 'components/Layout/Quote';

class Verify extends Component {
  state = {
    submitError: null,
    submitStatus: null,
    open: false
  };

  componentDidMount() {
    this.signal = true;

    const { location, strings } = this.props;

    const parsed = queryString.parse(location.search);

    if (parsed && parsed.email && parsed.key) {
      this.handleVerify(parsed.email, parsed.key);
      this.setState({
        submitStatus: 'pending'
      });
    } else {
      this.setState({
        submitError: strings.errors.missingParamsError,
        submitStatus: 'fatal'
      });
    }
  }

  handleClose = () => {
    this.props.history.push('/sign-in');

    this.setState({
      open: false
    });
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleVerify = async (email, key) => {
    const { strings } = this.props;

    console.log('verify');

    await this.props.verify(email, key, (result) => {
      console.log('result', result);

      if (result) {
        this.setState({
          submitError:
            result.error === 'EmailAlreadyVerified'
              ? strings.errors.alreadyValidatedError
              : strings.errors.unknownError,
          submitStatus: 'fatal'
        });
      } else {
        this.setState({
          submitStatus: 'ok',
          open: true
        });
      }
    });
  };

  render() {
    const { classes, strings } = this.props;
    const { submitError, submitStatus, open } = this.state;

    const parsed = queryString.parse(this.props.location.search);

    return (
      <>
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid className={classes.quoteWrapper} item lg={6}>
              <Quote />
            </Grid>
            <Grid className={classes.content} item lg={6} xs={12}>
              <div className={classes.content}>
                <div className={classes.contentHeader} />
                <div className={classes.contentBody}>
                  <form className={classes.form}>
                    {submitStatus === 'ok' && (
                      <>
                        <Typography className={classes.title} variant="h2">
                          {parsed && parsed.firstName && parsed.lastName
                            ? `${strings.title.welcome} ${parsed.firstName} ${parsed.lastName}`
                            : `${strings.title.welcome}`}
                        </Typography>
                        <Typography className={classes.subtitle} variant="body1">
                          {strings.subtitle.finish}
                        </Typography>
                      </>
                    )}
                    {submitStatus === 'pending' && (
                      <>
                        <Typography className={classes.title} variant="h2">
                          {parsed && parsed.firstName && parsed.lastName
                            ? `${strings.title.welcome} ${parsed.firstName} ${parsed.lastName}`
                            : `${strings.title.welcome}`}
                        </Typography>
                        <Typography className={classes.subtitle} variant="body1">
                          {strings.subtitle.loading}
                        </Typography>
                      </>
                    )}
                    {submitStatus === 'fatal' && (
                      <>
                        <Typography className={classes.title} variant="h2">
                          {strings.title.error}
                        </Typography>
                        {submitError && (
                          <Typography className={classes.submitError} variant="body1">
                            {submitError}
                          </Typography>
                        )}
                      </>
                    )}
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          autoHideDuration={6000}
          className={classes.snackbar}
          onClose={this.handleClose}
          open={open}
        >
          <SnackbarContent
            action={[
              <IconButton className={classes.snackbarClose} color="inherit" key="close" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            ]}
            className={classes.snackbarContent}
            message={
              <span className={classes.snackbarMessage}>
                <CheckCircleIcon />
                {strings.success}
              </span>
            }
          />
        </Snackbar>
      </>
    );
  }
}

Verify.propTypes = {
  classes: PropTypes.object.isRequired,
  verify: PropTypes.func.isRequired,
  strings: PropTypes.object
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  verify
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
  translate('Verify')
)(Verify);
