export default {
  'notes.fr': {
    presence: { allowEmpty: true }
  },
  'notes.en': {
    presence: { allowEmpty: true }
  },
  'notes.nl': {
    presence: { allowEmpty: true }
  }
};
