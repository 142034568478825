import green from '@material-ui/core/colors/green';

export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3
  },
  content: {
    marginTop: theme.spacing.unit * 2
  },
  mapWrapper: {
    height: '50vh',
    minHeight: 200,
    marginBottom: theme.spacing.unit * 2
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center'
  },
  textWrapper: {
    paddingTop: '48px',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center'
  },
  switchField: {
    marginLeft: 0,
    width: '100%',
    height: '56px',
    justifyContent: 'flex-end',
    '& span:last-child': {
      fontSize: '16px'
    }
  },
  textField: {
    width: '100%'
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  },
  portletLoader: {},
  portletFooter: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  snackbar: {
    margin: theme.spacing.unit * 3
  },
  snackbarContent: {
    backgroundColor: green[600]
  },
  back: {
    marginLeft: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3
  },
  close: {
    padding: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing.unit
    }
  },
  deleteButton: {
    borderColor: theme.palette.danger.main + '!important',
    color: theme.palette.danger.main + '!important'
  },
  chip: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit
  },
  description: {
    marginTop: theme.spacing.unit
  }
});
