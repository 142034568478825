import actions from './actions';

import * as userService from 'services/user';
import * as invitationService from 'services/invitation';

// eslint-disable-next-line no-unused-vars
export const fetchInvitations = () => async (dispatch, getState) => {
  // if (getState().user.invitations.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_INVITATIONS });

  try {
    const organization = getState().organization.currentOrganization;

    const invitations = await invitationService.getInvitations(
      organization._id
    );

    dispatch({
      type: actions.SET_INVITATIONS,
      value: invitations
    });

    dispatch({ type: actions.END_FETCH_INVITATIONS });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_INVITATIONS });
  }

  return dispatch;
};

// eslint-disable-next-line no-unused-vars
export const deleteInvitations = (invitationsToDelele) => async (dispatch, getState) => {
  dispatch({ type: actions.START_DELETING_INVITATIONS });

  try {
    const organization = getState().organization.currentOrganization;

    const invitations = await invitationService.deleteInvitations(organization._id, invitationsToDelele);

    dispatch({
      type: actions.SET_INVITATIONS,
      value: invitations
    });

    dispatch({ type: actions.END_DELETING_INVITATIONS });
  } catch (error) {
    dispatch({ type: actions.END_DELETING_INVITATIONS });
  }

  return dispatch;
};

// eslint-disable-next-line no-unused-vars
export const fetchUsers = () => async (dispatch, getState) => {
  // if (getState().user.users.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_USERS });

  try {
    const organization = getState().organization.currentOrganization;

    const users = await userService.getUsers(organization._id);

    dispatch({
      type: actions.SET_USERS,
      value: users
    });

    dispatch({ type: actions.END_FETCH_USERS });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_USERS });
  }

  return dispatch;
};

// eslint-disable-next-line no-unused-vars
export const deleteUsers = (usersToDelele) => async (dispatch, getState) => {
  dispatch({ type: actions.START_DELETING_USERS });

  try {
    const organization = getState().organization.currentOrganization;

    const users = await userService.deleteUsers(organization._id, usersToDelele);

    dispatch({
      type: actions.SET_USERS,
      value: users
    });

    dispatch({ type: actions.END_DELETING_USERS });
    return {error : null};
  } catch (error) {
    dispatch({ type: actions.END_DELETING_USERS });
    return {error : error};
  }
};

// eslint-disable-next-line no-unused-vars
export const deleteUsersFromCurrentOrganization = (usersToDelele) => async (dispatch, getState) => {
  dispatch({ type: actions.START_DELETING_USERS });

  try {
    const organization = getState().organization.currentOrganization;

    const users = await userService.deleteUsersFromCurrentOrganization(organization._id, usersToDelele);

    dispatch({
      type: actions.SET_USERS,
      value: users
    });

    dispatch({ type: actions.END_DELETING_USERS });
    return {error : null};
  } catch (error) {
    dispatch({ type: actions.END_DELETING_USERS });
    return {error : error};
  }
};

// eslint-disable-next-line no-unused-vars
export const fetchAllUsers = () => async (dispatch, getState) => {
  // if (getState().user.users.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_USERS });

  try {
    const users = await userService.getAllUsers();

    dispatch({
      type: actions.SET_USERS,
      value: users
    });

    dispatch({ type: actions.END_FETCH_USERS });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_USERS });
  }

  return dispatch;
};

// eslint-disable-next-line no-unused-vars
export const inviteUser = (
  email,
  role,
  firstname,
  lastname,
  onFinished
) => async (dispatch, getState) => {
  dispatch({ type: actions.START_INVITE_USER });
  try {
    const organization = getState().organization.currentOrganization;

    const response = await userService.inviteUser(
      organization._id,
      email,
      role,
      firstname,
      lastname
    );

    if (response.user) {
      dispatch({
        type: actions.SET_USERS,
        value: [...getState().user.users, response.user]
      });
    } else if (response.access) {
      dispatch({
        type: actions.SET_INVITATIONS,
        value: [...getState().user.invitations, response.access]
      });
    }

    dispatch({ type: actions.END_INVITE_USER });
    onFinished();
  } catch (error) {
    onFinished(error);
    dispatch({ type: actions.END_INVITE_USER });
  }
};

// eslint-disable-next-line no-unused-vars
export const fetchUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: actions.START_FETCH_USER });

  try {
    const organization = getState().organization.currentOrganization;

    const user = await userService.getUser(organization._id, userId);

    dispatch({
      type: actions.SET_USER,
      value: user
    });

    dispatch({ type: actions.END_FETCH_USER });
    return { user: user };
  } catch (error) {
    dispatch({ type: actions.END_FETCH_USER });
    return { error: error };
  }
};

export const updateUser = (userId, userToUpdate) => async (dispatch, getState) => {
  dispatch({ type: actions.START_UPDATE_USER });

  try {
    const organization = getState().organization.currentOrganization;

    const user = await userService.updateUser(organization._id, userId, userToUpdate);

    dispatch({
      type: actions.SET_USER,
      value: user
    });
    dispatch({ type: actions.END_UPDATE_USER });
    return { error: null };
  } catch(error) {
    dispatch({ type: actions.END_UPDATE_USER });
    return { error: error };
  }
};

export const updateUserProfile = (userId, userToUpdate) => async (dispatch) => {
  dispatch({ type: actions.START_UPDATE_USERPROFILE });
  
  try {
    const user = await userService.updateUserProfile(userId, userToUpdate);

    dispatch({
      type: actions.SET_USER,
      value: user
    });
    dispatch({ type: actions.END_UPDATE_USERPROFILE });
    return { user: user };
  } catch(error) {
    dispatch({ type: actions.END_UPDATE_USERPROFILE });
    return { error: error };
  }
}

export const fetchPaymentInfo = () => async (dispatch) => {
  dispatch({ type: actions.START_FETCH_PAYMENTINFO });

  try {
    const paymentSource = await userService.getPaymentInfo();

    dispatch({
      type: actions.SET_PAYMENTINFO,
      value: paymentSource
    });
    dispatch({ type: actions.END_FETCH_PAYMENTINFO});
  } catch (error) {
    dispatch({ type: actions.END_FETCH_PAYMENTINFO});
  }
}

export const updatePaymentInfo = (stripeToken, onFinished) => async (dispatch) => {
  dispatch({ type: actions.START_UPDATE_PAYMENTINFO });

  try {
    const paymentSource = await userService.updatePaymentInfo(stripeToken);

    dispatch({
      type: actions.SET_PAYMENTINFO,
      value: paymentSource
    });
    dispatch({ type: actions.END_UPDATE_PAYMENTINFO});
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_UPDATE_PAYMENTINFO});
    onFinished(error);
  }
}

export const deletePaymentMethod = (cardId) => async (dispatch) => {
  dispatch({ type: actions.START_DELETE_PAYMENTINFO });

  try {
    await userService.deletePaymentMethod(cardId);

    dispatch({
      type: actions.SET_PAYMENTINFO,
      value: {}
    });
    return { error: null };
  } catch (error) {
    dispatch({ type: actions.END_DELETE_PAYMENTINFO });
    return { error: error };
  }
}


