import actions from './actions';

const initState = {
  loading: false,
  currentOrganization: null,
  organizations: [],
  organization: {}
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.START_FETCH_ORGANIZATIONS:
      return { ...state, loading: true };
    case actions.END_FETCH_ORGANIZATIONS:
      return { ...state, loading: false };
    case actions.START_DELETING_ORGANIZATIONS:
      return { ...state, loading: true };
    case actions.END_DELETING_ORGANIZATIONS:
      return { ...state, loading: false };
    case actions.START_ADD_ORGANIZATION:
      return { ...state, loading: true };
    case actions.END_ADD_ORGANIZATION:
      return { ...state, loading: false };
    case actions.START_FETCH_ORGANIZATION:
      return { ...state, loading: true };
    case actions.END_FETCH_ORGANIZATION:
      return { ...state, loading: false };
    case actions.START_UPDATE_ORGANIZATION:
      return { ...state, loading: true };
    case actions.END_UPDATE_ORGANIZATION:
      return { ...state, loading: false };
    case actions.START_UPDATESTATUS_ORGANIZATION:
      return { ...state, loading: true };
    case actions.END_UPDATESTATUS_ORGANIZATION:
      return { ...state, loading: false };
    case actions.SET_ORGANIZATIONS:
      return { ...state, organizations: action.value };
    case actions.SET_ORGANIZATION:
      return { ...state, organization: action.value };
    case actions.SET_CURRENT_ORGANIZATION:
      return { ...state, currentOrganization: action.value };
    default:
      return state;
  }
};

export default reducer;
