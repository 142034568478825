export default theme => ({
  root: {},
  dialogTitle: {
    '& h6': {
      fontSize: '20px'
    }
  },
  dialogLoader: {
    marginTop: theme.spacing.unit * 2
  },
  dialogForm: {
    marginTop: theme.spacing.unit * 2
  },
  switchField: {
    width: '100%',
    justifyContent: 'center',
    '& span:last-child': {
      fontSize: '16px',
    }
  },
  textField: {
    width: '100%'
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  }
});
