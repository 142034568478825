import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Bar } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

// Material Icons
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletToolbar from 'components/PortletToolbar';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';

// Shared services
import { fetchBookings, fetchRents } from 'store/rent/actionsCreator';

// Chart configuration
import { data, options } from './chart';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class RentsChart extends Component {
  componentDidMount() {
    this.signal = true;
    this.props.fetchBookings();
    this.props.fetchRents();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchBookings();
      this.props.fetchRents();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  renderRentsChart() {
    const { classes, className, isLoading, rents, bookings, error, strings } = this.props;

    const rootClassName = classNames(classes.root, className);

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel title={strings.title} />
          <PortletToolbar>
            <Typography variant="subtitle2">{strings.last7days}</Typography>
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Bar data={data(rents, bookings, strings)} options={options} />
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Link to={'/rents/'}>
            <Button color="primary" size="small" variant="text">
              {strings.overview} <ArrowRightIcon />
            </Button>
          </Link>
        </PortletFooter>
      </Portlet>
    );
  }

  render() {
    return <React.Fragment>{this.renderRentsChart()}</React.Fragment>;
  }
}

RentsChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.rent.loading,
    bookings: state.rent.bookings,
    rents: state.rent.rents
  };
};

const mapDispatchToProps = {
  fetchBookings,
  fetchRents
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  translate('RentsChart')
)(RentsChart);
