import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';

// Roles
import * as Roles from 'common/roles';

const PrivateRoute = ({ component: Component, authorizedRoles: AuthorizedRoles = [], ...rest }) => {
  console.log('private route');
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.isAuthenticated) {
          console.log('private route : isAuthenticated');
          if ((AuthorizedRoles && AuthorizedRoles.length === 0) || AuthorizedRoles.includes(rest.role)) {
            console.log('private route : ok role constraints');
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/not-found'
                }}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                search: `?redirect=${encodeURIComponent(props.location.pathname)}`,
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};

PrivateRoute.props = {
  location: PropTypes.object,
  component: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    role: state.authorize.role
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
