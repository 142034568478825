import actions from './actions';

const initState = {
  language: localStorage.getItem('x-localization') ? localStorage.getItem('x-localization') : 'fr',
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_LANGUAGE:
      return { ...state, language: action.value };
    default:
      return state;
  }
};

export default reducer;
