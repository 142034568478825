import actions from './actions';

const initState = {
  role: undefined,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_ROLE:
      return { ...state, role: action.value };
    default:
      return state;
  }
};

export default reducer;
