import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import _ from 'lodash';
import generator from 'generate-password';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles/index';

// Material components
import Button from '@material-ui/core/Button/index';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import Divider from '@material-ui/core/Divider/index';
import Grid from '@material-ui/core/Grid/index';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem/index';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Stepper from '@material-ui/core/Stepper/index';
import Step from '@material-ui/core/Step/index';
import StepLabel from '@material-ui/core/StepLabel/index';
import StepContent from '@material-ui/core/StepContent/index';
import TextField from '@material-ui/core/TextField/index';
import Typography from '@material-ui/core/Typography/index';

// Material icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

// Shared components
import Link from 'components/Link';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema1 from './schema1';
import schema2 from './schema2';
import schema3 from './schema3';

// Actions
import { signUp } from 'store/authentication/actionsCreator';
import Quote from 'components/Layout/Quote';

class SignUp extends Component {
  state = {
    steps: [
      {
        values: {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: ''
        },
        touched: {
          firstName: false,
          lastName: false,
          email: false,
          password: false,
          confirmPassword: false
        },
        errors: {
          firstName: null,
          lastName: null,
          email: null,
          password: null,
          confirmPassword: null
        },
        isValid: false
      },
      {
        values: {
          birthYear: '',
          gender: '',
          phoneNumber: '',
          phoneType: '',
          street: '',
          number: '',
          zip: '',
          city: ''
        },
        touched: {
          birthYear: false,
          gender: false,
          phoneNumber: false,
          phoneType: false,
          street: false,
          number: false,
          zip: false,
          city: false
        },
        errors: {
          birthYear: null,
          gender: null,
          phoneNumber: null,
          phoneType: null,
          street: null,
          number: null,
          zip: null,
          city: null
        },
        isValid: false
      },
      {
        values: {
          cardNumber: ''
        },
        touched: {
          cardNumber: false
        },
        errors: {
          cardNumber: null
        },
        isValid: false
      }
    ],
    submitError: null,
    activeStep: 0,
    skippedStep: false,
    open: false
  };

  validateForm = (index) => {
    const newState = { ...this.state };

    const errors = validate(
      newState.steps[index].values,
      index === 0 ? schema1 : index === 1 ? schema2 : index === 2 ? schema3 : null
    );

    newState.steps[index].errors = errors || {};
    newState.steps[index].isValid = errors ? false : true;

    this.setState(newState);
  };

  handleClose = () => {
    this.props.history.push('/sign-in');

    this.setState({
      open: false
    });
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleChange = (index, field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.steps[index].touched[field] = true;
    newState.steps[index].values[field] = value;

    this.setState(newState, () => this.validateForm(index));
  };

  handleSignUp = async () => {
    const { strings } = this.props;
    const { steps, activeStep, skippedStep } = this.state;

    let firstName = steps[0].values.firstName;
    let lastName = steps[0].values.lastName;
    let email = steps[0].values.email;
    let password = steps[0].values.password;
    let birthYear = steps[1].values.birthYear;
    let gender = steps[1].values.gender;
    let phoneNumber = steps[1].values.phoneNumber;
    let phoneType = steps[1].values.phoneType;
    let street = steps[1].values.street;
    let number = steps[1].values.number;
    let zip = steps[1].values.zip;
    let city = steps[1].values.city;
    let stripeToken = null;
    let accessLevel = skippedStep ? activeStep - 1 : 3;
    let keyVerify = generator.generate({
      length: 50,
      numbers: true
    });

    await this.props.signUp(
      firstName,
      lastName,
      email,
      password,
      birthYear,
      gender,
      phoneNumber,
      phoneType,
      street,
      number,
      zip,
      city,
      stripeToken,
      accessLevel,
      keyVerify,
      (result, error) => {
        if (error) {
          if (result && result.message && result.message.code === 11000) {
            this.setState({
              submitError: strings.errors.alreadyExist
            });
          } else {
            this.setState({
              submitError: strings.errors.unknownError
            });
          }
        } else {
          if (result && result.user && result.user.email && result.user.keyVerify) {
            this.sendMail(result.user.email, result.user.keyVerify, result.user.firstname, result.user.name);
          }
        }
      }
    );
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.skippedStep ? 1 : this.state.activeStep - 1,
      skippedStep: false
    });
  };

  handleNext = () => {
    this.setState({
      activeStep: this.state.skippedStep ? this.getSteps().length - 1 : this.state.activeStep + 1,
      skippedStep: false
    });
  };

  handleSkip = () => {
    this.setState({
      activeStep: this.getSteps().length - 1,
      skippedStep: true
    });
  };

  getSteps = () => {
    const { strings } = this.props;

    return [
      { label: strings.step1, index: 0 },
      { label: strings.step2, index: 1 },
      // { label: strings.step3, index: 2 },
      { label: strings.step4, index: 3 }
    ];
  };

  getStepContent = (index) => {
    const { classes, strings, loading } = this.props;
    const { steps } = this.state;

    const step = steps[index];

    switch (index) {
      case 0: {
        const showFirstNameError = step.touched.firstName && step.errors.firstName;
        const showLastNameError = step.touched.lastName && step.errors.lastName;
        const showEmailError = step.touched.email && step.errors.email;
        const showPasswordError = step.touched.password && step.errors.password;
        const showConfirmPasswordError = step.touched.confirmPassword && step.errors.confirmPassword;
        const showConfirmPasswordDifferent =
          step.touched.confirmPassword && step.values.password !== step.values.confirmPassword;

        return (
          <>
            <TextField
              className={classes.textField}
              error={showFirstNameError ? true : false}
              helperText={showFirstNameError ? strings.firstName.error : ''}
              label={strings.firstName.label}
              name="firstName"
              onChange={(event) => this.handleChange(0, 'firstName', event.target.value)}
              type="text"
              value={step.values.firstName}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={showLastNameError ? true : false}
              helperText={showLastNameError ? strings.lastName.error : ''}
              label={strings.lastName.label}
              name="lastName"
              onChange={(event) => this.handleChange(0, 'lastName', event.target.value)}
              type="text"
              value={step.values.lastName}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={showEmailError ? true : false}
              helperText={showEmailError ? strings.email.error : ''}
              label={strings.email.label}
              name="email"
              onChange={(event) => this.handleChange(0, 'email', event.target.value)}
              type="text"
              value={step.values.email}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={showPasswordError ? true : false}
              helperText={showPasswordError ? strings.password.error : ''}
              label={strings.password.label}
              name="password"
              onChange={(event) => this.handleChange(0, 'password', event.target.value)}
              type="password"
              value={step.values.password}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={showConfirmPasswordError || showConfirmPasswordDifferent ? true : false}
              helperText={
                showConfirmPasswordError
                  ? strings.confirmPassword.error
                  : showConfirmPasswordDifferent
                  ? strings.confirmPassword.different
                  : ''
              }
              label={strings.confirmPassword.label}
              name="confirmPassword"
              onChange={(event) => this.handleChange(0, 'confirmPassword', event.target.value)}
              type="password"
              value={step.values.confirmPassword}
              variant="outlined"
            />
            <div className={classes.buttonContainer}>
              <Button className={classes.button} disabled onClick={this.handleBack} size="large">
                {strings.action.back}
              </Button>
              <div className={classes.buttonSpace} />
              <Button
                className={classes.button}
                color="primary"
                disabled={!step.isValid}
                onClick={this.handleNext}
                size="large"
                variant="contained"
              >
                {strings.action.next}
              </Button>
            </div>
          </>
        );
      }
      case 1: {
        const showBirthYearError = step.touched.birthYear && step.errors.birthYear;
        const showGenderError = step.touched.gender && step.errors.gender;
        const showPhoneNumberError = step.touched.phoneNumber && step.errors.phoneNumber;
        const showPhoneTypeError = step.touched.phoneType && step.errors.phoneType;
        const showStreetError = step.touched.street && step.errors.street;
        const showNumberError = step.touched.number && step.errors.number;
        const showZipError = step.touched.zip && step.errors.zip && step.errors.zip.includes("Zip can't be blank");
        const showZipInvalid = step.touched.zip && step.errors.zip && step.errors.zip.includes('Zip is invalid');
        const showCityError = step.touched.city && step.errors.city;

        return (
          <>
            <div className={classes.textFieldContainer}>
              <TextField
                className={classes.textField}
                error={showBirthYearError ? true : false}
                helperText={showBirthYearError ? strings.birthYear.error : ''}
                label={strings.birthYear.label}
                name="birthYear"
                onChange={(event) => this.handleChange(1, 'birthYear', event.target.value)}
                select
                type="text"
                value={step.values.birthYear}
                variant="outlined"
              >
                {_.map(new Array(new Date().getFullYear() - 1917), (_undefined, index) => (
                  <MenuItem key={new Date().getFullYear() - 17 - index} value={new Date().getFullYear() - 17 - index}>
                    {new Date().getFullYear() - 18 - index}
                  </MenuItem>
                ))}
              </TextField>
              <div className={classes.textFieldSpace} />
              <TextField
                className={classes.textField}
                error={showGenderError ? true : false}
                helperText={showGenderError ? strings.gender.error : ''}
                label={strings.gender.label}
                name="gender"
                onChange={(event) => this.handleChange(1, 'gender', event.target.value)}
                select
                type="text"
                value={step.values.gender}
                variant="outlined"
              >
                <MenuItem value="F">{strings.genders.woman}</MenuItem>
                <MenuItem value="H">{strings.genders.man}</MenuItem>
                <MenuItem value="O">{strings.genders.other}</MenuItem>
              </TextField>
            </div>
            <div className={classes.textFieldContainer}>
              <TextField
                className={classes.textField}
                error={showPhoneNumberError ? true : false}
                helperText={showPhoneNumberError ? strings.phoneNumber.error : ''}
                label={strings.phoneNumber.label}
                name="phoneNumber"
                onChange={(event) => this.handleChange(1, 'phoneNumber', event.target.value)}
                type="text"
                value={step.values.phoneNumber}
                variant="outlined"
              />
              <div className={classes.textFieldSpace} />
              <TextField
                className={classes.textField}
                error={showPhoneTypeError ? true : false}
                helperText={showPhoneTypeError ? strings.phoneType.error : ''}
                label={strings.phoneType.label}
                name="phoneType"
                onChange={(event) => this.handleChange(1, 'phoneType', event.target.value)}
                select
                type="text"
                value={step.values.phoneType}
                variant="outlined"
              >
                <MenuItem value="Android">{strings.phoneTypes.android}</MenuItem>
                <MenuItem value="iPhone">{strings.phoneTypes.iphone}</MenuItem>
                <MenuItem value="X">{strings.phoneTypes.unknown}</MenuItem>
              </TextField>
            </div>
            <div className={classes.textFieldContainer}>
              <TextField
                className={classes.textField}
                error={showStreetError ? true : false}
                helperText={showStreetError ? strings.street.error : ''}
                label={strings.street.label}
                name="street"
                onChange={(event) => this.handleChange(1, 'street', event.target.value)}
                style={{ width: '70%' }}
                type="text"
                value={step.values.street}
                variant="outlined"
              />
              <div className={classes.textFieldSpace} />
              <TextField
                className={classes.textField}
                error={showNumberError ? true : false}
                helperText={showNumberError ? strings.number.error : ''}
                label={strings.number.label}
                name="number"
                onChange={(event) => this.handleChange(1, 'number', event.target.value)}
                style={{ width: '30%' }}
                type="text"
                value={step.values.number}
                variant="outlined"
              />
            </div>
            <div className={classes.textFieldContainer}>
              <TextField
                className={classes.textField}
                error={showZipError || showZipInvalid ? true : false}
                helperText={showZipError ? strings.zip.error : showZipInvalid ? strings.zip.invalid : ''}
                label={strings.zip.label}
                name="zip"
                onChange={(event) => this.handleChange(1, 'zip', event.target.value)}
                type="text"
                value={step.values.zip}
                variant="outlined"
              />
              <div className={classes.textFieldSpace} />
              <TextField
                className={classes.textField}
                error={showCityError ? true : false}
                helperText={showCityError ? strings.city.error : ''}
                label={strings.city.label}
                name="city"
                onChange={(event) => this.handleChange(1, 'city', event.target.value)}
                type="text"
                value={step.values.city}
                variant="outlined"
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button className={classes.button} onClick={this.handleBack} size="large">
                {strings.action.back}
              </Button>
              <div className={classes.buttonSpace} />
              <Button
                className={classes.button}
                color="primary"
                onClick={this.handleSkip}
                size="large"
                variant="contained"
              >
                {strings.action.skip}
              </Button>
              <div className={classes.buttonSpace} />
              <Button
                className={classes.button}
                color="primary"
                disabled={!step.isValid}
                onClick={this.handleNext}
                size="large"
                variant="contained"
              >
                {strings.action.next}
              </Button>
            </div>
          </>
        );
      }
      case 2: {
        const showCardNumberError = step.touched.cardNumber && step.errors.cardNumber;

        return (
          <>
            <TextField
              className={classes.textField}
              error={showCardNumberError ? true : false}
              helperText={showCardNumberError ? strings.cardNumber.error : ''}
              label={strings.cardNumber.label}
              name="cardNumber"
              onChange={(event) => this.handleChange(2, 'cardNumber', event.target.value)}
              type="text"
              value={step.values.cardNumber}
              variant="outlined"
            />
            <div className={classes.buttonContainer}>
              <Button className={classes.button} onClick={this.handleBack} size="large">
                {strings.action.back}
              </Button>
              <div className={classes.buttonSpace} />
              <Button
                className={classes.button}
                color="primary"
                disabled={!step.isValid}
                onClick={this.handleNext}
                size="large"
                variant="contained"
              >
                {strings.action.next}
              </Button>
            </div>
          </>
        );
      }
      case 3: {
        return (
          <>
            <Typography variant="body1">
              {strings.signUpConfirmationPartOne}{' '}
              <a href="/terms-of-use" target="_blank">
                {strings.signUpConfirmationPartTwo}
              </a>
              .
            </Typography>
            <div className={classes.buttonContainer}>
              <div className={classes.wrapper}>
                <Button className={classes.button} onClick={this.handleBack} size="large">
                  {strings.action.back}
                </Button>
              </div>
              <div className={classes.buttonSpace} />
              <div className={classes.wrapper}>
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={loading}
                  onClick={this.handleSignUp}
                  size="large"
                  // type="submit"
                  variant="contained"
                >
                  {strings.action.finish}
                </Button>
                {loading && <CircularProgress className={classes.progress} />}
              </div>
            </div>
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  sendMail = (email, key, firstName, lastName) => {
    const { strings } = this.props;

    const url = `${process.env.REACT_APP_SITE_HOSTNAME}/verify?email=${email}&key=${key}&firstName=${firstName}&lastName=${lastName}`;

    fetch('https://api.sendinblue.com/v3/smtp/email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': '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'
      },
      body: JSON.stringify({
        templateId: 1,
        to: [{ email }],
        params: {
          url: url,
          email,
          firstName,
          lastName
        }
      })
    })
      .then((response) => {
        if (response.ok) {
          this.handleOpen();
        } else {
          this.setState({
            submitError: strings.errors.unknownError
          });
        }
      })
      .catch((error) => {
        this.setState({
          submitError: strings.errors.unknownError
        });
      });
  };

  render() {
    const { classes, strings } = this.props;
    const { submitError, activeStep, open } = this.state;

    return (
      <>
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid className={classes.quoteWrapper} item lg={6}>
              <Quote />
            </Grid>
            <Grid className={classes.content} item lg={6} xs={12}>
              <div className={classes.content}>
                <div className={classes.contentHeader} />
                <div className={classes.contentBody}>
                  <form className={classes.form}>
                    <Typography className={classes.title} variant="h2">
                      {strings.title}
                    </Typography>
                    <Typography className={classes.subtitle} variant="body1">
                      {strings.subtitle}
                    </Typography>
                    <Stepper
                      activeStep={activeStep}
                      classes={{
                        root: classes.stepper
                      }}
                      orientation="vertical"
                    >
                      {this.getSteps().map((step) => (
                        <Step key={step.index}>
                          <StepLabel>
                            <Typography className={classes.stepperLabel} variant="body1">
                              {step.label}
                            </Typography>
                          </StepLabel>
                          <StepContent>{this.getStepContent(step.index)}</StepContent>
                        </Step>
                      ))}
                    </Stepper>
                    {submitError && (
                      <Typography className={classes.submitError} variant="body2">
                        {submitError}
                      </Typography>
                    )}
                    <Divider className={classes.divider} variant="fullWidth" />
                    <Link component="button" href="/sign-in" variant="body1">
                      {strings.signIn}
                    </Link>
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          autoHideDuration={6000}
          className={classes.snackbar}
          onClose={this.handleClose}
          open={open}
        >
          <SnackbarContent
            action={[
              <IconButton className={classes.snackbarClose} color="inherit" key="close" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            ]}
            className={classes.snackbarContent}
            message={
              <span className={classes.snackbarMessage}>
                <CheckCircleIcon />
                {strings.success}
              </span>
            }
          />
        </Snackbar>
      </>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  signUp: PropTypes.func.isRequired,
  strings: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    loading: state.authentication.loading
  };
};

const mapDispatchToProps = {
  signUp
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
  translate('SignUp')
)(SignUp);
