import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import _ from 'lodash';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import randomColor from 'randomcolor';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Material icons
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Custom Components
import ZonesMap from './components/ZonesMap';
import ZonesTable from './components/ZonesTable';
import ZonesToolbar from './components/ZonesToolbar';
import AddZoneDialog from './components/AddZoneDialog';
import ManageZoneNoteDialog from './components/ManageZoneNoteDialog';

// Shared services
import { fetchOrganization, updateOrganization, updateOrganizationStatus } from 'store/organization/actionsCreator';

// Common
import * as roles from 'common/roles';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './style';

// Form validation schema
import schema from './schema';

class OrganizationDetails extends Component {
  signal = true;

  state = {
    values: {
      name: '',
      isPublic: false,
      bookingMaxDurationInMinutes: 30,
      simultaneousBookingMaxCount: 1,
      rentMaxDurationInMinutes: 480,
      simultaneousRentMaxCount: 1,
      accessLevel: 3,
      domains: [],
      isEnable: false
    },
    touched: {
      name: false,
      isPublic: false
    },
    errors: {
      name: null,
      isPublic: null
    },
    open: false,
    isValid: false,
    submitError: null,
    openAddZoneDialog: false,
    openManageZoneNoteDialog: false,
    zoneManageZoneNoteDialog: null,
    selectedZones: [],
    zoneColors: [],
    domainInputValue: ''
  };

  componentDidMount() {
    this.signal = true;
    this.handleFetch();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.handleFetch();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors.name = null;
    newState.errors.isPublic = null;
    newState.isValid = errors ? false : true;

    if (errors) {
      Object.keys(errors).forEach(function (key, index) {
        var field = key.split('.');

        if (Array.isArray(field) && field.length === 2) {
          newState.errors[field[0]][field[1]] = errors[key];
        } else {
          newState.errors[field] = errors[key];
        }
      });
    }

    this.setState(newState);
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    if (Array.isArray(field) && field.length === 2) {
      if (newState.touched[field[0]]) newState.touched[field[0]][field[1]] = true;
      if (newState.values[field[0]]) newState.values[field[0]][field[1]] = value;
    } else {
      newState.touched[field] = true;
      newState.values[field] = value;
    }

    this.setState(newState, this.validateForm);
  };

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleOpenAddZoneDialog = () => {
    this.setState({ openAddZoneDialog: true });
  };

  handleAddDomain = () => {
    let { domainInputValue } = this.state;
    let { domains } = this.state.values;

    if (!domains.includes(domainInputValue)) domains.push(domainInputValue);

    this.handleChange('domains', domains);
    this.setState({ ...this.state, domainInputValue: '' });
  };

  handleDeleteDomain = (domain) => {
    let { domains } = this.state.values;
    domains = _.filter(domains, (d) => d !== domain);

    this.handleChange('domains', domains);
  };

  handleCloseAddZoneDialog = () => {
    this.setState({ openAddZoneDialog: false });
  };

  handleOpenManageZoneNoteDialog = (zone) => {
    this.setState({ openManageZoneNoteDialog: true, zoneManageZoneNoteDialog: zone });
  };

  handleCloseManageZoneNoteDialog = () => {
    this.setState({ openManageZoneNoteDialog: false, zoneManageZoneNoteDialog: null });
  };

  handleFetch = async () => {
    const { strings } = this.props;

    // fetch the organization by id
    await this.props.fetchOrganization(this.props.match.params.id, (error) => {
      if (error) {
        this.setState({
          submitError: strings.errors.fetchError
        });
      } else {
        const { organization } = this.props;

        let values = { ...this.state.values };
        values.name = organization.name ? organization.name : '';
        values.isPublic = organization.isPublic;
        values.bookingMaxDurationInMinutes = organization.bookingMaxDurationInMinutes
          ? organization.bookingMaxDurationInMinutes
          : 30;
        values.simultaneousBookingMaxCount = organization.simultaneousBookingMaxCount
          ? organization.simultaneousBookingMaxCount
          : 1;
        values.rentMaxDurationInMinutes = organization.rentMaxDurationInMinutes
          ? organization.rentMaxDurationInMinutes
          : 480;
        values.simultaneousRentMaxCount = organization.simultaneousRentMaxCount
          ? organization.simultaneousRentMaxCount
          : 1;
        values.accessLevel = organization.accessLevel ? organization.accessLevel : 3;
        values.domains = organization.domains ? organization.domains : [];
        values.isEnable = organization.isEnable;
        this.setState({
          values
        });
        let zoneColors = [];
        for (let i = 0; i < organization.zones.length; i++) {
          zoneColors.push(
            randomColor({
              luminosity: 'bright',
              format: 'rgb',
              seed: organization.zones[i].name
            })
          );
        }
        this.setState({
          zoneColors
        });
      }
    });
  };

  handleSave = async () => {
    const { strings } = this.props;
    const { values } = this.state;

    await this.props.updateOrganization(
      this.props.match.params.id,
      { ...this.props.organization, ...values },
      (error) => {
        if (error) {
          this.setState({
            submitError: strings.errors.unknownError
          });
        } else {
          const { organization } = this.props;
          let values = { ...this.state.values };
          values.name = organization.name ? organization.name : '';
          values.isPublic = organization.isPublic;
          values.bookingMaxDurationInMinutes = organization.bookingMaxDurationInMinutes
            ? organization.bookingMaxDurationInMinutes
            : 30;
          values.simultaneousBookingMaxCount = organization.simultaneousBookingMaxCount
            ? organization.simultaneousBookingMaxCount
            : 1;
          values.rentMaxDurationInMinutes = organization.rentMaxDurationInMinutes
            ? organization.rentMaxDurationInMinutes
            : 480;
          values.simultaneousRentMaxCount = organization.simultaneousRentMaxCount
            ? organization.simultaneousRentMaxCount
            : 1;
          values.accessLevel = organization.accessLevel ? organization.accessLevel : 3;
          values.domains = organization.domains ? organization.domains : [];
          this.setState({
            values
          });
          let zoneColors = [];
          for (let i = 0; i < organization.zones.length; i++) {
            zoneColors.push(
              randomColor({
                luminosity: 'bright',
                format: 'rgb',
                seed: organization.zones[i].name
              })
            );
          }
          this.setState({
            zoneColors,
            isValid: false
          });
          this.handleOpen();
        }
      }
    );
  };

  handleSelect = (selectedZones) => {
    this.setState({ selectedZones });
  };

  handleStatus = async () => {
    const { organization, strings } = this.props;
    await this.props.updateOrganizationStatus(organization._id, (error) => {
      if (error) {
        this.setState({
          submitError: strings.errors.unknownError
        });
      } else {
        let values = { ...this.state.values };
        values.isEnable = !values.isEnable;
        this.setState({
          values
        });
        this.handleOpen();
      }
    });
  };

  renderZones() {
    const { classes, error, isLoading, strings, organization, bikes } = this.props;

    const { zoneColors } = this.state;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (organization.zones && organization.zones.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    return (
      <ZonesTable
        bikes={bikes}
        onSelect={this.handleSelect}
        openManageZoneNoteDialog={(zone) => this.handleOpenManageZoneNoteDialog(zone)}
        zoneColors={zoneColors}
        zones={organization.zones}
      />
    );
  }

  render() {
    const { classes, isLoading, strings, role, organization } = this.props;

    const { values, touched, errors, isValid, submitError, selectedZones, zoneColors, domainInputValue } = this.state;

    const accessLevels = [
      { value: 1, name: strings.accessLevels.basic },
      { value: 2, name: strings.accessLevels.complete },
      { value: 3, name: strings.accessLevels.creditcard }
    ];

    const showNameError = touched.name && errors.name;

    return (
      <DashboardLayout title={strings.title}>
        {role === roles.Admin && (
          <div className={classes.header}>
            <IconButton className={classes.back} onClick={this.handleBack} title={strings.common.back}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.root}>
          <Portlet>
            <PortletHeader>
              <PortletLabel
                subtitle={role === roles.Admin && strings.portletSubtitle ? strings.portletSubtitle : ''}
                title={strings.portletTitle}
              />
              {role === roles.Admin && (
                <Button
                  className={values.isEnable ? classes.deleteButton : ''}
                  onClick={() => this.handleStatus()}
                  size="small"
                  variant="contained"
                >
                  {values.isEnable ? 'Désactiver' : 'Activer'}
                </Button>
              )}
            </PortletHeader>
            <PortletContent>
              {isLoading && <LinearProgress className={classes.portletLoader} />}
              <form autoComplete="off" noValidate>
                <Grid container spacing={24}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      disabled={role !== roles.Admin}
                      error={showNameError ? true : false}
                      helperText={showNameError ? strings.name.error : ''}
                      label={strings.name.label}
                      margin="normal"
                      name="name"
                      onChange={(event) => this.handleChange('name', event.target.value)}
                      required
                      type="text"
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h5">{strings.bookings}</Typography>
                    <TextField
                      className={classes.textField}
                      disabled={role !== roles.Admin}
                      label={strings.bookingMaxDurationInMinutes.label}
                      margin="normal"
                      name="bookingMaxDurationInMinutes"
                      onChange={(event) => this.handleChange('bookingMaxDurationInMinutes', event.target.value)}
                      type="number"
                      value={values.bookingMaxDurationInMinutes}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      disabled={role !== roles.Admin}
                      label={strings.simultaneousBookingMaxCount.label}
                      margin="normal"
                      name="simultaneousBookingMaxCount"
                      onChange={(event) => this.handleChange('simultaneousBookingMaxCount', event.target.value)}
                      type="number"
                      value={values.simultaneousBookingMaxCount}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h5">{strings.rentings}</Typography>
                    <TextField
                      className={classes.textField}
                      disabled={role !== roles.Admin}
                      label={strings.rentMaxDurationInMinutes.label}
                      margin="normal"
                      name="rentMaxDurationInMinutes"
                      onChange={(event) => this.handleChange('rentMaxDurationInMinutes', event.target.value)}
                      type="number"
                      value={values.rentMaxDurationInMinutes}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      disabled={role !== roles.Admin}
                      label={strings.simultaneousRentMaxCount.label}
                      margin="normal"
                      name="simultaneousRentMaxCount"
                      onChange={(event) => this.handleChange('simultaneousRentMaxCount', event.target.value)}
                      type="number"
                      value={values.simultaneousRentMaxCount}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h5">{strings.access}</Typography>
                    <FormControlLabel
                      className={classes.switchField}
                      control={
                        <Switch
                          checked={values.isPublic}
                          color="primary"
                          disabled={role !== roles.Admin}
                          name="isPublic"
                          onChange={() => {
                            const newValue = !values.isPublic;
                            if (newValue) {
                              this.handleChange('accessLevel', 3);
                            }
                            this.handleChange('isPublic', newValue);
                          }}
                          value={values.isPublic}
                        />
                      }
                      label={strings.isPublic.label}
                      labelPlacement="start"
                    />
                    <TextField
                      className={classes.textField}
                      disabled={values.isPublic || role !== roles.Admin}
                      inputProps={{
                        name: 'accessLevel',
                        id: 'accessLevel'
                      }}
                      label={strings.accessLevel.label}
                      margin="normal"
                      onChange={(event) => this.handleChange('accessLevel', event.target.value)}
                      select
                      value={values.accessLevel}
                      variant="outlined"
                    >
                      {accessLevels.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h5">{strings.domainAccess}</Typography>
                    <Typography className={classes.description}>{strings.accessDomains.description}</Typography>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={domainInputValue.length === 0}
                              edge="end"
                              onClick={this.handleAddDomain}
                            >
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      className={classes.textField}
                      disabled={role !== roles.Admin}
                      inputProps={{
                        name: 'accessDomains',
                        id: 'accessDomains'
                      }}
                      label={strings.accessDomains.label}
                      margin="normal"
                      onChange={(event) => this.setState({ ...this.state, domainInputValue: event.target.value })}
                      value={domainInputValue}
                      variant="outlined"
                    />
                    <div>
                      {_.map(values.domains, (domain, i) => (
                        <Chip
                          className={classes.chip}
                          key={'domain' + i}
                          label={domain}
                          onDelete={() => this.handleDeleteDomain(domain)}
                        />
                      ))}
                    </div>
                  </Grid>
                </Grid>
                {submitError && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitError}
                  </Typography>
                )}
              </form>
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
              <Button color="primary" disabled={!isValid} onClick={this.handleSave} variant="contained">
                {strings.save}
              </Button>
            </PortletFooter>
          </Portlet>
        </div>
        <div className={classes.root}>
          <Portlet className={classes.mapWrapper}>
            <PortletHeader>
              <PortletLabel
                subtitle={role === roles.Admin && strings.portletSubtitle ? strings.portletSubtitle : ''}
                title={strings.zonesTitle}
              />
            </PortletHeader>
            <PortletContent noPadding>
              <ZonesMap isDrawing={false} zoneColors={zoneColors} zones={organization.zones} />
            </PortletContent>
          </Portlet>
          <ZonesToolbar
            onFilterChange={this.handleFilterChange}
            onSelect={this.handleSelect}
            openAddZoneDialog={this.handleOpenAddZoneDialog}
            selectedZones={selectedZones}
          />
          <div className={classes.content}>{this.renderZones()}</div>
        </div>
        <AddZoneDialog
          handleClose={this.handleCloseAddZoneDialog}
          open={this.state.openAddZoneDialog}
          refreshTable={this.handleFetch}
        />
        <ManageZoneNoteDialog
          handleClose={this.handleCloseManageZoneNoteDialog}
          open={this.state.openManageZoneNoteDialog}
          zone={this.state.zoneManageZoneNoteDialog}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          autoHideDuration={6000}
          className={classes.snackbar}
          onClose={this.handleClose}
          open={this.state.open}
        >
          <SnackbarContent
            action={[
              <IconButton className={classes.close} color="inherit" key="close" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            ]}
            aria-describedby="message-id"
            className={classes.snackbarContent}
            message={
              <span className={classes.message} id="message-id">
                <CheckCircleIcon />
                {strings.success}
              </span>
            }
          />
        </Snackbar>
      </DashboardLayout>
    );
  }
}

OrganizationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.organization.loading,
    organization: state.organization.organization,
    role: state.authorize.role,
    bikes: state.bike.bikes
  };
};

const mapDispatchToProps = {
  fetchOrganization,
  updateOrganization,
  updateOrganizationStatus
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('OrganizationDetails'),
  withStyles(styles)
)(OrganizationDetails);
