export const getUsers = (organizationId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/users', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const getAllUsers = () =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/users/all', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const deleteUsers = (organizationId, users) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/users', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify(users)
      });
      const json = await response.json();
      if (response.ok) {
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const deleteUsersFromCurrentOrganization = (organizationId, users) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/users/roles', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify(users)
      });
      const json = await response.json();
      if (response.ok) {
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const getProfile = () =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const json = await response.json();
        reject(json);
      }
    } catch (error) {
      reject(error);
    }
  });

export const signIn = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password
        })
      });

      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const json = await response.json();
        reject(json);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const signUp = async (
  firstName,
  lastName,
  email,
  password,
  birthYear,
  gender,
  phoneNumber,
  phoneType,
  street,
  number,
  zip,
  city,
  stripeToken,
  accessLevel,
  keyVerify
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstname: firstName,
          name: lastName,
          email: email,
          password: password,
          birthYear: birthYear,
          gender: gender,
          phone: phoneNumber,
          phoneType: phoneType,
          street: street && number ? `${street}, ${number}` : undefined,
          postalCode: zip,
          city: city,
          stripeToken: stripeToken,
          accessLevel: accessLevel,
          keyVerify: keyVerify
        })
      });

      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const json = await response.json();
        reject(json);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const verify = async (email, key) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          key: key
        })
      });

      if (response.ok) {
        resolve();
      } else {
        const json = await response.json();
        reject(json);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const inviteUser = (organizationId, email, role, firstname, name) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/users/invite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify({
          email,
          role,
          firstname,
          name
        })
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const error = await response.json();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });

export const getUser = (organizationId, userId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/users/get/' + userId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const updateUser = (organizationId, userId, user) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/users/update/' + userId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify(user)
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const authorize = async (call, ...props) => {
  const promise = new Promise(async (resolve, reject) => {
    const auth = localStorage.getItem('x-auth');
    if (!auth) {
      reject({
        error: 'missing auth token'
      });
      return;
    }
    await call(resolve, reject, auth, ...props);
  });
  return promise;
};

export const updateUserProfile = (userId, user) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/update/' + userId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        },
        body: JSON.stringify(user)
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const getPaymentInfo = () =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/payments/userInfo', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const updatePaymentInfo = (stripeToken) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/payment/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        },
        body: JSON.stringify({ stripeToken: stripeToken })
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const deletePaymentMethod = (cardId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/payment/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        },
        body: JSON.stringify({ cardId: cardId })
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const deleteAccount = (cardId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/account/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });
