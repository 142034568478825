import { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// Material components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Shared services
import { deleteAccount } from 'store/authentication/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema

class ConfirmDeleteAccountDialog extends Component {
  componentDidMount() {}

  componentWillUnmount() {}

  handleConfirm = async () => {
    await this.props.deleteAccount();
  };

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    const { classes, fullScreen, open, strings } = this.props;

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        onClose={this.handleClose}
        open={open}
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {strings.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.subtitle}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {strings.cancel}
          </Button>
          <Button color="primary" className={classes.confirmButton} onClick={this.handleConfirm}>
            {strings.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmDeleteAccountDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  deleteAccount
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('ConfirmDeleteAccountDialog'),
  withMobileDialog(),
  withStyles(styles)
)(ConfirmDeleteAccountDialog);
