import React from 'react';

// Externals
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon
} from 'react-google-maps';

import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';

const ZonesMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyA6jQzIU1OcQ7UWfmyOnq-o000grQ03ySo&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    center={props.zones && props.zones.length > 0 ? coordinatesToLatLng(props.zones[0].polygon.coordinates)[0] : {lat: 50.4541, lng: 3.9523}}
    defaultZoom={10}
  >
    <DrawingManager
      defaultOptions={{
        drawingControl: false,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            window.google.maps.drawing.OverlayType.POLYGON,
          ],
        },
        polygonOptions: {
          fillColor: '#ffffff99',
          fillOpacity: 1,
          strokeWeight: 5,
          clickable: true,
          draggable:true,
          editable: true,
          zIndex: 1,
        },
      }}
      drawingMode={props.isDrawing ? window.google.maps.drawing.OverlayType.POLYGON : null}
      onPolygonComplete={props.onPolygonCompleted}
    />
    {props.zones &&
      props.zones.map(
        (zone, index) => 
          zone.polygon.coordinates && (
            <Polygon
              key={zone._id}
              label={zone.name}
              options={{
                strokeColor: props.zoneColors && props.zoneColors[index] ? props.zoneColors[index] : '#ffffffff',
                fillColor: props.zoneColors && props.zoneColors[index] ? props.zoneColors[index] : 'ffffff99',
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillOpacity: 0.5
              }}
              path={coordinatesToLatLng(zone.polygon.coordinates)}
            />
          )
      )}
  </GoogleMap>
));

function coordinatesToLatLng(coordinates) {
  let paths = [];

  coordinates.forEach(coordinate => 
    coordinate.forEach(point => 
      paths.push({lat : point[0], lng : point[1]})
    )
  );

  return paths;
}

export default ZonesMap;
