import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

// Material icons
import BikeIcon from '@material-ui/icons/DirectionsBikeOutlined';
import CommentIcon from '@material-ui/icons/ModeCommentOutlined';
import CreditPriceIcon from '@material-ui/icons/PaymentOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import DomainIcon from '@material-ui/icons/DomainOutlined';
import EuroIcon from '@material-ui/icons/EuroSymbolOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import ListIcon from '@material-ui/icons/ListAltOutlined';
import LockIcon from '@material-ui/icons/LockOpenOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlineRounded';
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import StyleIcon from '@material-ui/icons/StyleOutlined';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

// Common
import * as roles from 'common/roles';

// Shared services
import { setSidebar } from 'store/layout/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bikeCollapsed: props.sidebar.bikeCollapsed,
      userCollapsed: props.sidebar.userCollapsed
    };
  }

  handleClick = (e) => {
    this.setState({ [e]: !this.state[e] }, () => {
      const newState = { ...this.state };
      this.props.setSidebar(newState);
    });
  };

  render() {
    const { classes, className, currentOrganization, strings, user, role } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link className={classes.logoLink} to="/">
            <img alt="ShareABike logo" className={classes.logoImage} src="/images/logos/logo-large.png" />
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
        <div className={classes.profile}>
          <Avatar alt="Avatar" className={classes.avatar} src="/images/avatars/avatar_placeholder.png" />
          <Typography className={classes.nameText} variant="h6">
            {user && user.firstname && user.name
              ? user.firstname + ' ' + user.name
              : user.firstname && !user.name
              ? user.firstname
              : !user.firstname && !user.name
              ? user.name
              : '/'}
          </Typography>
          <Typography className={classes.bioText} variant="caption">
            {role === roles.Admin && strings.common.administrator}
            {role === roles.Operator && strings.common.operator}
            {role === roles.User && strings.common.user}
          </Typography>
        </div>
        <Divider className={classes.profileDivider} />
        <PerfectScrollbar
          className={classes.navScrollBar}
          ref={(ref) => {
            this._perfectScrollBarRef = ref;
          }}
        >
          {(role === roles.Admin || role === roles.Operator) && (
            <>
              <List component="div" disablePadding>
                <ListItem
                  activeClassName={classes.activeListItem}
                  className={classes.listItem}
                  component={NavLink}
                  to="/home"
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={strings.dashboard} />
                </ListItem>
                <ListItem
                  className={classes.listItem}
                  onClick={() => {
                    this.handleClick('userCollapsed');
                  }}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={strings.users} />
                  {this.state.userCollapsed ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  component="li"
                  in={this.state.userCollapsed}
                  onEntered={() => this._perfectScrollBarRef.updateScroll()}
                  onExited={() => this._perfectScrollBarRef.updateScroll()}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    <ListItem
                      activeClassName={classes.activeListItemNested}
                      className={classes.listItemNested}
                      component={NavLink}
                      to="/users"
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.listItemText }} primary={strings.userList} />
                    </ListItem>
                    <ListItem
                      activeClassName={classes.activeListItemNested}
                      className={classes.listItemNested}
                      component={NavLink}
                      to="/invitations"
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <PersonAddIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.listItemText }} primary={strings.invitationList} />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  className={classes.listItem}
                  onClick={() => {
                    this.handleClick('bikeCollapsed');
                  }}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <BikeIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={strings.bikes} />
                  {this.state.bikeCollapsed ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  component="li"
                  in={this.state.bikeCollapsed}
                  onEntered={() => this._perfectScrollBarRef.updateScroll()}
                  onExited={() => this._perfectScrollBarRef.updateScroll()}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    <ListItem
                      activeClassName={classes.activeListItemNested}
                      className={classes.listItemNested}
                      component={NavLink}
                      to="/bikes"
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.listItemText }} primary={strings.bikeList} />
                    </ListItem>
                    <ListItem
                      activeClassName={classes.activeListItemNested}
                      className={classes.listItemNested}
                      component={NavLink}
                      to="/bike-types"
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <StyleIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.listItemText }} primary={strings.bikeTypes} />
                    </ListItem>
                    <ListItem
                      activeClassName={classes.activeListItemNested}
                      className={classes.listItemNested}
                      component={NavLink}
                      to="/pricing-plans"
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <EuroIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.listItemText }} primary={strings.pricingPlans} />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  activeClassName={classes.activeListItem}
                  className={classes.listItem}
                  component={NavLink}
                  to="/rents"
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={strings.rentals} />
                </ListItem>
                <ListItem
                  activeClassName={classes.activeListItem}
                  className={classes.listItem}
                  component={NavLink}
                  to="/comments"
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <CommentIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={strings.comments} />
                </ListItem>
                {role === roles.Operator && currentOrganization && (
                  <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={'/organization/' + currentOrganization._id}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.listItemText }} primary={strings.organization} />
                  </ListItem>
                )}
              </List>
              <Divider className={classes.listDivider} />
            </>
          )}
          {role === roles.Admin && (
            <React.Fragment>
              <List
                component="div"
                disablePadding
                subheader={<ListSubheader className={classes.listSubheader}>{strings.administration}</ListSubheader>}
              >
                <ListItem
                  activeClassName={classes.activeListItem}
                  className={classes.listItem}
                  component={NavLink}
                  to="/all-users"
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={strings.allUserList} />
                </ListItem>
                <ListItem
                  activeClassName={classes.activeListItem}
                  className={classes.listItem}
                  component={NavLink}
                  to="/organizations"
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <DomainIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={strings.organizations} />
                </ListItem>
                <ListItem
                  activeClassName={classes.activeListItem}
                  className={classes.listItem}
                  component={NavLink}
                  to="/prices"
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <CreditPriceIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={strings.prices} />
                </ListItem>
                <ListItem
                  activeClassName={classes.activeListItem}
                  className={classes.listItem}
                  component={NavLink}
                  to="/contact-form"
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <ContactSupportIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={strings.contactForm} />
                </ListItem>
              </List>
              <Divider className={classes.listDivider} />{' '}
            </React.Fragment>
          )}
          <List
            component="div"
            disablePadding
            subheader={<ListSubheader className={classes.listSubheader}>Support</ListSubheader>}
          >
            <ListItem className={classes.listItem} component="a" href="/help" target="_blank">
              <ListItemIcon className={classes.listItemIcon}>
                <MessageIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary={strings.support} />
            </ListItem>
            <ListItem className={classes.listItem} component="a" href="/about" target="_blank">
              <ListItemIcon className={classes.listItemIcon}>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary={strings.about} />
            </ListItem>
          </List>
        </PerfectScrollbar>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organization.currentOrganization,
    role: state.authorize.role,
    sidebar: state.layout.sidebar,
    user: state.authentication.user
  };
};

const mapDispatchToProps = {
  setSidebar
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), translate('Sidebar'))(Sidebar);
