export default {
  'name.fr': {
    presence: { allowEmpty: false }
  },
  'name.en': {
    presence: { allowEmpty: false }
  },
  'name.nl': {
    presence: { allowEmpty: false }
  },
  pricingPlan: {
    presence: { allowEmpty: false }
  }
};
