import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';

// Shared components
import SearchInput from 'components/SearchInput';

// Shared services
import { deleteBikes } from 'store/bike/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class BikesToolbar extends Component {
  handleDeleteBikes = async () => {
    const { onSelect, selectedBikes } = this.props;

    const { error } = await this.props.deleteBikes(selectedBikes);
    if (error) {
      this.setState({
        submitError: error
      });

      return;
    } 
      
    onSelect([]);
  };

  render() {
    const {
      classes,
      className,
      openAddBikeDialog,
      selectedBikes,
      strings
    } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            onChange={this.props.onFilterChange}
            placeholder={strings.search}
          />
          <span className={classes.spacer} />
          {selectedBikes.length > 0 && (
            <Button
              className={classes.deleteButton}
              color="default"
              onClick={this.handleDeleteBikes}
              size="small"
              variant="contained"
            >
              <DeleteIcon className={classes.deleteIcon} /> {strings.delete}
            </Button>
          )}
          <Button
            className={classes.addButton}
            color="primary"
            onClick={openAddBikeDialog}
            size="small"
            variant="contained"
          >
            {strings.addBike}
          </Button>
        </div>
      </div>
    );
  }
}

BikesToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func,
  openAddBikeDialog: PropTypes.func.isRequired,
  selectedBikes: PropTypes.array,
  strings: PropTypes.object.isRequired
};

BikesToolbar.defaultProps = {
  onFilterChange: () => {},
  onSelect: () => {},
  selectedBikes: []
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  deleteBikes
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('BikesToolbar'),
  withStyles(styles)
)(BikesToolbar);
