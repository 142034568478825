export const getOrganizations = () =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/organizations/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else if (response.status === 401) {
        const json = await response.json();
        reject(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const deleteOrganizations = (organizationId, organizations) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/organizations', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify(organizations)
      });
      const json = await response.json();
      if (response.ok) {
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const addOrganization = (organization) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/organizations/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        },
        body: JSON.stringify(organization)
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const getOrganization = (organizationId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/api/v1/admin/organizations/get/' + organizationId,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth': auth
          }
        }
      );
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const updateOrganization = (organizationId, organization) =>
  authorize(async (resolve, reject, auth) => {
    try {
      console.log('Before orga update: ', organization);

      const response = await fetch(
        process.env.REACT_APP_API_URL + '/api/v1/admin/organizations/update/' + organizationId,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-auth': auth
          },
          body: JSON.stringify(organization)
        }
      );
      if (response.ok) {
        const json = await response.json();
        console.log('Received after orga updated: ', json);

        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const updateStatus = (organizationId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/api/v1/admin/organizations/update/status/' + organizationId,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth': auth
          }
        }
      );
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const authorize = async (call, ...props) => {
  const promise = new Promise(async (resolve, reject) => {
    const auth = localStorage.getItem('x-auth');
    if (!auth) {
      reject({
        error: 'missing auth token'
      });
      return;
    }
    await call(resolve, reject, auth, ...props);
  });
  return promise;
};
