export default theme => ({
  root: {
    paddingBottom: theme.spacing.unit * 8
  },
  deleteButton: {
    color: theme.palette.danger.main
  },
  table :{
  },
  tableRow: {
    height: '64px'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
});
