export const getBikeTypes = (organizationId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/bike-types/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId,
          language: localStorage.getItem('x-localization') ? localStorage.getItem('x-localization') : 'fr'
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const deleteBikeTypes = (organizationId, bikeTypes) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/bike-types', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify(bikeTypes)
      });
      const json = await response.json();
      if (response.ok) {
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const addBikeType = (organizationId, bikeType) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/bike-types/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId,
          language: localStorage.getItem('x-localization') ? localStorage.getItem('x-localization') : 'fr'
        },
        body: JSON.stringify(bikeType)
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const getBikeType = (organizationId, bikeTypeId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/bike-types/get/' + bikeTypeId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        }
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const updateBike = (organizationId, bikeTypeId, bikeType) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/bike-types/update/' + bikeTypeId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId,
          language: localStorage.getItem('x-localization') ? localStorage.getItem('x-localization') : 'fr'
        },
        body: JSON.stringify(bikeType)
      });
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const authorize = async (call, ...props) => {
  const promise = new Promise(async (resolve, reject) => {
    const auth = localStorage.getItem('x-auth');
    if (!auth) {
      reject({
        error: 'missing auth token'
      });
      return;
    }
    await call(resolve, reject, auth, ...props);
  });
  return promise;
};
