const actions = {
  START_FETCH_ORGANIZATIONS: 'organization/START_FETCH_ORGANIZATIONS',
  END_FETCH_ORGANIZATIONS: 'organization/END_FETCH_ORGANIZATIONS',
  START_DELETING_ORGANIZATIONS: 'organization/START_DELETING_ORGANIZATIONS',
  END_DELETING_ORGANIZATIONS: 'organization/END_DELETING_ORGANIZATIONS',
  START_ADD_ORGANIZATION: 'bike/START_ADD_ORGANIZATION',
  END_ADD_ORGANIZATION: 'bike/END_ADD_ORGANIZATION',
  START_FETCH_ORGANIZATION: 'bike/START_FETCH_ORGANIZATION',
  END_FETCH_ORGANIZATION: 'bike/END_FETCH_ORGANIZATION',
  START_UPDATE_ORGANIZATION: 'bike/START_UPDATE_ORGANIZATION',
  END_UPDATE_ORGANIZATION: 'bike/END_UPDATE_ORGANIZATION',
  START_UPDATESTATUS_ORGANIZATION: 'bike/START_UPDATESTATUS_ORGANIZATION',
  END_UPDATESTATUS_ORGANIZATION: 'bike/END_UPDATESTATUS_ORGANIZATION',
  SET_ORGANIZATIONS: 'organization/SET_ORGANIZATIONS',
  SET_ORGANIZATION: 'organization/SET_ORGANIZATION',
  SET_CURRENT_ORGANIZATION: 'organization/SET_CURRENT_ORGANIZATION'
};

export default actions;
