import React from 'react';

// Externals
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline
} from 'react-google-maps';

const BikeLocationsMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyA6jQzIU1OcQ7UWfmyOnq-o000grQ03ySo&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    center={
      props.markers[0] &&
      props.markers[0].location && {
        lat: props.markers[0].location.coordinates[0],
        lng: props.markers[0].location.coordinates[1]
      }
    }
    defaultZoom={13}
  >
    {props.markers &&
      props.markers.map(
        (marker, index) =>
          marker.location && (
            <Marker
              key={marker._id}
              label={(index + 1).toString(10)}
              position={{
                lat: marker.location.coordinates[0],
                lng: marker.location.coordinates[1]
              }}
              styles={{zIndex : index}}
            />
          )
      )}
    {props.polylines && (
      <Polyline
        geodesic
        options={{
          strokeColor: '#ff2527',
          strokeOpacity: 0.75,
          strokeWeight: 2
        }}
        path={props.polylines}
      />
    )}
  </GoogleMap>
));

export default BikeLocationsMap;
