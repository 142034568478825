export default {
  birthYear: {
    presence: { allowEmpty: false }
  },
  gender: {
    presence: { allowEmpty: false }
  },
  phoneNumber: {
    presence: { allowEmpty: false }
  },
  phoneType: {
    presence: { allowEmpty: false }
  },
  street: {
    presence: { allowEmpty: false }
  },
  number: {
    presence: { allowEmpty: false }
  },
  zip: {
    presence: { allowEmpty: false },
    format: '[0-9]*'
  },
  city: {
    presence: { allowEmpty: false }
  }
};
