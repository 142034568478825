export default {
  name: {
    presence: { allowEmpty: false }
  },
  bookingCost: {
    presence: { allowEmpty: false }
  },
  firstHalfHour: {
    presence: { allowEmpty: false }
  },
  secondHalfHour: {
    presence: { allowEmpty: false }
  },
  thirdHalfHour: {
    presence: { allowEmpty: false }
  },
  nextHalfHours: {
    presence: { allowEmpty: false }
  },
};
