import React from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// Material components
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { User, Operator } from 'common/roles';

import { inviteUser } from 'store/user/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './style';

// Form validation schema
import schema from './schema';

class AddUserDialog extends React.Component {
  state = {
    values: {
      firstname: '',
      name: '',
      email: '',
      phone: '',
      role: User
    },
    touched: {
      name: false,
      type: false
    },
    errors: {
      name: null,
      type: null
    },
    isValid: false,
    submitError: null
  };

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleChange = (field, event) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = event.target.value;

    this.setState(newState, this.validateForm);
  };

  handleAdd = async () => {
    const { firstname, name, email, role } = this.state.values;
    await this.props.inviteUser(email, role, firstname, name, error => {
      if (error) {
        this.setState({
          submitError: error
        });
      } else {
        let values = { ...this.state.values };
        values.firstname = '';
        values.name = '';
        values.email = '';
        values.phone =  '';
        values.role = User;
        let touched = { ...this.state.touched };
        touched.name = false;
        touched.type = false;
        let errors = { ...this.state.errors };
        errors.name = null;
        errors.type = null;
        this.setState({
          values,
          touched,
          errors,
          isValid: false,
          submitError: null
        });
        this.props.handleClose();
      }
    });
  };

  handleClose = () => {
    let values = { ...this.state.values };
    values.firstname = '';
    values.name = '';
    values.email = '';
    values.phone =  '';
    values.role = User;
    let touched = { ...this.state.touched };
    touched.name = false;
    touched.type = false;
    let errors = { ...this.state.errors };
    errors.name = null;
    errors.type = null;
    this.setState({
      values,
      touched,
      errors,
      isValid: false,
      submitError: null
    });
    this.props.handleClose();
  };

  render() {
    const { classes, fullScreen, loading, open, handleClose, strings } = this.props;

    const { values, touched, errors, isValid, submitError } = this.state;

    const showFirstnameError = touched.firstname && errors.firstname;
    const showNameError = touched.name && errors.name;
    const showEmailError = touched.email && errors.email;

    return (
      <Dialog aria-labelledby="responsive-dialog-title" fullScreen={fullScreen} onClose={handleClose} open={open}>
        <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">
          {strings.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.subtitle}</DialogContentText>
          {loading && <LinearProgress className={classes.dialogLoader} />}
          <form autoComplete="off" className={classes.dialogForm}>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                error={showFirstnameError ? true : false}
                helperText={showFirstnameError ? strings.firstname.error : ''}
                label={strings.firstname.label}
                margin="dense"
                name="firstname"
                onChange={e => this.handleChange('firstname', e)}
                required
                value={values.firstname}
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                error={showNameError ? true : false}
                helperText={showNameError ? strings.name.error : ''}
                label={strings.name.label}
                margin="dense"
                name="name"
                onChange={e => this.handleChange('name', e)}
                required
                value={values.name}
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                error={showEmailError ? true : false}
                helperText={showEmailError ? strings.email.error : ''}
                label={strings.email.label}
                margin="dense"
                name="email"
                onChange={e => this.handleChange('email', e)}
                required
                value={values.email}
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
              <TextField
                SelectProps={{ native: true }}
                className={classes.textField}
                helperText={strings.roleHelperText}
                label={strings.roleLabel}
                margin="dense"
                onChange={e => this.handleChange('role', e)}
                required
                select
                value={values.role}
                variant="outlined"
              >
                <option key={User} value={User}>
                  {strings.roles[User]}
                </option>
                <option key={Operator} value={Operator}>
                  {strings.roles[Operator]}
                </option>
              </TextField>
            </div>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {strings.errors[submitError.error] || strings.errors.UnknownError}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {strings.cancel}
          </Button>
          <Button color="primary" disabled={!isValid} onClick={this.handleAdd}>
            {strings.add}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddUserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    loading: state.user.inviteLoading
  };
};

const mapDispatchToProps = {
  inviteUser
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('AddUserDialog'),
  withMobileDialog(),
  withStyles(styles)
)(AddUserDialog);
