import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/EditOutlined';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletToolbar from 'components/PortletToolbar';
import PortletContent from 'components/PortletContent';

// Shared services
import { deleteBikeTypes } from 'store/bikeType/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class BikeTypesTable extends Component {
  state = {
    selectedBikeTypes: []
  };

  handleDeleteBikeTypes = async () => {
    const { selectedBikeTypes } = this.state;
    const { error } = await this.props.deleteBikeTypes(selectedBikeTypes);
    if (error) {
      this.setState({
        submitError: error
      });
    } 
      
    this.setState({ selectedBikeTypes: [] });
  };

  handleSelectAll = event => {
    const { bikeTypes } = this.props;

    let selectedBikeTypes;

    if (event.target.checked) {
      selectedBikeTypes = bikeTypes.map(pricingPlan => pricingPlan._id);
    } else {
      selectedBikeTypes = [];
    }

    this.setState({ selectedBikeTypes });
  };

  handleSelectOne = (event, id) => {
    const { selectedBikeTypes } = this.state;

    const selectedIndex = selectedBikeTypes.indexOf(id);
    let newSelectedBikeTypes = [];

    if (selectedIndex === -1) {
      newSelectedBikeTypes = newSelectedBikeTypes.concat(selectedBikeTypes, id);
    } else if (selectedIndex === 0) {
      newSelectedBikeTypes = newSelectedBikeTypes.concat(
        selectedBikeTypes.slice(1)
      );
    } else if (selectedIndex === selectedBikeTypes.length - 1) {
      newSelectedBikeTypes = newSelectedBikeTypes.concat(
        selectedBikeTypes.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedBikeTypes = newSelectedBikeTypes.concat(
        selectedBikeTypes.slice(0, selectedIndex),
        selectedBikeTypes.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedBikeTypes: newSelectedBikeTypes });
  };

  render() {
    const { classes, className, bikeTypes, strings } = this.props;
    const { selectedBikeTypes } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel
            subtitle={`${bikeTypes.length} ${strings.common.total}`}
            title={strings.title}
          />
          <PortletToolbar>
            {selectedBikeTypes.length > 0 && (
              <Button
                className={classes.deleteButton}
                color="default"
                onClick={this.handleDeleteBikeTypes}
                size="small"
                variant="outlined"
              >
                <DeleteIcon className={classes.deleteIcon} /> {strings.delete}
              </Button>
            )}
          </PortletToolbar>
        </PortletHeader>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedBikeTypes.length === bikeTypes.length}
                      color="primary"
                      indeterminate={
                        selectedBikeTypes.length > 0 &&
                        selectedBikeTypes.length < bikeTypes.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="center">{strings.actions}</TableCell>
                  <TableCell align="left" padding="none">{strings.name}</TableCell>
                  <TableCell align="left">{strings.description}</TableCell>
                  <TableCell align="left">{strings.pricingPlan}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bikeTypes.map(bikeType => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={bikeType._id}
                    selected={selectedBikeTypes.indexOf(bikeType._id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <div className={classes.tableCellInner}>
                        <Checkbox
                          checked={
                            selectedBikeTypes.indexOf(bikeType._id) !== -1
                          }
                          color="primary"
                          onChange={event =>
                            this.handleSelectOne(event, bikeType._id)
                          }
                          value="true"
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <Link to={'/bike-type/' + bikeType._id}>
                        <IconButton title={strings.biketypedetails}>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell} padding="none">
                      <Typography className={classes.nameText} variant="body1">
                        {bikeType.name ? bikeType.name : '/'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {bikeType.description ? bikeType.description : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {bikeType.pricingPlan && bikeType.pricingPlan.name
                        ? bikeType.pricingPlan.name
                        : '/'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    );
  }
}

BikeTypesTable.propTypes = {
  bikeTypes: PropTypes.array.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  strings: PropTypes.object.isRequired
};

BikeTypesTable.defaultProps = {
  onSelect: () => {},
  bikeTypes: []
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.bikeType.loading
  };
};

const mapDispatchToProps = {
  deleteBikeTypes
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('BikeTypesTable'),
  withStyles(styles)
)(BikeTypesTable);
