import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';

import localizationReducer from './localization/reducer';
import authenticationReducer from './authentication/reducer';
import authorizeReducer from './authorize/reducer';
import bikeReducer from './bike/reducer';
import bikeTypeReducer from './bikeType/reducer';
import layoutReducer from './layout/reducer';
import organizationReducer from './organization/reducer';
import priceReducer from './price/reducer';
import pricingPlanReducer from './pricingPlan/reducer';
import rentReducer from './rent/reducer';
import userReducer from './user/reducer';
import commentReducer from './comment/reducer';
import contactFormReducer from './contactForm/reducer';

export default function configureStore(history, initialState) {
  const reducers = {
    localization: localizationReducer,
    authentication: authenticationReducer,
    authorize: authorizeReducer,
    bike: bikeReducer,
    bikeType: bikeTypeReducer,
    comment : commentReducer,
    contactForm : contactFormReducer,
    layout: layoutReducer,
    organization: organizationReducer,
    price: priceReducer,
    pricingPlan: pricingPlanReducer,
    rent: rentReducer,
    user: userReducer,
  };

  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  // eslint-disable-next-line no-undef
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  );
}
