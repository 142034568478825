import actions from './actions';

const initState = {
  loading: false,
  bikeTypes: [],
  bikeType: {}
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.START_FETCH_BIKETYPES:
      return { ...state, loading: true };
    case actions.END_FETCH_BIKETYPES:
      return { ...state, loading: false };
    case actions.START_DELETING_BIKETYPES:
      return { ...state, loading: true };
    case actions.END_DELETING_BIKETYPES:
      return { ...state, loading: false };
    case actions.START_ADD_BIKETYPE:
      return { ...state, loading: true };
    case actions.END_ADD_BIKETYPE:
      return { ...state, loading: false };
    case actions.START_FETCH_BIKETYPE:
      return { ...state, loading: true };
    case actions.END_FETCH_BIKETYPE:
      return { ...state, loading: false };
    case actions.START_UPDATE_BIKETYPE:
      return { ...state, loading: true };
    case actions.END_UPDATE_BIKETYPE:
      return { ...state, loading: false };
    case actions.SET_BIKETYPES:
      return { ...state, bikeTypes: action.value };
    case actions.SET_BIKETYPE:
      return { ...state, bikeType: action.value };
    default:
      return state;
  }
};

export default reducer;
