export const getComments = (organizationId) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/api/v1/comments/get?organization_id=' + organizationId,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth': auth
          }
        }
      );
      if (response.ok) {
        const json = await response.json();
        resolve(json);
      } else {
        const text = await response.json();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const deleteComments = (organizationId, comments) =>
  authorize(async (resolve, reject, auth) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/admin/comments', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth,
          'x-organization-id': organizationId
        },
        body: JSON.stringify(comments)
      });
      const json = await response.json();
      if (response.ok) {
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });

export const authorize = async (call, ...props) => {
  const promise = new Promise(async (resolve, reject) => {
    const auth = localStorage.getItem('x-auth');
    if (!auth) {
      reject({
        error: 'missing auth token'
      });
      return;
    }
    await call(resolve, reject, auth, ...props);
  });
  return promise;
};
