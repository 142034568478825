import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Translations
import translate from 'helpers/translate';

import {
  setLanguage
} from 'store/localization/actionsCreator';

// Component styles
import styles from './styles';

class LanguageSwitch extends Component {
  handleChange = event => {
    this.props.setLanguage(event.target.value);
  };

  render() {
    const { className, classes, language, strings } = this.props;

    const languages = [
      { value: 'en', name: strings.languages.en },
      { value: 'fr', name: strings.languages.fr },
      { value: 'nl', name: strings.languages.nl }
    ];
    
    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.header}>
          <Typography variant="h6">{strings.title}</Typography>
          <Typography className={classes.subtitle} variant="body2">
            {strings.subtitle}
          </Typography>
        </div>
        {languages.length > 0 ? (
          <form autoComplete="off" className={classes.content}>
            <FormControl className={classes.formControl}>
              <Select
                inputProps={{
                  name: 'language-switch',
                  id: 'language-switch'
                }}
                onChange={this.handleChange}
                value={
                  language
                    ? language
                    : '-1'
                }
              >
                {languages.map(option => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        ) : (
          <div className={classes.empty}>
            <div className={classes.emptyImageWrapper}>
              <img
                alt="Empty list"
                className={classes.emptyImage}
                src="/images/empty.png"
              />
            </div>
            <Typography variant="h4">{strings.nothing}</Typography>
          </div>
        )}
      </div>
    );
  }
}

LanguageSwitch.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  setLanguage: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    language: state.localization.language
  };
};

const mapDispatchToProps = {
  setLanguage
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('LanguageSwitch'),
  withStyles(styles)
)(LanguageSwitch);
