export default theme => ({
  root: {
    padding: theme.spacing.unit * 3
  },
  content: {
    marginTop: theme.spacing.unit * 2
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center'
  },
  textWrapper: {
    paddingTop: '48px',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center'
  },
  theme: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  }
});
