import React, { useEffect, useState } from 'react';

// Material
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';

// Externals
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';
import { LinearProgress, Typography } from '@material-ui/core';

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | undefined>('');
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<PaymentIntent.Status>();

  const mobile = new URLSearchParams(window.location.search).get('mobile');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setStatus(paymentIntent!.status);
      switch (paymentIntent!.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: mobile ? 'shareabike://launch' : `${window.location.origin}/checkout`
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occured.');
    }

    setIsLoading(false);
  };

  if (status === 'succeeded') {
    setTimeout(() => {
      window.location.replace('/home');
    }, 5000);
    return (
      <Portlet>
        <PortletContent>
          <Typography variant="h5" style={{ marginBottom: 8 }}>
            Votre paiement a bien été reçu !
          </Typography>
          <Typography variant="body1">
            Les crédits sont immédiatement transférés sur votre compte. Vous serez redirigé vers votre profil dans
            quelques instants.
          </Typography>
          <LinearProgress variant="indeterminate" />
        </PortletContent>
      </Portlet>
    );
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}</span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
