import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import validate from 'validate.js';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles/index';

// Material components
import Button from '@material-ui/core/Button/index';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import Divider from '@material-ui/core/Divider/index';
import Grid from '@material-ui/core/Grid/index';
import TextField from '@material-ui/core/TextField/index';
import Typography from '@material-ui/core/Typography/index';

// Shared components
import Link from 'components/Link';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

import * as contactFormService from '../../services/contactForm/index';
import { FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import Quote from 'components/Layout/Quote';

class ContactUs extends Component {
  state = {
    values: {
      name: '',
      email: '',
      message: '',
      profile: '',
      city: ''
    },
    touched: {
      name: false,
      email: false,
      message: false,
      profile: false,
      city: false
    },
    errors: {
      name: null,
      email: null,
      message: null,
      profile: null,
      city: null
    },
    isValid: false,
    submitError: null,
    messageSent: false,
    loading: false
  };

  validateForm = () => {
    const newState = { ...this.state };

    const errors = validate(newState.values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSendMessage = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    const { strings } = this.props;
    const { values } = this.state;
    const { name, email, message, profile, city } = values;

    try {
      const result = await contactFormService.addMessage({ name, email, message, profile, city });
    } catch (error) {
      this.setState({
        submitError: strings.errors.unknownError
      });
    }

    this.setState({ loading: false });
  };

  render() {
    const { classes, strings } = this.props;
    const { values, touched, errors, isValid, submitError, loading } = this.state;

    const showEmailError = touched.email && errors.email;
    const showMessageError = touched.message && errors.message;
    const showNameError = touched.name && errors.name;
    const showProfileError = touched.profile && errors.profile;
    const showCityError = touched.city && errors.city;

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteWrapper} item lg={6}>
            <Quote />
          </Grid>
          <Grid className={classes.content} item lg={6} xs={12}>
            <div className={classes.contentHeader} />
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <Typography className={classes.title} variant="h2">
                  {strings.title}
                </Typography>
                <Typography className={classes.subtitle} variant="body1">
                  {strings.subtitle}
                </Typography>
                <div className={classes.fields}>
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.textField}
                    error={showNameError ? true : false}
                    helperText={showNameError ? strings.name.error : ''}
                    label={strings.name.label}
                    name="name"
                    onChange={(event) => this.handleChange('name', event.target.value)}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.textField}
                    error={showEmailError ? true : false}
                    helperText={showEmailError ? strings.email.error : ''}
                    label={strings.email.label}
                    name="email"
                    onChange={(event) => this.handleChange('email', event.target.value)}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <FormControl className={classes.textField} variant="outlined">
                    <InputLabel id="demo-simple-select-filled-label">{strings.profile.label}</InputLabel>
                    <Select
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={(event) => this.handleChange('profile', event.target.value)}
                      value={values.profile}
                    >
                      <MenuItem disabled value="">
                        {strings.profile.label}
                      </MenuItem>
                      <MenuItem value="city">{strings.profileOptions.city}</MenuItem>
                      <MenuItem value="enterprise">{strings.profileOptions.enterprise}</MenuItem>
                      <MenuItem value="user">{strings.profileOptions.user}</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.textField}
                    error={showCityError ? true : false}
                    helperText={showCityError ? strings.city.error : ''}
                    label={strings.city.label}
                    name="city"
                    onChange={(event) => this.handleChange('city', event.target.value)}
                    type="city"
                    value={values.city}
                    variant="outlined"
                  />

                  <Divider className={classes.divider} variant="fullWidth" />

                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.textField}
                    error={showMessageError ? true : false}
                    helperText={showMessageError ? strings.message.error : ''}
                    label={strings.message.label}
                    multiline
                    name="message"
                    onChange={(event) => this.handleChange('message', event.target.value)}
                    rows={4}
                    value={values.message}
                    variant="outlined"
                  />
                </div>
                {submitError && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitError}
                  </Typography>
                )}
                <div className={classes.wrapper}>
                  <Button
                    className={classes.button}
                    color="primary"
                    disabled={!isValid || loading}
                    onClick={this.handleSendMessage}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {strings.action}
                  </Button>
                  {loading && <CircularProgress className={classes.progress} />}
                </div>
              </form>
            </div>
            <div className={classes.contentFooter}>
              <Divider className={classes.divider} variant="fullWidth" />
              <div className={classes.footerLinks}>
                <Link component="button" href="/sign-in" variant="body1">
                  {strings.signIn}
                </Link>
                <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                <Link component="button" href="/contact-us" variant="body1">
                  {strings.contactUs}
                </Link>
                <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                <Link component="button" href="/terms-of-use" target="_blank" variant="body1">
                  {strings.CGU}
                </Link>
                <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                <Link component="button" href="/help" target="_blank" variant="body1">
                  {strings.help}
                </Link>
                <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                <Link component="button" href="/about" variant="body1">
                  {strings.about}
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
  translate('ContactUs')
)(ContactUs);
