import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import _ from 'lodash';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// Material components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared services
import { updateOrganization } from 'store/organization/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

const MAX_NOTES_LENGTH = 200;
class ManageZoneNoteDialog extends Component {
  signal = true;

  state = {
    values: {
      notes: { fr: '', en: '', nl: '' }
    },
    touched: {
      notes: { fr: false, en: false, nl: false }
    },
    errors: {
      notes: { fr: null, en: null, nl: null }
    },
    isValid: false,
    submitError: null
  };

  componentDidMount() {
    this.signal = true;
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.zone !== prevProps.zone) {
      this.initForm();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  initForm = () => {
    const { zone } = this.props;

    if(zone) {
      let values = { ...this.state.values };
      values.notes.fr = zone.notes && zone.notes.fr ? zone.notes.fr : '';
      values.notes.en = zone.notes && zone.notes.en ? zone.notes.en : '';
      values.notes.nl = zone.notes && zone.notes.nl ? zone.notes.nl : '';
  
      this.setState({
        values
      });
    }
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors.notes.fr = null;
    newState.errors.notes.en = null;
    newState.errors.notes.nl = null;
    newState.isValid = errors ? false : true;

    if (errors) {
      Object.keys(errors).forEach(function(key) {
        var field = key.split('.');

        if (Array.isArray(field) && field.length === 2) {
          newState.errors[field[0]][field[1]] = errors[key];
        } else {
          newState.errors[field] = errors[key];
        }
      });
    }

    this.setState(newState);
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    if (Array.isArray(field) && field.length === 2) {
      if (newState.touched[field[0]]) newState.touched[field[0]][field[1]] = true;
      if (newState.values[field[0]]) newState.values[field[0]][field[1]] = value;
    } else {
      newState.touched[field] = true;
      newState.values[field] = value;
    }

    this.setState(newState, this.validateForm);
  };

  handleUpdate = async () => {
    const { values } = this.state;

    const { organization, zone, strings } = this.props;
    
    organization.zones = _.map(organization.zones, (z) => {
      return z._id === zone._id ? {...zone, ...values} : z;
    });

    await this.props.updateOrganization(
      this.props.organization._id,
      organization,
      error => {
        if (error) {
          this.setState({
            submitError: strings.errors.unknownError
          });
        } else {
          let values = { ...this.state.values };
          values.notes.fr = '';
          values.notes.en = '';
          values.notes.nl = '';
          let touched = { ...this.state.touched };
          touched.notes.fr = false;
          touched.notes.en = false;
          touched.notes.nl = false;
          let errors = { ...this.state.errors };
          errors.notes.fr = null;
          errors.notes.en = null;
          errors.notes.nl = null;
          this.setState({
            values,
            touched,
            errors,
            isValid: false,
            submitError: null
          });
          this.props.handleClose();
        }
      }
    );
  };

  handleClose = () => {
    let values = { ...this.state.values };
    values.notes.fr = '';
    values.notes.en = '';
    values.notes.nl = '';
    let touched = { ...this.state.touched };
    touched.notes.fr = false;
    touched.notes.en = false;
    touched.notes.nl = false;
    let errors = { ...this.state.errors };
    errors.notes.fr = null;
    errors.notes.en = null;
    errors.notes.nl = null;
    this.setState({
      values,
      touched,
      errors,
      isValid: false,
      submitError: null
    });
    this.props.handleClose();
  };

  render() {
    const { classes, fullScreen, isLoading, open, strings } = this.props;

    const { values, touched, errors, isValid, submitError } = this.state;

    const showNotesFrError = touched.notes.fr && errors.notes.fr;
    const showNotesEnError = touched.notes.en && errors.notes.en;
    const showNotesNlError = touched.notes.nl && errors.notes.nl;

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        onClose={this.handleClose}
        open={open}
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {strings.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.subtitle}</DialogContentText>
          {isLoading && <LinearProgress className={classes.dialogLoader} />}
          <form autoComplete="off" className={classes.dialogForm}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  error={showNotesFrError ? true : false}
                  helperText={showNotesFrError ? strings.notes.fr.error : ''}
                  label={strings.notes.fr.label}
                  margin="normal"
                  multiline
                  name="notes.fr"
                  onChange={(event) =>
                    this.handleChange(['notes', 'fr'], event.target.value.substr(0, MAX_NOTES_LENGTH))
                  }
                  rows={2}
                  type="text"
                  value={values.notes.fr}
                  variant="outlined"
                />
                <Typography
                  align="right"
                  color={values.notes.fr.length >= MAX_NOTES_LENGTH ? 'error' : 'default'}
                  variant="body1"
                >
                  {values.notes.fr.length + '/' + MAX_NOTES_LENGTH}
                </Typography>
                <TextField
                  className={classes.textField}
                  error={showNotesEnError ? true : false}
                  helperText={showNotesEnError ? strings.notes.en.error : ''}
                  label={strings.notes.en.label}
                  margin="normal"
                  multiline
                  name="notes.en"
                  onChange={(event) =>
                    this.handleChange(['notes', 'en'], event.target.value.substr(0, MAX_NOTES_LENGTH))
                  }
                  required={!schema['notes.en'].presence.allowEmpty}
                  rows={2}
                  type="text"
                  value={values.notes.en}
                  variant="outlined"
                />
                <Typography
                  align="right"
                  color={values.notes.en.length >= MAX_NOTES_LENGTH ? 'error' : 'default'}
                  variant="body1"
                >
                  {values.notes.en.length + '/' + MAX_NOTES_LENGTH}
                </Typography>
                <TextField
                  className={classes.textField}
                  error={showNotesNlError ? true : false}
                  helperText={showNotesNlError ? strings.notes.nl.error : ''}
                  label={strings.notes.nl.label}
                  margin="normal"
                  multiline
                  name="notes.nl"
                  onChange={(event) =>
                    this.handleChange(['notes', 'nl'], event.target.value.substr(0, MAX_NOTES_LENGTH))
                  }
                  required={!schema['notes.nl'].presence.allowEmpty}
                  rows={2}
                  type="text"
                  value={values.notes.nl}
                  variant="outlined"
                />
                <Typography
                  align="right"
                  color={values.notes.nl.length >= MAX_NOTES_LENGTH ? 'error' : 'default'}
                  variant="body1"
                >
                  {values.notes.nl.length + '/' + MAX_NOTES_LENGTH}
                </Typography>
              </Grid>
            </Grid>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {strings.errors.unknownError}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {strings.cancel}
          </Button>
          <Button color="primary" disabled={!isValid} onClick={this.handleUpdate}>
            {strings.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ManageZoneNoteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  organization: PropTypes.any,
  strings: PropTypes.object.isRequired,
  updateOrganization: PropTypes.func,
  zone: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.organization.loading,
    organization: state.organization.organization
  };
};

const mapDispatchToProps = {
  updateOrganization
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('ManageZoneNoteDialog'),
  withMobileDialog(),
  withStyles(styles)
)(ManageZoneNoteDialog);
