import actions from './actions';

const initState = {
  loading: false,
  comments: []
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.START_FETCH_COMMENTS:
      return { ...state, loading: true };
    case actions.END_FETCH_COMMENTS:
      return { ...state, loading: false };
    case actions.START_DELETING_COMMENTS:
      return { ...state, loading: true };
    case actions.END_DELETING_COMMENTS:
      return { ...state, loading: false };
    case actions.SET_COMMENTS:
      return { ...state, comments: action.value };
    default:
      return state;
  }
};

export default reducer;
