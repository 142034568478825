import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import SwipeableViews from 'react-swipeable-views';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Shared services
import { fetchBookings, fetchRents } from 'store/rent/actionsCreator';

// Shared components
import Portlet from 'components/Portlet';

// Custom components
import BookingsTable from './components/BookingsTable';
import RentsTable from './components/RentsTable';

// Component styles
import styles from './style';

class RentList extends Component {
  signal = true;

  state = {
    error: null,
    isLoading: false,
    limit: 10,
    value: 0
  };

  componentDidMount() {
    this.signal = true;
    // fetch only the first tab
    this.props.fetchBookings();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      const { value } = this.state;

      switch (value) {
        case 0:
          this.props.fetchBookings();
          break;
        case 1:
          this.props.fetchRents();
          break;
        default:
          break;
      }
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleChange = (event, value) => {
    this.setState({ value });

    switch (value) {
      case 0:
        this.props.fetchBookings();
        break;
      case 1:
        this.props.fetchRents();
        break;
      default:
        break;
    }
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  renderBookings() {
    const { classes, isLoading, bookings, error, strings } = this.props;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (bookings.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing.bookings}</Typography>
          </div>
        </Portlet>
      );
    }

    return <BookingsTable bookings={bookings} />;
  }

  renderRents() {
    const { classes, isLoading, rents, error, strings } = this.props;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (rents.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing.rents}</Typography>
          </div>
        </Portlet>
      );
    }

    return <RentsTable rents={rents} />;
  }

  render() {
    const { classes, strings } = this.props;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <Paper>
            <Tabs
              indicatorColor="primary"
              onChange={this.handleChange}
              textColor="primary"
              value={this.state.value}
              variant="fullWidth"
            >
              <Tab label={strings.bookings} />
              <Tab label={strings.rentals} />
            </Tabs>
          </Paper>
          <SwipeableViews
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
          >
            <div className={classes.content}>{this.renderBookings()}</div>
            <div className={classes.content}>{this.renderRents()}</div>
          </SwipeableViews>
        </div>
      </DashboardLayout>
    );
  }
}

RentList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.rent.loading,
    bookings: state.rent.bookings,
    rents: state.rent.rents
  };
};

const mapDispatchToProps = {
  fetchBookings,
  fetchRents
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('RentList'),
  withStyles(styles)
)(RentList);
