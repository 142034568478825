export default {
  email: {
    presence: { allowEmpty: false },
    email: true,
    length: {
      maximum: 64
    }
  },
  message: {
    presence: { allowEmpty: false }
  },
  name: {
    presence: { allowEmpty: false }
  },
  profile: {
    presence: { allowEmpty: false }
  },
  city: {
    presence: { allowEmpty: false }
  }
};
