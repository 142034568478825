import { authorize } from '../user';

interface createPaymentResult {
  type: 'v1_success' | 'v1_action_required' | 'v2_action_required';
  paymentErrorType: string;
  requires_action: boolean;
  client_secret: string;
  payment_intent_id: string;
  redirect_url: string;
  clientSecret: string;
}

export const createPaymentIntent = (creditPriceId: string): Promise<createPaymentResult> =>
  authorize(async (resolve: any, reject: any, auth: string) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/payments/creditPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': auth
        },
        body: JSON.stringify({ creditPrice: creditPriceId })
      });
      const json = await response.json();
      if (response.ok) {
        resolve(json);
      } else {
        const text = await response.text();
        reject(text);
      }
    } catch (error) {
      reject(error);
    }
  });
