import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Material icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Shared services
import { fetchBikeType, updateBikeType } from 'store/bikeType/actionsCreator';
import { fetchPricingPlans } from 'store/pricingPlan/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './style';

// Form validation schema
import schema from './schema';

class BikeTypeDetails extends Component {
  signal = true;

  state = {
    values: {
      name: { fr: '', en: '', nl: '' },
      description: { fr: '', en: '', nl: '' },
      pricingPlan: ''
    },
    touched: {
      name: { fr: false, en: false, nl: false },
      pricingPlan: false
    },
    errors: {
      name: { fr: null, en: null, nl: null },
      pricingPlan: null
    },
    open: false,
    isValid: false,
    submitError: null
  };

  componentDidMount() {
    this.signal = true;
    this.handleFetch();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.handleFetch();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors.name.fr = null;
    newState.errors.name.en = null;
    newState.errors.name.nl = null;
    newState.errors.pricingPlan = null;
    newState.isValid = errors ? false : true;

    if (errors) {
      Object.keys(errors).forEach(function(key, index) {
        var field = key.split('.');

        if (Array.isArray(field) && field.length === 2) {
          newState.errors[field[0]][field[1]] = errors[key];
        } else {
          newState.errors[field] = errors[key];
        }
      });
    }

    this.setState(newState);
  };

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    if (Array.isArray(field) && field.length === 2) {
      if (newState.touched[field[0]])
        newState.touched[field[0]][field[1]] = true;
      if (newState.values[field[0]])
        newState.values[field[0]][field[1]] = value;
    } else {
      newState.touched[field] = true;
      newState.values[field] = value;
    }

    this.setState(newState, this.validateForm);
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleFetch = async () => {
    const { strings } = this.props;
    // fetch all the pricingPlans
    this.props.fetchPricingPlans();
    // fetch the bikeType by id
    await this.props.fetchBikeType(this.props.match.params.id, error => {
      if (error) {
        this.setState({
          submitError: strings.errors.fetchError
        });
      } else {
        const { bikeType } = this.props;
        let values = { ...this.state.values };
        values.name.fr = bikeType.name.fr ? bikeType.name.fr : '';
        values.name.en = bikeType.name.en ? bikeType.name.en : '';
        values.name.nl = bikeType.name.nl ? bikeType.name.nl : '';
        values.description.fr = bikeType.description.fr
          ? bikeType.description.fr
          : '';
        values.description.en = bikeType.description.en
          ? bikeType.description.en
          : '';
        values.description.nl = bikeType.description.nl
          ? bikeType.description.nl
          : '';
        values.pricingPlan =
          bikeType.pricingPlan && bikeType.pricingPlan._id
            ? bikeType.pricingPlan._id
            : '';
        this.setState({
          submitError: null,
          values
        });
      }
    });
  };

  handleSave = async () => {
    const { strings } = this.props;
    const { values } = this.state;

    await this.props.updateBikeType(
      this.props.match.params.id,
      values,
      error => {
        if (error) {
          this.setState({
            submitError: strings.errors.unknownError
          });
        } else {
          const { bikeType } = this.props;
          let values = { ...this.state.values };
          values.name.fr = bikeType.name.fr ? bikeType.name.fr : '';
          values.name.en = bikeType.name.en ? bikeType.name.en : '';
          values.name.nl = bikeType.name.nl ? bikeType.name.nl : '';
          values.description.fr = bikeType.description.fr
            ? bikeType.description.fr
            : '';
          values.description.en = bikeType.description.en
            ? bikeType.description.en
            : '';
          values.description.nl = bikeType.description.nl
            ? bikeType.description.nl
            : '';
          values.pricingPlan = bikeType.pricingPlan._id;
          this.setState({
            values,
            isValid: false
          });
          this.handleOpen();
        }
      }
    );
  };

  render() {
    const {
      pricingPlans,
      classes,
      isBikeTypeLoading,
      isPricingPlanLoading,
      strings
    } = this.props;

    const { values, touched, errors, isValid, submitError, open } = this.state;

    const showNameFrError = touched.name.fr && errors.name.fr;
    const showNameEnError = touched.name.en && errors.name.en;
    const showNameNlError = touched.name.nl && errors.name.nl;
    const showPricingPlanError = touched.pricingPlan && errors.pricingPlan;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.header}>
          <IconButton
            className={classes.back}
            onClick={this.handleBack}
            title={strings.common.back}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className={classes.root}>
          <Portlet>
            <PortletHeader>
              <PortletLabel
                subtitle={strings.portletSubtitle}
                title={strings.portletTitle}
              />
            </PortletHeader>
            <PortletContent>
              {isBikeTypeLoading && (
                <LinearProgress className={classes.portletLoader} />
              )}
              <form autoComplete="off" noValidate>
                <Grid container spacing={24}>
                  <Grid item md={3} xs={12}>
                    <TextField
                      InputProps={{
                        endAdornment: isPricingPlanLoading ? (
                          <InputAdornment position="end">
                            <CircularProgress />
                          </InputAdornment>
                        ) : null
                      }}
                      SelectProps={{
                        MenuProps: {}
                      }}
                      className={classes.textField}
                      disabled={isPricingPlanLoading}
                      error={showPricingPlanError ? true : false}
                      helperText={
                        showPricingPlanError ? strings.pricingPlan.error : ''
                      }
                      label={strings.pricingPlan.label}
                      margin="normal"
                      name="pricingPlan"
                      onChange={event =>
                        this.handleChange('pricingPlan', event.target.value)
                      }
                      required
                      select
                      type="text"
                      value={values.pricingPlan}
                      variant="outlined"
                    >
                      {pricingPlans.map(option => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item md={3} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={showNameFrError ? true : false}
                      helperText={showNameFrError ? strings.name.fr.error : ''}
                      label={strings.name.fr.label}
                      margin="normal"
                      name="name.fr"
                      onChange={event =>
                        this.handleChange(['name', 'fr'], event.target.value)
                      }
                      required
                      type="text"
                      value={values.name.fr}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      label={strings.description.fr.label}
                      margin="normal"
                      multiline
                      name="description.fr"
                      onChange={event =>
                        this.handleChange(
                          ['description', 'fr'],
                          event.target.value
                        )
                      }
                      rowsMax="4"
                      type="text"
                      value={values.description.fr}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item md={3} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={showNameEnError ? true : false}
                      helperText={showNameEnError ? strings.name.en.error : ''}
                      label={strings.name.en.label}
                      margin="normal"
                      name="name.en"
                      onChange={event =>
                        this.handleChange(['name', 'en'], event.target.value)
                      }
                      required
                      type="text"
                      value={values.name.en}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      label={strings.description.en.label}
                      margin="normal"
                      multiline
                      name="description.en"
                      onChange={event =>
                        this.handleChange(
                          ['description', 'en'],
                          event.target.value
                        )
                      }
                      rowsMax="4"
                      type="text"
                      value={values.description.en}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item md={3} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={showNameNlError ? true : false}
                      helperText={showNameNlError ? strings.name.nl.error : ''}
                      label={strings.name.nl.label}
                      margin="normal"
                      name="name.nl"
                      onChange={event =>
                        this.handleChange(['name', 'nl'], event.target.value)
                      }
                      required
                      type="text"
                      value={values.name.nl}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      label={strings.description.nl.label}
                      margin="normal"
                      multiline
                      name="description.nl"
                      onChange={event =>
                        this.handleChange(
                          ['description', 'nl'],
                          event.target.value
                        )
                      }
                      rowsMax="4"
                      type="text"
                      value={values.description.nl}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      label={strings.imageUrl}
                      margin="normal"
                      name="imageUrl"
                      onChange={event =>
                        this.uploadPicture(['imageUrl'], event.target.value)
                      }
                      type="file"
                      value={values.imageUrl}
                      variant="outlined"
                    />
                    <img
                      alt=""
                      className={classes.imageField}
                      onError={() => {
                        let values = { ...this.state.values };
                        values.imagePreview = '/images/bike_placeholder.jpg';
                        this.setState({
                          values
                        });
                      }}
                      src={
                        values.imagePreview
                          ? values.imagePreview
                          : '/images/bike_placeholder.jpg'
                      }
                    />
                  </Grid>
                </Grid>
                {submitError && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitError}
                  </Typography>
                )}
              </form>
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
              <Button
                color="primary"
                disabled={!isValid}
                onClick={this.handleSave}
                variant="contained"
              >
                {strings.save}
              </Button>
            </PortletFooter>
          </Portlet>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          autoHideDuration={6000}
          className={classes.snackbar}
          onClose={this.handleClose}
          open={open}
        >
          <SnackbarContent
            action={[
              <IconButton
                className={classes.close}
                color="inherit"
                key="close"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
            aria-describedby="message-id"
            className={classes.snackbarContent}
            message={
              <span className={classes.message} id="message-id">
                <CheckCircleIcon />
                {strings.success}
              </span>
            }
          />
        </Snackbar>
      </DashboardLayout>
    );
  }
}

BikeTypeDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isBikeTypeLoading: state.bikeType.loading,
    isPricingPlanLoading: state.pricingPlan.loading,
    bikeType: state.bikeType.bikeType,
    pricingPlans: state.pricingPlan.pricingPlans
  };
};

const mapDispatchToProps = {
  fetchBikeType,
  updateBikeType,
  fetchPricingPlans
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('BikeTypeDetails'),
  withStyles(styles)
)(BikeTypeDetails);
