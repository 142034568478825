import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Shared services
import { fetchPricingPlans } from 'store/pricingPlan/actionsCreator';

// Shared components
import Portlet from 'components/Portlet';

// Custom components
import PricingPlansTable from './components/PricingPlansTable';
import AddPricingPlan from './components/AddPricingPlan';

// Component styles
import styles from './style';

class PricingPlanList extends Component {
  signal = true;

  state = {
    error: null,
    selectedPricingPlans: []
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchPricingPlans();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchPricingPlans();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = selectedPricingPlans => {
    this.setState({ selectedPricingPlans });
  };

  renderPrincingPlans() {
    const { classes, isLoading, error, strings, pricingPlans } = this.props;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (pricingPlans.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    return <PricingPlansTable onSelect={this.handleSelect} pricingPlans={pricingPlans} />;
  }

  render() {
    const { classes, strings } = this.props;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <div className={classes.content}>
            <Grid container spacing={24}>
              <Grid item lg={8} xs={12}>
                <div>{this.renderPrincingPlans()}</div>
              </Grid>
              <Grid item lg={4} xs={12}>
                <AddPricingPlan />
              </Grid>
            </Grid>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

PricingPlanList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.pricingPlan.loading,
    pricingPlans: state.pricingPlan.pricingPlans
  };
};

const mapDispatchToProps = {
  fetchPricingPlans
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('PricingPlanList'),
  withStyles(styles)
)(PricingPlanList);
