import green from '@material-ui/core/colors/green';

export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3
  },
  content: {
    marginTop: theme.spacing.unit * 2
  },
  stepper: {
    backgroundColor: '#8A13C2'
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center'
  },
  portletFooter: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  textWrapper: {
    paddingTop: '48px',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center'
  },
  theme: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  textField: {
    width: '100%'
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  },
  snackbar: {
    margin: theme.spacing.unit * 3
  },
  snackbarContent: {
    backgroundColor: green[600]
  },
  close: {
    padding: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing.unit
    }
  },
  savedCard: {
    width: '100%',
    paddingLeft: theme.spacing.unit * 5,
    paddingRight: theme.spacing.unit * 5,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  newCard: {
    width: '75%',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  expireDate: {
    float: 'right'
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    p: {
      whiteSpace: 'nowrap'
    }
  },
  savedCardContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  deleteButton: {
    borderColor: theme.palette.danger.main + '!important',
    marginLeft: theme.spacing.unit,
    color: theme.palette.danger.main + '!important',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  deleteAccountButton: {
    borderColor: theme.palette.danger.main + '!important',
    backgroundColor: theme.palette.danger.light,
    color: theme.palette.danger.main + '!important'
  },
  deleteIcon: {
    marginRight: theme.spacing.unit
  }
});
