export default {
  AddBikeDialog: {
    title: 'Het toevoegen van een fiets',
    subtitle:
      'Voor het toevoegen van een fiets, vul dan de onderstaande velden in. De velden gemarkeerd met een sterretje zijn verplicht.',
    name: {
      label: 'Naam',
      error: 'De naam van de fiets is verplicht'
    },
    type: {
      label: 'Type',
      error: 'Het type van de fiets is verplicht'
    },
    description: {
      label: 'Beschrijving'
    },
    padlockKind: {
      label: 'Hangslot type',
      error: 'Het type van de hangslot is verplicht'
    },
    padlockMac: {
      label: 'Hangslot MAC-adres',
      error: 'Het MAC-adres van de hangslot is verplicht'
    },
    imageUrl: {
      label: 'De link voor afbeelding'
    },
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het toevoegen van de fiets.'
    },
    cancel: 'Annuleren',
    add: 'Toevoegen'
  },
  AddBikeTypeDialog: {
    title: 'Voeg het type van de fiets',
    subtitle:
      'Voor het toevoegen van een type van de fiets, vul dan de onderstaande velden in. De velden gemarkeerd met een sterretje zijn verplicht.',
    name: {
      label: 'Naam',
      error: 'De naam van het type van de fiets is verplicht'
    },
    description: {
      label: 'Beschrijving'
    },
    pricingPlan: {
      label: 'Tariefplan',
      error: 'Het abonnement is verplicht'
    },
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het toevoegen van het type van de fiets.'
    },
    cancel: 'Annuleren',
    add: 'Toevoegen'
  },
  AddOrganizationDialog: {
    title: 'Voor het toevoegen van een organisatie',
    subtitle:
      'Voor het toevoegen van een organisatie, vul dan de onderstaande velden in. De velden gemarkeerd met een sterretje zijn verplicht.',
    name: {
      label: 'Naam',
      error: 'De naam is verplicht'
    },
    isPublic: {
      label: 'De organisatie van de openbare',
      error: 'Het veld is verplicht'
    },
    bookingMaxDurationInMinutes: {
      label: 'De maximale duur van reserveringen'
    },
    simultaneousBookingMaxCount: {
      label: 'Maximum aantal gelijktijdige boekingen'
    },
    rentMaxDurationInMinutes: {
      label: 'De maximale looptijd van de huurcontracten'
    },
    simultaneousRentMaxCount: {
      label: 'Maximum aantal gelijktijdige verhuur'
    },
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het toevoegen van de organisatie.'
    },
    cancel: 'Annuleren',
    add: 'Toevoegen'
  },
  AddPricingPlanDialog: {
    title: 'Voeg een tariefplan',
    subtitle:
      'Voor het toevoegen van een tariefplan, vul dan de onderstaande velden in. De velden gemarkeerd met een sterretje zijn verplicht.',
    name: {
      label: 'Naam',
      error: 'De naam is verplicht'
    },
    bookingCost: {
      label: 'Kosten van de boeking',
      error: 'De hoeveelheid is verplicht'
    },
    firstHalfHour: {
      label: 'Het eerste half uur',
      error: 'Het eerste half uur is verplicht'
    },
    secondHalfHour: {
      label: 'Tweede half uur',
      error: 'Het tweede half uur is verplicht'
    },
    thirdHalfHour: {
      label: 'Derde half uur',
      error: 'Het derde half uur is verplicht'
    },
    nextHalfHours: {
      label: 'Een half uur na',
      error: 'Het halve uur het volgende is verplicht'
    },
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het toevoegen van het tariefplan.'
    },
    cancel: 'Annuleren',
    add: 'Toevoegen'
  },
  AddUserDialog: {
    roles: {
      User: 'Gebruiker',
      Operator: 'Operator'
    },
    firstname: {
      label: 'Voornaam',
      error: 'De eerste naam is verplicht'
    },
    name: {
      label: 'Naam',
      error: 'De naam is verplicht'
    },
    email: {
      label: 'E-mail',
      error: 'Een geldig emailadres is verplicht'
    },
    roleLabel: 'Rol van de selectie',
    roleHelperText:
      'De rol bepaalt de toegang van de gebruiker. Een eenvoudige toepassing van de gebruiker of een operator met administratie rechten.',
    subtitle:
      'Als de uitgenodigde gebruiker al een account heeft, een recht van toegang tot hem zal worden gegeven voor deze organisatie. In tegendeel het geval is, zal worden gevraagd een gebruikersaccount te maken op ShareABike.',
    errors: {
      HasPendingAccess: 'Deze gebruiker is al uitgenodigd en heeft nog niet in op de uitnodiging.',
      HasAccess: 'Deze gebruiker toegang heeft.',
      UnknownError: 'Er is een fout opgetreden tijdens de uitnodiging.'
    },
    title: 'Het uitnodigen van een gebruiker'
  },
  AddBikeType: {
    title: 'Voeg het type van de fiets',
    name: {
      fr: {
        label: 'Naam in het frans',
        error: 'De naam in het frans is verplicht'
      },
      en: {
        label: 'Naam in het engels',
        error: 'De naam in het engels is verplicht'
      },
      nl: {
        label: 'Nederlandse naam',
        error: 'De naam in het nederlands is verplicht'
      }
    },
    description: {
      fr: {
        label: 'Beschrijving in het frans',
        error: 'De beschrijving in het frans is verplicht'
      },
      en: {
        label: 'Beschrijving in het engels',
        error: 'De beschrijving in het engels verplicht'
      },
      nl: {
        label: 'Beschrijving in het nederlands',
        error: 'De beschrijving in het nederlands verplicht'
      }
    },
    pricingPlan: {
      label: 'Tariefplan',
      error: 'Het abonnement is verplicht'
    },
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het toevoegen van het type van de fiets.'
    },
    add: 'Toevoegen'
  },
  AddPrice: {
    title: 'Voeg een tarief',
    price: {
      label: 'Prijs',
      error: 'De prijs is verplicht'
    },
    credit: {
      label: 'Bedrag van de kredieten',
      error: 'De hoeveelheid is verplicht'
    },
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het toevoegen van het tarief.'
    },
    add: 'Toevoegen'
  },
  AddPricingPlan: {
    title: 'Voeg een tariefplan',
    name: {
      label: 'Naam',
      error: 'De naam is verplicht'
    },
    bookingCost: {
      label: 'Kosten van de boeking',
      error: 'De hoeveelheid is verplicht'
    },
    firstHalfHour: {
      label: 'Het eerste half uur',
      error: 'Het eerste half uur is verplicht'
    },
    secondHalfHour: {
      label: 'Tweede half uur',
      error: 'Het tweede half uur is verplicht'
    },
    thirdHalfHour: {
      label: 'Derde half uur',
      error: 'Het derde half uur is verplicht'
    },
    nextHalfHours: {
      label: 'Een half uur na',
      error: 'Het halve uur het volgende is verplicht'
    },
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het toevoegen van het tariefplan.'
    },
    add: 'Toevoegen'
  },
  BikeDetails: {
    title: 'Details',
    portletTitle: 'Fiets details',
    portletSubtitle: 'De onderstaande informatie kan worden bewerkt',
    imageUrl: 'De link voor afbeelding',
    isDefective: 'Deze fiets is defect',
    endRent: 'Stop de huur',
    name: {
      fr: {
        label: 'Naam in het frans',
        error: 'De naam in het frans is verplicht'
      },
      en: {
        label: 'Naam in het engels',
        error: 'De naam in het engels is verplicht'
      },
      nl: {
        label: 'Nederlandse naam',
        error: 'De naam in het nederlands is verplicht'
      }
    },
    state: {
      label: 'Staat',
      error: 'De staat is verplicht'
    },
    type: {
      label: 'Type',
      error: 'Het type van de fiets is verplicht'
    },
    padlockKind: {
      label: 'Hangslot type',
      error: 'Het type van de hangslot is verplicht'
    },
    padlockMac: {
      label: 'Hangslot MAC-adres',
      error: 'Het MAC-adres van de hangslot is verplicht'
    },
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het opslaan van de wijzigingen.',
      fetchError: 'Er is een fout opgetreden, de details van deze fiets kan niet worden weergegeven.',
      endRentError: 'Er is een fout opgetreden tijdens het stoppen van de huur.'
    },
    success: 'De wijzigingen zijn opgeslagen',
    save: 'Sla de wijzigingen op'
  },
  BikeList: {
    title: 'Fietsen',
    nothing: 'Geen fiets vermeld'
  },
  BikesTable: {
    name: 'Naam',
    type: 'Type',
    description: 'Beschrijving',
    pricingPlan: 'Tariefplan',
    state: 'Staat',
    actions: 'Acties',
    unknown: 'Onbekend'
  },
  BikesToolbar: {
    addBike: 'Het toevoegen van een fiets',
    search: 'Zoek een fiets',
    delete: 'Verwijderen'
  },
  BikeTypeList: {
    title: 'Type fiets',
    nothing: 'Elk type van de fiets vermeld'
  },
  BikeTypesTable: {
    title: 'Lijst van de types van de fiets',
    name: 'Naam',
    description: 'Beschrijving',
    pricingPlan: 'Tariefplan',
    delete: 'Verwijderen'
  },
  BookingsTable: {
    startDate: 'De start van de reservering',
    endDate: 'De voltooiing van de reservering',
    bike: 'Fiets',
    price: 'Prijs',
    user: 'Gebruiker'
  },
  Common: {
    common: {
      back: 'Terug',
      name: 'Naam',
      firstname: 'Voornaam',
      cancel: 'Annuleren',
      delete: 'Verwijderen',
      previousPage: 'Vorige Pagina',
      nextPage: 'Volgende Pagina',
      to: 'naar',
      of: 'van',
      total: 'in totaal',
      rowsPerPage: 'Aantal regels per pagina:',
      available: 'Beschikbaar',
      booked: 'Gereserveerd',
      rented: 'Verhuurd',
      toRelocate: 'Heeft verhuizen',
      relocating: 'Overdracht',
      repairing: 'Reparatie',
      unavailable: 'Niet beschikbaar',
      yes: 'Ja',
      no: 'Niet',
      administrator: 'Beheerder',
      operator: 'Operator'
    }
  },
  InvitationList: {
    nothing: 'Geen uitnodiging te wachten'
  },
  InvitationsTable: {
    invitationDate: 'Datum van de uitnodiging'
  },
  InvitationsToolbar: {
    search: 'Zoeken voor een uitnodiging'
  },
  LanguageSwitch: {
    title: 'De keuze van de taal',
    subtitle: 'Kies de taal waarmee u wilt werken.',
    nothing: 'Er is niets hier...',
    languages: {
      en: 'Engels',
      fr: 'Frans',
      nl: 'Nederlandse'
    }
  },
  OrganizationDetails: {
    title: 'Details',
    bookings: 'Reserveringen',
    rentings: 'Verhuur',
    access: 'Toegangsbeheer',
    domainAccess: 'Toegang per domein',
    portletTitle: 'Details van de organisatie',
    portletSubtitle: 'De onderstaande informatie kan worden bewerkt',
    zonesTitle: 'Beheer',
    zonesSubtitle: 'Wijzig de gebieden van het gebruik van de fiets',
    name: {
      label: 'Naam',
      error: 'De naam is verplicht'
    },
    isPublic: {
      label: 'De organisatie van de openbare',
      error: 'Het veld is verplicht'
    },
    bookingMaxDurationInMinutes: {
      label: 'De maximale duur van reserveringen'
    },
    simultaneousBookingMaxCount: {
      label: 'Maximum aantal gelijktijdige boekingen'
    },
    rentMaxDurationInMinutes: {
      label: 'De maximale looptijd van de huurcontracten'
    },
    simultaneousRentMaxCount: {
      label: 'Maximum aantal gelijktijdige verhuur'
    },
    accessLevel: {
      label: 'Vereist toegang niveau'
    },
    accessDomains: {
      label: 'Domain naam',
      description:
        'Nieuwe registranten die een van de opgegeven domeinnamen gebruiken, hebben toegang tot deze organisatie'
    },
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het opslaan van de wijzigingen.',
      fetchError: 'Er is een fout opgetreden, de details van deze organisatie kan niet worden weergegeven.'
    },
    success: 'De wijzigingen zijn opgeslagen',
    save: 'Sla de wijzigingen op',
    accessLevels: {
      basic: 'Basic',
      complete: 'Volledig',
      creditcard: 'Creditcard'
    }
  },
  OrganizationList: {
    title: 'Organisaties',
    nothing: 'Geen enkele organisatie vermeld'
  },
  OrganizationSwitch: {
    title: 'De keuze van de organisatie',
    subtitle: 'Selecteer de organisatie om mee te werken.',
    nothing: 'Er is niets hier...'
  },
  OrganizationsTable: {
    name: 'Naam',
    isPublic: 'Openbaar',
    bookingMaxDurationInMinutes: 'De maximale duur van reserveringen',
    simultaneousBookingMaxCount: 'Maximum aantal gelijktijdige boekingen',
    rentMaxDurationInMinutes: 'De maximale looptijd van de huurcontracten',
    simultaneousRentMaxCount: 'Maximum aantal gelijktijdige verhuur'
  },
  OrganizationsToolbar: {
    addOrganization: 'Voor het toevoegen van een organisatie',
    search: 'Zoeken naar een organisatie',
    delete: 'Verwijderen'
  },
  PriceList: {
    title: 'Tarieven',
    nothing: 'Geen prijs vermeld'
  },
  PricesTable: {
    title: 'Prijs lijst',
    amountcredit: 'Bedrag van de kredieten',
    credit: 'credit(s)',
    delete: 'Verwijderen',
    price: 'Prijs'
  },
  PricingPlanList: {
    title: 'Tariefplan',
    nothing: 'Geen tarief vermeld'
  },
  PricingPlansTable: {
    title: 'Lijst van tariefplannen',
    name: 'Naam',
    bookingCost: 'Kosten van de boeking',
    firstHalfHour: 'Het eerste half uur',
    secondHalfHour: 'Tweede half uur',
    thirdHalfHour: 'Derde half uur',
    nextHalfHours: 'Een half uur na',
    credit: 'credit(s)',
    delete: 'Verwijderen'
  },
  RentList: {
    title: 'Verhuur',
    bookings: 'Reserveringen',
    rentals: 'Huurgelden',
    nothing: {
      rents: 'Een (huur) vermeld',
      bookings: 'Geen reservering'
    }
  },
  RentsTable: {
    startDate: 'Het begin van de huur',
    endDate: 'Einde van de huurovereenkomst',
    bike: 'Fiets',
    price: 'Prijs',
    user: 'Gebruiker'
  },
  SignIn: {
    quote: {
      text: '"Het leven is als een fiets, is het noodzakelijk om vooraf niet het evenwicht te verliezen."',
      author: 'Albert Einstein',
      bio: 'Theoretisch natuurkundige'
    },
    title: 'Sluit',
    subtitle: 'Uw e-mailadres gebruiken',
    email: {
      label: 'E-mail adres',
      error: 'Het e-mailadres is verplicht'
    },
    password: {
      label: 'Wachtwoord',
      error: 'Het wachtwoord is verplicht'
    },
    action: 'Verbinding',
    errors: {
      unknownError: 'Er is een fout opgetreden, probeer het later opnieuw.',
      wrongCredentialsError: 'Uw gebruikersnaam of wachtwoord is onjuist.',
      notEnoughPrivilegesError: 'U hebt niet de vereiste rechten om verbinding te maken.'
    }
  },
  Sidebar: {
    administrator: 'Admin',
    manager: 'Operator',
    users: 'Gebruikers',
    bikes: 'Fietsen',
    bikeList: 'Lijst van fietsen',
    bikeTypes: 'Type fiets',
    pricingPlans: 'Tariefplan',
    rentals: 'Verhuur',
    zones: 'Gebieden',
    settings: 'Instellingen',
    administration: 'Beheer',
    organization: 'Organisatie',
    organizations: 'Organisaties',
    accounting: 'Beheer',
    prices: 'Tarieven',
    support: 'Klantenservice',
    invitationList: 'Lijst van uitnodigingen',
    userList: 'Lijst van gebruikers'
  },
  UserDetails: {
    title: 'Details',
    portletTitle: 'Details van de gebruiker',
    firstname: {
      label: 'Voornaam',
      error: 'De eerste naam is verplicht'
    },
    name: {
      label: 'Naam',
      error: 'De naam is verplicht'
    },
    email: {
      label: 'E-mail',
      error: 'De e-mail is verplicht'
    },
    phoneNumber: {
      label: 'Telefoonnummer'
    },
    birthYear: {
      label: 'Jaar van geboorte'
    },
    street: {
      label: 'Straat'
    },
    postalCode: {
      label: 'Postcode'
    },
    city: {
      label: 'Stad'
    },
    gender: {
      label: 'Geslacht'
    },
    phoneType: {
      label: 'Type smartphone'
    },
    balance: {
      label: 'Credits'
    },
    address: 'Adres',
    personalDetails: 'Persoonlijke informatie',
    userSettings: 'Gebruiker instellingen',
    errors: {
      unknownError: 'Er is een fout opgetreden tijdens het opslaan van de wijzigingen.',
      fetchError: 'Er is een fout opgetreden, worden de gegevens van deze gebruiker kan niet worden weergegeven.'
    },
    success: 'De wijzigingen zijn opgeslagen',
    save: 'Sla de wijzigingen op'
  },
  UserList: {
    title: 'Gebruikers',
    nothing: 'Elke gebruiker vermeld'
  },
  UsersToolbar: {
    addUser: 'Een gebruiker toevoegen',
    search: 'Zoek een gebruiker',
    invitations: 'Uitnodigingen'
  },
  UsersTable: {
    name: 'Naam',
    email: 'E-mail',
    address: 'Adres',
    phone: 'Telefoon',
    registrationDate: 'Datum van registratie'
  },
  ZonesTable: {
    name: 'Naam',
    bikesCount: 'Aantal fietsen'
  },
  ZonesToolbar: {
    addZone: 'Maak een nieuwe zone',
    search: 'Het vinden van een gebied'
  }
};
