import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Material icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Shared services
import {
  fetchPricingPlan,
  updatePricingPlan
} from 'store/pricingPlan/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './style';

// Form validation schema
import schema from './schema';

class PricingPlanDetails extends Component {
  signal = true;

  state = {
    values: {
      name: '',
      bookingCost: 0,
      firstHalfHour: 0,
      secondHalfHour: 0,
      thirdHalfHour: 0,
      nextHalfHours: 0
    },
    touched: {
      name: false,
      bookingCost: false,
      firstHalfHour: false,
      secondHalfHour: false,
      thirdHalfHour: false,
      nextHalfHours: false
    },
    errors: {
      name: null,
      bookingCost: null,
      firstHalfHour: null,
      secondHalfHour: null,
      thirdHalfHour: null,
      nextHalfHours: null
    },
    open: false,
    isValid: false,
    submitError: null
  };

  componentDidMount() {
    this.signal = true;
    this.handleFetch();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.handleFetch();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleFetch = async () => {
    const { strings } = this.props;
    // fetch the pricingPlan by id
    await this.props.fetchPricingPlan(this.props.match.params.id, error => {
      if (error) {
        this.setState({
          submitError: strings.errors.fetchError
        });
      } else {
        const { pricingPlan } = this.props;
        let values = { ...this.state.values };
        values.name = pricingPlan.name ? pricingPlan.name : '';
        values.bookingCost = pricingPlan.bookingCost
          ? pricingPlan.bookingCost
          : '';
        values.firstHalfHour = pricingPlan.firstHalfHour
          ? pricingPlan.firstHalfHour
          : 0;
        values.secondHalfHour = pricingPlan.secondHalfHour
          ? pricingPlan.secondHalfHour
          : 0;
        values.thirdHalfHour = pricingPlan.thirdHalfHour
          ? pricingPlan.thirdHalfHour
          : 0;
        values.nextHalfHours = pricingPlan.nextHalfHours
          ? pricingPlan.nextHalfHours
          : 0;
        this.setState({
          values
        });
      }
    });
  };

  handleSave = async () => {
    const { strings } = this.props;
    const { values } = this.state;

    await this.props.updatePricingPlan(
      this.props.match.params.id,
      values,
      error => {
        if (error) {
          this.setState({
            submitError: strings.errors.unknownError
          });
        } else {
          const { pricingPlan } = this.props;
          let values = { ...this.state.values };
          values.name = pricingPlan.name ? pricingPlan.name : '';
          values.bookingCost = pricingPlan.bookingCost
            ? pricingPlan.bookingCost
            : '';
          values.firstHalfHour = pricingPlan.firstHalfHour
            ? pricingPlan.firstHalfHour
            : 0;
          values.secondHalfHour = pricingPlan.secondHalfHour
            ? pricingPlan.secondHalfHour
            : 0;
          values.thirdHalfHour = pricingPlan.thirdHalfHour
            ? pricingPlan.thirdHalfHour
            : 0;
          values.nextHalfHours = pricingPlan.nextHalfHours
            ? pricingPlan.nextHalfHours
            : 0;
          this.setState({
            values,
            isValid: false
          });
          this.handleOpen();
        }
      }
    );
  };

  render() {
    const { classes, isLoading, strings } = this.props;

    const { values, touched, errors, isValid, submitError, open } = this.state;

    const showNameError = touched.name && errors.name;
    const showBookingCostError = touched.bookingCost && errors.bookingCost;
    const showFirstHalfHourError =
      touched.firstHalfHour && errors.firstHalfHour;
    const showSecondHalfHourError =
      touched.secondHalfHour && errors.secondHalfHour;
    const showThirdHalfHourError =
      touched.thirdHalfHour && errors.thirdHalfHour;
    const showNextHalfHoursError =
      touched.nextHalfHours && errors.nextHalfHours;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.header}>
          <IconButton
            className={classes.back}
            onClick={this.handleBack}
            title={strings.common.back}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className={classes.root}>
          <Portlet>
            <PortletHeader>
              <PortletLabel
                subtitle={strings.portletSubtitle}
                title={strings.portletTitle}
              />
            </PortletHeader>
            <PortletContent>
              {isLoading && (
                <LinearProgress className={classes.portletLoader} />
              )}
              <form autoComplete="off" noValidate>
                <Grid container spacing={24}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={showNameError ? true : false}
                      helperText={showNameError ? strings.name.error : ''}
                      label={strings.name.label}
                      margin="normal"
                      name="name"
                      onChange={event =>
                        this.handleChange('name', event.target.value)
                      }
                      required
                      type="text"
                      value={values.name}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={showBookingCostError ? true : false}
                      helperText={
                        showBookingCostError ? strings.bookingCost.error : ''
                      }
                      label={strings.bookingCost.label}
                      margin="normal"
                      name="bookingCost"
                      onChange={event =>
                        this.handleChange('bookingCost', event.target.value)
                      }
                      required
                      type="number"
                      value={values.bookingCost}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={showFirstHalfHourError ? true : false}
                      helperText={
                        showFirstHalfHourError
                          ? strings.firstHalfHour.error
                          : ''
                      }
                      label={strings.firstHalfHour.label}
                      margin="normal"
                      name="firstHalfHour"
                      onChange={event =>
                        this.handleChange('firstHalfHour', event.target.value)
                      }
                      required
                      type="number"
                      value={values.firstHalfHour}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={showSecondHalfHourError ? true : false}
                      helperText={
                        showSecondHalfHourError
                          ? strings.secondHalfHour.error
                          : ''
                      }
                      label={strings.secondHalfHour.label}
                      margin="normal"
                      name="secondHalfHour"
                      onChange={event =>
                        this.handleChange('secondHalfHour', event.target.value)
                      }
                      required
                      type="number"
                      value={values.secondHalfHour}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={showThirdHalfHourError ? true : false}
                      helperText={
                        showThirdHalfHourError
                          ? strings.thirdHalfHour.error
                          : ''
                      }
                      label={strings.thirdHalfHour.label}
                      margin="normal"
                      name="thirdHalfHour"
                      onChange={event =>
                        this.handleChange('thirdHalfHour', event.target.value)
                      }
                      required
                      type="number"
                      value={values.thirdHalfHour}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={showNextHalfHoursError ? true : false}
                      helperText={
                        showNextHalfHoursError
                          ? strings.nextHalfHours.error
                          : ''
                      }
                      label={strings.nextHalfHours.label}
                      margin="normal"
                      name="nextHalfHours"
                      onChange={event =>
                        this.handleChange('nextHalfHours', event.target.value)
                      }
                      required
                      type="number"
                      value={values.nextHalfHours}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {submitError && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitError}
                  </Typography>
                )}
              </form>
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
              <Button
                color="primary"
                disabled={!isValid}
                onClick={this.handleSave}
                variant="contained"
              >
                {strings.save}
              </Button>
            </PortletFooter>
          </Portlet>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          autoHideDuration={6000}
          className={classes.snackbar}
          onClose={this.handleClose}
          open={open}
        >
          <SnackbarContent
            action={[
              <IconButton
                className={classes.close}
                color="inherit"
                key="close"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
            aria-describedby="message-id"
            className={classes.snackbarContent}
            message={
              <span className={classes.message} id="message-id">
                <CheckCircleIcon />
                {strings.success}
              </span>
            }
          />
        </Snackbar>
      </DashboardLayout>
    );
  }
}

PricingPlanDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.pricingPlan.loading,
    pricingPlan: state.pricingPlan.pricingPlan
  };
};

const mapDispatchToProps = {
  fetchPricingPlan,
  updatePricingPlan
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('PricingPlanDetails'),
  withStyles(styles)
)(PricingPlanDetails);
