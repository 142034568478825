import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

// Material icons
import WarningIcon from '@material-ui/icons/WarningOutlined';

// Shared components
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';
import BikeBadge from 'components/BikeBadge';
import UserBadge from 'components/UserBadge';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class CommentsTable extends Component {
  state = {
    selectedComments: [],
    rowsPerPage: 10,
    page: 0
  };

  handleSelectAll = event => {
    const { comments, onSelect } = this.props;

    let selectedComments;

    if (event.target.checked) {
      selectedComments = comments.map(c => c._id);
    } else {
      selectedComments = [];
    }

    this.setState({ selectedComments });

    onSelect(selectedComments);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedComments } = this.state;

    const selectedIndex = selectedComments.indexOf(id);
    let newSelectedComments = [];

    if (selectedIndex === -1) {
      newSelectedComments = newSelectedComments.concat(selectedComments, id);
    } else if (selectedIndex === 0) {
      newSelectedComments = newSelectedComments.concat(
        selectedComments.slice(1)
      );
    } else if (selectedIndex === selectedComments.length - 1) {
      newSelectedComments = newSelectedComments.concat(
        selectedComments.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedComments = newSelectedComments.concat(
        selectedComments.slice(0, selectedIndex),
        selectedComments.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedComments: newSelectedComments });

    onSelect(newSelectedComments);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, className, comments, strings } = this.props;
    const { selectedComments, rowsPerPage, page } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedComments.length === comments.length}
                      color="primary"
                      indeterminate={
                        selectedComments.length > 0 &&
                        selectedComments.length < comments.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="left" padding="none">
                    {strings.user}
                  </TableCell>
                  <TableCell align="left">{strings.bike}</TableCell>
                  <TableCell align="left">{strings.comment}</TableCell>
                  <TableCell align="left">{strings.issue}</TableCell>
                  <TableCell align="left">{strings.date}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {comments
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map(comment => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={comment._id}
                      selected={selectedComments.indexOf(comment._id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <div className={classes.tableCellInner}>
                          <Checkbox
                            checked={
                              selectedComments.indexOf(comment._id) !== -1
                            }
                            color="primary"
                            onChange={event =>
                              this.handleSelectOne(event, comment._id)
                            }
                            value="true"
                          />
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell} padding="none">
                        <UserBadge userId={comment.user_id} />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <BikeBadge bikeId={comment.bike_id} />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {comment.message}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {(comment.isBikeDefective && !comment.isBikeOutOfService) && (
                          <div className={classes.iconText}>
                            <WarningIcon className={classes.orangeText} />
                            {strings.defective}
                          </div>
                        )}
                        {(comment.isBikeDefective && comment.isBikeOutOfService) && (
                          <div className={classes.iconText}>
                            <WarningIcon className={classes.redText} />
                            {strings.outofservice}
                          </div>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {moment(comment.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            backIconButtonProps={{
              'aria-label': strings.common.previousPage
            }}
            component="div"
            count={comments.length}
            labelDisplayedRows={({ from, to, count }) => {
              return from + '-' + to + ' ' + strings.common.of + ' ' + count;
            }}
            labelRowsPerPage={strings.common.rowsPerPage}
            nextIconButtonProps={{
              'aria-label': strings.common.nextPage
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

CommentsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  comments: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func
};

CommentsTable.defaultProps = {
  comments: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

const mapStateToProps = state => {
  return {
    role: state.authorize.role
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('CommentsTable'),
  withStyles(styles)
)(CommentsTable);
