import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

// Material components
import Drawer from '@material-ui/core/Drawer';

// Custom components
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Footer from './components/Footer';

// Shared services
import { setDashboard } from 'store/layout/actionsCreator';

// Component styles
import styles from './styles';

class DashboardLayout extends Component {
  constructor(props) {
    super(props);

    const isMobile = ['xs', 'sm', 'md'].includes(props.width);

    if(props.dashboard.isOpen == null) {
      this.state = {
        isOpen: !isMobile
      };
    }
    else {
      this.state = {
        isOpen: props.dashboard.isOpen
      };
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false }, () => {
      const newState = { ...this.state };
      this.props.setDashboard(newState);
    });
  };

  handleToggleOpen = () => {
    this.setState(
      prevState => ({
        isOpen: !prevState.isOpen
      }),
      () => {
        const newState = { ...this.state };
        this.props.setDashboard(newState);
      }
    );
  };

  render() {
    const { classes, width, title, children } = this.props;
    const { isOpen } = this.state;

    const isMobile = ['xs', 'sm', 'md'].includes(width);
    const shiftTopbar = isOpen && !isMobile;
    const shiftContent = isOpen && !isMobile;

    return (
      <Fragment>
        <Topbar
          className={classNames(classes.topbar, {
            [classes.topbarShift]: shiftTopbar
          })}
          isSidebarOpen={isOpen}
          onToggleSidebar={this.handleToggleOpen}
          title={title}
        />
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          onClose={this.handleClose}
          open={isOpen}
          variant={isMobile ? 'temporary' : 'persistent'}
        >
          <Sidebar className={classes.sidebar} />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: shiftContent
          })}
        >
          {children}
          <Footer />
        </main>
      </Fragment>
    );
  }
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    role: state.authorize.role,
    dashboard: state.layout.dashboard,
    user: state.authentication.user
  };
};

const mapDispatchToProps = {
  setDashboard
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  withWidth()
)(DashboardLayout);
