import React from 'react';

// Externals
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon
} from 'react-google-maps';

const BikesMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyA6jQzIU1OcQ7UWfmyOnq-o000grQ03ySo&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    center={props.marker && props.marker.lastKnownLocation
      ? 
      {
        lat: props.marker.lastKnownLocation.location.coordinates[0],
        lng: props.marker.lastKnownLocation.location.coordinates[1]
      }
      : 
      {
        lat: 50.85045,
        lng: 4.34878
      }
    }
    defaultZoom={props.marker && props.marker.lastKnownLocation ? 15 : 10}
  >
    {props.zones &&
      props.zones.map(
        (zone, index) => 
          zone.polygon.coordinates && (
            <Polygon
              key={zone._id}
              label={zone.name}
              options={{
                strokeColor: props.zoneColors && props.zoneColors[index] ? props.zoneColors[index] : '#ffffffff',
                fillColor: props.zoneColors && props.zoneColors[index] ? props.zoneColors[index] : 'ffffff99',
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillOpacity: 0.5
              }}
              path={coordinatesToLatLng(zone.polygon.coordinates)}
            />
          )
      )}
    <Marker
      draggable
      // key={props.marker._id}
      onDragEnd={e => props.changeLocation([e.latLng.lat(), e.latLng.lng()])}
      position={props.marker && props.marker.lastKnownLocation
        ? 
        {
          lat: props.marker.lastKnownLocation.location.coordinates[0],
          lng: props.marker.lastKnownLocation.location.coordinates[1]
        }
        : 
        {
          lat: 50.85045,
          lng: 4.34878
        }
      }
    />
  </GoogleMap>
));

function coordinatesToLatLng(coordinates) {
  let paths = [];

  coordinates.forEach(coordinate => 
    coordinate.forEach(point => 
      paths.push({lat : point[0], lng : point[1]})
    )
  );

  return paths;
}

export default BikesMap;
