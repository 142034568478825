import React from 'react'
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import queryString from 'query-string';

const AnonymousRoute = ({component: Component, ...rest}) => {
  return <Route
    {...rest}
    render={props => {
      if(!rest.isAuthenticated || !rest.role) {
        return <Component {...props} />;
      }
      else{
        const parsed = queryString.parse(props.location.search);
        return <Redirect
          to={{
            pathname : parsed.redirect || '/',
            state : {from: props.location}
          }}   />
      }
    }}   />
}

AnonymousRoute.props = {
  location : PropTypes.object,
  component : PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated : state.authentication.isAuthenticated,
    role : state.authorize.role
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousRoute)