import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';

// Shared components
import SearchInput from 'components/SearchInput';

// Shared services
import { deleteUsers } from 'store/user/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class AllUsersToolbar extends Component {
  handleDeleteUsers = async () => {
    const { onSelect, selectedUsers } = this.props;

    const { error } = await this.props.deleteUsers(selectedUsers);
    if (error) {
      this.setState({
        submitError: error
      });

      return;
    } 
      
    onSelect([]);
  }

  render() {
    const { classes, className, selectedUsers, strings } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput className={classes.searchInput} onChange={this.props.onFilterChange} placeholder={strings.search} />
          <span className={classes.spacer} />
          {selectedUsers.length > 0 && (
            <Button className={classes.deleteButton} onClick={this.handleDeleteUsers} size="small" variant="contained">
              <DeleteIcon className={classes.deleteIcon} /> {strings.common.delete}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

AllUsersToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func,
  selectedUsers: PropTypes.array,
  strings: PropTypes.object.isRequired
};

AllUsersToolbar.defaultProps = {
  onFilterChange: () => {},
  onSelect: () => {},
  selectedUsers: []
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  deleteUsers
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('AllUsersToolbar'),
  withStyles(styles)
)(AllUsersToolbar);
