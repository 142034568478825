export default theme => ({
  dialogTitle: {
    '& h6': {
      fontSize: '20px'
    }
  },
  dialogLoader: {
    marginTop: theme.spacing.unit * 2
  },
  dialogForm: {
    marginTop: theme.spacing.unit * 2
  },
  field: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1
  },
  textField: {
    width: '100%',
    marginRight: theme.spacing.unit * 3
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  }
});
