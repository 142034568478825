import { default as React } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import en from '../i18n/en';
import fr from '../i18n/fr';
import nl from '../i18n/nl';

const languages = {
  en,
  fr,
  nl
};

export default function translate(key) {
  return (Component) => {
    class TranslationComponent extends React.Component {
      render() {
        var frStrings = {
          ...languages['fr'][key],
          ...languages['fr']['Common']
        };

        var strings = {
          ...languages[this.context.currentLanguage][key],
          ...languages[this.context.currentLanguage]['Common']
        };

        const mergedStrings = _.merge(frStrings, strings);

        return <Component {...this.props} {...this.state} strings={mergedStrings} />;
      }
    }

    TranslationComponent.contextTypes = {
      currentLanguage: PropTypes.string
    };

    return TranslationComponent;
  };
}
