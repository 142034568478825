import actions from './actions';

const initState = {
  loading: false,
  bookings: [],
  rents: []
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.START_FETCH_BOOKINGS:
      return { ...state, loading: true };
    case actions.END_FETCH_BOOKINGS:
      return { ...state, loading: false };
    case actions.SET_BOOKINGS:
      return { ...state, bookings: action.value };
    case actions.START_FETCH_RENTS:
      return { ...state, loading: true };
    case actions.END_FETCH_RENTS:
      return { ...state, loading: false };
    case actions.SET_RENTS:
      return { ...state, rents: action.value };
    default:
      return state;
  }
};

export default reducer;
