import actions from './actions';

import * as rentService from 'services/rent';

// eslint-disable-next-line no-unused-vars
export const fetchBookings = () => async (dispatch, getState) => {
  // if (getState().rent.bookings.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_BOOKINGS });

  try {
    const organization = getState().organization.currentOrganization;

    const bookings = await rentService.getBookings(organization._id);

    dispatch({
      type: actions.SET_BOOKINGS,
      value: bookings
    });

    dispatch({ type: actions.END_FETCH_BOOKINGS });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_BOOKINGS });
  }
};

// eslint-disable-next-line no-unused-vars
export const fetchRents = () => async (dispatch, getState) => {
  // if (getState().rent.rents.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_RENTS });

  try {
    const organization = getState().organization.currentOrganization;

    const rents = await rentService.getRents(organization._id);

    dispatch({
      type: actions.SET_RENTS,
      value: rents
    });

    dispatch({ type: actions.END_FETCH_RENTS });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_RENTS });
  }
};
