const actions = {
  START_FETCH_PRICES: 'price/START_FETCH_PRICES',
  END_FETCH_PRICES: 'price/END_FETCH_PRICES',
  START_ADD_PRICE: 'price/START_ADD_PRICE',
  END_ADD_PRICE: 'price/END_ADD_PRICE',
  START_DELETING_PRICE: 'price/START_DELETING_PRICE',
  END_DELETING_PRICE: 'price/END_DELETING_PRICE',
  SET_PRICES: 'price/SET_PRICES'
};

export default actions;
