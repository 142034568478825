import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

// Shared components
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';
import UserBadge from 'components/UserBadge';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class MessagesTable extends Component {
  state = {
    selectedMessages: [],
    rowsPerPage: 10,
    page: 0
  };

  handleSelectAll = event => {
    const { messages, onSelect } = this.props;

    let selectedMessages;

    if (event.target.checked) {
      selectedMessages = messages.map(c => c._id);
    } else {
      selectedMessages = [];
    }

    this.setState({ selectedMessages });

    onSelect(selectedMessages);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedMessages } = this.state;

    const selectedIndex = selectedMessages.indexOf(id);
    let newSelectedMessages = [];

    if (selectedIndex === -1) {
      newSelectedMessages = newSelectedMessages.concat(selectedMessages, id);
    } else if (selectedIndex === 0) {
      newSelectedMessages = newSelectedMessages.concat(
        selectedMessages.slice(1)
      );
    } else if (selectedIndex === selectedMessages.length - 1) {
      newSelectedMessages = newSelectedMessages.concat(
        selectedMessages.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedMessages = newSelectedMessages.concat(
        selectedMessages.slice(0, selectedIndex),
        selectedMessages.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedMessages: newSelectedMessages });

    onSelect(newSelectedMessages);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, className, messages, strings } = this.props;
    const { selectedMessages, rowsPerPage, page } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedMessages.length === messages.length}
                      color="primary"
                      indeterminate={
                        selectedMessages.length > 0 &&
                        selectedMessages.length < messages.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="left" padding="none">
                    {strings.name}
                  </TableCell>
                  <TableCell align="left">{strings.profile}</TableCell>
                  <TableCell align="left">{strings.city}</TableCell>
                  <TableCell align="left">{strings.message}</TableCell>
                  <TableCell align="left">{strings.createdAt}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map(message => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={message._id}
                      selected={selectedMessages.indexOf(message._id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <div className={classes.tableCellInner}>
                          <Checkbox
                            checked={
                              selectedMessages.indexOf(message._id) !== -1
                            }
                            color="primary"
                            onChange={event =>
                              this.handleSelectOne(event, message._id)
                            }
                            value="true"
                          />
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell} padding="none">
                        <UserBadge
                          user={{
                            name: message.name,
                            email: message.email
                          }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {message.profile}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {message.city}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {message.message}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {moment(message.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            backIconButtonProps={{
              'aria-label': strings.common.previousPage
            }}
            component="div"
            count={messages.length}
            labelDisplayedRows={({ from, to, count }) => {
              return from + '-' + to + ' ' + strings.common.of + ' ' + count;
            }}
            labelRowsPerPage={strings.common.rowsPerPage}
            nextIconButtonProps={{
              'aria-label': strings.common.nextPage
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

MessagesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func
};

MessagesTable.defaultProps = {
  messages: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

const mapStateToProps = state => {
  return {
    role: state.authorize.role
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('MessagesTable'),
  withStyles(styles)
)(MessagesTable);
