import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// Material icons
import FavoriteIcon from '@material-ui/icons/Favorite';

// Component styles
import styles from './styles';

class Footer extends Component {
  render() {
    const { classes, className } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <Divider />
        <Typography className={classes.company} variant="body1">
          &copy; ShareABike. {new Date(Date.now()).getFullYear()}
        </Typography>
        <Typography variant="caption">
          Made with{' '}
          <span>
            <FavoriteIcon className={classes.favorite} />
          </span>{' '}
          by{' '}
          <a
            href="https://www.sparkle.tech/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Sparkle
          </a>
        </Typography>
      </div>
    );
  }
}

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
