export default {
  firstname: {
    presence: { allowEmpty: false }
  },
  name: {
    presence: { allowEmpty: false }
  },
  email: {
    email: true,
    presence: { allowEmpty: false }
  }
};
