const actions = {
  START_FETCH_BIKETYPES: 'bikeType/START_FETCH_BIKETYPES',
  END_FETCH_BIKETYPES: 'bikeType/END_FETCH_BIKETYPES',
  START_DELETING_BIKETYPES: 'bikeType/START_DELETING_BIKETYPES',
  END_DELETING_BIKETYPES: 'bikeType/END_DELETING_BIKETYPES',
  START_ADD_BIKETYPE: 'bikeType/START_ADD_BIKETYPE',
  END_ADD_BIKETYPE: 'bikeType/END_ADD_BIKETYPE',
  START_FETCH_BIKETYPE: 'bike/START_FETCH_BIKETYPE',
  END_FETCH_BIKETYPE: 'bike/END_FETCH_BIKETYPE',
  START_UPDATE_BIKETYPE: 'bike/START_UPDATE_BIKETYPE',
  END_UPDATE_BIKETYPE: 'bike/END_UPDATE_BIKETYPE',
  SET_BIKETYPES: 'bikeType/SET_BIKETYPES',
  SET_BIKETYPE: 'bikeType/SET_BIKETYPE'
};

export default actions;
