import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/EditOutlined';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletToolbar from 'components/PortletToolbar';
import PortletContent from 'components/PortletContent';

// Shared services
import { deletePricingPlans } from 'store/pricingPlan/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class PricingPlansTable extends Component {
  state = {
    selectedPricingPlans: []
  };

  handleDeletePrices = async () => {
    const { onSelect } = this.props;

    const { error } = await this.props.deletePricingPlans(this.state.selectedPricingPlans);
    if (error) {
      this.setState({
        submitError: error
      });

      return;
    } 
      
    onSelect([]);
  };

  handleSelectAll = event => {
    const { pricingPlans, onSelect } = this.props;

    let selectedPricingPlans;

    if (event.target.checked) {
      selectedPricingPlans = pricingPlans.map(pricingPlan => pricingPlan._id);
    } else {
      selectedPricingPlans = [];
    }

    this.setState({ selectedPricingPlans });

    onSelect(selectedPricingPlans);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedPricingPlans } = this.state;

    const selectedIndex = selectedPricingPlans.indexOf(id);
    let newSelectedPricingPlans = [];

    if (selectedIndex === -1) {
      newSelectedPricingPlans = newSelectedPricingPlans.concat(
        selectedPricingPlans,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedPricingPlans = newSelectedPricingPlans.concat(
        selectedPricingPlans.slice(1)
      );
    } else if (selectedIndex === selectedPricingPlans.length - 1) {
      newSelectedPricingPlans = newSelectedPricingPlans.concat(
        selectedPricingPlans.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedPricingPlans = newSelectedPricingPlans.concat(
        selectedPricingPlans.slice(0, selectedIndex),
        selectedPricingPlans.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedPricingPlans: newSelectedPricingPlans });
    onSelect(newSelectedPricingPlans);
  };

  render() {
    const { classes, className, pricingPlans, strings } = this.props;
    const { selectedPricingPlans } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel
            subtitle={`${pricingPlans.length} ${strings.common.total}`}
            title={strings.title}
          />
          <PortletToolbar>
            {selectedPricingPlans.length > 0 && (
              <Button
                className={classes.deleteButton}
                color="default"
                onClick={this.handleDeletePrices}
                size="small"
                variant="outlined"
              >
                <DeleteIcon className={classes.deleteIcon} /> {strings.delete}
              </Button>
            )}
          </PortletToolbar>
        </PortletHeader>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedPricingPlans.length === pricingPlans.length
                      }
                      color="primary"
                      indeterminate={
                        selectedPricingPlans.length > 0 &&
                        selectedPricingPlans.length < pricingPlans.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="center">{strings.actions}</TableCell>
                  <TableCell align="left" padding="none">{strings.name}</TableCell>
                  <TableCell align="left">
                    {strings.bookingCost}
                  </TableCell>
                  <TableCell align="left">
                    {strings.firstHalfHour}
                  </TableCell>
                  <TableCell align="left">
                    {strings.secondHalfHour}
                  </TableCell>
                  <TableCell align="left">
                    {strings.thirdHalfHour}
                  </TableCell>
                  <TableCell align="left">
                    {strings.nextHalfHours}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricingPlans.map(pricingPlan => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={pricingPlan._id}
                    selected={
                      selectedPricingPlans.indexOf(pricingPlan._id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <div className={classes.tableCellInner}>
                        <Checkbox
                          checked={
                            selectedPricingPlans.indexOf(pricingPlan._id) !== -1
                          }
                          color="primary"
                          onChange={event =>
                            this.handleSelectOne(event, pricingPlan._id)
                          }
                          value="true"
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <Link to={'/pricing-plan/' + pricingPlan._id}>
                        <IconButton title={strings.pricingplandetails}>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell} padding="none">
                      <Typography className={classes.nameText} variant="body1">
                        {pricingPlan.name ? pricingPlan.name : '/'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {pricingPlan.bookingCost ? pricingPlan.bookingCost + ' ' + strings.credit : '/' }
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {pricingPlan.firstHalfHour ? pricingPlan.firstHalfHour + ' ' + strings.credit : '/' }
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {pricingPlan.secondHalfHour ? pricingPlan.secondHalfHour + ' ' + strings.credit : '/' }
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {pricingPlan.thirdHalfHour ? pricingPlan.thirdHalfHour + ' ' + strings.credit : '/' }
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {pricingPlan.nextHalfHours ? pricingPlan.nextHalfHours + ' ' + strings.credit : '/' }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    );
  }
}

PricingPlansTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  pricingPlans: PropTypes.array.isRequired,
  strings: PropTypes.object.isRequired
};

PricingPlansTable.defaultProps = {
  onSelect: () => {},
  pricingPlans: []
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.pricingPlan.loading
  };
};

const mapDispatchToProps = {
  deletePricingPlans
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('PricingPlansTable'),
  withStyles(styles)
)(PricingPlansTable);
