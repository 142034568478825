export default {
  AddBikeDialog: {
    title: 'Add a bike',
    subtitle: 'To add a bike, please fill in the fields below. The fields marked with an asterisk are required.',
    name: {
      label: 'Name',
      error: 'The name of the bike is required'
    },
    type: {
      label: 'Type',
      error: 'The type of the bike is required'
    },
    description: {
      label: 'Description'
    },
    padlockKind: {
      label: 'Padlock kind',
      error: 'The kind of padlock is required'
    },
    padlockMac: {
      label: 'Padlock MAC address',
      error: 'The padlock MAC address is required'
    },
    imageUrl: {
      label: 'Link of the image'
    },
    errors: {
      unknownError: "An error occurred during the bike's registration."
    },
    cancel: 'Cancel',
    add: 'Add'
  },
  AddBikeTypeDialog: {
    title: 'Add a type of bike',
    subtitle:
      'To add a type of bike, please fill in the fields below. The fields marked with an asterisk are required.',
    name: {
      label: 'Name',
      error: 'The name of the type of bike is required'
    },
    description: {
      label: 'Description'
    },
    pricingPlan: {
      label: 'Pricing Plan',
      error: 'The pricing plan of the type of bike is required'
    },
    errors: {
      unknownError: "An error occurred during the type of bike's registration."
    },
    cancel: 'Cancel',
    add: 'Add'
  },
  AddOrganizationDialog: {
    title: 'Add an organization',
    subtitle:
      'To add an organization, please complete the fields below. The fields marked with an asterisk are required.',
    name: {
      label: 'Name',
      error: 'The name is required'
    },
    isPublic: {
      label: 'The organization is public',
      error: 'The field is required'
    },
    bookingMaxDurationInMinutes: {
      label: 'Maximum duration of bookings'
    },
    simultaneousBookingMaxCount: {
      label: 'Maximum number of bookings simultaneous'
    },
    rentMaxDurationInMinutes: {
      label: 'Maximum duration of rentals'
    },
    simultaneousRentMaxCount: {
      label: 'Maximum number of rentals simultaneous'
    },
    errors: {
      unknownError: "An error occurred during the organization's registration."
    },
    cancel: 'Cancel',
    add: 'Add'
  },
  AddPricingPlanDialog: {
    title: 'Add a pricing plan',
    subtitle:
      'To add a pricing plan, please complete the fields below. The fields marked with an asterisk are required.',
    name: {
      label: 'Name',
      error: 'The name of the pricing plan is required'
    },
    bookingCost: {
      label: 'Cost of booking',
      error: 'The cost of booking of the pricing plan is required'
    },
    firstHalfHour: {
      label: 'The first half-hour',
      error: 'The first half-hour of the pricing plan is required'
    },
    secondHalfHour: {
      label: 'Second half-hour',
      error: 'The second half-hour of the pricing plan is required'
    },
    thirdHalfHour: {
      label: 'Third half-hour',
      error: 'The third half-hour of the pricing plan is required'
    },
    nextHalfHours: {
      label: 'Next half-hours ',
      error: 'The next half-hours of the pricing plan is required'
    },
    errors: {
      unknownError: "An error occurred during the pricing plan's registration."
    },
    cancel: 'Cancel',
    add: 'Add'
  },
  AddUserDialog: {
    roles: {
      User: 'User',
      Operator: 'Operator'
    },
    firstname: {
      label: 'First name',
      error: 'The first name is required'
    },
    name: {
      label: 'Last name',
      error: 'The last name is required'
    },
    email: {
      label: 'Email',
      error: 'A valid email address is required'
    },
    roleLabel: 'Role selection',
    roleHelperText:
      'The role determines the access that the user will have. A simple application user or an operator with administration rights.',
    subtitle:
      'If the invited user already has an account, a right of access to him will be given for this organization. In the contrary case, it will be prompted to create a user account on ShareABike.',
    errors: {
      HasPendingAccess: 'This user has already been invited and has not yet accepted the invitation.',
      HasAccess: 'This user already has access.',
      UnknownError: 'An error occurred during the invitation.'
    },
    title: 'Invite a user'
  },
  AddBikeType: {
    title: 'Add a type of bike',
    name: {
      fr: {
        label: 'Name in French',
        error: 'The name in French is required'
      },
      en: {
        label: 'Name in English',
        error: 'The name in English is required'
      },
      nl: {
        label: 'Name in Dutch',
        error: 'The name in Dutch is required'
      }
    },
    description: {
      fr: {
        label: 'Description in French',
        error: 'The description in French is required'
      },
      en: {
        label: 'Description in English',
        error: 'The description in English is required'
      },
      nl: {
        label: 'Description in Dutch',
        error: 'The description in Dutch is required'
      }
    },
    pricingPlan: {
      label: 'Pricing Plan',
      error: 'The pricing plan is required'
    },
    errors: {
      unknownError: "An error occurred during the type of bike's registration."
    },
    add: 'Add'
  },
  AddPrice: {
    title: 'Add a tariff',
    price: {
      label: 'Price',
      error: 'The price is required'
    },
    credit: {
      label: 'Amount of credits',
      error: 'The amount of credits is required'
    },
    errors: {
      unknownError: "An error occurred during the tariff's registration."
    },
    add: 'Add'
  },
  AddPricingPlan: {
    title: 'Add a pricing plan',
    name: {
      label: 'Name',
      error: 'The name of the pricing plan is required'
    },
    bookingCost: {
      label: 'Cost of booking',
      error: 'The cost of booking of the pricing plan is required'
    },
    firstHalfHour: {
      label: 'The first half-hour',
      error: 'The first half-hour of the pricing plan is required'
    },
    secondHalfHour: {
      label: 'Second half-hour',
      error: 'The second half-hour of the pricing plan is required'
    },
    thirdHalfHour: {
      label: 'Third half-hour',
      error: 'The third half-hour of the pricing plan is required'
    },
    nextHalfHours: {
      label: 'Next half-hours ',
      error: 'The next half-hours of the pricing plan is required'
    },
    errors: {
      unknownError: "An error occurred during the pricing plan's registration."
    },
    add: 'Add'
  },
  BikeDetails: {
    title: 'Details',
    portletTitle: 'Bike details',
    portletSubtitle: 'The information below can be edited',
    imageUrl: 'Link of the image',
    isDefective: 'This bike is faulty',
    endRent: 'End rent',
    name: {
      fr: {
        label: 'Name in French',
        error: 'The name in French is required'
      },
      en: {
        label: 'Name in English',
        error: 'The name in English is required'
      },
      nl: {
        label: 'Dutch name',
        error: 'The name in Dutch is required'
      }
    },
    state: {
      label: 'State',
      error: 'The state is required'
    },
    type: {
      label: 'Type',
      error: 'The type of the bike is required'
    },
    padlockKind: {
      label: 'Padlock kind',
      error: 'The kind of padlock is required'
    },
    padlockMac: {
      label: 'Padlock MAC address',
      error: 'The padlock MAC address is required'
    },
    errors: {
      unknownError: 'An error occurred while saving changes.',
      fetchError: 'An error occurred, the details of this bike can not be displayed.',
      endRentError: 'An error occurred while stoping rent.'
    },
    success: 'The changes have been saved',
    save: 'Save the changes'
  },
  BikeList: {
    title: 'Bikes',
    nothing: 'No bike listed'
  },
  BikesTable: {
    name: 'Name',
    type: 'Type',
    description: 'Description',
    pricingPlan: 'Pricing Plan',
    state: 'State',
    actions: 'Actions',
    unknown: 'Unknown'
  },
  BikesToolbar: {
    addBike: 'Add a bike',
    search: 'Search for a bike',
    delete: 'Delete'
  },
  BikeTypeList: {
    title: 'Type of bike',
    nothing: 'No type of bike listed'
  },
  BikeTypesTable: {
    title: 'List of types of bike',
    name: 'Name',
    description: 'Description',
    pricingPlan: 'Pricing Plan',
    delete: 'Delete'
  },
  BookingsTable: {
    startDate: 'The start of the booking',
    endDate: 'Completion of the booking',
    bike: 'Bike',
    price: 'Cost',
    user: 'User'
  },
  Common: {
    common: {
      back: 'Return',
      name: 'Last name',
      firstname: 'First name',
      cancel: 'Cancel',
      delete: 'Delete',
      previousPage: 'Previous Page',
      nextPage: 'Next Page',
      to: 'to',
      of: 'of',
      total: 'in total',
      rowsPerPage: 'Number of lines per page:',
      available: 'Available',
      booked: 'Reserved',
      rented: 'Rented',
      toRelocate: 'To relocate',
      relocating: 'Transfer',
      repairing: 'Repair',
      unavailable: 'Unavailable',
      yes: 'Yes',
      no: 'Not',
      administrator: 'Administrator',
      operator: 'Operator'
    }
  },
  InvitationList: {
    nothing: 'No invitation listed'
  },
  InvitationsTable: {
    invitationDate: 'Date of the invitation'
  },
  InvitationsToolbar: {
    search: 'Search for a invitation'
  },
  LanguageSwitch: {
    title: 'Choice of language',
    subtitle: 'Select the language with which you want to work.',
    nothing: 'There is nothing here...',
    languages: {
      en: 'English',
      fr: 'French',
      nl: 'Dutch'
    }
  },
  OrganizationDetails: {
    title: 'Details',
    bookings: 'Booking',
    rentings: 'Rentals',
    access: 'Access management',
    domainAccess: 'Access by domain',
    portletTitle: 'Details of the organization',
    portletSubtitle: 'The information below can be edited',
    zonesTitle: 'Management areas',
    zonesSubtitle: 'Change the areas of use of the bicycle',
    name: {
      label: 'Name',
      error: 'The name is required'
    },
    isPublic: {
      label: 'The organization is public',
      error: 'The field is required'
    },
    bookingMaxDurationInMinutes: {
      label: 'Maximum duration of bookings'
    },
    simultaneousBookingMaxCount: {
      label: 'Maximum number of bookings simultaneous'
    },
    rentMaxDurationInMinutes: {
      label: 'Maximum duration of rentals'
    },
    simultaneousRentMaxCount: {
      label: 'Maximum number of rentals simultaneous'
    },
    accessLevel: {
      label: 'Required access level'
    },
    accessDomains: {
      label: "Domain's name",
      description: 'New registrants using one of the specified domain names will have access to this organization'
    },
    errors: {
      unknownError: 'An error occurred while saving changes.',
      fetchError: 'An error occurred, the details of this organization cannot be displayed.'
    },
    success: 'The changes have been saved',
    save: 'Save the changes',
    accessLevels: {
      basic: 'Basic',
      complete: 'Full',
      creditcard: 'Credit card'
    }
  },
  OrganizationList: {
    title: 'Organizations',
    nothing: 'No organization listed'
  },
  OrganizationSwitch: {
    title: 'Choice of the organization',
    subtitle: 'Select the organization with which you want to work.',
    nothing: 'There is nothing here...'
  },
  OrganizationsTable: {
    name: 'Name',
    isPublic: 'Is public',
    bookingMaxDurationInMinutes: 'Maximum duration of bookings',
    simultaneousBookingMaxCount: 'Maximum number of bookings simultaneous',
    rentMaxDurationInMinutes: 'Maximum duration of rentals',
    simultaneousRentMaxCount: 'Maximum number of rentals simultaneous'
  },
  OrganizationsToolbar: {
    addOrganization: 'Add an organization',
    search: 'Search for an organization',
    delete: 'Delete'
  },
  PriceList: {
    title: 'Tariff',
    nothing: 'No tariff listed'
  },
  PricesTable: {
    title: 'Tariff list',
    amountcredit: 'Amount of credits',
    credit: 'credit(s)',
    delete: 'Delete',
    price: 'Price'
  },
  PricingPlanList: {
    title: 'Pricing Plan',
    nothing: 'No pricing plan listed'
  },
  PricingPlansTable: {
    title: 'List of pricing plans',
    name: 'Name',
    bookingCost: 'Cost of booking',
    firstHalfHour: 'The first half-hour',
    secondHalfHour: 'Second half-hour',
    thirdHalfHour: 'Third half-hour',
    nextHalfHours: 'Next half-hours',
    credit: 'credit(s)',
    delete: 'Delete'
  },
  RentList: {
    title: 'Rents',
    bookings: 'Bookings',
    rentals: 'Rentals',
    nothing: {
      rents: 'No rental listed',
      bookings: 'No booking listed'
    }
  },
  RentsTable: {
    startDate: 'The beginning of the rents',
    endDate: 'End of the rents',
    bike: 'Bike',
    price: 'Cost',
    user: 'User'
  },
  SignIn: {
    quote: {
      text: '“Life is like a bicycle, it is necessary to advance not to lose balance.”',
      author: 'Albert Einstein',
      bio: 'Theoretical physicist'
    },
    title: 'Sign In',
    subtitle: 'Use your email address',
    email: {
      label: 'Email address',
      error: 'The email address is required'
    },
    password: {
      label: 'Password',
      error: 'The password is required'
    },
    action: 'Sign In',
    errors: {
      unknownError: 'An error occurred, please try again later.',
      wrongCredentialsError: 'Your username or your password is incorrect.',
      notEnoughPrivilegesError: 'You do not have the necessary rights to sign in.'
    }
  },
  Sidebar: {
    administrator: 'Administrator',
    manager: 'Operator',
    users: 'Users',
    bikes: 'Bikes',
    bikeList: 'List of bikes',
    bikeTypes: 'Type of bike',
    pricingPlans: 'Pricing plan',
    rentals: 'Rentals',
    settings: 'Settings',
    administration: 'Administration',
    organization: 'Organization',
    organizations: 'Organizations',
    prices: 'Tariff',
    support: 'Customer support',
    invitationList: 'List of invitations',
    userList: 'List of users'
  },
  UserDetails: {
    title: 'Details',
    portletTitle: 'Details of the user',
    firstname: {
      label: 'First name',
      error: 'The first name is required'
    },
    name: {
      label: 'Name',
      error: 'The name is required'
    },
    email: {
      label: 'Email',
      error: 'The email is required'
    },
    phoneNumber: {
      label: 'Phone number'
    },
    birthYear: {
      label: 'Year of birth'
    },
    street: {
      label: 'Street'
    },
    postalCode: {
      label: 'Postal Code'
    },
    city: {
      label: 'City'
    },
    gender: {
      label: 'Sex'
    },
    phoneType: {
      label: 'Type of smartphone'
    },
    balance: {
      label: 'Credits'
    },
    address: 'Address',
    personalDetails: 'Personal information',
    userSettings: 'User settings',
    errors: {
      unknownError: 'An error occurred while saving changes.',
      fetchError: 'An error occurred, the details of this user can not be displayed.'
    },
    success: 'The changes have been saved',
    save: 'Save the changes'
  },
  UserList: {
    title: 'Users',
    nothing: 'Any user listed'
  },
  UserProfile: {
    title: 'Votre profil'
  },
  UsersToolbar: {
    addUser: 'Add a user',
    search: 'Search for a user',
    invitations: 'Invitations'
  },
  UsersTable: {
    name: 'Name',
    email: 'Email',
    address: 'Address',
    phone: 'Phone',
    registrationDate: 'Date of registration'
  },
  ZonesTable: {
    name: 'Name',
    bikesCount: 'Number of bikes'
  },
  ZonesToolbar: {
    addZone: 'Draw a new zone',
    search: 'Find an area'
  }
};
