import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';

// Shared components
import SearchInput from 'components/SearchInput';

// Shared services
import { deleteUsersFromCurrentOrganization } from 'store/user/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class UsersToolbar extends Component {
  handleDeleteUsers = async () => {
    const { onSelect, selectedUsers } = this.props;

    const { error } = await this.props.deleteUsersFromCurrentOrganization(selectedUsers);
    if (error) {
      this.setState({
        submitError: error
      });

      return;
    }

    onSelect([]);
  };

  render() {
    const { classes, className, selectedUsers, openAddUserDialog, strings } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            onChange={this.props.onFilterChange}
            placeholder={strings.search}
          />
          <span className={classes.spacer} />
          {selectedUsers.length > 0 && (
            <Button className={classes.deleteButton} onClick={this.handleDeleteUsers} size="small" variant="contained">
              <DeleteIcon className={classes.deleteIcon} /> {strings.common.delete}
            </Button>
          )}
          <Button color="primary" onClick={openAddUserDialog} size="small" variant="contained">
            {strings.addUser}
          </Button>
        </div>
      </div>
    );
  }
}

UsersToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func,
  openAddUserDialog: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array,
  strings: PropTypes.object.isRequired
};

UsersToolbar.defaultProps = {
  onFilterChange: () => {},
  onSelect: () => {},
  selectedUsers: []
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  deleteUsersFromCurrentOrganization
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('UsersToolbar'),
  withStyles(styles)
)(UsersToolbar);
