import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material icons
import Comment from '@material-ui/icons/Comment';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import Status from 'components/Status';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// GeoLib
import isPointInPolygon from 'geolib/es/isPointInPolygon';

class ZonesTable extends Component {
  state = {
    selectedZones: []
  };

  handleSelectAll = (event) => {
    const { zones, onSelect } = this.props;

    let selectedZones;

    if (event.target.checked) {
      selectedZones = zones.map((zone) => zone._id);
    } else {
      selectedZones = [];
    }

    this.setState({ selectedZones });

    onSelect(selectedZones);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedZones } = this.state;

    const selectedIndex = selectedZones.indexOf(id);
    let newSelectedZones = [];

    if (selectedIndex === -1) {
      newSelectedZones = newSelectedZones.concat(selectedZones, id);
    } else if (selectedIndex === 0) {
      newSelectedZones = newSelectedZones.concat(selectedZones.slice(1));
    } else if (selectedIndex === selectedZones.length - 1) {
      newSelectedZones = newSelectedZones.concat(selectedZones.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedZones = newSelectedZones.concat(
        selectedZones.slice(0, selectedIndex),
        selectedZones.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedZones: newSelectedZones });

    onSelect(newSelectedZones);
  };

  render() {
    const { classes, className, zoneColors, zones, strings, bikes } = this.props;
    const { selectedZones } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel subtitle={`${zones.length} ${strings.common.total}`} title={strings.title} />
        </PortletHeader>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedZones.length === zones.length}
                      color="primary"
                      indeterminate={selectedZones.length > 0 && selectedZones.length < zones.length}
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="center">{strings.actions}</TableCell>
                  <TableCell align="left">{strings.name}</TableCell>
                  <TableCell align="left">{strings.bikesCount}</TableCell>
                  <TableCell align="center">{strings.color}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getZonesWithBikeCount(zones, bikes).map((zoneWithBike, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={zoneWithBike.zone._id}
                    selected={selectedZones.indexOf(zoneWithBike.zone._id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <div className={classes.tableCellInner}>
                        <Checkbox
                          checked={selectedZones.indexOf(zoneWithBike.zone._id) !== -1}
                          color="primary"
                          onChange={(event) => this.handleSelectOne(event, zoneWithBike.zone._id)}
                          value="true"
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <IconButton
                        onClick={() => this.props.openManageZoneNoteDialog(zoneWithBike.zone)}
                        title={strings.notes}
                      >
                        <Comment />
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {zoneWithBike.zone.name ? zoneWithBike.zone.name : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{zoneWithBike.bikeCount}</TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <Status color="custom" customColor={zoneColors[index]} size="sm" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    );
  }
}

function getZonesWithBikeCount(zones, bikes) {
  let zonesWithBikes = [];

  zones.forEach((zone) => {
    let bikeCount = 0;
    bikes.forEach((bike) => {
      if (
        bike &&
        bike.lastKnownLocation &&
        isPointInPolygon(bike.lastKnownLocation.location.coordinates, coordinatesToLatLng(zone.polygon.coordinates))
      )
        bikeCount += 1;
    });
    zonesWithBikes.push({ zone: zone, bikeCount: bikeCount });
  });

  return zonesWithBikes;
}

function coordinatesToLatLng(coordinates) {
  let paths = [];

  coordinates.forEach((coordinate) => coordinate.forEach((point) => paths.push([point[0], point[1]])));

  return paths;
}

ZonesTable.propTypes = {
  bikes: PropTypes.array.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  openManageZoneNoteDialog: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired,
  zoneColors: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired
};

ZonesTable.defaultProps = {
  onSelect: () => {},
  zones: [],
  zoneColors: [],
  bikes: []
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.bikeType.loading
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('ZonesTable'),
  withStyles(styles)
)(ZonesTable);
