import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

// Material icons
import EditIcon from '@material-ui/icons/EditOutlined';

// Shared components
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';

// Common
// import * as roles from 'common/roles';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

const ActiveChip = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
    border: '1px solid',
    color: theme.palette.success.dark
  }
}))(Chip);

const InactiveChip = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.danger.light,
    borderColor: theme.palette.danger.main,
    border: '1px solid',
    color: theme.palette.danger.dark
  }
}))(Chip);

class OrganizationsTable extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    selectedOrganizations: []
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleSelectAll = (event) => {
    const { organizations, onSelect } = this.props;

    let selectedOrganizations;

    if (event.target.checked) {
      selectedOrganizations = organizations.map((bike) => bike._id);
    } else {
      selectedOrganizations = [];
    }

    this.setState({ selectedOrganizations });

    onSelect(selectedOrganizations);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedOrganizations } = this.state;

    const selectedIndex = selectedOrganizations.indexOf(id);
    let newSelectedOrganizations = [];

    if (selectedIndex === -1) {
      newSelectedOrganizations = newSelectedOrganizations.concat(selectedOrganizations, id);
    } else if (selectedIndex === 0) {
      newSelectedOrganizations = newSelectedOrganizations.concat(selectedOrganizations.slice(1));
    } else if (selectedIndex === selectedOrganizations.length - 1) {
      newSelectedOrganizations = newSelectedOrganizations.concat(selectedOrganizations.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrganizations = newSelectedOrganizations.concat(
        selectedOrganizations.slice(0, selectedIndex),
        selectedOrganizations.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedOrganizations: newSelectedOrganizations });

    onSelect(newSelectedOrganizations);
  };

  render() {
    const { classes, className, organizations, strings } = this.props;
    const { page, rowsPerPage, selectedOrganizations } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedOrganizations.length === organizations.length}
                      color="primary"
                      indeterminate={
                        selectedOrganizations.length > 0 && selectedOrganizations.length < organizations.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="center">{strings.actions}</TableCell>
                  <TableCell align="left" padding="none">
                    {strings.name}
                  </TableCell>
                  <TableCell align="left">{strings.isPublic}</TableCell>
                  <TableCell align="left">{strings.bookingMaxDurationInMinutes}</TableCell>
                  <TableCell align="left">{strings.simultaneousBookingMaxCount}</TableCell>
                  <TableCell align="left">{strings.rentMaxDurationInMinutes}</TableCell>
                  <TableCell align="left">{strings.simultaneousRentMaxCount}</TableCell>
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizations.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((organization) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={organization._id}
                    selected={selectedOrganizations.indexOf(organization._id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <div className={classes.tableCellInner}>
                        <Checkbox
                          checked={selectedOrganizations.indexOf(organization._id) !== -1}
                          color="primary"
                          onChange={(event) => this.handleSelectOne(event, organization._id)}
                          value="true"
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <Link to={'/organization/' + organization._id}>
                        <IconButton title={strings.organizationdetails}>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell} padding="none">
                      <Typography className={classes.nameText} variant="body1">
                        {organization.name}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {organization.isPublic && organization.isPublic === true ? strings.common.yes : strings.common.no}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {organization.bookingMaxDurationInMinutes ? organization.bookingMaxDurationInMinutes : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {organization.simultaneousBookingMaxCount ? organization.simultaneousBookingMaxCount : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {organization.rentMaxDurationInMinutes ? organization.rentMaxDurationInMinutes : '/'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {organization.simultaneousRentMaxCount ? organization.simultaneousRentMaxCount : '/'}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {organization.isEnable ? (
                        <ActiveChip label="active" color="primary" variant="default" />
                      ) : (
                        <InactiveChip label="inactive" color="primary" variant="default" />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            backIconButtonProps={{
              'aria-label': strings.common.previousPage
            }}
            component="div"
            count={organizations.length}
            labelDisplayedRows={({ from, to, count }) => {
              return from + '-' + to + ' ' + strings.common.of + ' ' + count;
            }}
            labelRowsPerPage={strings.common.rowsPerPage}
            nextIconButtonProps={{
              'aria-label': strings.common.nextPage
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

OrganizationsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  organizations: PropTypes.array.isRequired,
  strings: PropTypes.object.isRequired
};

OrganizationsTable.defaultProps = {
  organizations: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

const mapStateToProps = (state) => {
  return {
    role: state.authorize.role
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('OrganizationsTable'),
  withStyles(styles)
)(OrganizationsTable);
