import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Doughnut } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Material icons
import BasicIcon from '@material-ui/icons/Brightness1';
import CompleteIcon from '@material-ui/icons/Check';
import CreditCardIcon from '@material-ui/icons/CreditCard';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';

// Shared services
import { fetchUsers } from 'store/user/actionsCreator';

// Palette
import palette from 'theme/palette';

// Chart configuration
import { data, options } from './chart';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class UsersChart extends Component {
  componentDidMount() {
    this.signal = true;
    this.props.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchUsers();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  renderUsersChart() {
    const { classes, className, isLoading, users, error, strings } = this.props;

    const rootClassName = classNames(classes.root, className);

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    let newData = data(users, strings);
    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel title={strings.title} />
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Doughnut data={newData} options={options} />
          </div>
          <div className={classes.stats}>
            <div className={classes.typeAccount}>
              <BasicIcon className={classes.typeAccountIcon} />
              <Typography variant="body1">{strings.basic}</Typography>
              <Typography style={{ color: palette.warning.main }} variant="h2">
                {newData.datasets[0].data[0]}%
              </Typography>
            </div>
            <div className={classes.typeAccount}>
              <CompleteIcon className={classes.typeAccountIcon} />
              <Typography variant="body1">{strings.complete}</Typography>
              <Typography style={{ color: palette.info.main }} variant="h2">
                {newData.datasets[0].data[1]}%
              </Typography>
            </div>
            <div className={classes.typeAccount}>
              <CreditCardIcon className={classes.typeAccountIcon} />
              <Typography variant="body1">{strings.creditcard}</Typography>
              <Typography style={{ color: palette.success.main }} variant="h2">
                {newData.datasets[0].data[2]}%
              </Typography>
            </div>
          </div>
        </PortletContent>
      </Portlet>
    );
  }

  render() {
    return <React.Fragment>{this.renderUsersChart()}</React.Fragment>;
  }
}

UsersChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.user.loading,
    users: state.user.users
  };
};

const mapDispatchToProps = {
  fetchUsers
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  translate('UsersChart')
)(UsersChart);
