import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';

// Material icons
import DeleteIcon from '@material-ui/icons/Delete';

// Shared components
import SearchInput from 'components/SearchInput';

// Common 
//import * as roles from 'common/roles';
// Shared services
import { deleteMessages  } from '../../../../store/contactForm/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

class MessagesToolbar extends Component {

  handleDeleteMessages = async () => {
    const { onSelect, selectedMessages } = this.props;

    await this.props.deleteMessages(selectedMessages, error => {
      if(error){
        this.setState({
          submitError: error
        });
      } else {
        onSelect([]);
      }
    })
  }

  render() {
    const { classes, className, selectedMessages, strings } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput className={classes.searchInput} onChange={this.props.onFilterChange} placeholder={strings.search} />
          <span className={classes.spacer} />
          {selectedMessages.length > 0 && (
            <Button className={classes.deleteButton} onClick={this.handleDeleteMessages} size="small" variant="contained">
              <DeleteIcon className={classes.deleteIcon} /> {strings.common.delete}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

MessagesToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func,
  onSelect: PropTypes.func,
  selectedMessages: PropTypes.array,
  strings: PropTypes.object.isRequired
};

MessagesToolbar.defaultProps = {
  onFilterChange: () => {},
  selectedMessages: []
};

const mapStateToProps = state => {
  return {
    role: state.authorize.role
  };
};

const mapDispatchToProps = {
  deleteMessages
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('MessagesToolbar'),
  withStyles(styles)
)(MessagesToolbar);
