import actions from './actions';

import * as pricingPlanService from 'services/pricingPlan';

// eslint-disable-next-line no-unused-vars
export const fetchPricingPlans = () => async (dispatch, getState) => {
  // if (getState().pricingPlan.pricingPlans.length > 0) {
  //   return;
  // }

  dispatch({ type: actions.START_FETCH_PRICINGPLANS });

  try {
    const organization = getState().organization.currentOrganization;

    const pricingPlans = await pricingPlanService.getPricingPlans(organization._id);

    dispatch({
      type: actions.SET_PRICINGPLANS,
      value: pricingPlans
    });

    dispatch({ type: actions.END_FETCH_PRICINGPLANS });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_PRICINGPLANS });
  }
};

// eslint-disable-next-line no-unused-vars
export const deletePricingPlans = (pricingPlansToDelele) => async (dispatch, getState) => {
  dispatch({ type: actions.START_DELETING_PRICINGPLANS });

  try {
    const organization = getState().organization.currentOrganization;

    const pricingPlans = await pricingPlanService.deletePricingPlans(organization._id, pricingPlansToDelele);

    dispatch({
      type: actions.SET_PRICINGPLANS,
      value: pricingPlans
    });

    dispatch({ type: actions.END_DELETING_PRICINGPLANS });
    return {error : null};
  } catch (error) {
    dispatch({ type: actions.END_DELETING_PRICINGPLANS });
    return {error : error};
  }
};

// eslint-disable-next-line no-unused-vars
export const addPricingPlan = (pricingPlan, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_ADD_PRICINGPLAN });

  try {
    const organization = getState().organization.currentOrganization;

    const pricingPlans = await pricingPlanService.addPricingPlan(organization._id, pricingPlan);

    dispatch({
      type: actions.SET_PRICINGPLANS,
      value: pricingPlans
    });

    dispatch({ type: actions.END_ADD_PRICINGPLAN });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_ADD_PRICINGPLAN });
    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const fetchPricingPlan = (pricingPlanId, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_FETCH_PRICINGPLAN });

  try {
    const organization = getState().organization.currentOrganization;

    const pricingPlan = await pricingPlanService.getPricingPlan(organization._id, pricingPlanId);

    dispatch({
      type: actions.SET_PRICINGPLAN,
      value: pricingPlan
    });

    dispatch({ type: actions.END_FETCH_PRICINGPLAN });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_FETCH_PRICINGPLAN });
    onFinished(error);
  }
};

// eslint-disable-next-line no-unused-vars
export const updatePricingPlan = (pricingPlanId, pricingPlanToUpdate, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_UPDATE_PRICINGPLAN });

  try {
    const organization = getState().organization.currentOrganization;

    const pricingPlan = await pricingPlanService.updatePricingPlan(organization._id, pricingPlanId, pricingPlanToUpdate);

    dispatch({
      type: actions.SET_PRICINGPLAN,
      value: pricingPlan
    });

    dispatch({ type: actions.END_UPDATE_PRICINGPLAN });
    onFinished();
  } catch (error) {
    dispatch({ type: actions.END_UPDATE_PRICINGPLAN });
    onFinished(error);
  }
};