import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Shared components
import Portlet from 'components/Portlet';

// Shared services
import { fetchMessages } from '../../store/contactForm/actionsCreator';

// Custom components
import MessagesToolbar from './components/MessagesToolbar';
import MessagesTable from './components/MessagesTable';

// Component styles
import styles from './style';

class ContactForm extends Component {
  signal = true;

  state = {
    limit: 10,
    messages: [],
    selectedMessages: [],
    error: null
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchMessages();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = selectedMessages => {
    this.setState({ selectedMessages });
  };

  handleFilterChange = event => {
    this.setState({
      filter: event.target.value
    });
  };

  getFilteredMessages = () => {
    const { messages } = this.props;
    const { filter } = this.state;
    if (!filter) {
      return messages;
    }
    const lowerFilter = filter;

    return messages.filter(c => {
      return (
        c.email.toLowerCase().indexOf(lowerFilter) >= 0 ||
        c.message.toLowerCase().indexOf(lowerFilter) >= 0
      );
    });
  };

  renderMessages() {
    const { classes, isLoading, messages, error, strings } = this.props;

    if (isLoading) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </Portlet>
      );
    }

    if (error) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{error}</Typography>
          </div>
        </Portlet>
      );
    }

    if (messages.length === 0) {
      return (
        <Portlet className={classes.root}>
          <div className={classes.textWrapper}>
            <Typography variant="h6">{strings.nothing}</Typography>
          </div>
        </Portlet>
      );
    }

    return (
      <MessagesTable
        messages={this.getFilteredMessages()}
        onSelect={this.handleSelect}
      />
    );
  }

  render() {
    const { classes, strings } = this.props;
    const { selectedMessages } = this.state;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <MessagesToolbar
            onFilterChange={this.handleFilterChange}
            onSelect={this.handleSelect}
            selectedMessages={selectedMessages}
          />
          <div className={classes.content}>{this.renderMessages()}</div>
        </div>
      </DashboardLayout>
    );
  }
}

ContactForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    isLoading: state.contactForm.loading,
    messages: state.contactForm.messages
  };
};

const mapDispatchToProps = {
  fetchMessages
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('ContactForm'),
  withStyles(styles)
)(ContactForm);
