import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';

// Shared services
import { addPrice } from 'store/price/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

class AddPrice extends Component {
  state = {
    values: {
      price: 0,
      credit: 0
    },
    touched: {
      price: false,
      credit: false
    },
    errors: {
      price: null,
      credit: null
    },
    isValid: false,
    submitError: null
  };

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleAdd = async () => {
    const { values } = this.state;
    const { strings } = this.props;

    await this.props.addPrice(values, error => {
      if (error) {
        this.setState({
          submitError: strings.errors.unknownError
        });
      } else {
        let values = { ...this.state.values };
        values.price = '';
        values.credit = '';
        let touched = { ...this.state.touched };
        touched.price = false;
        touched.credit = false;
        let errors = { ...this.state.errors };
        errors.price = null;
        errors.credit = null;
        this.setState({
          values,
          touched,
          errors,
          isValid: false,
          submitError: null
        });
      }
    });
  };

  render() {
    const { classes, className, strings } = this.props;

    const { values, touched, errors, isValid, submitError } = this.state;

    const rootClassName = classNames(classes.root, className);

    const showPriceError = touched.price && errors.price;
    const showCreditError = touched.credit && errors.credit;

    return (
      <Portlet className={rootClassName}>
        <PortletHeader>
          <PortletLabel title={strings.title} />
        </PortletHeader>
        <PortletContent>
          <form autoComplete="off" className={classes.form}>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  error={showPriceError ? true : false}
                  helperText={showPriceError ? strings.price.error : ''}
                  inputProps={{ min: '0', step: '1' }} 
                  label={strings.price.label}
                  margin="normal"
                  name="price"
                  onChange={event => this.handleChange('price', event.target.value)}
                  required
                  type="number"
                  value={values.price}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  error={showCreditError ? true : false}
                  helperText={showCreditError ? strings.credit.error : ''}
                  inputProps={{ min: '0', step: '1' }} 
                  label={strings.credit.label}
                  margin="normal"                  
                  name="credit"
                  onChange={event => this.handleChange('credit', event.target.value)}
                  required
                  type="number"
                  value={values.credit}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {submitError}
              </Typography>
            )}
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            disabled={!isValid}
            onClick={this.handleAdd}
            variant="contained"
          >
            {strings.add}
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

AddPrice.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.price.loading
  };
};

const mapDispatchToProps = {
  addPrice
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('AddPrice'),
  withStyles(styles)
)(AddPrice);
