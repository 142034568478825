import actions from './actions';

// eslint-disable-next-line no-unused-vars
export const setLanguage = language => async (dispatch, getState) => {
  localStorage.setItem('x-localization', language);
  
  return dispatch({
    type: actions.SET_LANGUAGE,
    value: language
  });
};
