import green from '@material-ui/core/colors/green';

export default (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh'
  },
  grid: {
    height: '100vh',
    '& > div': {
      overflow: 'auto'
    }
  },
  quoteWrapper: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    position: 'relative'
  },
  overlay: {
    zIndex: 10,
    backgroundColor: 'black',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  quote: {
    backgroundColor: theme.palette.common.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/sign_up.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    zIndex: 15,
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.common.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing.unit * 3,
    color: theme.palette.common.white
  },
  bio: {
    color: theme.palette.common.white
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 5,
    paddingBototm: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingBottom: '125px',
    flexBasis: '700px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    }
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  title: {
    marginTop: theme.spacing.unit * 3
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 0.5
  },
  fields: {
    marginTop: theme.spacing.unit * 2
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing.unit * 2
    }
  },
  textFieldContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.unit * 2
  },
  textFieldSpace: {
    padding: theme.spacing.unit * 0.5
  },
  submitError: {
    color: theme.palette.danger.main,
    marginTop: theme.spacing.unit * 2
  },
  stepper: {
    background: 'unset',
    padding: `${theme.spacing.unit * 2}px 0 0 0`
  },
  stepperLabel: {
    fontWeight: 500
  },
  wrapper: {
    flex: '1 1 0',
    display: 'flex',
    position: 'relative'
  },
  button: {
    flex: '1 1 0'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit * 2
  },
  buttonSpace: {
    padding: theme.spacing.unit * 0.5
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -(theme.spacing.unit * 2 + theme.spacing.unit / 2),
    marginLeft: -(theme.spacing.unit * 2 + theme.spacing.unit / 2)
  },
  snackbar: {
    margin: theme.spacing.unit * 3
  },
  snackbarContent: {
    backgroundColor: green[600]
  },
  snackbarClose: {
    padding: theme.spacing.unit
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing.unit
    }
  }
});
