import actions from './actions';

const initState = {
  loading: false,
  messages: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.START_FETCH_MESSAGES:
      return { ...state, loading: true };
    case actions.END_FETCH_MESSAGES:
      return { ...state, loading: false };
    case actions.START_DELETING_MESSAGES:
      return { ...state, loading: true };
    case actions.END_DELETING_MESSAGES: 
      return { ...state, loading: false };
    case actions.SET_MESSAGES:
      return { ...state, messages: action.value };
    default:
      return state;
  }
};

export default reducer;
