import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// Material components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared services
import {
  updateOrganization
} from 'store/organization/actionsCreator';

// Custom Components
import ZonesMap from '../ZonesMap';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

class AddZoneDialog extends Component {
  signal = true;

  state = {
    values: {
      name: '',
      center: {
        type: 'Point',
        coordinates: [0, 0]
      },
      polygon: {
        type: 'Polygon',
        coordinates: []
      }
    },
    touched: {
      name: false
    },
    errors: {
      name: null
    },
    isValid: false,
    submitError: null,
    canCreateZone: true
  };

  componentDidMount() {
    this.signal = true;
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values, canCreateZone } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors || canCreateZone ? false : true;

    this.setState(newState);
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleAdd = async () => {
    const { strings, organization, handleClose, refreshTable } = this.props;

    const { values } = this.state;

    if(organization.zones == null)
      organization.zones = [];
    
    organization.zones.push(values);

    await this.props.updateOrganization(
      this.props.organization._id,
      organization,
      error => {
        if (error) {
          this.setState({
            submitError: strings.errors.unknownError
          });
        } else {
          let values = { ...this.state.values };
          values.name = '';
          values.polygon.coordinates = [];
          let touched = { ...this.state.touched };
          touched.name = false;
          let errors = { ...this.state.errors };
          errors.name = null;
          this.setState({
            values,
            touched,
            errors,
            isValid: false,
            submitError: null,
            canCreateZone: true,
          });
          handleClose();
          refreshTable();
        }
      }
    );
  };

  handleClose = () => {
    let values = { ...this.state.values };
    values.name = '';
    values.polygon.coordinates = [];
    let touched = { ...this.state.touched };
    touched.name = false;
    let errors = { ...this.state.errors };
    errors.name = null;
    this.setState({
      values,
      touched,
      errors,
      isValid: false,
      submitError: null,
      canCreateZone: true,
    });
    this.props.handleClose();
  };

  onPolygonCompleted = polygon => {
    let coordinatesArray = [];
    const path = polygon.getPath();

    path.forEach((latlng) => {
      const coordinates = [latlng.lat(), latlng.lng()];
      coordinatesArray.push(coordinates)
    });

    const closingLatlng = path.getAt(0);
    const coordinates = [closingLatlng.lat(), closingLatlng.lng()];
    coordinatesArray.push(coordinates)

    const newState = { ...this.state };

    newState.values.polygon.coordinates.push(coordinatesArray);

    newState.canCreateZone = false;

    this.setState(newState);
    this.validateForm();
  };

  render() {
    const { classes, fullScreen, isLoading, organization, open, strings } = this.props;

    const { canCreateZone, values, touched, errors, isValid, submitError } = this.state;

    const showNameError = touched.name && errors.name;

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={this.handleClose}
        open={open}
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {strings.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.subtitle}</DialogContentText>
          {isLoading && <LinearProgress className={classes.dialogLoader} />}
          <form autoComplete="off" className={classes.dialogForm}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  error={showNameError ? true : false}
                  helperText={showNameError ? strings.name.error : ''}
                  label={strings.name.label}
                  margin="normal"
                  name="name"
                  onChange={event =>
                    this.handleChange('name', event.target.value)
                  }
                  required
                  type="text"
                  value={values.name}
                  variant="outlined"
                />
                <div className={classes.mapField}>
                  <ZonesMap
                    isDrawing={canCreateZone}
                    onPolygonCompleted={this.onPolygonCompleted}
                    zones={organization.zones}
                  />
                  {canCreateZone && (
                    <Typography className={classes.mapHelperText} variant="body2">
                      {strings.map.helper}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {strings.errors.unknownError}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {strings.cancel}
          </Button>
          <Button color="primary" disabled={!isValid} onClick={this.handleAdd}>
            {strings.add}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddZoneDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.organization.loading,
    organization: state.organization.organization
  };
};

const mapDispatchToProps = {
  updateOrganization
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('AddZoneDialog'),
  withMobileDialog(),
  withStyles(styles)
)(AddZoneDialog);
