import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// Material components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared services
import { addPricingPlan } from 'store/pricingPlan/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

class AddPricingPlanDialog extends Component {
  signal = true;

  state = {
    values: {
      name: '',
      bookingCost: 0,
      firstHalfHour: 0,
      secondHalfHour: 0,
      thirdHalfHour: 0,
      nextHalfHours: 0
    },
    touched: {
      name: false,
      bookingCost: false,
      firstHalfHour: false,
      secondHalfHour: false,
      thirdHalfHour: false,
      nextHalfHours: false
    },
    errors: {
      name: null,
      bookingCost: null,
      firstHalfHour: null,
      secondHalfHour: null,
      thirdHalfHour: null,
      nextHalfHours: null
    },
    isValid: false,
    submitError: null
  };

  componentDidMount() {
    this.signal = true;
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };
  
  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleAdd = async () => {
    const { values } = this.state;

    await this.props.addPricingPlan(values, error => {
      if (error) {
        this.setState({
          submitError: error
        });
      } else {
        let values = { ...this.state.values };
        values.name = '';
        values.bookingCost = '';
        values.firstHalfHour = 0;
        values.secondHalfHour = 0;
        values.thirdHalfHour = 0;
        values.nextHalfHours = 0;
        let touched = { ...this.state.touched };
        touched.name = false;
        touched.bookingCost = false;
        touched.firstHalfHour = false;
        touched.secondHalfHour = false;
        touched.thirdHalfHour = false;
        touched.nextHalfHours = false;
        let errors = { ...this.state.errors };
        errors.name = null;
        errors.bookingCost = null;
        errors.firstHalfHour = null;
        errors.secondHalfHour = null;
        errors.thirdHalfHour = null;
        errors.nextHalfHours = null;
        this.setState({
          values,
          touched,
          errors,
          isValid: false,
          submitError: null
        });
        this.props.handleClose();
      }
    });
  };

  handleClose = () => {
    let values = { ...this.state.values };
    values.name = '';
    values.bookingCost = '';
    values.firstHalfHour = 0;
    values.secondHalfHour = 0;
    values.thirdHalfHour = 0;
    values.nextHalfHours = 0;
    let touched = { ...this.state.touched };
    touched.name = false;
    touched.bookingCost = false;
    touched.firstHalfHour = false;
    touched.secondHalfHour = false;
    touched.thirdHalfHour = false;
    touched.nextHalfHours = false;
    let errors = { ...this.state.errors };
    errors.name = null;
    errors.bookingCost = null;
    errors.firstHalfHour = null;
    errors.secondHalfHour = null;
    errors.thirdHalfHour = null;
    errors.nextHalfHours = null;
    this.setState({
      values,
      touched,
      errors,
      isValid: false,
      submitError: null
    });
    this.props.handleClose();
  };

  render() {
    const { classes, fullScreen, isLoading, open, strings } = this.props;

    const { values, touched, errors, isValid, submitError } = this.state;

    const showNameError = touched.name && errors.name;
    const showBookingCostError = touched.bookingCost && errors.bookingCost;
    const showFirstHalfHourError =
      touched.firstHalfHour && errors.firstHalfHour;
    const showSecondHalfHourError =
      touched.secondHalfHour && errors.secondHalfHour;
    const showThirdHalfHourError =
      touched.thirdHalfHour && errors.thirdHalfHour;
    const showNextHalfHoursError =
      touched.nextHalfHours && errors.nextHalfHours;

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        onClose={this.handleClose}
        open={open}
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {strings.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.subtitle}</DialogContentText>
          {isLoading && <LinearProgress className={classes.dialogLoader} />}
          <form autoComplete="off" className={classes.dialogForm}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  error={showNameError ? true : false}
                  helperText={showNameError ? strings.name.error : ''}
                  label={strings.name.label}
                  margin="normal"
                  name="name"
                  onChange={event =>
                    this.handleChange('name', event.target.value)
                  }
                  required
                  type="text"
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={showBookingCostError ? true : false}
                  helperText={
                    showBookingCostError ? strings.bookingCost.error : ''
                  }
                  label={strings.bookingCost.label}
                  margin="normal"
                  name="bookingCost"
                  onChange={event =>
                    this.handleChange('bookingCost', event.target.value)
                  }
                  required
                  type="number"
                  value={values.bookingCost}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={showFirstHalfHourError ? true : false}
                  helperText={
                    showFirstHalfHourError ? strings.firstHalfHour.error : ''
                  }
                  label={strings.firstHalfHour.label}
                  margin="normal"
                  name="firstHalfHour"
                  onChange={event =>
                    this.handleChange('firstHalfHour', event.target.value)
                  }
                  required
                  type="number"
                  value={values.firstHalfHour}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={showSecondHalfHourError ? true : false}
                  helperText={
                    showSecondHalfHourError ? strings.secondHalfHour.error : ''
                  }
                  label={strings.secondHalfHour.label}
                  margin="normal"
                  name="secondHalfHour"
                  onChange={event =>
                    this.handleChange('secondHalfHour', event.target.value)
                  }
                  required
                  type="number"
                  value={values.secondHalfHour}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={showThirdHalfHourError ? true : false}
                  helperText={
                    showThirdHalfHourError ? strings.thirdHalfHour.error : ''
                  }
                  label={strings.thirdHalfHour.label}
                  margin="normal"
                  name="thirdHalfHour"
                  onChange={event =>
                    this.handleChange('thirdHalfHour', event.target.value)
                  }
                  required
                  type="number"
                  value={values.thirdHalfHour}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={showNextHalfHoursError ? true : false}
                  helperText={
                    showNextHalfHoursError ? strings.nextHalfHours.error : ''
                  }
                  label={strings.nextHalfHours.label}
                  margin="normal"
                  name="nextHalfHours"
                  onChange={event =>
                    this.handleChange('nextHalfHours', event.target.value)
                  }
                  required
                  type="number"
                  value={values.nextHalfHours}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {strings.errors.unknownError}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {strings.cancel}
          </Button>
          <Button color="primary" disabled={!isValid} onClick={this.handleAdd}>
            {strings.add}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddPricingPlanDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.pricingPlan.loading
  };
};

const mapDispatchToProps = {
  addPricingPlan
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('AddPricingPlanDialog'),
  withMobileDialog(),
  withStyles(styles)
)(AddPricingPlanDialog);
