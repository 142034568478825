import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// Material components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared services
import { addOrganization } from 'store/organization/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

class AddOrganizationDialog extends Component {
  signal = true;

  state = {
    values: {
      name: '',
      isPublic: false,
      bookingMaxDurationInMinutes: 30,
      simultaneousBookingMaxCount: 1,
      rentMaxDurationInMinutes: 480,
      simultaneousRentMaxCount: 1
    },
    touched: {
      name: false,
      isPublic: false,
    },
    errors: {
      name: null,
      isPublic: null,
    },
    isValid: false,
    submitError: null
  };

  componentDidMount() {
    this.signal = true;
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleAdd = async () => {
    const { values } = this.state;

    await this.props.addOrganization(values, error => {
      if (error) {
        this.setState({
          submitError: error
        });
      } else {
        let values = { ...this.state.values };
        values.name = '';
        values.isPublic = false;
        values.bookingMaxDurationInMinutes = 30;
        values.simultaneousBookingMaxCount = 1;
        values.rentMaxDurationInMinutes = 480;
        values.simultaneousRentMaxCount = 1;
        let touched = { ...this.state.touched };
        touched.name = false;
        touched.isPublic = false;
        let errors = { ...this.state.errors };
        touched.name = null;
        touched.isPublic = null;
        this.setState({
          values,
          touched,
          errors,
          isValid: false,
          submitError: null
        });
        this.props.handleClose();
      }
    });
  };

  handleClose = () => {
    let values = { ...this.state.values };
    values.name = '';
    values.isPublic = false;
    values.bookingMaxDurationInMinutes = 30;
    values.simultaneousBookingMaxCount = 1;
    values.rentMaxDurationInMinutes = 480;
    values.simultaneousRentMaxCount = 1;
    let touched = { ...this.state.touched };
    touched.name = false;
    touched.isPublic = false;
    let errors = { ...this.state.errors };
    touched.name = null;
    touched.isPublic = null;
    this.setState({
      values,
      touched,
      errors,
      isValid: false,
      submitError: null
    });
    this.props.handleClose();
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  render() {
    const { classes, fullScreen, isLoading, open, strings } = this.props;

    const { values, touched, errors, isValid, submitError } = this.state;

    const showNameError = touched.name && errors.name;
    // const showIsPublicError = touched.isPublic && errors.isPublic;

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        onClose={this.handleClose}
        open={open}
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {strings.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.subtitle}</DialogContentText>
          {isLoading && <LinearProgress className={classes.dialogLoader} />}
          <form autoComplete="off" className={classes.dialogForm}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  error={showNameError ? true : false}
                  helperText={showNameError ? strings.name.error : ''}
                  label={strings.name.label}
                  margin="normal"
                  name="name"
                  onChange={event =>
                    this.handleChange('name', event.target.value)
                  }
                  required
                  type="text"
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label={strings.bookingMaxDurationInMinutes.label}
                  margin="normal"
                  name="bookingMaxDurationInMinutes"
                  onChange={event =>
                    this.handleChange('bookingMaxDurationInMinutes', event.target.value)
                  }
                  required
                  type="number"
                  value={values.bookingMaxDurationInMinutes}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label={strings.simultaneousBookingMaxCount.label}
                  margin="normal"
                  name="simultaneousBookingMaxCount"
                  onChange={event =>
                    this.handleChange('simultaneousBookingMaxCount', event.target.value)
                  }
                  required
                  type="number"
                  value={values.simultaneousBookingMaxCount}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label={strings.rentMaxDurationInMinutes.label}
                  margin="normal"
                  name="rentMaxDurationInMinutes"
                  onChange={event =>
                    this.handleChange('rentMaxDurationInMinutes', event.target.value)
                  }
                  required
                  type="number"
                  value={values.rentMaxDurationInMinutes}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label={strings.simultaneousRentMaxCount.label}
                  margin="normal"
                  name="simultaneousRentMaxCount"
                  onChange={event =>
                    this.handleChange('simultaneousRentMaxCount', event.target.value)
                  }
                  required
                  type="number"
                  value={values.simultaneousRentMaxCount}
                  variant="outlined"
                />
                <FormControlLabel
                  className={classes.switchField}
                  control={
                    <Switch
                      checked={values.isDefective}
                      color="primary"
                      name="isDefective"
                      onChange={() =>
                        this.handleChange(
                          'isPublic',
                          !values.isPublic
                        )
                      }
                      value={values.isPublic}
                    />
                  }
                  label={strings.isPublic.label}
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {strings.errors.unknownError}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {strings.cancel}
          </Button>
          <Button color="primary" disabled={!isValid} onClick={this.handleAdd}>
            {strings.add}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddOrganizationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isLoading: state.organization.loading
  };
};

const mapDispatchToProps = {
  addOrganization
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('AddOrganizationDialog'),
  withMobileDialog(),
  withStyles(styles)
)(AddOrganizationDialog);
