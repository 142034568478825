import actions from './actions';

import * as contactFormService from 'services/contactForm';

// eslint-disable-next-line no-unused-vars
export const fetchMessages = () => async (dispatch, getState) => {

  dispatch({ type: actions.START_FETCH_MESSAGES });

  try {
    const messages = await contactFormService.getMessages();

    dispatch({
      type: actions.SET_MESSAGES,
      value: messages
    });

    dispatch({ type: actions.END_FETCH_MESSAGES });
  } catch (error) {
    dispatch({ type: actions.END_FETCH_MESSAGES });
  }
};

export const deleteMessages = (messagesToDelete, onFinished) => async (dispatch, getState) => {
  dispatch({ type: actions.START_DELETING_MESSAGES });

  try {
    
    const messages = await contactFormService.deleteMessages(messagesToDelete);

    dispatch({ 
      type: actions.SET_MESSAGES,
      value: messages
    });

    dispatch({ type: actions.END_DELETING_MESSAGES });
    onFinished();
  }
  catch (error) {
    dispatch({ type: actions.END_DELETING_MESSAGES });
    onFinished(error);
  }
}
