import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

// Material icons
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpen from '@material-ui/icons/MenuOpen';
import DomainIcon from '@material-ui/icons/DomainOutlined';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import SignoutIcon from '@material-ui/icons/ExitToAppOutlined';

// Shared services
import { getNotifications } from 'services/notification';

// Custom components
import LanguageSwitch from './components/LanguageSwitch';
import OrganizationSwitch from './components/OrganizationSwitch';
import * as Roles from 'common/roles';

// Actions
import { logout } from 'store/authentication/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

const ActiveChip = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
    border: '1px solid',
    color: theme.palette.success.dark
  }
}))(Chip);

const InactiveChip = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.danger.light,
    borderColor: theme.palette.danger.main,
    border: '1px solid',
    color: theme.palette.danger.dark
  }
}))(Chip);

class Topbar extends Component {
  signal = true;

  state = {
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null,
    languagesEl: null,
    organizationsEl: null
  };

  async getNotifications() {
    try {
      const { notificationsLimit } = this.state;

      const { notifications, notificationsCount } = await getNotifications(notificationsLimit);

      if (this.signal) {
        this.setState({
          notifications,
          notificationsCount
        });
      }
    } catch (error) {
      return;
    }
  }

  componentDidMount() {
    this.signal = true;
    this.getNotifications();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSignOut = () => {
    this.props.logout();
  };

  handleShowNotifications = (event) => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleShowLanguageSwitcher = (event) => {
    this.setState({
      languagesEl: event.currentTarget
    });
  };

  handleShowOrganizationSwitcher = (event) => {
    this.setState({
      organizationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = () => {
    this.setState({
      notificationsEl: null
    });
  };

  handleCloseLanguageSwitcher = () => {
    this.setState({
      languagesEl: null
    });
  };

  handleCloseOrganizationSwitcher = () => {
    this.setState({
      organizationsEl: null
    });
  };

  render() {
    const {
      classes,
      className,
      currentOrganization,
      isSidebarOpen,
      onToggleSidebar,
      title,
      strings,
      role
    } = this.props;

    const {
      // notifications,
      // notificationsCount,
      // notificationsEl,
      languagesEl,
      organizationsEl
    } = this.state;

    const rootClassName = classNames(classes.root, className);
    // const showNotifications = Boolean(notificationsEl);
    const showLanguages = Boolean(languagesEl);
    const showOrganizations = Boolean(organizationsEl);

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            {/* Left side */}
            <IconButton
              className={classes.menuButton}
              onClick={onToggleSidebar}
              title={isSidebarOpen ? strings.closeMenu : strings.openMenu}
              variant="text"
            >
              {isSidebarOpen ? <MenuOpen /> : <MenuIcon />}
            </IconButton>
            <Typography className={classes.title} variant="h4">
              {title}
            </Typography>

            {/* Right side */}
            <Typography className={classes.organizationsText} variant="body2">
              {currentOrganization ? currentOrganization.name : ''}
            </Typography>
            {(role === Roles.Admin || role === Roles.Operator) && (
              <IconButton
                className={classes.organizationsButton}
                onClick={this.handleShowOrganizationSwitcher}
                title={strings.organizations}
              >
                <DomainIcon />
              </IconButton>
            )}
            {currentOrganization ? (
              currentOrganization.isEnable ? (
                <ActiveChip label="active" color="primary" variant="default" className={classes.organizationsStatus} />
              ) : (
                <InactiveChip
                  label="inactive"
                  color="primary"
                  variant="default"
                  className={classes.organizationStatus}
                />
              )
            ) : null}
            <IconButton
              className={classes.languagesButton}
              onClick={this.handleShowLanguageSwitcher}
              title={strings.languages}
            >
              <LanguageIcon />
            </IconButton>
            {/* <IconButton
              className={classes.notificationsButton}
              onClick={this.handleShowNotifications}
              title={strings.notifications}>
              <Badge
                badgeContent={notificationsCount}
                color="primary"
                variant="dot">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton className={classes.signOutButton} onClick={this.handleSignOut} title={strings.signout}>
              <SignoutIcon />
            </IconButton>
          </Toolbar>
        </div>
        <Popover
          anchorEl={organizationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseOrganizationSwitcher}
          open={showOrganizations}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <OrganizationSwitch />
        </Popover>
        <Popover
          anchorEl={languagesEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseLanguageSwitcher}
          open={showLanguages}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <LanguageSwitch />
        </Popover>
        {/* <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}>
          <NotificationList
            notifications={notifications}
            onSelect={this.handleCloseNotifications}
          />
        </Popover> */}
      </Fragment>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  onToggleSidebar: PropTypes.func,
  strings: PropTypes.object.isRequired,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organization.currentOrganization,
    user: state.authentication.user,
    role: state.authorize.role
  };
};

const mapDispatchToProps = {
  logout
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
  translate('Topbar')
)(Topbar);
