import actions from './actions';

const initState = {
  loading: false,
  inviteLoading: false,
  deleteLoading: false,
  users: [],
  user: {},
  invitations: [],
  paymentSource: {}
};

const reducer = (state = initState, action) => {
  console.log(action.type);
  switch (action.type) {
    case actions.START_FETCH_INVITATIONS:
      return { ...state, loading: true };
    case actions.END_FETCH_INVITATIONS:
      return { ...state, loading: false };
    case actions.START_DELETING_INVITATIONS:
      return { ...state, deleteLoading: true };
    case actions.END_DELETING_INVITATIONS:
      return { ...state, deleteLoading: false };
    case actions.START_FETCH_USERS:
      return { ...state, loading: true };
    case actions.END_FETCH_USERS:
      return { ...state, loading: false };
    case actions.START_DELETING_USERS:
      return { ...state, loading: true };
    case actions.END_DELETING_USERS:
      return { ...state, loading: false };
    case actions.START_INVITE_USER:
      return { ...state, inviteLoading: true };
    case actions.END_INVITE_USER:
      return { ...state, inviteLoading: false };
    case actions.START_FETCH_USER:
      return { ...state, loading: true };
    case actions.END_FETCH_USER:
      return { ...state, loading: false };
    case actions.START_UPDATE_USER:
      return { ...state, loading: true };
    case actions.END_UPDATE_USER:
      return { ...state, loading: false };
    case actions.START_UPDATE_USERPROFILE:
      return { ...state, loading: true };
    case actions.END_UPDATE_USERPROFILE:
      return { ...state, loading: false };
    case actions.START_FETCH_PAYMENTINFO:
      return { ...state, loading: true };
    case actions.END_FETCH_PAYMENTINFO:
      return { ...state, loading: false };
    case actions.START_UPDATE_PAYMENTINFO:
      return { ...state, loading: true };
    case actions.END_UPDATE_PAYMENTINFO:
      return { ...state, loading: false };
    case actions.START_DELETE_PAYMENTINFO:
      return { ...state, loading: true };
    case actions.END_DELETE_PAYMENTINFO:
      return { ...state, loading: false };
    case actions.SET_INVITATIONS:
      return { ...state, invitations: action.value };
    case actions.SET_USERS:
      return { ...state, users: action.value };
    case actions.SET_USER:
      return { ...state, user: action.value };
    case actions.SET_PAYMENTINFO:
      return { ...state, paymentSource: action.value };
    default:
      return state;
  }
};

export default reducer;
