const actions = {
  START_FETCH_PROFILE: 'authentication/START_FETCH_PROFILE',
  END_FETCH_PROFILE: 'authentication/END_FETCH_PROFILE',
  START_SIGN_IN: 'authentication/START_SIGN_IN',
  END_SIGN_IN: 'authentication/END_SIGN_IN',
  START_SIGN_UP: 'authentication/START_SIGN_UP',
  END_SIGN_UP: 'authentication/END_SIGN_UP',
  START_SIGN_OUT: 'authentication/START_SIGN_OUT',
  END_SIGN_OUT: 'authentication/END_SIGN_OUT',
  START_VERIFY: 'authentication/START_VERIFY',
  END_VERIFY: 'authentication/END_VERIFY',
  SET_PROFILE: 'authentication/SET_PROFILE',
  SET_UNAUTHENTICATED: 'authentication/SET_UNAUTHENTICATED'
};

export default actions;
