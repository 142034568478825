import React, { Component } from 'react';
import { connect } from 'react-redux';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// Material components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Material icons
import Add from '@material-ui/icons/Add';

// Shared services
import { addBikeType } from 'store/bikeType/actionsCreator';
import { fetchPricingPlans } from 'store/pricingPlan/actionsCreator';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

class AddBikeTypeDialog extends Component {
  signal = true;

  state = {
    values: {
      name: '',
      description: '',
      pricingPlan: ''
    },
    touched: {
      name: false,
      pricingPlan: false
    },
    errors: {
      name: null,
      pricingPlan: null
    },
    isValid: false,
    submitError: null
  };

  componentDidMount() {
    this.signal = true;
    this.props.fetchPricingPlans();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentOrganization !== prevProps.currentOrganization) {
      this.props.fetchPricingPlans();
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };
  
  handleAdd = async () => {
    const { values } = this.state;

    await this.props.addBikeType(values, error => {
      if (error) {
        this.setState({
          submitError: error
        });
      } else {
        let values = { ...this.state.values };
        values.name = '';
        values.description = '';
        values.pricingPlan = '';
        let touched = { ...this.state.touched };
        touched.name = false;
        touched.pricingPlan = false;
        let errors = { ...this.state.errors };
        errors.name = null;
        errors.pricingPlan = null;
        this.setState({
          values,
          touched,
          errors,
          isValid: false,
          submitError: null
        });
        this.props.handleClose();
      }
    });
  };

  handleClose = () => {
    let values = { ...this.state.values };
    values.name = '';
    values.description = '';
    values.pricingPlan = '';
    let touched = { ...this.state.touched };
    touched.name = false;
    touched.pricingPlan = false;
    let errors = { ...this.state.errors };
    errors.name = null;
    errors.pricingPlan = null;
    this.setState({
      values,
      touched,
      errors,
      isValid: false,
      submitError: null
    });
    this.props.handleClose();
  };

  render() {
    const {
      classes,
      fullScreen,
      isBikeTypeLoading,
      isPricingPlanLoading,
      open,
      openAddPricingPlanDialog,
      pricingPlans,
      strings
    } = this.props;

    const { values, touched, errors, isValid, submitError } = this.state;

    const showNameError = touched.name && errors.name;
    const showPricingPlanError = touched.pricingPlan && errors.pricingPlan;

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        onClose={this.handleClose}
        open={open}
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {strings.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.subtitle}</DialogContentText>
          {isBikeTypeLoading && (
            <LinearProgress className={classes.dialogLoader} />
          )}
          <form autoComplete="off" className={classes.dialogForm}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  error={showNameError ? true : false}
                  helperText={showNameError ? strings.name.error : ''}
                  label={strings.name.label}
                  margin="normal"
                  name="name"
                  onChange={event =>
                    this.handleChange('name', event.target.value)
                  }
                  required
                  type="text"
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label={strings.description.label}
                  margin="dense"
                  multiline
                  name="description"
                  onChange={event =>
                    this.handleChange('description', event.target.value)
                  }
                  rows="3"
                  rowsMax="3"
                  type="text"
                  value={values.description}
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    endAdornment: isPricingPlanLoading ? (
                      <InputAdornment position="end">
                        <CircularProgress />
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton onClick={openAddPricingPlanDialog}>
                          <Add />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  SelectProps={{
                    MenuProps: {}
                  }}
                  className={classes.textField}
                  disabled={isPricingPlanLoading}
                  error={showPricingPlanError ? true : false}
                  helperText={
                    showPricingPlanError ? strings.pricingPlan.error : ''
                  }
                  label={strings.pricingPlan.label}
                  margin="normal"
                  name="pricingPlan"
                  onChange={event =>
                    this.handleChange('pricingPlan', event.target.value)
                  }
                  required
                  select
                  type="text"
                  value={values.pricingPlan}
                  variant="outlined"
                >
                  {pricingPlans.map(option => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {strings.errors.unknownError}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {strings.cancel}
          </Button>
          <Button color="primary" disabled={!isValid} onClick={this.handleAdd}>
            {strings.add}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddBikeTypeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openAddPricingPlanDialog: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentOrganization: state.organization.currentOrganization,
    isBikeTypeLoading: state.bikeType.loading,
    isPricingPlanLoading: state.pricingPlan.loading,
    pricingPlans: state.pricingPlan.pricingPlans
  };
};

const mapDispatchToProps = {
  addBikeType,
  fetchPricingPlans
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('AddBikeTypeDialog'),
  withMobileDialog(),
  withStyles(styles)
)(AddBikeTypeDialog);
