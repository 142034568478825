export default (theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh'
  },
  refreshWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  refreshImage: {
    width: '250px'
  },
  refreshProgress: {
    marginTop: theme.spacing.unit * 2,
    width: '180px'
  }
});
