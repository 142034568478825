const actions = {
  START_FETCH_INVITATIONS: 'user/START_FETCH_INVITATIONS',
  END_FETCH_INVITATIONS: 'user/END_FETCH_INVITATIONS',
  START_DELETING_INVITATIONS : 'user/START_DELETING_INVITATIONS',
  END_DELETING_INVITATIONS : 'user/END_DELETING_INVITATIONS',
  START_FETCH_USERS: 'user/START_FETCH_USERS',
  END_FETCH_USERS: 'user/END_FETCH_USERS',
  START_DELETING_USERS: 'user/START_DELETING_USERS',
  END_DELETING_USERS: 'user/END_DELETING_USERS',
  START_INVITE_USER: 'user/START_INVITE_USER',
  END_INVITE_USER: 'user/END_INVITE_USER',
  START_FETCH_USER: 'user/START_FETCH_USER',
  END_FETCH_USER: 'user/END_FETCH_USER',
  START_UPDATE_USER : 'user/START_UPDATE_USER',
  END_UPDATE_USER : 'user/END_UPDATE_USER',
  START_UPDATE_USERPROFILE : 'user/START_UPDATE_USERPROFILE',
  END_UPDATE_USERPROFILE : 'user/END_UPDATE_USERPROFILE',
  START_FETCH_PAYMENTINFO: 'user/START_FETCH_PAYMENTINFO',
  END_FETCH_PAYMENTINFO: 'user/END_FETCH_PAYMENTINFO',
  START_UPDATE_PAYMENTINFO: 'user/START_UPDATE_PAYMENTINFO',
  END_UPDATE_PAYMENTINFO: 'user/END_UPDATE_PAYMENTINFO',
  START_DELETE_PAYMENTINFO: 'user/START_DELETE_PAYMENTINFO',
  END_DELETE_PAYMENTINFO: 'user/END_DELETE_PAYMENTINFO',
  SET_INVITATIONS: 'user/SET_INVITATIONS',
  SET_USERS: 'user/SET_USERS',
  SET_USER: 'user/SET_USER',
  SET_PAYMENTINFO: 'user/SET_PAYMENTINFO'
};

export default actions;
