import React from 'react';

// Externals
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const BikesMap = compose(
  withProps({
    googleMapURL:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyA6jQzIU1OcQ7UWfmyOnq-o000grQ03ySo&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    center={
      props.markers[0] &&
      props.markers[0].lastKnownLocation ? {
          lat: props.markers[0].lastKnownLocation.location.coordinates[0],
          lng: props.markers[0].lastKnownLocation.location.coordinates[1]
        } : {
          lat: 50.85045,
          lng: 4.34878
        }
    }
    defaultZoom={ 
      props.marker &&
      props.marker.lastKnownLocation ? 15 : 8
    }
  >
    {props.markers &&
      props.markers.map(
        marker =>
          marker.lastKnownLocation && (
            <Marker
              key={marker._id}
              position={{
                lat: marker.lastKnownLocation.location.coordinates[0],
                lng: marker.lastKnownLocation.location.coordinates[1]
              }}
            />
          )
      )}
  </GoogleMap>
));

export default BikesMap;
