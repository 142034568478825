import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Grid from '@material-ui/core/Grid';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Translations
import translate from 'helpers/translate';

// Custom components
// import UsersCard from './components/UsersCard';
// import ProfitCard from './components/ProfitCard';
import RentsChart from './components/RentsChart';
import UsersChart from './components/UsersChart';

// Component styles
import styles from './styles';

class Dashboard extends Component {
  render() {
    const { classes, strings } = this.props;

    return (
      <DashboardLayout title={strings.title}>
        <div className={classes.root}>
          <Grid container spacing={32}>
            {/* <Grid item lg={6} sm={6} xl={6} xs={12}>
              <UsersCard />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <ProfitCard />
            </Grid> */}
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <RentsChart className={classes.item} />
            </Grid>
            <Grid item lg={4} md={12} xl={3} xs={12}>
              <UsersChart className={classes.item} />
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Dashboard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired
};

export default compose(
  translate('Dashboard'),
  withStyles(styles)
)(Dashboard);
